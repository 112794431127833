// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/cssWithMappingToString.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".BatchSelector-module__warning-selected-item--1PHz0 .ant-select-selector{color:red;background-color:#ffccc7 !important}.BatchSelector-module__default-selected-item--2MQL5 .ant-select-selector{color:#000}.BatchSelector-module__batch-selector--Pyd3Z{width:100%;display:flex}.BatchSelector-module__batch-selector--Pyd3Z>div:first-child{width:100%}.BatchSelector-module__container--1NbxF{display:flex;flex-direction:row}.BatchSelector-module__container--1NbxF>*{flex-basis:50%}", "",{"version":3,"sources":["webpack://./src/components/Selectors/BatchSelector/BatchSelector.module.css"],"names":[],"mappings":"AACE,yEACE,SAAA,CACA,mCAAA,CAKF,yEACE,UAAA,CAIJ,6CACE,UAAA,CACA,YAAA,CACA,6DACE,UAAA,CAIJ,wCACE,YAAA,CACA,kBAAA,CACA,0CACE,cAAA","sourcesContent":[".warning-selected-item {\n  :global(.ant-select-selector) {\n    color: red;\n    background-color: #ffccc7 !important;\n  }\n}\n\n.default-selected-item {\n  :global(.ant-select-selector) {\n    color: black;\n  }\n}\n\n.batch-selector {\n  width: 100%;\n  display: flex;\n  > div:first-child {\n    width: 100%;\n  }\n}\n\n.container {\n  display: flex;\n  flex-direction: row;\n  & > * {\n    flex-basis: 50%;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"warning-selected-item": "BatchSelector-module__warning-selected-item--1PHz0",
	"default-selected-item": "BatchSelector-module__default-selected-item--2MQL5",
	"batch-selector": "BatchSelector-module__batch-selector--Pyd3Z",
	"container": "BatchSelector-module__container--1NbxF"
};
export default ___CSS_LOADER_EXPORT___;
