import { Alert, Button, Modal, Spin } from "antd";
import React, { useState } from "react";
import styles from "./MajorActionButton.module.css";

interface MajorActionButtonProps {
  action: () => Promise<void>;
  loading: boolean;
  actionTitle: string;
  actionMessage: string;
  actionConfirmation: string;
}

export const MajorActionButton = (props: MajorActionButtonProps) => {
  const { action, actionTitle, actionMessage, loading, actionConfirmation } =
    props;
  const [modalVisible, setModalVisible] = useState(false);

  const onApply = async () => {
    await action();
    setModalVisible(false);
  };
  const onCancel = () => setModalVisible(false);

  if (loading) {
    return (
      <div className={styles.centered}>
        <Spin />
      </div>
    );
  }
  return (
    <div className={styles.container}>
      <Button
        value="release"
        onClick={() => setModalVisible(true)}
        type="primary"
        size="large"
      >
        {actionTitle}
      </Button>
      <Modal
        visible={modalVisible}
        title={`Action Confirmation`}
        onCancel={onCancel}
        footer={[
          <Button key="back" onClick={onCancel}>
            Cancel
          </Button>,
          <Button key="submit" type="primary" onClick={onApply}>
            Proceed
          </Button>,
        ]}
      >
        <Alert
          type="warning"
          showIcon
          message={actionMessage}
          description={<p>{actionConfirmation}</p>}
        />
      </Modal>
    </div>
  );
};
