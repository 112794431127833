import React, { useState } from "react";
import { useFloorplan } from "../../components/FloorplanEditor/hooks/useFloorplan";
import { SequenceModeWrapper } from "../../components/SequenceMode/SequenceModeWrapper";
import { SpatialConfigMode } from "../../components/SpatialConfigurationMode/SpatialConfigurationMode";
import { useScenes } from "../../components/SpatialConfigurationMode/hooks/useScenes";
import { SpatialConfigHeaderBar } from "./SpatialConfigHeaderBar";

export enum ActiveMode {
  DEFAULT,
  SEQUENCE,
}

export const SpatialConfigPage = () => {
  const [activeMode, setActiveMode] = useState<ActiveMode>(ActiveMode.DEFAULT);

  const floorplansEditor = useFloorplan();
  const scenesEditor = useScenes(floorplansEditor.floorplan?.space.id);

  const HeaderBar: React.FunctionComponent = ({ children }) => {
    const customButtons = <React.Fragment>{children}</React.Fragment>;

    return (
      <SpatialConfigHeaderBar
        activeMode={activeMode}
        setActiveMode={setActiveMode}
        floorplanIdToSpaceId={floorplansEditor.floorplanIdToSpaceId}
        selectedFloorlanId={floorplansEditor.floorplan?.id}
        onSelectFloorplanId={floorplansEditor.onChange}
        customButtons={customButtons}
      />
    );
  };

  return (
    <>
      {activeMode === ActiveMode.SEQUENCE && (
        <SequenceModeWrapper
          floorplan={floorplansEditor.floorplan}
          scenes={Object.values(scenesEditor.scenes)}
          HeaderBarWrapper={HeaderBar}
        />
      )}
      <SpatialConfigMode
        floorplansEditor={floorplansEditor}
        scenesEditor={scenesEditor}
        isInvisible={activeMode === ActiveMode.SEQUENCE}
        HeaderBarWrapper={HeaderBar}
      />
    </>
  );
};
