import { Button } from "antd";
import React from "react";
import { useHistory } from "react-router";
import { useAuth } from "../contexts/Auth";
import styles from "./Page.module.css";

const SignInPage = () => {
  const auth = useAuth();
  const history = useHistory();
  if (auth.status === "loading" || auth.status === "error") {
    return <div />;
  }
  if (auth.status === "authenticated") {
    return (
      <div>
        <p>{"You are already logged in, so you shouldn't really be here..."}</p>
        <button onClick={() => history.push("/")}>Return Home</button>
      </div>
    );
  }
  return (
    <div className={styles.page}>
      <Button onClick={auth.login}>Sign In</Button>
    </div>
  );
};

export default SignInPage;
