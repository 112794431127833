// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/cssWithMappingToString.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".DrawingTool-module__colors-legend-box--aJH-t{position:absolute;bottom:10px;left:360px;background-color:#fff;min-width:fit-content;min-height:fit-content;width:120px;padding:10px;display:flex;flex-direction:column;gap:10px;border:1px solid gray;border-radius:5%}.DrawingTool-module__colors-legend-row--ywiUk{display:flex;justify-content:space-between;align-items:center}.DrawingTool-module__colors-legend-name--a3KBF{font-size:14px}.DrawingTool-module__colors-legend-circle--PshRw{height:14px;width:14px;border-radius:50%}", "",{"version":3,"sources":["webpack://./src/components/DrawingTool/DrawingTool.module.css"],"names":[],"mappings":"AAAA,8CACI,iBAAA,CACA,WAAA,CACA,UAAA,CACA,qBAAA,CACA,qBAAA,CACA,sBAAA,CACA,WAAA,CACA,YAAA,CACA,YAAA,CACA,qBAAA,CACA,QAAA,CACA,qBAAA,CACA,gBAAA,CAGJ,8CACI,YAAA,CACA,6BAAA,CACA,kBAAA,CAGJ,+CACI,cAAA,CAGJ,iDACG,WAAA,CACA,UAAA,CACA,iBAAA","sourcesContent":[".colors-legend-box {\n    position: absolute;\n    bottom: 10px;\n    left: 360px;\n    background-color: white;\n    min-width: fit-content;\n    min-height: fit-content;\n    width: 120px;\n    padding: 10px;\n    display: flex;\n    flex-direction: column;\n    gap: 10px;\n    border: 1px solid gray;\n    border-radius: 5%;\n}\n\n.colors-legend-row {\n    display: flex;\n    justify-content: space-between;\n    align-items: center;\n}\n\n.colors-legend-name {\n    font-size: 14px;\n}\n\n.colors-legend-circle {\n   height: 14px;\n   width: 14px;\n   border-radius: 50%;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"colors-legend-box": "DrawingTool-module__colors-legend-box--aJH-t",
	"colors-legend-row": "DrawingTool-module__colors-legend-row--ywiUk",
	"colors-legend-name": "DrawingTool-module__colors-legend-name--a3KBF",
	"colors-legend-circle": "DrawingTool-module__colors-legend-circle--PshRw"
};
export default ___CSS_LOADER_EXPORT___;
