import { Spin } from "antd";
import _ from "lodash";
import React, { useMemo } from "react";
import { useProject } from "../../../App/state";
import { useGetComponentsExcludingPlanQuery } from "../../../generated/types";
import { Component } from "../../../types";
import { getComponentFromGraphQlResponse } from "../types";
import { ComponentSelector } from "./ComponentSelector";

type ComponentSelectorContainerProps = {
  planId: string;
  onChange?: (value?: Component) => void;
};

export const ComponentSelectorContainer = (
  props: ComponentSelectorContainerProps,
) => {
  const project = useProject();

  const { data, loading, error } = useGetComponentsExcludingPlanQuery({
    variables: {
      ...project,
      planIdToExclude: props.planId,
    },
  });

  const components = useMemo(() => {
    if (data?.componentsExcludingPlan != null) {
      return data.componentsExcludingPlan
        .map((c) => getComponentFromGraphQlResponse(c))
        .sort((c1, c2) => (c1.name > c2.name ? 1 : -1));
    } else {
      return null;
    }
  }, [data?.componentsExcludingPlan]);
  if (loading) {
    return <Spin />;
  }

  if (error || !data || !components) {
    return <div> Error fetching components </div>;
  }

  return (
    <ComponentSelector
      components={components}
      onSelect={(componentId) =>
        props.onChange &&
        props.onChange(_.find(components, (c) => c.id === componentId))
      }
    />
  );
};
