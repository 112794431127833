export const DELETE_COMPONENTS_SUCCESS = "Components deleted!";
export const DELETE_COMPONENTS_ERROR = "Failed to delete components";
export const REMOVE_COMPONENTS_FROM_TASK_SUCCESS =
  "Components removed from task!";
export const REMOVE_COMPONENTS_FROM_TASK_ERROR =
  "Failed to remove components from task";
export const CREATE_COMPONENT_SUCCESS = "Component created!";
export const CREATE_COMPONENT_ERROR = "Failed to create component";
export const ASSIGN_COMPONENT_SUCCESS = "Component assigned successfuly!";
export const ASSIGN_COMPONENT_ERROR = "Failed to assign component";
export const UPDATE_COMPONENTS_SUCCESS = "Components updated!";
export const UPDATE_COMPONENTS_ERROR = "Failed to update components";
export const CREATE_COMPONENT_TYPE_SUCCESS = "Component type created!";
export const CREATE_COMPONENT_TYPE_ERROR = "Failed to create component type";
export const CREATE_SUBCONTRACTOR_SUCCESS = "Subcontractor created!";
export const CREATE_SUBCONTRACTOR_ERROR = "Failed to create subcontractor";
