import { Button, Card } from "antd";
import { format } from "date-fns";
import _ from "lodash";
import { ScanSequenceLevelType } from "models";
import React from "react";
import xlsx from "xlsx";
import { useNotification } from "../../contexts/Notifications";
import {
  DumpSheetSceneData,
  useGenerateSceneSpacesMappingsLazyQuery,
} from "../../generated/types";
import { Project } from "../../types";

export const DEFAULT_WORKBOOK_NAME = "Scene-Object Mapping";

interface SceneSpaceMappingsGeneratorProps {
  project: Project;
}

const toFilename = (project: Project) => {
  // use a custom date format instead of ISO to avoid dots in the filename
  const dateString = format(new Date(), "yyyyMMdd_HHmmss");
  return `${project.customer}-${project.project}-${dateString}-dump.xlsx`;
};

export type SceneData = {
  sceneShell360Id: string;
  sceneName: string;
  ancestorShell360Ids: (string | null)[];
  ancestorNames: string[];
  sceneLocation: string;
  floorName?: string | null;
  scanLevel?: string | null;
  sequenceNumber?: string | null;
  sceneAliasShell360Id?: string | null;
  sceneIdsAliasing: string[];
  sceneLocationsAliasing: string[];
};

enum ScanSequenceLevelTypeAdittional {
  Unsorted = "UNSORTED",
}

type ScanSequenceLevelTypeWithUnsorted =
  | ScanSequenceLevelTypeAdittional
  | ScanSequenceLevelType;

const getScenesDataPerScanningPhase = (
  dumpScenesData: DumpSheetSceneData[],
) => {
  const scenesPerScanningPhase: Record<
    ScanSequenceLevelTypeWithUnsorted,
    SceneData[]
  > = {
    [ScanSequenceLevelType.Early]: [],
    [ScanSequenceLevelType.Mid]: [],
    [ScanSequenceLevelType.Late]: [],
    [ScanSequenceLevelTypeAdittional.Unsorted]: [],
  };
  const scenesByAlias = _.groupBy(dumpScenesData, "sceneAliasShell360Id");
  dumpScenesData.forEach((s) => {
    scenesPerScanningPhase[
      s.scanLevel ?? ScanSequenceLevelTypeAdittional.Unsorted
    ].push({
      ...s,
      sceneIdsAliasing: scenesByAlias[s.sceneShell360Id]
        ? scenesByAlias[s.sceneShell360Id].map((as) => as.sceneShell360Id)
        : [],
      sceneLocationsAliasing: scenesByAlias[s.sceneShell360Id]
        ? scenesByAlias[s.sceneShell360Id].map((as) => as.sceneLocation)
        : [],
    });
  });
  return scenesPerScanningPhase;
};

const generateColumnNamesForAncestorNames = (
  scenesData: SceneData[],
): string[] => {
  const sceneWithMaxAncestorsNumer = _.maxBy(
    scenesData,
    (sceneData) => sceneData.ancestorShell360Ids.length,
  );
  if (
    _.maxBy(scenesData, (sceneData) => sceneData.ancestorNames.length) !==
    sceneWithMaxAncestorsNumer
  ) {
    throw new Error(
      `Incorrect data recieved: number of ancestor ids ${sceneWithMaxAncestorsNumer?.ancestorShell360Ids.length} doesn't correspond to the number of ancesto names - scene ${sceneWithMaxAncestorsNumer?.sceneShell360Id}`,
    );
  }
  const maxAncestorsNumber =
    sceneWithMaxAncestorsNumer?.ancestorShell360Ids.length ?? 0;
  const ancestorColumns = _.range(maxAncestorsNumber - 1) // First parent column has different name
    .map((_c, idx) => `P${idx + 2} Name`);
  return ancestorColumns;
};

const spaceOutRowDataToMatchColumns = (
  ancestorColumns: string[],
  sceneAliasesColumnsLength: number,
  scenesData: SceneData[],
) => {
  return scenesData.map((sceneData) => [
    sceneData.sceneName,
    ..._.zipWith(
      ancestorColumns,
      sceneData.ancestorNames,
      (_c, name) => name ?? "",
    ),
    sceneData.sceneLocation,
    sceneData.sceneShell360Id,
    sceneData.floorName,
    sceneData.scanLevel,
    sceneData.sequenceNumber,
    sceneData.sceneAliasShell360Id,
    ..._.flatMap(
      _.range(sceneAliasesColumnsLength).map((idx) => [
        sceneData.sceneIdsAliasing[idx] ?? "",
        sceneData.sceneLocationsAliasing[idx] ?? "",
      ]),
    ),
    ...sceneData.ancestorShell360Ids,
    sceneData.ancestorShell360Ids.length < ancestorColumns.length
      ? "#"
      : undefined,
  ]);
};

export const generateWorkbook = (dumpScenesData: DumpSheetSceneData[]) => {
  const workbook = xlsx.utils.book_new();
  const scenesDataPerScanningPhase =
    getScenesDataPerScanningPhase(dumpScenesData);
  Object.entries(scenesDataPerScanningPhase).forEach(([phase, data]) => {
    const sheetName =
      phase === ScanSequenceLevelTypeAdittional.Unsorted
        ? DEFAULT_WORKBOOK_NAME
        : phase;
    const ancestorNamesColumns = generateColumnNamesForAncestorNames(data);
    const maxNumAliasingScenes = Math.max(
      ...data.map((d) => d.sceneIdsAliasing.length),
      1,
    );
    const worksheetContent = spaceOutRowDataToMatchColumns(
      ["", ...ancestorNamesColumns],
      maxNumAliasingScenes,
      data,
    );
    const worksheet = xlsx.utils.aoa_to_sheet([
      [
        "Scene Name",
        "Parent Name",
        ...ancestorNamesColumns,
        "Scene Location",
        "Scene ID",
        "Floor Name",
        "Scan Level",
        "Sequence Number",
        "Alias Scene ID",
        ..._.flatMap(
          _.range(maxNumAliasingScenes).map((idx) => [
            `Scene IDs Aliasing ${idx + 1}`,
            `Scene Locations Aliasing ${idx + 1}`,
          ]),
        ),
        "Parent ID",
        ...ancestorNamesColumns.map((_c, idx) => `P${idx + 2} ID`),
      ],
      ...worksheetContent,
    ]);
    xlsx.utils.book_append_sheet(workbook, worksheet, sheetName);
  });
  return workbook;
};

export const SceneSpaceMappingsGenerator = (
  props: SceneSpaceMappingsGeneratorProps,
) => {
  const { project } = props;
  const { project: proj, customer, scope } = project;
  const notify = useNotification();
  const [generateDumpQuery, { data }] = useGenerateSceneSpacesMappingsLazyQuery(
    {
      onError: (error) =>
        notify(
          `Failed to generate dump sheet for scene-objects mapping: ${error}`,
          "error",
        ),
      fetchPolicy: "no-cache", // let the User decide when data has changed
    },
  );

  React.useEffect(() => {
    if (data?.generateDumpSheetData && data.generateDumpSheetData.length > 0) {
      try {
        const workbook = generateWorkbook(data.generateDumpSheetData);
        xlsx.writeFile(workbook, toFilename(project));
      } catch (error) {
        notify(`Failed to generate csv: ${error}`, "error");
      }
    }
  }, [data, notify, project]);

  const onApply = async () => {
    await generateDumpQuery({
      variables: {
        customer: customer,
        project: proj,
        scope: scope,
      },
    });
  };

  return (
    <Card>
      <Button value="generate" onClick={onApply}>
        Generate Dump Sheet with Scene-Object Mapping
      </Button>
    </Card>
  );
};
