import React, { PropsWithChildren } from "react";
import { useDrop } from "react-dnd";
import { DraggableItemType } from "./types";

type DropTargetProps<T> = {
  itemTypes: DraggableItemType[];
  onItemDropped: Record<string, (item: T) => void>;
  className: string;
};

export const DropTarget = <T,>(
  props: PropsWithChildren<DropTargetProps<T>>,
) => {
  const [, drop] = useDrop(
    () => ({
      accept: props.itemTypes,
      drop: (item: T, monitor) => {
        const type = monitor.getItemType() as DraggableItemType | null;
        type && props.onItemDropped[type] && props.onItemDropped[type](item);
      },
    }),
    [props.onItemDropped],
  );

  return (
    <div className={props.className} ref={drop}>
      {props.children}
    </div>
  );
};
