import { Empty } from "antd";
import { Card, Space, Switch } from "antd";
import classNames from "classnames";
import React, { useState } from "react";
import { Project } from "../../types";
import { TaskCard } from "../TaskCard";
import styles from "./ComponentViewer.module.css";
import { ComponentsTableContainer } from "./ComponentsTableContainer";
import { UnassignedComponentsTableContainer } from "./UnassignedComponentsTableContainer";

type ComponentViewerProps = {
  project: Project;
  taskId?: string;
  planId?: string;
};

export const ComponentViewer = (props: ComponentViewerProps) => {
  const { project, taskId, planId } = props;
  return (
    <Space direction="vertical" className={styles.space}>
      {taskId && <TaskCard project={project} taskId={taskId} />}
      <ComponentsCard project={project} taskId={taskId} planId={planId} />
    </Space>
  );
};

type ComponentsCardProps = {
  project: Project;
  taskId?: string;
  planId?: string;
};

const ComponentsCard = ({ project, taskId, planId }: ComponentsCardProps) => {
  const [componentsForTask, setComponentsForTask] = useState(true);

  return (
    <Card
      title="Components"
      extra={
        <span className={styles.toggle}>
          <span
            className={classNames({
              [styles.toggle_label_enabled]: !componentsForTask,
            })}
          >
            Unassigned{" "}
          </span>
          <Switch checked={componentsForTask} onChange={setComponentsForTask} />
          <span
            className={classNames({
              [styles.toggle_label_enabled]: componentsForTask,
            })}
          >
            Task specific
          </span>
        </span>
      }
    >
      {componentsForTask ? (
        taskId && planId ? (
          <ComponentsTableContainer
            project={project}
            taskId={taskId}
            planId={planId}
          />
        ) : (
          <Empty description="No task selected to show components"></Empty>
        )
      ) : (
        <UnassignedComponentsTableContainer project={project} />
      )}
    </Card>
  );
};
