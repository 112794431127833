import { CopyOutlined } from "@ant-design/icons";
import { Button, ButtonProps } from "antd";
import React from "react";
import { CopyToClipboard } from "react-copy-to-clipboard";
import { useNotification } from "../../contexts/Notifications";

interface IndexProps {
  text: string;
  className?: string;
  buttonText?: string;
  buttonType?: ButtonProps["type"];
}

export const CopyToClipboardButton = ({
  text,
  className,
  buttonText,
  buttonType = "text",
}: IndexProps) => {
  const notify = useNotification();
  const onCopy = () => {
    notify(`${text} copied to clipboard`);
  };

  return (
    <span className={className} onClick={(e) => e.stopPropagation()}>
      <CopyToClipboard text={text} onCopy={onCopy}>
        <Button
          title={buttonText}
          icon={<CopyOutlined />}
          type={buttonType}
          size={"small"}
        >
          {buttonText}
        </Button>
      </CopyToClipboard>
    </span>
  );
};
