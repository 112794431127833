// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/cssWithMappingToString.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".pageStyles-module__project-status-container--38kKw{padding:24px}", "",{"version":3,"sources":["webpack://./src/pages/pageStyles.module.css"],"names":[],"mappings":"AAAA,oDACI,YAAA","sourcesContent":[".project-status-container {\n    padding: 24px;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"project-status-container": "pageStyles-module__project-status-container--38kKw"
};
export default ___CSS_LOADER_EXPORT___;
