import _ from "lodash";
import React, { useCallback, useEffect, useMemo, useState } from "react";
import { useProject } from "../../App/state";
import { findClosestNodeId } from "../../components/DrawingTool/drawing-utils";
import { SequenceModePanel } from "../../components/SequenceMode/SequenceModePanel";
import { useNotification } from "../../contexts/Notifications";
import {
  ScanSequencesByFilterDocument,
  useCreateScanSequenceMutation,
  useScanSequencesByFilterQuery,
  useUpdateScanSequenceMutation,
} from "../../generated/types";
import { useKeyEvent } from "../../pages/SpatialConfigModePage/hooks/useKeyEvent";
import {
  Floorplan,
  ScanSequenceLevelType,
  ScanSequencePrefixType,
  Scene,
  Sequence,
} from "../SpatialConfigurationMode/types";
import {
  isSceneWithCoordinates,
  transformSequenceFromGqlResponse,
} from "../SpatialConfigurationMode/utils";
import { ColorModeSwitch } from "./ColorModeSwitch";
import styles from "./SequenceMode.module.css";
import { DrawingToolContainer } from "./SequenceModeDrawingToolContainer";

const SEQUENCE_MODE_MODIFIER = "s";

export enum ColorMode {
  SCENE_TYPES = "Scene types",
  SCANNING_STAGE = "Scanning stage",
}
export interface SequenceModeProps {
  selectedScanningLevel?: ScanSequenceLevelType;
  setSelectedScanningLevel: (scanLevel: ScanSequenceLevelType) => void;
  floorplan: Floorplan | null;
  scenes: Scene[];
  HeaderBarWrapper: React.FunctionComponent;
}

export const SequenceMode = (props: SequenceModeProps) => {
  const notify = useNotification();
  const project = useProject();

  const {
    selectedScanningLevel,
    setSelectedScanningLevel,
    floorplan,
    scenes,
    HeaderBarWrapper,
  } = props;

  const [floorPlanSequences, setFloorplanSequences] = useState<Sequence[]>([]);
  const [editSequence, setEditSequence] = useState<boolean>(false);
  const [selectedSequence, setSelectedSequence] = useState<Sequence>();
  const [modifiedSequenceIds, setModifiedSequenceIds] = useState<Set<string>>(
    new Set(),
  );

  const [colorMode, setColorMode] = useState<ColorMode>(ColorMode.SCENE_TYPES);

  const { keyEvent, onKeyUp, onKeyDown } = useKeyEvent();

  const [updateScanSequence] = useUpdateScanSequenceMutation({
    refetchQueries: [ScanSequencesByFilterDocument],
    onError: (error) =>
      notify(`Sequence update failed: ${error.message}`, "error"),
  });

  const [createScanSequence] = useCreateScanSequenceMutation({
    refetchQueries: [ScanSequencesByFilterDocument],
    onError: (error) =>
      notify(`Sequence creation failed: ${error.message}`, "error"),
  });

  const { data: sequencesByFilterData } = useScanSequencesByFilterQuery({
    onError: (error) => {
      notify(`Failed to fetch sequences: ${error}`, "error");
    },
    variables: {
      tenant: project,
      floorplanId: floorplan?.id,
    },
    skip: !floorplan?.id,
  });

  useEffect(() => {
    sequencesByFilterData?.scanSequencesByFilter &&
      setFloorplanSequences(
        transformSequenceFromGqlResponse(
          sequencesByFilterData?.scanSequencesByFilter,
        ),
      );
  }, [sequencesByFilterData]);

  const onSequenceScenesChange = useCallback(
    (sceneIds: string[]) => {
      if (selectedSequence) {
        setModifiedSequenceIds(modifiedSequenceIds.add(selectedSequence.id));
        setFloorplanSequences(
          floorPlanSequences.map((s) =>
            s.id === selectedSequence.id
              ? {
                  ...s,
                  sceneIds: sceneIds,
                }
              : s,
          ),
        );
      }
    },
    [selectedSequence, modifiedSequenceIds, floorPlanSequences],
  );

  const onEditSequenceChange = (edit: boolean) => {
    setEditSequence(edit);
    if (!edit) {
      //if the edit mode gets canceled instead of saved, we want to overwrite the unsaved changes with the original query response
      setFloorplanSequences(
        sequencesByFilterData
          ? transformSequenceFromGqlResponse(
              sequencesByFilterData.scanSequencesByFilter,
            )
          : [],
      );
      setModifiedSequenceIds(new Set());
    }
  };

  const onModifiedSequencesSave = useCallback(async () => {
    floorPlanSequences.forEach(async (s) => {
      modifiedSequenceIds.has(s.id) &&
        (await updateScanSequence({
          variables: {
            tenant: project,
            id: s.id,
            floorplanId: s.floorPlanId,
            sequencePrefix: s.sequencePrefix,
            scanLevel: s.scanLevel,
            sceneIds: s.sceneIds,
          },
        }));
    });
    notify("Sequence changes saved", "success");
    setModifiedSequenceIds(new Set());
  }, [
    project,
    updateScanSequence,
    floorPlanSequences,
    modifiedSequenceIds,
    notify,
  ]);

  const onCreateSequence = useCallback(
    async (
      prefix: ScanSequencePrefixType,
      scanLevel: ScanSequenceLevelType,
    ) => {
      floorplan?.id &&
        (await createScanSequence({
          variables: {
            tenant: project,
            floorplanId: floorplan.id,
            sequencePrefix: prefix,
            scanLevel: scanLevel,
            sceneIds: [],
          },
        }));
    },
    [floorplan, createScanSequence, project],
  );

  const handleDrawingClick = async (x: number, y: number) => {
    if (editSequence && keyEvent === SEQUENCE_MODE_MODIFIER) {
      const nodeId = findClosestNodeId(
        scenes.filter(isSceneWithCoordinates),
        x,
        y,
      );
      nodeId &&
        selectedSequence &&
        setFloorplanSequences(
          floorPlanSequences.map((seq: Sequence) =>
            modifySequenceScenes(seq, nodeId, selectedSequence),
          ),
        );
    }
  };

  const sequencesPerPhase: { [key: string]: Sequence[] } = useMemo(
    () => _.groupBy(floorPlanSequences, "scanLevel"),
    [floorPlanSequences],
  );

  const modifySequenceScenes = (
    sequence: Sequence,
    nodeId: string,
    modifiedSequence: Sequence,
  ) => {
    if (sequence.id === modifiedSequence.id) {
      return {
        ...sequence,
        sceneIds: addSceneToSequence(sequence.id, sequence.sceneIds, nodeId),
      };
    } else if (sequence.scanLevel === modifiedSequence.scanLevel) {
      return {
        ...sequence,
        sceneIds: removeSceneFromSequence(
          sequence.id,
          sequence.sceneIds,
          nodeId,
        ),
      };
    } else {
      return sequence;
    }
  };

  const addSceneToSequence = useCallback(
    (id: string, sequenceScenesIds: string[] | undefined, nodeId: string) => {
      if (sequenceScenesIds) {
        if (!sequenceScenesIds.includes(nodeId)) {
          setModifiedSequenceIds(modifiedSequenceIds.add(id));
          return [...sequenceScenesIds, nodeId];
        } else {
          notify("Scene already exist in the selected sequence", "warning");
          return sequenceScenesIds;
        }
      } else {
        setModifiedSequenceIds(modifiedSequenceIds.add(id));
        return [nodeId];
      }
    },
    [notify, modifiedSequenceIds],
  );

  const removeSceneFromSequence = useCallback(
    (id: string, sequenceScenesIds: string[] | undefined, nodeId: string) => {
      if (sequenceScenesIds && sequenceScenesIds.includes(nodeId)) {
        setModifiedSequenceIds(modifiedSequenceIds.add(id));
        return sequenceScenesIds.filter((sceneId) => sceneId !== nodeId);
      } else {
        return sequenceScenesIds;
      }
    },
    [modifiedSequenceIds],
  );

  const sequenceScenes = useMemo(() => {
    const selectedSequenceSceneIds = selectedSequence?.sceneIds;
    return selectedSequenceSceneIds
      ? _.keyBy(
          scenes.filter((s) => selectedSequenceSceneIds?.includes(s.id)),
          "id",
        )
      : undefined;
  }, [scenes, selectedSequence?.sceneIds]);

  const handleColorModeChange = (checked: boolean) => {
    checked
      ? setColorMode(ColorMode.SCANNING_STAGE)
      : setColorMode(ColorMode.SCENE_TYPES);
  };

  return (
    <>
      <HeaderBarWrapper>
        <ColorModeSwitch
          checkedValue={colorMode === ColorMode.SCANNING_STAGE}
          onChange={handleColorModeChange}
          disabled={!selectedScanningLevel}
        />
      </HeaderBarWrapper>
      <div
        className={styles["main-container"]}
        onKeyDown={onKeyDown}
        onKeyUp={onKeyUp}
        tabIndex={0}
      >
        {floorplan && (
          <>
            <div className={styles["sequence-mode-panel"]}>
              <SequenceModePanel
                selectedSequence={selectedSequence}
                onSelectSequence={setSelectedSequence}
                setScanLevel={setSelectedScanningLevel}
                selectedScanLevel={selectedScanningLevel}
                editMode={editSequence}
                onSetEditMode={onEditSequenceChange}
                sequences={floorPlanSequences ?? []}
                onSave={onModifiedSequencesSave}
                onCreate={onCreateSequence}
                onSequenceScenesChange={onSequenceScenesChange}
                scenes={sequenceScenes}
              />
            </div>
            <div className={styles["contents-container"]}>
              <DrawingToolContainer
                colorMode={colorMode}
                scenes={scenes}
                onDrawingClick={handleDrawingClick}
                drawing={floorplan}
                sequencesPerPhase={sequencesPerPhase}
                selectedFilter={selectedScanningLevel}
              />
            </div>
          </>
        )}
      </div>
    </>
  );
};
