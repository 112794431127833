import { Tree, TreeProps } from "antd";
import { EventDataNode } from "antd/lib/tree";
import classNames from "classnames";
import React, { useEffect, useMemo } from "react";
import { DisplayTreeCheckedInfo } from "./DisplayTreeCheckedInfo";
import { DisplayTreeDivider } from "./DisplayTreeDivider";
import styles from "./DisplayTreeStyles.module.css";
import { SelectAllNoneLink } from "./SelectAllNoneLink";
import {
  findNode,
  getParentFolderKeys,
  isStringArray,
} from "./display-tree-utils";
import { useCheckable } from "./hooks/checkable";
import { useExpandable } from "./hooks/expandable";
import { DisplayTreeNode } from "./types";

type DisplayTreeProps = {
  treeData: DisplayTreeNode[];
  selectedKeys?: string[];
  allowMultiSelect?: boolean;
  checkable?: boolean;
  editable?: boolean;
  onCheckNodes?: (node: string[]) => void;
  onSelectNode?: (selectedNode: DisplayTreeNode) => void;
  onDropNode?: (info: {
    dragNode: DisplayTreeNode;
    dropToGap: boolean;
    node: DisplayTreeNode;
    dropPosition: number;
  }) => void;
  onLoad?: (treeNode: DisplayTreeNode) => Promise<void>;
  searchHits?: { keys: string[]; parentKeys: string[] };
  disabled?: boolean;
};

export const DisplayTree = (props: DisplayTreeProps) => {
  const {
    treeData,
    selectedKeys,
    allowMultiSelect,
    editable = false,
    checkable = false,
    onCheckNodes,
    onSelectNode,
    onDropNode,
    onLoad,
    searchHits,
    disabled = false,
  } = props;

  const {
    keys,
    setCheckedKeys,
    setCheckedChildKeys,
    checkExpanded,
    uncheckAll,
    checkExpandedSearchHits,
  } = useCheckable(treeData, onCheckNodes);
  const {
    keys: expandedKeys,
    autoExpandParent,
    onExpandNode: onExpand,
  } = useExpandable();

  useEffect(() => {
    if (searchHits !== undefined) {
      onExpand(searchHits.parentKeys, false);
    } else if (selectedKeys === undefined) {
      onExpand([], false);
    }
  }, [onExpand, selectedKeys, searchHits]);

  useEffect(() => {
    if (searchHits === undefined && selectedKeys !== undefined) {
      const keysToExpand = getParentFolderKeys(selectedKeys, treeData);
      onExpand(keysToExpand, true);
    }
  }, [onExpand, treeData, selectedKeys, searchHits]);

  const onExpandCallback = (e: (string | number)[]) => {
    // antd supports keys both as numbers & strings
    // our typing only supports strings...
    const keysToExpand: string[] = isStringArray(e) ? e : [];
    selectedKeys &&
      keysToExpand.push(...getParentFolderKeys(selectedKeys, treeData));
    onExpand(keysToExpand, false);
  };

  const treeDataMemo = useMemo(() => {
    if (!treeData) {
      return [];
    }

    const modifySearchHitsTitle = (
      data: DisplayTreeNode[],
    ): DisplayTreeNode[] =>
      data.map((node) => {
        const isSearchHit = searchHits?.keys.includes(node.key);
        const title = (
          <div
            className={classNames({
              [styles["tree-search-value-hit"]]: isSearchHit,
            })}
          >
            {node.title}
          </div>
        );

        return {
          ...node,
          title,
          children: node.children
            ? modifySearchHitsTitle(node.children)
            : undefined,
        };
      });

    return modifySearchHitsTitle(treeData);
  }, [searchHits, treeData]);

  const handleOnSelect = (
    _selectedKeys: string[],
    info: {
      nativeEvent: MouseEvent;
      selectedNodes: DisplayTreeNode[];
      node?: DisplayTreeNode;
    },
  ) => {
    if (info.node) {
      onSelectNode?.(info.node);
    }
    if (info.node && info.nativeEvent.shiftKey && checkable) {
      setCheckedChildKeys(info.node.key, expandedKeys);
    }
  };

  const handleOnSelectExpanded = () => checkExpanded(expandedKeys);

  const handleOnSelectSearchHits =
    searchHits !== undefined && searchHits.keys.length > 0
      ? () => checkExpandedSearchHits(searchHits.keys, expandedKeys)
      : undefined;

  const handleOnCheck = (
    checkedKeys: { checked: string[] },
    info: { node: EventDataNode; nativeEvent: MouseEvent },
  ) => setCheckedKeys(checkedKeys, expandedKeys, info);

  const expandSelectedNodes = () => {
    const parentKeys: string[] = [];
    keys.checked.forEach((key) => {
      const node = findNode(treeData, key);
      node?.ancestorKeys && parentKeys.push(...node.ancestorKeys);
    });
    onExpand(parentKeys, false);
  };

  return (
    <>
      {checkable && (
        <div className={classNames(styles["select-all-none-container"])}>
          {keys.checked.length > 0 && (
            <>
              <DisplayTreeCheckedInfo
                numberCheckedKeys={keys.checked.length}
                expandCheckedCallback={expandSelectedNodes}
              />
              <DisplayTreeDivider />
            </>
          )}
          <SelectAllNoneLink
            selectExpandedCallback={handleOnSelectExpanded}
            selectNoneCallback={uncheckAll}
            selectSearchHitsCallback={handleOnSelectSearchHits}
          />
        </div>
      )}
      <Tree
        style={{ paddingBottom: "48px" }} // need to use styles to override antd defaults
        showLine={{ showLeafIcon: false }}
        treeData={treeDataMemo}
        draggable={editable}
        blockNode
        multiple={allowMultiSelect}
        // We need to optionally pass the selectedKeys as any other
        // option (undefined, null, empty array) makes the component
        // controlled and then there is no way to modify the selection
        {...(selectedKeys ? { selectedKeys: selectedKeys } : {})}
        /* TODO: fix casting
        Antd's tree component expects a specific type. If we give it more properties than it expects (which we do here) it will not be able todo 
        anything with that data as it does not know about it.
        Any use of these extra parameters we have in our logic/callbacks we are leaving on here for our own convenience, not antd's.
        The callbacks should use antd's expected types and additional lofic added to retireve all of the DisplayTreeNode properties.
        */
        onDrop={onDropNode as TreeProps["onDrop"]}
        onSelect={handleOnSelect as unknown as TreeProps["onSelect"]} // unknown required as intermediory as antd onSelect can't resolve DisplayTreeNode[] to a DataNode[] on selectedNodes
        onCheck={handleOnCheck as TreeProps["onCheck"]}
        onExpand={onExpandCallback as TreeProps["onExpand"]}
        loadData={onLoad as TreeProps["loadData"]}
        checkable={checkable}
        checkedKeys={keys.checked}
        checkStrictly
        expandedKeys={expandedKeys}
        autoExpandParent={autoExpandParent}
        disabled={disabled}
      />
    </>
  );
};
