import { Switch } from "antd";
import classNames from "classnames";
import React from "react";
import { DisplayTree } from "./DisplayTree";
import styles from "./DisplayTreeStyles.module.css";
import { DisplayTreeNode } from "./types";

type TreeWithTagsSwitchProps = {
  treeData: DisplayTreeNode[];
  showTags: boolean;
  setShowTags: (showTags: boolean) => void;
  editable?: boolean;
  checkable?: boolean;
  disabled?: boolean;
  searchHits?: { keys: string[]; parentKeys: string[] };
  onCheckNodes?: (node: string[]) => void;
  onSelectNode?: (selectedNode: DisplayTreeNode) => void;
  onDropNode?: (info: {
    dragNode: DisplayTreeNode;
    dropToGap: boolean;
    node: DisplayTreeNode;
    dropPosition: number;
  }) => void;
  onLoad?: (treeNode: DisplayTreeNode) => Promise<void>;
};

const SHOW_TAGS_SWITCH_TEXT = "Show tags";

export const TreeWithTagsSwitch = (props: TreeWithTagsSwitchProps) => {
  const { showTags, setShowTags, ...treeDataProps } = props;

  return (
    <>
      <div className={classNames(styles["switch-container"])}>
        <Switch
          checked={showTags}
          checkedChildren={SHOW_TAGS_SWITCH_TEXT}
          unCheckedChildren={SHOW_TAGS_SWITCH_TEXT}
          onChange={setShowTags}
          size={"small"}
        />
      </div>
      <DisplayTree {...treeDataProps} />
    </>
  );
};
