import { Button, Card, Select } from "antd";
import React, { useMemo, useState } from "react";
import { useNotification } from "../../contexts/Notifications";
import { useGetAllFloorplansScanSequencesAndScenesLazyQuery } from "../../generated/types";
import { Project } from "../../types";
import styles from "./SequenceMapGenerator.module.css";
import { generateSequenceMaps, toFilename } from "./sequence-maps-generator";
import { SequenceMapData } from "./types";
import { gqlToSequenceMapsData } from "./utils";

interface SequenceMapsGeneratorProps {
  project: Project;
}

export const SequenceMapsGenerator = (props: SequenceMapsGeneratorProps) => {
  const { project } = props;
  const [loading, setLoading] = useState(false);
  const notify = useNotification();
  const [sequences, setSequences] = useState<SequenceMapData[]>([]);
  const [selectedSequences, setSelectedSequences] = useState<string[]>([]);
  const [selectedSequencesSize, setSelectedSequencesSize] = useState<number>();
  const [showSelector, setShowSelector] = useState(false);

  const [fetchAllFloorplansScanSequencesAndScenes] =
    useGetAllFloorplansScanSequencesAndScenesLazyQuery({
      fetchPolicy: "no-cache", // let the User decide when data has changed
      onError: (error) => {
        notify(`Failed to generate site scanner maps: ${error}`, "error");
        setLoading(false);
      },
      onCompleted: async (data) => {
        if (data) {
          setSequences(gqlToSequenceMapsData(data));
        } else {
          notify(
            `No floorplan data for ${project.customer} ${project.project}`,
          );
        }
        setLoading(false);
      },
    });

  const fetchSequenceMaps = async () => {
    setLoading(true);
    await fetchAllFloorplansScanSequencesAndScenes({
      variables: {
        tenant: project,
      },
    });
    setShowSelector(true);
  };

  const downloadSelectedSequeceMaps = async () => {
    setLoading(true);
    const sequenceToDownload = () => {
      const selectedSequenceSet = new Set(selectedSequences);
      return sequences.filter((sequence: any) =>
        selectedSequenceSet.has(
          `${sequence.scanLevel}_${sequence.floorplan.id}`,
        ),
      );
    };
    try {
      await generateSequenceMaps(
        sequenceToDownload(),
        "pdf",
        selectedSequencesSize,
      );
    } catch (error) {
      notify(`Failed to generate map export: ${error}`, "error");
    }
    setLoading(false);
  };

  const downloadAllSequeceMaps = async () => {
    setLoading(true);
    try {
      await generateSequenceMaps(sequences, "pdf", selectedSequencesSize);
    } catch (error) {
      notify(`Failed to generate map export: ${error}`, "error");
    }
    setLoading(false);
  };

  const options = useMemo(
    () =>
      sequences.map((seq: any) => ({
        value: seq.scanLevel + "_" + seq.floorplan.id,
        label: toFilename(seq),
      })),
    [sequences], // Dependency array
  );

  return (
    <Card>
      {!showSelector && (
        <Button value="generate" onClick={fetchSequenceMaps} loading={loading}>
          Fetch Sequence Maps
        </Button>
      )}
      {showSelector && (
        <>
          <Select
            mode="multiple"
            className={styles["select"]}
            placeholder="Select sequences"
            value={selectedSequences}
            onChange={(value) => setSelectedSequences(value)}
            options={options}
          />
          <Select
            className={styles["select-size"]}
            placeholder="Select Size Of Sequence Points"
            value={selectedSequencesSize}
            onChange={(size) => setSelectedSequencesSize(size)}
            options={[
              { value: 1, label: "1" },
              { value: 2, label: "2" },
              { value: 3, label: "3" },
              { value: 4, label: "4" },
              { value: 5, label: "5" },
            ]}
          />
          <Button
            className={styles["button"]}
            value="generateSelected"
            onClick={downloadSelectedSequeceMaps}
            loading={loading}
          >
            Download Selected Sequence Maps
          </Button>
          <Button
            value="generateSelected"
            onClick={downloadAllSequeceMaps}
            loading={loading}
          >
            Download All
          </Button>
        </>
      )}
    </Card>
  );
};
