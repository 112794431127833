import React from "react";
import { Drawing } from "../SpatialConfigurationMode/types";
import { usePanAndZoom } from "./hooks/usePanAndZoom";
import { OriginAndScale } from "./shapes/OriginAndScale";

// There are better ways to compute this
// but for now it should work fine
const DRAWING_TOOL_PIXELS_HEIGHT = 800;

type DrawingToolProps = {
  nodes: React.ReactNode;
  onDrawingClick?: (x: number, y: number) => void;
  drawing: Drawing;
  showOriginAndScale?;
};

export const DrawingTool = (props: DrawingToolProps) => {
  const { nodes, onDrawingClick, drawing, showOriginAndScale } = props;

  const { containerRef, origin, scale } = usePanAndZoom({
    scale:
      DRAWING_TOOL_PIXELS_HEIGHT / (drawing.height * drawing.mmScaleFactor),
    origin: {
      x: -(drawing.originX * drawing.mmScaleFactor),
      y: -(drawing.originY * drawing.mmScaleFactor),
    },
  });

  const handleDrawingClick = (e) => {
    onDrawingClick?.(
      e.nativeEvent.offsetX / scale + origin.x,
      e.nativeEvent.offsetY / scale + origin.y,
    );
  };

  return (
    <svg
      viewBox={`${origin.x} ${origin.y} ${4000 / scale} ${4000 / scale}`}
      width="4000px"
      height="4000px"
      xmlns="http://www.w3.org/2000/svg"
      onClick={handleDrawingClick}
      // prevent opening context menu on right click - auxclick would not work
      // see https://developer.mozilla.org/en-US/docs/Web/API/Element/auxclick_event#preventing_default_actions
      onContextMenu={(e) => e.preventDefault()}
      ref={containerRef}
    >
      <image
        href={drawing.url}
        x={-(drawing.originX * drawing.mmScaleFactor)}
        y={-(drawing.originY * drawing.mmScaleFactor)}
        height={drawing.height * drawing.mmScaleFactor}
      />
      {showOriginAndScale && <OriginAndScale />}
      {nodes}
    </svg>
  );
};
