import { Button, Form, Input } from "antd";
import React from "react";

const MISSING_INPUT_MESSAGE = "Missing input!";

// Todo: need to pass in existing objects to check for duplicates and then alter form.validate somehow
export const CreateObjectForm = (props: {
  onSubmit: () => void;
  setNewVal: (newVal: string) => void;
}) => {
  const [form] = Form.useForm();
  const handleOk = async () => {
    try {
      await form.validateFields();
    } catch (err) {
      return;
    }
    props.setNewVal(form.getFieldValue("name"));
    form.resetFields();
    props.onSubmit();
  };

  return (
    <>
      <Form form={form} onFinish={handleOk}>
        <Form.Item
          label="Name"
          name="name"
          rules={[{ required: true, message: MISSING_INPUT_MESSAGE }]}
        >
          <Input data-testid="form-object-name-input" />
        </Form.Item>
      </Form>
      <Button type="primary" onClick={handleOk} data-testid="form-ok-button">
        Ok
      </Button>
    </>
  );
};
