import React from "react";
import { Batch } from "../../../types";
import styles from "./BatchSelector.module.css";
import { Dropdown } from "./Dropdown";

export const isWeekBeforeRefBatch = (
  comparisonBatch: Batch | undefined,
  referenceBatch: Batch | undefined,
): boolean => {
  return (
    comparisonBatch?.weekNumber != null &&
    referenceBatch?.weekNumber != null &&
    comparisonBatch.weekNumber + 1 === referenceBatch?.weekNumber
  );
};

type ComparisonBatchDropdownProps = React.PropsWithChildren<{
  batches: Batch[];
  comparisonBatch?: Batch;
  referenceBatch?: Batch;
  onChange: (referenceBatch: Batch) => void;
}>;

const ComparisonBatchDropdown = (props: ComparisonBatchDropdownProps) => {
  const isWeekBeforeReferenceBatch = isWeekBeforeRefBatch(
    props.comparisonBatch,
    props.referenceBatch,
  );

  return (
    <Dropdown
      className={
        isWeekBeforeReferenceBatch === false
          ? styles["warning-selected-item"]
          : styles["default-selected-item"]
      }
      defaultBatch={props.comparisonBatch}
      batches={props.batches}
      onChange={props.onChange}
    ></Dropdown>
  );
};

export default ComparisonBatchDropdown;
