import { DeleteOutlined } from "@ant-design/icons";
import { Button, Space } from "antd";
import React, { useState } from "react";
import { DeleteConfirmationModal } from "../DeleteConfirmationModal/DeleteConfirmationModal";

type TaskDeletionModalProps = {
  onDelete: () => Promise<void>;
};

export const DELETE_TASK_CONFIRMATION_REQ_TEXT = "delete task";
const DELETE_TASK_MODAL_TITLE = "Delete Task";
const DELETE_CONFIRMATION_ALERT_MESSAGE =
  "Are you sure you want to delete this task?";
const DELETE_TASK_MODAL_BODY = (
  <p>
    This action will delete the task; any child tasks will also be deleted, but
    their components will be unassigned and retained.
  </p>
);

export const TaskDeletionModal = (props: TaskDeletionModalProps) => {
  const { onDelete } = props;
  const [visible, setVisible] = useState(false);

  const showModal = () => {
    setVisible(true);
  };

  return (
    <Space>
      <Button
        danger
        data-testid="delete-task-button"
        type="primary"
        onClick={showModal}
        icon={<DeleteOutlined />}
      />
      <DeleteConfirmationModal
        title={DELETE_TASK_MODAL_TITLE}
        visible={visible}
        setVisible={setVisible}
        onOk={onDelete}
        deletionText={DELETE_TASK_CONFIRMATION_REQ_TEXT}
        modalBody={DELETE_TASK_MODAL_BODY}
        alertText={DELETE_CONFIRMATION_ALERT_MESSAGE}
      />
    </Space>
  );
};
