import { EditFilled } from "@ant-design/icons";
import { Alert, Button, Modal } from "antd";
import React, { ReactNode, useState } from "react";
import { DisplayTreeNode } from "./DisplayTree/types";
import { SpacesTree } from "./SpacesTree/SpacesTree";

interface SpaceSelectionModalProps {
  onSelect: (spaceId: string) => void;
  defaultOpen?: boolean;
  initialSpaceId?: string;
  buttonContent?: ReactNode;
}

export const SpaceSelectionModal = (props: SpaceSelectionModalProps) => {
  const { onSelect, defaultOpen, initialSpaceId, buttonContent } = props;
  const [isVisible, setIsVisible] = useState(defaultOpen ?? false);
  const [selectedSpaceId, setSelectedSpaceId] = useState<string | undefined>(
    initialSpaceId,
  );

  const handleOk = React.useCallback(async () => {
    if (selectedSpaceId == null) {
      throw new Error("Can't select an undefined space");
    }

    setIsVisible(false);
    onSelect(selectedSpaceId);
  }, [onSelect, selectedSpaceId]);

  return (
    <>
      <Button
        type="primary"
        icon={<EditFilled />}
        onClick={() => setIsVisible(true)}
      >
        {buttonContent}
      </Button>
      <Modal
        title="Select Space"
        visible={isVisible}
        okButtonProps={{ disabled: selectedSpaceId == null }}
        cancelButtonProps={{ style: { display: "none" } }}
        onCancel={() => setIsVisible(false)}
        onOk={handleOk}
      >
        <Alert message="Please select a space." type="info" showIcon />
        <SpacesTree
          onSelect={(selectedNode: DisplayTreeNode | null) => {
            setSelectedSpaceId(selectedNode?.key || undefined);
          }}
        />
      </Modal>
    </>
  );
};
