import { Breadcrumb, Space } from "antd";
import React from "react";
import { useGetFullNameForSpaceQuery } from "../../../generated/types";
import { Project } from "../../../types";
import { SpaceSelectionModal } from "../../SpaceSelectionModal";

interface SpaceSelectorProps {
  project: Project;
  rootSpaceId?: string;
  onSelect: (spaceId: string) => void;
}

export const SpaceSelector = (props: SpaceSelectorProps) => {
  const { project, rootSpaceId, onSelect } = props;
  const { data: currentSpace, error } = useGetFullNameForSpaceQuery({
    skip: rootSpaceId == null,
    variables: {
      spaceId: rootSpaceId as string,
      ...project,
    },
  });

  if (error != null) {
    return <div>Something went wrong... {error.message}</div>;
  }

  return (
    <Space style={{ display: "flex", flexDirection: "row" }}>
      <SpaceSelectionModal onSelect={onSelect} defaultOpen={false} />
      {currentSpace != null && currentSpace.spacesByIds.length > 0 && (
        <Breadcrumb>
          {currentSpace.spacesByIds[0].ancestors.map((anc) => (
            <Breadcrumb.Item key={anc.id}>{anc.name}</Breadcrumb.Item>
          ))}
        </Breadcrumb>
      )}
    </Space>
  );
};
