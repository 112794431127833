import { Divider } from "antd";
import classNames from "classnames";
import React, { useEffect, useMemo, useState } from "react";
import {
  DisplayTreeNode,
  DisplayTreeNodeType,
} from "../../components/DisplayTree/types";
import { DrawingToolContainer } from "../../components/DrawingTool/DrawingToolContainer";
import {
  FloorplanButton,
  FloorplanEditor,
} from "../../components/FloorplanEditor";
import { FloorplansEditor } from "../../components/FloorplanEditor/hooks/useFloorplan";
import {
  SceneAliasButton,
  SceneAliasEditor,
} from "../../components/SceneAliasEditor";
import { SpacesTree } from "../../components/SpacesTree/SpacesTree";
import { useKeyEvent } from "../../pages/SpatialConfigModePage/hooks/useKeyEvent";
import {
  CopySpaceButton,
  CopySpaceEditor,
  CopySpaceUnderParentButton,
} from "../CopySpaceEditor";
import { ROOT_PARENT_KEY } from "../DisplayTree/search-utils";
import { FetchScene } from "./OptionsPanel/FetchScene";
import { SceneOptionsPanel } from "./OptionsPanel/SceneOptionsPanel";
import { SpaceOptionsPanel } from "./OptionsPanel/SpaceOptionsPanel";
import styles from "./SpatialConfigurationMode.module.css";
import {
  createSceneDrawingStrategy,
  moveScenesDrawingStrategy,
  selectSceneNodeDrawingStrategy,
} from "./Strategies/drawingStrategies";
import {
  CreateSceneButton,
  CreateSpaceButton,
  CreateSpaceGroupButton,
  MoveSceneButton,
  SelectSceneButton,
} from "./ToolbarActionButtons";
import { ScenesEditor } from "./hooks/useScenes";
import { useSpaces } from "./hooks/useSpaces";
import { SpatialConfigTreeNode } from "./types";
import {
  areNodesSiblings,
  isNodeFolder,
  isNodeScene,
  isNodeSpace,
  multiSelectSelection,
} from "./utils";

const MULTIPLE_SELECT_MODIFIER_MAC = "Meta";
const MULTIPLE_SELECT_MODIFIER_WINDOWS = "Control";
enum SceneAction {
  SELECT,
  MOVE,
  CREATE,
}

interface SpatialConfigModeProps {
  floorplansEditor: FloorplansEditor;
  scenesEditor: ScenesEditor;
  isInvisible: boolean;
  HeaderBarWrapper: React.FunctionComponent;
}

export const SpatialConfigMode = (props: SpatialConfigModeProps) => {
  const { floorplansEditor, scenesEditor, isInvisible, HeaderBarWrapper } =
    props;
  const {
    floorplan,
    floorplanIdToSpaceId,
    onCreate: onCreateFloorplan,
    onPatch: onPatchFloorplan,
    onDelete: onDeleteFloorplan,
  } = floorplansEditor;
  const {
    scenes,
    onCreate: onCreateScene,
    onPatch: onPatchScene,
    onDelete: onDeleteScenes,
    onUpdate: onUpdateScenes,
  } = scenesEditor;
  const {
    onCreate: onCreateSpace,
    onPatch: onPatchSpace,
    onCopySpatialData,
  } = useSpaces();

  const [treeNodesSelection, setTreeNodesSelection] = useState<
    SpatialConfigTreeNode[]
  >([]);

  useEffect(() => {
    setTreeNodesSelection([]);
  }, [floorplan]);

  const { keyEvent, onKeyUp, onKeyDown } = useKeyEvent();
  const [sceneActionMode, setSceneActionMode] = useState<
    SceneAction | undefined
  >();
  const [isMultiSelectMode, setIsMultiSelectMode] = useState(false);

  const [isFloorplanEditorVisible, setIsFloorplanEditorVisible] =
    useState<boolean>(false);
  const [isSceneAliasEditorVisible, setIsSceneAliasEditorVisible] =
    useState<boolean>(false);
  const [isCopySpaceEditorVisible, setIsCopySpaceEditorVisible] =
    useState<boolean>(false);

  const handleTreeNodeSelect = (displayTreeNode: DisplayTreeNode) => {
    const ancestorKey = displayTreeNode.ancestorKeys
      ? displayTreeNode.ancestorKeys[displayTreeNode.ancestorKeys?.length - 1]
      : undefined;
    const parentSpaceId =
      ancestorKey !== ROOT_PARENT_KEY ? ancestorKey : undefined;
    const node: SpatialConfigTreeNode = {
      ...displayTreeNode,
      parentSpaceId,
    };
    if (!isNodeFolder(displayTreeNode)) {
      if (isMultiSelectMode) {
        const selection = multiSelectSelection(treeNodesSelection, node);
        selection && setTreeNodesSelection(selection);
      } else {
        setTreeNodesSelection([node]);
      }
    }
  };

  const createSpaceCallback = async (spaceName?: string) => {
    if (!isSelectionSingleSpace) {
      return;
    }
    const newSpace = await onCreateSpace(treeNodesSelection[0].key, spaceName);
    setTreeNodesSelection([
      {
        key: newSpace.id,
        name: newSpace.name,
        nodeType: DisplayTreeNodeType.Space,
        parentSpaceId: treeNodesSelection[0].parentSpaceId,
      },
    ]);
  };

  const copySpaceUnderSameParentCallback = async () => {
    disableCreateSceneMode();
    if (!isSelectionSingleSpace || !hasNodesWithoutFloorplanSelected) {
      return;
    }
    treeNodesSelection[0].parentSpaceId &&
      (await onCopySpatialData(
        treeNodesSelection[0].key,
        treeNodesSelection[0].parentSpaceId,
      ));
  };

  const createSpaceGroupCallback = async (spaceName?: string) => {
    if (
      !hasNodesWithoutFloorplanSelected ||
      !areNodesSiblings(treeNodesSelection)
    ) {
      return;
    }
    const { parentSpaceId } = treeNodesSelection[0];
    //root spaces should not be created in spatial config
    if (parentSpaceId) {
      const newSpace = await onCreateSpace(parentSpaceId, spaceName);
      const sceneIdsToPatch = treeNodesSelection
        .filter((s) => isNodeScene(s))
        .map((s) => s.key);
      sceneIdsToPatch.length &&
        (await onPatchScene(sceneIdsToPatch, { spaceId: newSpace.id }));
      const spaceIdsToPatch = treeNodesSelection
        .filter((s) => isNodeSpace(s))
        .map((s) => s.key);
      spaceIdsToPatch.length &&
        (await onPatchSpace(spaceIdsToPatch, {
          setParentId: { newValue: newSpace.id },
        }));
      setTreeNodesSelection([
        {
          key: newSpace.id,
          name: newSpace.name,
          nodeType: DisplayTreeNodeType.Space,
          parentSpaceId,
        },
      ]);
    }
  };

  const selectedScenes = useMemo(
    () =>
      treeNodesSelection
        .map((node) => scenes[node.key])
        .filter((scene) => !!scene),
    [treeNodesSelection, scenes],
  );

  const createSceneDrawingClick = createSceneDrawingStrategy(
    treeNodesSelection,
    setTreeNodesSelection,
    onCreateScene,
  );

  const moveSceneDrawingClick = moveScenesDrawingStrategy(
    selectedScenes,
    () => setSceneActionMode(SceneAction.SELECT),
    onUpdateScenes,
  );
  const selectSceneDrawingClick = selectSceneNodeDrawingStrategy(
    scenes,
    setTreeNodesSelection,
    treeNodesSelection,
    isMultiSelectMode,
  );

  useEffect(() => {
    if (
      keyEvent === MULTIPLE_SELECT_MODIFIER_MAC ||
      keyEvent === MULTIPLE_SELECT_MODIFIER_WINDOWS
    ) {
      setIsMultiSelectMode(true);
    } else {
      setIsMultiSelectMode(false);
    }
  }, [keyEvent]);

  const handleDrawingClick = async (x: number, y: number) => {
    switch (sceneActionMode) {
      case SceneAction.CREATE:
        return createSceneDrawingClick(x, y);
      case SceneAction.MOVE:
        return moveSceneDrawingClick(x, y);
      case SceneAction.SELECT:
        return selectSceneDrawingClick(x, y);
    }
  };

  const disableCreateSceneMode = (): boolean => {
    if (sceneActionMode === SceneAction.CREATE) {
      setSceneActionMode(undefined);
      return true;
    }
    return false;
  };

  const isSelectionSingleScene = useMemo(
    () => treeNodesSelection.length === 1 && isNodeScene(treeNodesSelection[0]),
    [treeNodesSelection],
  );

  const isSelectionScenes = useMemo(
    () =>
      treeNodesSelection.length > 0 &&
      treeNodesSelection.filter(
        (node) => node.nodeType !== DisplayTreeNodeType.Scene,
      ).length === 0,
    [treeNodesSelection],
  );

  const isSelectionSingleSpace = useMemo(
    () => treeNodesSelection.length === 1 && isNodeSpace(treeNodesSelection[0]),
    [treeNodesSelection],
  );

  const hasNodesWithoutFloorplanSelected = useMemo(
    () =>
      !!treeNodesSelection.length &&
      treeNodesSelection[0].key !== floorplan?.space.id,
    [treeNodesSelection, floorplan],
  );

  useEffect(() => {
    if (isInvisible) {
      disableCreateSceneMode();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isInvisible]);

  const updateIsCopySpaceEditorVisible = (value: boolean) => {
    setIsCopySpaceEditorVisible(() => {
      disableCreateSceneMode();
      return value;
    });
  };
  const updateIsFloorplanEditorVisible = (value: boolean) => {
    setIsFloorplanEditorVisible(() => {
      disableCreateSceneMode();
      return value;
    });
  };

  return (
    <div
      className={classNames(styles["mode-container"], {
        [styles["invisible"]]: isInvisible,
      })}
    >
      <HeaderBarWrapper>
        <FloorplanButton
          key="floorplan-button"
          size="large"
          type="text"
          onClick={() => updateIsFloorplanEditorVisible(true)}
        />
        <Divider className={styles["divider"]} type="vertical" />
        <CreateSpaceButton
          isSpaceSelected={isSelectionSingleSpace}
          onClick={createSpaceCallback}
          disableCreateSceneMode={disableCreateSceneMode}
        />
        <CopySpaceUnderParentButton
          isNodeSpaceWithoutFloorplan={
            isSelectionSingleSpace && hasNodesWithoutFloorplanSelected
          }
          size="large"
          type="text"
          onClick={copySpaceUnderSameParentCallback}
        />
        <CopySpaceButton
          isNodeSpace={isSelectionSingleSpace}
          size="large"
          type="text"
          onClick={() => updateIsCopySpaceEditorVisible(true)}
        />
        <CreateSpaceGroupButton
          hasSiblingNodesWithoutFloorplanSelected={
            hasNodesWithoutFloorplanSelected &&
            areNodesSiblings(treeNodesSelection)
          }
          onClick={createSpaceGroupCallback}
          disableCreateSceneMode={disableCreateSceneMode}
        />
        <Divider className={styles["divider"]} type="vertical" />
        <CreateSceneButton
          isActive={sceneActionMode === SceneAction.CREATE}
          isTreeNodeSelected={treeNodesSelection.length === 1}
          onClick={() => {
            if (!disableCreateSceneMode()) {
              setSceneActionMode(SceneAction.CREATE);
            }
          }}
        />
        <SelectSceneButton
          isActive={sceneActionMode === SceneAction.SELECT}
          isFloorplanSelected={!!floorplan}
          onClick={() => {
            setSceneActionMode(SceneAction.SELECT);
          }}
        />
        <MoveSceneButton
          isActive={sceneActionMode === SceneAction.MOVE}
          isSelectionScenes={isSelectionScenes}
          onClick={() => {
            setSceneActionMode(SceneAction.MOVE);
          }}
        />
        <SceneAliasButton
          key="scenealias-button"
          selectedNodeIsScene={isSelectionSingleScene}
          aliasExists={
            isSelectionSingleScene &&
            !!scenes[treeNodesSelection[0].key]?.aliasSceneId
          }
          size="large"
          type="text"
          onClick={() => setIsSceneAliasEditorVisible(true)}
        />
      </HeaderBarWrapper>
      <div
        className={styles["main-container"]}
        onKeyDown={onKeyDown}
        onKeyUp={onKeyUp}
        tabIndex={0}
      >
        {isFloorplanEditorVisible && (
          <FloorplanEditor
            floorplanIdToSpaceId={floorplanIdToSpaceId}
            onCreate={onCreateFloorplan}
            onPatch={onPatchFloorplan}
            onDelete={onDeleteFloorplan}
            onClose={() => updateIsFloorplanEditorVisible(false)}
          />
        )}
        {isSceneAliasEditorVisible &&
          isSelectionSingleScene &&
          scenes[treeNodesSelection[0].key] && (
            <SceneAliasEditor
              scene={scenes[treeNodesSelection[0].key]}
              onCreate={async (id: string, aliasId: string) =>
                await onPatchScene([id], { aliasId })
              }
              onDelete={async (id: string) =>
                await onPatchScene([id], { aliasId: null })
              }
              onClose={() => setIsSceneAliasEditorVisible(false)}
            />
          )}
        {isCopySpaceEditorVisible && isSelectionSingleSpace && (
          <CopySpaceEditor
            space={{
              id: treeNodesSelection[0].key,
              name: treeNodesSelection[0].name,
            }}
            onCopySpatialData={onCopySpatialData}
            onClose={() => updateIsCopySpaceEditorVisible(false)}
          />
        )}
        <>
          <div className={styles["tree-container"]}>
            {floorplan?.space.id && (
              <SpacesTree
                editable={treeNodesSelection.length <= 1}
                searchable
                showScenes
                rootSpaceIds={[floorplan.space.id]}
                selectedKeys={treeNodesSelection.map((node) => node.key)}
                allowMultiSelect
                onSelect={handleTreeNodeSelect}
              />
            )}
          </div>
          <div className={styles["contents-container"]}>
            {floorplan && (
              <DrawingToolContainer
                scenes={Object.values(scenes)}
                selectedSceneIds={treeNodesSelection.map((node) => node.key)}
                onDrawingClick={handleDrawingClick}
                drawing={floorplan}
                showOriginAndScale={true}
              />
            )}
          </div>
          {isSelectionScenes && !!selectedScenes.length && (
            <FetchScene
              sceneId={
                isSelectionSingleScene
                  ? selectedScenes[0].aliasSceneId
                  : undefined
              }
            >
              {(sceneAlias) => (
                <SceneOptionsPanel
                  scenes={selectedScenes}
                  updateScene={onPatchScene}
                  deleteScenes={onDeleteScenes}
                  sceneAlias={sceneAlias}
                  floorHeight={
                    (floorplan?.floorHeight && floorplan.floorHeight) || 0
                  }
                  disableCreateSceneMode={disableCreateSceneMode}
                />
              )}
            </FetchScene>
          )}
          {isSelectionSingleSpace && (
            <SpaceOptionsPanel
              spaceId={treeNodesSelection[0].key}
              numScenes={
                Object.values(scenes).filter(
                  (s) => s.parentId === treeNodesSelection[0].key,
                ).length
              }
              disableCreateSceneMode={disableCreateSceneMode}
            />
          )}
        </>
      </div>
    </div>
  );
};
