import { Spin } from "antd";
import _ from "lodash";
import React, { useMemo } from "react";
import { useProject } from "../../App/state";
import { useNotification } from "../../contexts/Notifications";
import {
  useGetSceneCoordinatesQuery,
  useGetShotBySceneAndBatchQuery,
  useZeroPointShotMutation,
} from "../../generated/types";
import { useDualModeState } from "../../pages/DualModePage/state";
import { Batch, PanoView } from "../../types";
import { BimStateAction, useBimState } from "./BimViewer/useBimState";
import { DualViewer } from "./DualViewer";
import styles from "./DualViewer.module.css";
import { convertShotFragmentToShot } from "./utils";

type DualViewerContainerProps = {
  sceneId?: string;
  referenceBatch: Batch;
  comparisonBatch?: Batch;
  view: PanoView;
  onChangeView: (view: PanoView, viewerId?: string) => void;
  focusedSpotlightId?: string;
  arrowSceneNavigation: (evt: KeyboardEvent) => void;
};

export const DualViewerContainer = (props: DualViewerContainerProps) => {
  const {
    sceneId,
    referenceBatch,
    comparisonBatch,
    view,
    onChangeView,
    focusedSpotlightId,
    arrowSceneNavigation,
  } = props;
  const activeProject = useProject();
  const { currentSceneFloorplanData: floorplanData, currentSceneId } =
    useDualModeState();
  const notify = useNotification();

  const { data: sceneCoordinatesData, loading: sceneCoordinatesLoading } =
    useGetSceneCoordinatesQuery({
      variables: {
        ...activeProject,
        sceneId: currentSceneId?.value ?? "",
      },
      skip: !currentSceneId?.value,
    });

  const { bimState, onChangeBimState } = useBimState();

  const sceneHasFloorplanData =
    floorplanData?.bimOriginX &&
    floorplanData?.bimOriginY &&
    floorplanData.floorHeight &&
    floorplanData.angleToTrueNorth;

  const sceneHasCoordinates =
    sceneCoordinatesData &&
    sceneCoordinatesData.scene?.mmX &&
    sceneCoordinatesData.scene.mmY;

  sceneHasCoordinates && sceneHasFloorplanData
    ? onChangeBimState(BimStateAction.LOCATION_SUCCESS)
    : sceneCoordinatesLoading || currentSceneId?.loading
    ? onChangeBimState(BimStateAction.LOCATION_RESET)
    : onChangeBimState(BimStateAction.LOCATION_ERROR);

  const {
    data: lsData,
    loading: lsLoading,
    refetch: lsRefetch,
    error: lsError,
  } = useGetShotBySceneAndBatchQuery({
    variables: {
      sceneId,
      batchId: comparisonBatch?.id as string,
      tenant: activeProject,
    },
    skip: !comparisonBatch,
  });

  const {
    data: rsData,
    loading: rsLoading,
    refetch: rsRefetch,
    error: rsError,
  } = useGetShotBySceneAndBatchQuery({
    variables: {
      sceneId,
      batchId: referenceBatch.id,
      tenant: activeProject,
    },
  });

  const [zeroPointShot] = useZeroPointShotMutation({
    onCompleted: () => {
      notify("Updated zeropoint of reference shot", "success");
    },
    onError: (e) => notify(`Failed to zero point shot: ${e}`, "error"),
  });
  const leftShot = useMemo(
    () =>
      convertShotFragmentToShot(
        lsData?.shot ?? undefined,
        comparisonBatch as Batch,
        "Comparison Shot",
      ),
    [lsData, comparisonBatch],
  );
  const rightShot = useMemo(
    () =>
      convertShotFragmentToShot(
        rsData?.shot ?? undefined,
        referenceBatch,
        "Reference Shot",
      ),
    [rsData, referenceBatch],
  );

  const sceneCoordinates = sceneCoordinatesData?.scene
    ? _.pick(sceneCoordinatesData?.scene, ["id", "mmX", "mmY", "mmZOffset"])
    : undefined;

  return (
    <div className={styles["dual-viewer"]}>
      <Spin
        wrapperClassName={styles["loading-container"]}
        spinning={lsLoading || rsLoading}
      >
        {lsError || rsError ? (
          <DualViewerError left={lsError?.message} right={rsError?.message} />
        ) : (
          <DualViewer
            view={view}
            onChangeView={onChangeView}
            comparisonShot={leftShot}
            referenceShot={rightShot}
            onSpotlightUpdate={() => {
              rsRefetch();
              lsRefetch();
            }}
            focusedSpotlightId={focusedSpotlightId}
            arrowSceneNavigation={arrowSceneNavigation}
            onZeroPoint={(diff) => {
              if (rightShot) {
                zeroPointShot({
                  variables: {
                    customer: activeProject.customer,
                    project: activeProject.project,
                    scope: activeProject.scope,
                    shotId: rightShot.shotId,
                    batchId: referenceBatch.id,
                    yaw: rightShot.imageConfig.yawOffset + diff.yaw,
                  },
                });
              }
            }}
            floorPlanData={floorplanData}
            bimState={bimState}
            onChangeBimState={onChangeBimState}
            sceneCoordinates={sceneCoordinates}
          />
        )}
      </Spin>
    </div>
  );
};

const DualViewerError = ({
  left,
  right,
}: {
  left?: string;
  right?: string;
}) => (
  <div>
    Something went wrong loading scenes: <br />
    {left ? `left scene: ${left}` : ""}
    <br />
    {right ? `right scene: ${right}` : ""}
  </div>
);
