import { LogoutOutlined } from "@ant-design/icons";
import { Image, Menu } from "antd";
import React from "react";
import { App } from "../App/apps";
import { useAppState } from "../App/state";
import { useAuth } from "../contexts/Auth";
import DisperseLogo from "../statics/images/disperse-logo.png";

import styles from "./NavMenu.module.css";

const Header = () => (
  <div className={styles["white-circle"]}>
    <Image
      className={styles["disperse-logo"]}
      preview={false}
      src={DisperseLogo}
    />
  </div>
);

interface ItemProps {
  app: App;
  name: string;
  icon: React.ComponentType<Partial<Record<string, any>>>;
}

interface NavMenuProps {
  items: ItemProps[];
}

const NavMenu = (props: NavMenuProps) => {
  const auth = useAuth();
  const appState = useAppState();
  return (
    <div className={styles["nav-menu"]}>
      <div>
        <Header />
        {auth.status === "authenticated" && (
          <Menu
            theme="dark"
            selectedKeys={appState.app != null ? [appState.app] : []}
          >
            {props.items.map(({ name, icon: Icon, app }) => (
              <Menu.Item
                key={app}
                onClick={() => {
                  if (appState.app !== app) {
                    appState.setApp(app);
                  }
                }}
                icon={<Icon />}
              >
                {name}
              </Menu.Item>
            ))}
          </Menu>
        )}
      </div>
      {auth.status === "authenticated" && (
        <Menu theme="dark">
          <Menu.Item
            key="logout"
            icon={<LogoutOutlined />}
            onClick={auth.logout}
          >
            Logout
          </Menu.Item>
        </Menu>
      )}
    </div>
  );
};

export default NavMenu;
