// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/cssWithMappingToString.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".styles-module__date-picker--3sy--{width:130px}", "",{"version":3,"sources":["webpack://./src/pages/EditorPage/PlanStartDateInput/styles.module.css"],"names":[],"mappings":"AAAA,mCACE,WAAA","sourcesContent":[".date-picker {\n  width: 130px;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"date-picker": "styles-module__date-picker--3sy--"
};
export default ___CSS_LOADER_EXPORT___;
