import { Spin } from "antd";
import _ from "lodash";
import React from "react";
import { useProject } from "../../../App/state";
import { useBatchesQuery } from "../../../generated/types";
import { Batch } from "../../../types";
import { Dropdown } from "./Dropdown";

type SingleBatchSelectorProps = {
  onBatchSelected: (batch: Batch) => any;
};

export const SingleBatchSelector = (props: SingleBatchSelectorProps) => {
  const project = useProject();

  const { data, loading, error } = useBatchesQuery({
    variables: {
      ...project,
    },
  });

  const batches: Batch[] | null = React.useMemo(() => {
    if (data?.batches != null) {
      return _(data.batches)
        .sortBy((batch) => batch.timestamp)
        .reverse()
        .value();
    } else {
      return null;
    }
  }, [data?.batches]);

  if (loading) {
    return <Spin />;
  }

  if (error || !data || !batches) {
    return <div> Error fetching batches </div>;
  }

  return (
    <Dropdown
      batches={batches}
      onChange={props.onBatchSelected}
      className={"batch dropdown"}
    />
  );
};
