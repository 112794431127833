import { SequenceFragment } from "../../generated/types";
import { DisplayTreeNode, DisplayTreeNodeType } from "../DisplayTree/types";
import { Scene, Sequence, SpatialConfigTreeNode } from "./types";

export const isSceneWithCoordinates = (
  scene: Scene,
): scene is Required<Scene> => {
  return scene.x !== undefined && scene.y !== undefined;
};

export const isSceneWithCoordinatesWithoutAlias = (
  scene: Scene,
): scene is Required<Scene> => {
  return scene.x !== undefined && scene.y !== undefined && !scene.aliasSceneId;
};

export const isNodeSpace = (node: Pick<DisplayTreeNode, "nodeType">): boolean =>
  node.nodeType === DisplayTreeNodeType.Space;

export const isNodeScene = (node: Pick<DisplayTreeNode, "nodeType">): boolean =>
  node.nodeType === DisplayTreeNodeType.Scene;

export const isNodeFolder = (
  node: Pick<DisplayTreeNode, "nodeType">,
): boolean => node.nodeType === DisplayTreeNodeType.Folder;

export const transformSequenceFromGqlResponse: (
  data: SequenceFragment[],
) => Sequence[] = (data) =>
  data.map((s) => ({
    id: s.id,
    floorPlanId: s.floorplan.id,
    sequencePrefix: s.sequencePrefix,
    scanLevel: s.scanLevel,
    sceneIds: s.scenes.map((scene) => scene.id),
  }));

export const areNodesSiblings = (nodes: SpatialConfigTreeNode[]) => {
  const lastAncestors = new Set(
    nodes.map((n) => (n.parentSpaceId ? n.parentSpaceId : null)),
  );
  return lastAncestors.size === 1;
};

export const multiSelectSelection = (
  existingSelection: SpatialConfigTreeNode[],
  node: SpatialConfigTreeNode,
): SpatialConfigTreeNode[] | undefined => {
  if (existingSelection.some((s) => s.key === node.key)) {
    return existingSelection.filter((s) => s.key !== node.key);
  }
  return [...existingSelection, node];
};
