// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/cssWithMappingToString.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".EditorPage-module__tab-display--2Msrr .ant-tabs-nav-wrap{justify-content:flex-end}.EditorPage-module__layout--1pvj- .ant-layout-sider{height:100%}.EditorPage-module__layout--1pvj- .ant-tabs-extra-content{position:absolute}.EditorPage-module__editor-title--2SZrI{width:100%;overflow-x:auto}.EditorPage-module__editor-title--2SZrI>div:first-child{width:100%}.EditorPage-module__side-layout--3_7rq{background-color:#fff !important}.EditorPage-module__footer-layout--2TItg{background-color:#fff !important}", "",{"version":3,"sources":["webpack://./src/pages/EditorPage/EditorPage.module.css"],"names":[],"mappings":"AACE,0DACE,wBAAA,CAKF,oDACE,WAAA,CAEF,0DACE,iBAAA,CAIJ,wCACE,UAAA,CACA,eAAA,CACA,wDACE,UAAA,CAIJ,uCACE,gCAAA,CAGF,yCACE,gCAAA","sourcesContent":[".tab-display {\n  :global(.ant-tabs-nav-wrap) {\n    justify-content: flex-end;\n  }\n}\n\n.layout {\n  :global(.ant-layout-sider) {\n    height: 100%;\n  }\n  :global(.ant-tabs-extra-content) {\n    position: absolute;\n  }\n}\n\n.editor-title {\n  width: 100%;\n  overflow-x: auto;\n  > div:first-child {\n    width: 100%;\n  };\n}\n\n.side-layout {\n  background-color: white !important;\n}\n\n.footer-layout {\n  background-color: white !important;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"tab-display": "EditorPage-module__tab-display--2Msrr",
	"layout": "EditorPage-module__layout--1pvj-",
	"editor-title": "EditorPage-module__editor-title--2SZrI",
	"side-layout": "EditorPage-module__side-layout--3_7rq",
	"footer-layout": "EditorPage-module__footer-layout--2TItg"
};
export default ___CSS_LOADER_EXPORT___;
