import { Coordinates } from "./types";

const FOV = {
  min: 42,
  max: 100,
};
const FOV_STEP = 5;

export default class LockZoom implements Autodesk.Viewing.ToolInterface {
  private initialPosition: Coordinates;

  constructor(
    private viewer: Autodesk.Viewing.GuiViewer3D,
    initialPosition: Coordinates,
  ) {
    this.viewer = viewer;
    this.initialPosition = initialPosition;
  }

  private names: string[] = ["LockZoom"];

  /**
   * This method should return an array containing the names of all tools implemented by this class.
   * Often this would be a single name but it is possible to support multiple interactions with a single tool.
   * When this tool is registered with the ToolController each name gets registered as an available tool.
   * @returns {array} Array of strings. Should not be empty.
   */
  getNames(): string[] {
    return this.names;
  }
  /**
   * This is an optional convenience method to obtain the first name of this tool.
   * @returns {string} The tools default name.
   */
  getName(): string {
    return this.names[0];
  }
  /**
   * This method should return the priority of the tool inside the tool stack.
   * A tool with higher priority will get events first.
   * @returns {number} The tool's priority.
   */
  getPriority(): number {
    return 100;
  }

  /**
   * This method is called by {@link Autodesk.Viewing.ToolController#registerTool}.
   * Use this for initialization.
   */
  register(): void {
    // This method is intentionally left empty, but is required for the ToolInterface
  }

  /**
   * This method is called by {@link Autodesk.Viewing.ToolController#deregisterTool}.
   * Use this to clean up your tool.
   */

  deregister(): void {
    // This method is intentionally left empty, but is required for the ToolInterface
  }

  /**
   * The activate method is called by the ToolController when it adds this tool to the list of those
   * to receive event handling calls. Once activated, a tool's "handle*" methods may be called
   * if no other higher priority tool handles the given event. Each active tool's "update" method also gets
   * called once during each redraw loop.
   * @param {string} name - The name under which the tool has been activated.
   * @param {Autodesk.Viewing.Viewer3D} viewerApi - Viewer instance.
   */
  activate(): void {
    this.viewer.setFOV(FOV.max);
  }

  /**
   * The deactivate method is called by the ToolController when it removes this tool from the list of those
   * to receive event handling calls. Once deactivated, a tool's "handle*" methods and "update" method
   * will no longer be called.
   * @param {string} name - The name under which the tool has been deactivated.
   */
  deactivate(): void {
    // This method is intentionally left empty, but is required for the ToolInterface
  }

  /**
   * The update method is called by the ToolController once per frame and provides each tool
   * with the oportunity to make modifications to the scene or the view.
   * @param {number} highResTimestamp - The process timestamp passed to requestAnimationFrame by the web browser.
   * @returns {boolean} A state value indicating whether the tool has modified the view or the scene
   * and a full refresh is required.
   */
  update(): boolean {
    return false;
  }

  /**
   * This method is called when a keyboard button is depressed.
   * @param {KeyboardEvent} event - The event object that triggered this call.
   * @param {number} keyCode - The numerical key code identifying the key that was depressed.
   * Note that the keyCode parameter value may be different that the value indicated in the event object
   * due to key re-mapping preferences that may be applied. This value should be respected
   * over the value in the event object.
   * @returns {boolean} True if this tool wishes to consume the event and false to continue to pass the event
   * to lower priority active tools.
   */

  handleWheelInput(delta: number): boolean {
    const currentFov = this.viewer.getFOV(); // Get the current FOV

    // Calculate the new FOV (multiply with -1 to change direction)
    let newFov = currentFov - Math.sign(delta) * FOV_STEP;

    // Ensure the FOV stays within a reasonable range
    newFov = Math.max(FOV.min, Math.min(FOV.max, newFov));

    this.viewer.setFOV(newFov);

    /**the wheel action changes the camera position for some reason, 
    which causes a flicker when zooming in/out,
    so setting the position each time we zoom (change FOV)
    **/
    this.viewer.navigation.setPosition(
      new THREE.Vector3(
        this.initialPosition.x,
        this.initialPosition.y,
        this.initialPosition.z,
      ),
    );

    return true; // Prevent the default zoom behavior
  }
}
