import { Alert, Spin } from "antd";
import React from "react";
import { useGetAggservSyncDateQuery } from "../../generated/types";
import pageStyles from "../../pages/pageStyles.module.css";
import { Project } from "../../types";
import styles from "./module.css";

const POLL_INTERVAL = 1000 * 60; // 1 minute

const getStatus = (loading: boolean, syncTime: Date | null) => {
  if (loading) {
    return <Spin />;
  }
  if (syncTime === null) {
    return (
      <Alert
        type="error"
        showIcon
        message={"Aggregation service has never been synced for this project"}
      />
    );
  }
  return (
    <Alert
      type="info"
      showIcon
      message={`Aggregation service was synced at: ${syncTime}`}
    />
  );
};

export const AggservStatus = ({ project }: { project: Project }) => {
  const { data, loading } = useGetAggservSyncDateQuery({
    variables: {
      tenant: project,
    },
    pollInterval: POLL_INTERVAL,
  });

  const syncTime = data?.aggservSyncDate
    ? new Date(data.aggservSyncDate)
    : null;

  return (
    <div className={pageStyles["project-status-container"]}>
      <header className={styles.header}>Aggregation Service Status</header>
      <div className={styles.status}>{getStatus(loading, syncTime)}</div>
    </div>
  );
};
