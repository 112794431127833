import { Empty, Spin } from "antd";
import _ from "lodash";
import React, { useMemo } from "react";
import {
  QaSpotlightFragment,
  useGetSpotlightsForRootSpaceQuery,
} from "../../generated/types";
import { Project } from "../../types";
import { SpotlightQA } from "./SpotlightQA";
import { Spotlight } from "./types";

const convertSpotlight = (
  spotlight: QaSpotlightFragment,
  batchId: string,
): Spotlight => ({
  id: spotlight.id,
  type: spotlight.type,
  mapping: spotlight.mapping
    ? {
        id: spotlight.mapping.id,
        componentName: spotlight.mapping.component.name,
        subcontractor: spotlight.mapping.component.subcontractor?.name,
      }
    : null,
  isNew: spotlight.createdInBatch.id === batchId,
  sceneId: spotlight.scene?.id,
  yaw: spotlight.location.yaw,
  pitch: spotlight.location.pitch,
  createdInBatchId: spotlight.createdInBatch.id,
  resolvedInBatchId: spotlight.resolvedInBatch?.id,
});

const spotlightSortKey = (spotlight: Spotlight): string =>
  `${spotlight.sceneId} ${spotlight.yaw} ${spotlight.pitch}`;

type SpotlightQAContainerProps = {
  batchId: string;
  rootSpaceId: string;
  project: Project;
  onSpotlightSelect: (spotlight: Spotlight) => void;
};

export const SpotlightQAContainer = (props: SpotlightQAContainerProps) => {
  const { batchId, rootSpaceId, project, onSpotlightSelect } = props;
  const { customer, project: proj, scope } = project;
  const { data, loading, error, previousData } =
    useGetSpotlightsForRootSpaceQuery({
      variables: {
        spaceId: rootSpaceId,
        batchId: batchId,
        customer: customer,
        project: proj,
        scope: scope,
      },
    });
  const spotlights: Spotlight[] = useMemo(() => {
    // Also look at previousData to workaround the bug described here
    // https://disperse.atlassian.net/browse/DEVEFF-233
    const validData = data ?? previousData;
    if (!validData) {
      return [];
    }
    const spotlightsOnDescendantSpaces = _.flattenDeep(
      validData.space.descendantSpaces.map((space) =>
        space.scenes.map((scene) => scene.spotlights),
      ),
    );
    const spotlightsOnRoot = _.flattenDeep(
      validData.space.scenes.map((scene) => scene.spotlights),
    );
    const flatSpotlights = [
      ...spotlightsOnDescendantSpaces,
      ...spotlightsOnRoot,
    ].map((sp) => convertSpotlight(sp, batchId));

    // sort them so the order is deterministic between uses
    return flatSpotlights.sort((sp1, sp2) =>
      spotlightSortKey(sp1).localeCompare(spotlightSortKey(sp2)),
    );
  }, [data, previousData, batchId]);

  if (error) {
    return <div>Something went wrong with fetching errors: {error}</div>;
  }
  if (loading) {
    return <Spin />;
  }

  return spotlights.length > 0 ? (
    <SpotlightQA
      spotlights={spotlights}
      batchId={batchId}
      onSelect={onSpotlightSelect}
    />
  ) : (
    <Empty description={"No Spotlights exists"} />
  );
};
