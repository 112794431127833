import { Modal } from "antd";
import TextArea from "antd/lib/input/TextArea";
import React from "react";
import { useDualModeState } from "../../pages/DualModePage/state";
import { IssueTypeConfig } from "./types";

interface ModalContentsProps {
  message: string | undefined;
  onChangeMessage: (evt: React.ChangeEvent<HTMLTextAreaElement>) => void;
  arrowSceneNavigation: (evt: KeyboardEvent) => void;
}

const ModalContents = ({
  message,
  onChangeMessage,
  arrowSceneNavigation,
}: ModalContentsProps) => {
  const { currentSceneId } = useDualModeState();

  React.useEffect(() => {
    if (currentSceneId != null) {
      removeEventListener("keydown", arrowSceneNavigation);
      return () => {
        addEventListener("keydown", arrowSceneNavigation);
      };
    }
  }, [currentSceneId, arrowSceneNavigation]);

  return (
    <TextArea
      rows={4}
      placeholder="Please describe the issue here ..."
      value={message}
      onChange={onChangeMessage}
    />
  );
};

interface MessageModalProps {
  isOpen: boolean;
  onClickConfirm: (message: string | undefined) => void;
  onClickCancel: () => void;
  loading: boolean;
  issueConfig: IssueTypeConfig | undefined;
  message: string | undefined;
  arrowSceneNavigation: (evt: KeyboardEvent) => void;
}

export const MessageModal: React.FC<MessageModalProps> = (
  props: MessageModalProps,
) => {
  const {
    isOpen,
    loading,
    message,
    issueConfig,
    onClickConfirm,
    onClickCancel,
    arrowSceneNavigation,
  } = props;
  const [dirtyMessage, setDirtyMessage] = React.useState(message);

  return (
    <Modal
      visible={isOpen}
      title={`Report a ${issueConfig?.label} issue`}
      confirmLoading={loading}
      okText="Confirm"
      onOk={() => onClickConfirm(dirtyMessage)}
      onCancel={onClickCancel}
      destroyOnClose={true}
    >
      <ModalContents
        message={dirtyMessage}
        onChangeMessage={(e) => setDirtyMessage(e.target.value)}
        arrowSceneNavigation={arrowSceneNavigation}
      />
    </Modal>
  );
};
