import { Space as AntdSpace, Col, Form, Input, Row } from "antd";
import _ from "lodash";
import React, { useCallback, useMemo } from "react";
import { useNotification } from "../../../contexts/Notifications";
import { usePatchSpacesMutation } from "../../../generated/types";
import { Project } from "../../../types";
import { Space } from "../../SpacesTree/tree-nodes";
import { UPDATED_SPACES_ERROR, UPDATED_SPACES_SUCCESS } from "../messages";
import { CategorySelectorFormItem } from "./CategorySelectorFormItem";
import { IndeterminateCheckboxFormItem } from "./IndeterminateCheckboxFormItem";
import { TypeSelectorFormItem } from "./TypeSelectorFormItem";

interface EditSpaceFormProps {
  id?: string;
  project: Project;
  selectedSpaces: Space[];
  disabled?: boolean;
  onSubmit?: (scenes: string[]) => void;
}

export const EditSpaceForm = (props: EditSpaceFormProps) => {
  const { id, project, selectedSpaces, disabled, onSubmit } = props;
  const { customer, project: proj, scope } = project;

  const [form] = Form.useForm();

  const multipleNames = useMemo(
    () => _.uniq(selectedSpaces.map((space) => space.name)).length > 1,
    [selectedSpaces],
  );

  const defaultName = useMemo(
    () =>
      selectedSpaces.length > 0 && !multipleNames
        ? selectedSpaces[0].name
        : null,
    [multipleNames, selectedSpaces],
  );

  React.useEffect(() => {
    form.setFieldsValue({ name: defaultName });
  }, [defaultName, form]);

  const multipleCategories = useMemo(
    () => _.uniq(selectedSpaces.map((space) => space.category)).length > 1,
    [selectedSpaces],
  );

  const defaultCategory = useMemo(
    () =>
      selectedSpaces.length > 0 && !multipleCategories
        ? selectedSpaces[0].category
        : null,
    [multipleCategories, selectedSpaces],
  );

  React.useEffect(() => {
    form.setFieldsValue({ category: defaultCategory });
  }, [defaultCategory, form]);

  const multipleTypes = useMemo(
    () => _.uniq(selectedSpaces.map((space) => space.type)).length > 1,
    [selectedSpaces],
  );

  const defaultType = useMemo(
    () =>
      selectedSpaces.length > 0 && !multipleTypes
        ? selectedSpaces[0].type
        : null,
    [multipleTypes, selectedSpaces],
  );

  React.useEffect(() => {
    form.setFieldsValue({ type: defaultType });
  }, [defaultType, form]);

  React.useEffect(() => {
    const someHoistSpace = selectedSpaces.some((space) =>
      space.tags?.includes("hoist"),
    );
    const someNonHoistSpace = selectedSpaces.some(
      (space) => !space.tags?.includes("hoist"),
    );
    if (someHoistSpace && someNonHoistSpace) {
      form.setFieldsValue({ hoist: "indeterminate" });
    } else {
      form.setFieldsValue({ hoist: someHoistSpace ? "checked" : "unchecked" });
    }
  }, [selectedSpaces, form]);

  const notify = useNotification();
  const [patchSpaces] = usePatchSpacesMutation({
    onCompleted: (data) => {
      notify(UPDATED_SPACES_SUCCESS, "success");
      if (data.patchSpaces.length > 0) {
        onSubmit?.(data.patchSpaces.map((space) => space.id));
      }
    },
    onError: (error) => notify(`${UPDATED_SPACES_ERROR} - ${error}`, "error"),
  });

  const handleSubmit = useCallback(() => {
    const name = form.getFieldValue("name");
    const category = form.getFieldValue("category");
    const type = form.getFieldValue("type");
    const hoist = form.getFieldValue("hoist");

    return patchSpaces({
      variables: {
        customer: customer,
        project: proj,
        scope: scope,
        ids: selectedSpaces.map((s) => s.id),
        update: {
          ...(name != null && name !== "" && { setName: { newValue: name } }),
          ...(category != null && { setCategory: { newValue: category } }),
          ...(type != null && { setType: { newValue: type } }),
          ...(hoist === "checked" && { addTags: ["hoist"] }),
          ...(hoist === "unchecked" && { removeTags: ["hoist"] }),
        },
      },
    });
  }, [proj, scope, customer, patchSpaces, form, selectedSpaces]);

  return (
    <Form id={id} form={form} onFinish={handleSubmit}>
      <AntdSpace direction={"vertical"} style={{ width: "100%" }}>
        <Row gutter={8}>
          <Col span={16}>
            <Form.Item
              name="name"
              label="Name"
              rules={[
                {
                  message: "Required",
                  required: !multipleNames,
                },
              ]}
            >
              <Input
                placeholder={multipleNames ? "Multiple selected" : ""}
                disabled={disabled}
              />
            </Form.Item>
          </Col>
          <Col span={8}>
            <Form.Item name="hoist" label="Hoist">
              <IndeterminateCheckboxFormItem disabled={disabled} />
            </Form.Item>
          </Col>
        </Row>
        <Row gutter={8}>
          <Col span={14}>
            <Form.Item name="category" label="Category">
              {/* Antd will fill in the missing props */}
              <CategorySelectorFormItem
                placeholder={multipleCategories ? "Multiple selected" : ""}
                project={project}
                disabled={disabled}
              />
            </Form.Item>
          </Col>
          <Col span={10}>
            <Form.Item name="type" label="Type">
              {/* Antd will fill in the missing props */}
              <TypeSelectorFormItem
                placeholder={multipleTypes ? "Multiple selected" : ""}
                project={project}
                disabled={disabled}
              />
            </Form.Item>
          </Col>
        </Row>
      </AntdSpace>
    </Form>
  );
};
