import { Filter, MappingState } from "../../../pages/EditorPage/types";
import { matchesSearchTerm } from "../table-utils";
import { ComponentWithMappedSpaces } from "../types";

export const applyFilter = (
  components: ComponentWithMappedSpaces[],
  filter: Filter,
): ComponentWithMappedSpaces[] => {
  const filteredByName = components.filter((c) =>
    filter.name ? matchesSearchTerm(c.name, filter.name) : c,
  );
  return componentsFilteredByMappingState(filteredByName, filter.mappingState);
};

const componentsFilteredByMappingState = (
  components: ComponentWithMappedSpaces[],
  mappingState?: MappingState,
): ComponentWithMappedSpaces[] => {
  switch (mappingState) {
    case "Enabled":
      return components.filter((c) =>
        c.componentMappings.some((m) => !m.disabled),
      );
    case "Disabled":
      return components.filter((c) =>
        c.componentMappings.some((m) => m.disabled),
      );
    case "Missing":
      return components.filter((c) => c.componentMappings.length === 0);
    default:
      return components;
  }
};
