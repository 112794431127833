import { Select, Tooltip } from "antd";
import React from "react";
import { useNotification } from "../../../contexts/Notifications";
import { useGetAllSpaceTypesQuery } from "../../../generated/types";
import { Project } from "../../../types";
import styles from "../selectorStyles.module.css";
import { TYPES_LOADING_ERROR } from "./messages";

type SpaceTypeSelectorProps = {
  selectedTypes: string[];
  project: Project;
  disabled?: boolean;
  placeholder?: string;
  onChange: (value: string[]) => void;
  allowCreatingNewValue?: boolean;
};

export const SpaceTypeSelector = (props: SpaceTypeSelectorProps) => {
  const {
    data: allTypeTags,
    error,
    loading,
  } = useGetAllSpaceTypesQuery({
    variables: {
      customer: props.project.customer,
      project: props.project.project,
      scope: props.project.scope,
    },
  });
  const notify = useNotification();

  if (error) {
    notify(TYPES_LOADING_ERROR, "error");
  }

  return (
    <Select
      allowClear
      disabled={props.disabled || !!error}
      loading={loading}
      className={styles.selector}
      value={props.selectedTypes}
      mode={props.allowCreatingNewValue ? "tags" : "multiple"}
      placeholder={props.placeholder ?? "Select..."}
      onChange={props.onChange}
    >
      {allTypeTags?.spaceTypes.map((tag) => (
        <Select.Option key={tag} value={tag}>
          <Tooltip title={tag}>{tag}</Tooltip>
        </Select.Option>
      ))}
    </Select>
  );
};
