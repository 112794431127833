import _ from "lodash";
import {
  GetSpaceWithMappingsQuery,
  MappingFragment,
} from "../../generated/types";
import { AggregatedProgressBase } from "../../types";

export interface ComponentInfo {
  id: string;
  name: string;
  ancestorPath: string[];
  guiIndicesPath: number[];
}

export interface ComponentMapping {
  spaceId: string;
  spaceName: string;
  disabled: boolean;
  mappingId: string;
  weight?: number;
}

export interface MappingInfo extends ComponentMapping {
  component: ComponentInfo;
}

export interface ComponentWithProgresses extends ComponentInfo {
  aggregatedProgresses: AggregatedProgressBase[];
}

export interface ComponentWithMappedSpaces extends ComponentInfo {
  componentMappings: ComponentMapping[];
}

export type RawMappingProgress = {
  componentId: string;
  spaceId: string;
  batchId: string;
  progress: number;
};

export enum RecordType {
  ancestors,
  component,
}

export type TableName = "progresses" | "progressHistory" | "mappings" | "dates";

export const componentFromGraphQlComponent = (component): ComponentInfo => ({
  id: component.id,
  name: component.name,
  ancestorPath: component.parentTask.ancestors.map((t) => t.name),
  guiIndicesPath: [
    ...component.parentTask.ancestors.map((t) => t.guiIndex),
    component.guiIndex,
  ],
});

export const spacesWithMappingsFromGraphQlSpaces = (
  spacesResponseData?: GetSpaceWithMappingsQuery,
) =>
  spacesResponseData
    ? [
        _.pick(spacesResponseData.spacesByIds[0], ["id", "name", "mappings"]),
        ...spacesResponseData.spacesByIds[0].subSpaces,
      ]
    : [];

export const mappingFromGraphQlMapping = (
  mapping: MappingFragment,
  spaceId: string,
  spaceName: string,
) => ({
  component: {
    id: mapping.component.id,
  },
  spaceId: spaceId,
  spaceName: spaceName,
  mappingId: mapping.id,
  disabled: mapping.disabled,
  weight: mapping.weight ?? undefined,
});
