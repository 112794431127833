import { Spin } from "antd";
import React from "react";
import { useProject } from "../../App/state";
import { useGetTaskForTreeNodeQuery } from "../../generated/types";
import { CopyToClipboardButton } from "../CopyToClipboardButton";

type NodeProps = {
  taskId: string;
};

export const TaskTreeNode = (props: NodeProps) => {
  const project = useProject();
  const { data, loading, error } = useGetTaskForTreeNodeQuery({
    variables: {
      ...project,
      taskId: props.taskId,
    },
  });
  if (loading) {
    return <Spin />;
  } else if (error || !data) {
    console.error(error);
    return <span>Error loading task!</span>;
  } else {
    return (
      <span>
        {data.task.name}
        <CopyToClipboardButton text={props.taskId} />
      </span>
    );
  }
};
