import React, { useCallback, useMemo } from "react";
import { ColorsLegend } from "../DrawingTool/ColorsLegend";
import { DrawingTool } from "../DrawingTool/DrawingTool";
import {
  DEFAULT_NODE_COLOR,
  DEFAULT_NODE_OPACITY,
  DEFAULT_STROKE_MM,
  DEFAULT_TEXT_COLOR,
  SVG_CIRCLE_SIZE_MM,
  colorPerScanningSequence,
  colorPerSceneScanType,
  getNodeColorPerScanType,
  getNodeColorPerSequence,
} from "../DrawingTool/drawing-utils";
import { SimpleText } from "../DrawingTool/shapes";
import { DualColorCircle } from "../DrawingTool/shapes/DualColorCircle";
import {
  Drawing,
  ScanSequenceLevelType,
  Scene,
  SceneScanType,
  Sequence,
} from "../SpatialConfigurationMode/types";
import { isSceneWithCoordinatesWithoutAlias } from "../SpatialConfigurationMode/utils";
import { ColorMode } from "./SequenceMode";
import {
  getNodeColorsPerSequence,
  getNodeLabel,
  getSceneScanningLevels,
} from "./utils";

const getColorByColorMode = (
  colorMode: ColorMode,
  scanType: SceneScanType,
  activeSequence?: ScanSequenceLevelType,
) => {
  if (colorMode === ColorMode.SCENE_TYPES) {
    return getNodeColorPerScanType(scanType);
  } else if (colorMode === ColorMode.SCANNING_STAGE && activeSequence) {
    return getNodeColorPerSequence(activeSequence);
  } else {
    return DEFAULT_NODE_COLOR;
  }
};

type DrawingToolContainerProps = {
  scenes: Scene[];
  onDrawingClick?: (x: number, y: number) => void;
  drawing: Drawing;
  sequencesPerPhase: { [key: string]: Sequence[] };
  selectedFilter?: ScanSequenceLevelType;
  colorMode: ColorMode;
};

export const DrawingToolContainer = (props: DrawingToolContainerProps) => {
  const {
    scenes,
    onDrawingClick,
    drawing,
    sequencesPerPhase,
    selectedFilter,
    colorMode,
  } = props;

  const phasesPerSceneId = useMemo(
    () =>
      getSceneScanningLevels(
        scenes.map((scene) => scene.id),
        sequencesPerPhase,
      ),
    [scenes, sequencesPerPhase],
  );

  const getDualColorCircleColors = useCallback(
    (scene: Scene) => {
      const sceneScanningPhases = phasesPerSceneId[scene.id];
      switch (colorMode) {
        case ColorMode.SCENE_TYPES:
          return [getNodeColorPerScanType(scene.scanType)];
        case ColorMode.SCANNING_STAGE: {
          const colors = getNodeColorsPerSequence(sceneScanningPhases);
          return selectedFilter
            ? colors.filter(
                (color) =>
                  selectedFilter &&
                  color !== getNodeColorPerSequence(selectedFilter),
              )
            : [DEFAULT_NODE_COLOR];
        }
      }
    },
    [selectedFilter, colorMode, phasesPerSceneId],
  );

  const drawingNodes = useMemo(
    () =>
      scenes.filter(isSceneWithCoordinatesWithoutAlias).map((scene) => {
        const label =
          selectedFilter && sequencesPerPhase[selectedFilter]
            ? getNodeLabel(scene, sequencesPerPhase[selectedFilter])
            : undefined;

        return label ? (
          <SimpleText
            key={scene.id}
            node={{
              id: scene.id,
              x: scene.x,
              y: scene.y,
              color: DEFAULT_TEXT_COLOR,
              label: label,
              strokeWidth: DEFAULT_STROKE_MM,
              strokeColor: getColorByColorMode(
                colorMode,
                scene.scanType,
                selectedFilter,
              ),
              opacity: DEFAULT_NODE_OPACITY,
            }}
          />
        ) : (
          <DualColorCircle
            key={scene.id}
            node={{
              id: scene.id,
              x: scene.x,
              y: scene.y,
              colors: getDualColorCircleColors(scene),
              opacity: DEFAULT_NODE_OPACITY,
              size: SVG_CIRCLE_SIZE_MM,
            }}
          />
        );
      }),
    [
      scenes,
      sequencesPerPhase,
      selectedFilter,
      colorMode,
      getDualColorCircleColors,
    ],
  );

  return (
    <>
      <DrawingTool
        nodes={drawingNodes}
        onDrawingClick={onDrawingClick}
        drawing={drawing}
      />
      <ColorsLegend
        colors={
          colorMode === ColorMode.SCENE_TYPES
            ? colorPerSceneScanType
            : colorPerScanningSequence
        }
      />
    </>
  );
};
