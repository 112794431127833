import { EditOutlined } from "@ant-design/icons";
import { Button, Form, Input, Modal, Tooltip } from "antd";
import React, { useEffect, useState } from "react";
import { Task } from "../../types";

type TaskUpdateModalProps = {
  task: Task;
  onUpdate: (name: string) => Promise<void>;
};

export const TaskUpdateModal = (props: TaskUpdateModalProps) => {
  const { task, onUpdate } = props;
  const { name: taskName } = task;
  const [isVisible, setIsVisible] = useState(false);
  const [updateTaskForm] = Form.useForm();

  useEffect(() => {
    updateTaskForm.setFieldsValue({ name: taskName });
  }, [taskName, updateTaskForm]);

  return (
    <>
      <Tooltip title="edit task">
        <Button
          type="primary"
          icon={<EditOutlined />}
          onClick={() => setIsVisible(true)}
        ></Button>
      </Tooltip>
      <Modal
        title="Update Task"
        visible={isVisible}
        onOk={async () => {
          try {
            const values = await updateTaskForm.validateFields();
            updateTaskForm.resetFields();
            await onUpdate(values.name);
            setIsVisible(false);
          } catch (errorInfo) {
            console.warn("Validation failed", errorInfo);
          }
        }}
        onCancel={() => setIsVisible(false)}
      >
        <Form form={updateTaskForm}>
          <Form.Item
            name="name"
            label="Name"
            rules={[{ required: true, min: 1 }]}
          >
            <Input />
          </Form.Item>
        </Form>
      </Modal>
    </>
  );
};
