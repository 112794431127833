import {
  CalendarOutlined,
  ForkOutlined,
  HistoryOutlined,
  PercentageOutlined,
} from "@ant-design/icons";
import { Card, Empty, Layout, Space, Tooltip } from "antd";
import React, { useState } from "react";
import { useProject } from "../../App/state";
import { DisplayTreeNode } from "../../components/DisplayTree/types";
import { HolidayTable } from "../../components/HolidayTable";
import { MappingsTableContainer } from "../../components/ProgressTables/MappingsTable/MappingsTableContainer";
import { TablesContainer } from "../../components/ProgressTables/TablesContainer";
import { TableName } from "../../components/ProgressTables/types";
import { ProjectWorkQueueStatus } from "../../components/ProjectWorkQueueStatus/ProjectWorkQueueStatus";
import { PlanSelector } from "../../components/Selectors/PlanSelector/PlanSelector";
import { SpacesTree } from "../../components/SpacesTree/SpacesTree";
import { PalmTreeIcon } from "../../icons";
import { Plan } from "../../types";
import { ClearPlanDatesModal } from "./ClearPlanDatesModal";
import { ComponentFilter } from "./ComponentFilter";
import { DatesUploadModal } from "./DatesUploadModal";
import styles from "./EditorPage.module.css";
import { Footer } from "./EditorPageFooter";
import { PlanStartDateInput } from "./PlanStartDateInput";
import { ProgressUploadModal } from "./ProgressUploadModal";
import { ReweightButton } from "./ReweightButton";
import { Filter } from "./types";

const tabList = [
  {
    key: "dates",
    tab: (
      <Tooltip title="Dates Overview">
        <CalendarOutlined />
      </Tooltip>
    ),
  },
  {
    key: "holidays",
    tab: (
      <Tooltip title="Holiday Overview">
        <PalmTreeIcon />
      </Tooltip>
    ),
  },
  {
    key: "progresses",
    tab: (
      <Tooltip title="Current Progress Overview">
        <PercentageOutlined />
      </Tooltip>
    ),
  },
  {
    key: "progressHistory",
    tab: (
      <Tooltip title="Progress History">
        <HistoryOutlined />
      </Tooltip>
    ),
  },
  {
    key: "mappings",
    tab: (
      <Tooltip title="Mappings Overview">
        <ForkOutlined />
      </Tooltip>
    ),
  },
];

type TabType = TableName | "holidays";

type EditorTableProps = {
  tableType: TableName;
  planId?: string;
  spaceId?: string;
  filter: Filter;
};

const EditorTable = (props: EditorTableProps) =>
  props.planId && props.spaceId ? (
    <TablesContainer
      planId={props.planId}
      spaceId={props.spaceId}
      tableType={props.tableType}
      filter={props.filter}
    />
  ) : (
    <Empty description="Please select plan and space." />
  );

export const EditorPage = () => {
  const project = useProject();
  const [plan, setPlan] = useState<Plan>();
  const [selectedSpaceId, setSelectedSpaceId] = useState<string>();
  const [state, setState] = useState<TabType>("progresses");
  const [filter, setFilter] = useState<Filter>({});
  const [isSpacesTreeCollapsed, setIsSpacesTreeCollapsed] = useState(false);

  const handleOnSelect = (selectedNode: DisplayTreeNode) => {
    setSelectedSpaceId(selectedNode.key);
  };

  const contentList = {
    dates: <Empty description="Please select plan and space." />,
    progresses: (
      <EditorTable
        tableType="progresses"
        planId={plan?.id}
        spaceId={selectedSpaceId}
        filter={filter}
      />
    ),
    progressHistory: (
      <EditorTable
        tableType="progressHistory"
        planId={plan?.id}
        spaceId={selectedSpaceId}
        filter={filter}
      />
    ),
    mappings:
      plan?.id && selectedSpaceId ? (
        <Space direction="vertical">
          <MappingsTableContainer
            project={project}
            planId={plan?.id}
            spaceId={selectedSpaceId}
            filter={filter}
          />
          <ReweightButton project={project} planId={plan?.id} />
        </Space>
      ) : (
        <Empty description="Please select plan and space." />
      ),
    holidays: <HolidayTable project={project} />,
  };

  return (
    <Layout className={styles["layout"]}>
      <Layout className={styles["layout"]}>
        <Layout.Sider
          className={styles["side-layout"]}
          width={"25vw"} // not trivial to set via css because of antd layout magic
          collapsedWidth={"12vw"}
          collapsible
          collapsed={isSpacesTreeCollapsed}
          onCollapse={(collapsed) => {
            setIsSpacesTreeCollapsed(collapsed);
          }}
          theme="light"
        >
          <SpacesTree onSelect={handleOnSelect} />
        </Layout.Sider>
        <Layout.Content>
          <Card
            className={styles["tab-display"]}
            onTabChange={(key) => setState(key as TableName)}
            title={
              <Space className={styles["editor-title"]}>
                <PlanSelector project={project} onSelect={setPlan} />
                <ProjectWorkQueueStatus project={project} />
                <PlanStartDateInput project={project} plan={plan} />
                <DatesUploadModal project={project} />
                {plan && <ClearPlanDatesModal project={project} plan={plan} />}
                <ProgressUploadModal project={project} />
              </Space>
            }
            tabBarExtraContent={
              // TODO: move this component filter into the specific tabs
              state === "mappings" ||
              state === "progressHistory" ||
              state === "progresses" ? (
                <Space>
                  <ComponentFilter
                    placeholder="name"
                    onChange={(newfilter: Filter) =>
                      setFilter((f) => ({ ...f, ...newfilter }))
                    }
                    tableName={state}
                  />
                </Space>
              ) : undefined
            }
            tabList={tabList}
            defaultActiveTabKey={"progresses"}
          >
            {contentList[state]}
          </Card>
        </Layout.Content>
      </Layout>
      {isSpacesTreeCollapsed && selectedSpaceId && (
        <Footer selectedSpaceId={selectedSpaceId} project={project} />
      )}
    </Layout>
  );
};
