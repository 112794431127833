import { Col, Row, Space } from "antd";
import React from "react";
import { SpaceAndSceneDropdown } from "./SpaceAndSceneDropdown";
import { SubSpaceDropdown } from "./SubspaceDropdown";
import styles from "./style.module.css";

interface DropdownChainProps {
  spaces: { id: string; name: string }[];
  scene?: { id: string; name: string };
  onChangeSpace: (spaceId: string) => void;
  onChangeScene: (sceneId: string) => void;
}

export const DropdownChain = ({
  spaces,
  scene,
  onChangeSpace,
  onChangeScene,
}: DropdownChainProps) => {
  // Antd grid has a width of 24 slots, colSpan defines how many will be taken up by one slot
  // setting it to 3 means we can have 8 dropdowns in a row, then it starts to wrap around
  // practically I think that should be enough for all intermediate ones.
  // The last slot is reserved for subspaces,
  // the one before it fills all of the remaining space since it carries the most important information for the architects.
  const colSpan = spaces.length < 8 ? 3 : 2;
  const lastColumnSpan = 22 - (spaces.length - 1) * colSpan;
  const finalIdx = spaces.length - 1;
  const finalSpace = spaces[finalIdx];
  return (
    <Row gutter={8} style={{ flex: "1" }}>
      <Col>
        <Space direction="vertical" className={styles["label-container"]}>
          <div className={styles.label}>Spaces</div>
          <div className={styles.label}>Scenes</div>
        </Space>
      </Col>
      {spaces.map((ancestor, i) => {
        const isFinalIndex = i === finalIdx;
        return (
          <Col span={isFinalIndex ? lastColumnSpan : colSpan} key={ancestor.id}>
            <SpaceAndSceneDropdown
              key={ancestor.id}
              space={ancestor}
              disableSpaceSelection={i === 0}
              selectedScene={isFinalIndex ? scene : undefined}
              onChangeSpace={onChangeSpace}
              onChangeScene={onChangeScene}
              wrapLine={isFinalIndex}
            />
          </Col>
        );
      })}
      {finalSpace !== undefined && (
        <Col span={colSpan} key="subspace">
          <SubSpaceDropdown
            spaceId={finalSpace.id}
            onChangeSpace={onChangeSpace}
          />
        </Col>
      )}
    </Row>
  );
};
