import { CheckOutlined, CloseOutlined } from "@ant-design/icons";
import { Button, Space } from "antd";
import _ from "lodash";
import React from "react";
import { useNotification } from "../../contexts/Notifications";
import { useUpdateComponentsMutation } from "../../generated/types";
import { Component, Project } from "../../types";
import { UPDATE_COMPONENTS_ERROR, UPDATE_COMPONENTS_SUCCESS } from "./messages";
import { ComponentData } from "./types";

type ComponentUpdateButtonProps = {
  project: Project;
  taskId?: string;
  existingComponents: Component[];
  componentDataToUpdate: Record<string, ComponentData>;
  onComponentsUpdate: () => any;
  onCancelUpdate: () => any;
};

export const ComponentUpdateButton = (props: ComponentUpdateButtonProps) => {
  const notify = useNotification();
  const [updateComponents] = useUpdateComponentsMutation({
    onCompleted: () => notify(UPDATE_COMPONENTS_SUCCESS, "success"),
    onError: () => notify(UPDATE_COMPONENTS_ERROR, "error"),
    refetchQueries: ["GetObjectTypes"],
  });

  const componentsById = React.useMemo(
    () =>
      _.mapValues(_.keyBy(props.existingComponents, "id"), (component) => ({
        id: component.id,
        name: component.name,
        weight: component.weight,
        subcontractorId: component.subcontractor?.id,
        typeId: component.type.id,
        object: component.object,
        tags: component.tags,
        guiIndex: component.guiIndex,
      })),
    [props.existingComponents],
  );

  const getUpdates = (
    componentsDictionary,
    componentDataToUpdate: Record<string, ComponentData>,
  ) =>
    Object.keys(props.componentDataToUpdate).map((componentId) => ({
      ...componentsDictionary[componentId],
      ...componentDataToUpdate[componentId],
    }));

  const onConfirmUpdate = async () => {
    const componentsForUpdate = getUpdates(
      componentsById,
      props.componentDataToUpdate,
    );
    await updateComponents({
      variables: {
        customer: props.project.customer,
        project: props.project.project,
        scope: props.project.scope,
        components: componentsForUpdate.map((c) => ({
          id: c.id,
          taskId: props.taskId,
          name: c.name,
          componentTypeId: c.typeId,
          subcontractorId: c.subcontractorId,
          weight: c.weight,
          guiIndex: c.guiIndex,
          object: c.object,
          tags: c.tags ?? [],
        })),
      },
    });
    props.onComponentsUpdate();
  };

  return (
    <Space>
      <Button type="primary" icon={<CheckOutlined />} onClick={onConfirmUpdate}>
        Confirm
      </Button>
      <Button
        type="primary"
        danger
        onClick={props.onCancelUpdate}
        icon={<CloseOutlined />}
      >
        Cancel
      </Button>
    </Space>
  );
};
