import { UploadOutlined } from "@ant-design/icons";
import { Button, Divider, Modal, Spin } from "antd";
import React, { useState } from "react";
import { useNotification } from "../../contexts/Notifications";
import {
  useGetSpaceNameByIdQuery,
  useUploadBuildingStructureMutation,
} from "../../generated/types";
import { Project } from "../../types";
import { DisplayTreeNode } from "../DisplayTree/types";
import { FileUploader } from "../FileUploader";
import { SpacesTree } from "../SpacesTree/SpacesTree";

interface BuildingStructureUploaderProps {
  project: Project;
}

export const BuildingStructureUploadButton = (
  props: BuildingStructureUploaderProps,
) => {
  const { project } = props;
  const [showModal, setShowModal] = useState(false);
  const [selectedSpaceId, setSelectedSpaceId] = useState<string>();
  const [uploadedFile, setUploadedFile] = useState<File>();
  const [uploadInProgress, setUploadInProgress] = useState(false);

  const notify = useNotification();

  const [uploadFile] = useUploadBuildingStructureMutation({
    onCompleted: () => {
      notify("Building structure was instantiated successfully", "success");
      setShowModal(false);
      setSelectedSpaceId(undefined);
      setUploadedFile(undefined);
    },
    onError: (error) =>
      notify(`Failed to instantiate building structure - ${error}`, "error"),
  });

  const { data: spaceNameData } = useGetSpaceNameByIdQuery({
    variables: {
      ...project,
      spaceId: selectedSpaceId,
    },
  });
  const selectedSpaceName = spaceNameData?.spacesByIds[0].name;

  const upload = async () => {
    setUploadInProgress(true);
    await uploadFile({
      variables: {
        ...project,
        parentId: selectedSpaceId || null,
        file: uploadedFile,
      },
    });
    setUploadInProgress(false);
  };

  return (
    <div>
      <Button
        block
        type="primary"
        icon={<UploadOutlined />}
        onClick={() => setShowModal(true)}
      >
        Upload Building Structure
      </Button>
      <Modal
        visible={showModal}
        title="Upload Building Structure Sheet"
        onCancel={() => setShowModal(false)}
        footer={
          <span>
            <Button danger onClick={() => setShowModal(false)}>
              Cancel
            </Button>
            <Button
              disabled={!uploadedFile}
              type={"primary"}
              icon={<UploadOutlined />}
              onClick={() => upload()}
            >
              Upload
            </Button>
          </span>
        }
      >
        <Spin spinning={uploadInProgress} tip="Upload in progress...">
          <SpacesTree
            onSelect={(selectedNode: DisplayTreeNode | null) => {
              setSelectedSpaceId(selectedNode?.key || undefined);
            }}
          />
          <Divider />

          <FileUploader
            title="Select Building Structure File"
            onFilesSelected={async (files) => {
              if (files.length !== 1) {
                notify(
                  "Upload failed, only one file can be uploaded at a time.",
                );
              } else {
                setUploadedFile(files[0]);
              }
            }}
          />
        </Spin>
        {uploadedFile ? (
          <p>
            Upload <b>{uploadedFile.name}</b> structure under{" "}
            <b>{selectedSpaceName || "Top Level"}</b>
          </p>
        ) : (
          ""
        )}
      </Modal>
    </div>
  );
};
