import { useCallback, useEffect, useReducer, useRef, useState } from "react";

interface Coordinates {
  x: number;
  y: number;
}

interface OffsetPixelCoordinates {
  offsetX: number;
  offsetY: number;
}

interface Zoom {
  deltaY: number;
}

enum ActionTypes {
  PAN = "PAN",
  PAN_START = "PAN_START",
  ZOOM = "ZOOM",
}

interface PanStartAction {
  type: ActionTypes.PAN_START;
  payload: OffsetPixelCoordinates;
}

interface PanAction {
  type: ActionTypes.PAN;
  payload: OffsetPixelCoordinates;
}

interface ZoomAction {
  type: ActionTypes.ZOOM;
  payload: OffsetPixelCoordinates & Zoom;
}

type Action = PanStartAction | PanAction | ZoomAction;

interface State {
  origin: Coordinates;
  prevScaledOffset: Coordinates;
  scale: number;
}

export interface DrawingSettings {
  origin: Coordinates;
  scale: number;
}

// MIN & MAX SCALE are fixed but might worth having them
// as parameters to zooming & panning.
const MIN_SCALE = 0.005;
const MAX_SCALE = 1.5;
const WHEEL_DELTA_Y_TO_DELTA_SCALE = 0.0001;

const wheelDeltaYToScale = (scale: number, deltaY: number): number => {
  const newWheelScale = scale - deltaY * WHEEL_DELTA_Y_TO_DELTA_SCALE;
  return Math.min(Math.max(newWheelScale, MIN_SCALE), MAX_SCALE);
};

const MIDDLE_MOUSE_CLICK_EVENT = 1;
const RIGHT_MOUSE_CLICK_EVENT = 2;

const isRightOrMiddleMouseClick = (e: MouseEvent): boolean => {
  return (
    e.button === RIGHT_MOUSE_CLICK_EVENT ||
    e.button === MIDDLE_MOUSE_CLICK_EVENT
  );
};

const reducer = (state: State, action: Action): State => {
  const scaledOffsetX = action.payload.offsetX / state.scale;
  const scaledOffsetY = action.payload.offsetY / state.scale;
  switch (action.type) {
    case ActionTypes.PAN_START:
      return {
        ...state,
        prevScaledOffset: {
          x: scaledOffsetX,
          y: scaledOffsetY,
        },
      };
    case ActionTypes.PAN:
      return {
        ...state,
        origin: {
          x: state.prevScaledOffset.x - scaledOffsetX + state.origin.x,
          y: state.prevScaledOffset.y - scaledOffsetY + state.origin.y,
        },
        prevScaledOffset: {
          x: scaledOffsetX,
          y: scaledOffsetY,
        },
      };
    case ActionTypes.ZOOM: {
      const newScale = wheelDeltaYToScale(state.scale, action.payload.deltaY);
      const newScaledOffsetX = action.payload.offsetX / newScale;
      const newScaledOffsetY = action.payload.offsetY / newScale;
      return {
        ...state,
        scale: newScale,
        origin: {
          x: state.origin.x + scaledOffsetX - newScaledOffsetX,
          y: state.origin.y + scaledOffsetY - newScaledOffsetY,
        },
      };
    }
    default:
      return state;
  }
};

const defaultState: State = {
  origin: {
    x: 0,
    y: 0,
  },
  prevScaledOffset: {
    x: 0,
    y: 0,
  },
  scale: 1.0,
};

export const usePanAndZoom = (initialSettings?: DrawingSettings) => {
  const [state, dispatch] = useReducer(reducer, {
    ...defaultState,
    ...initialSettings,
  });
  const [refMounted, setRefMounted] = useState<boolean>(false);
  const ref = useRef<SVGSVGElement | null>(null);

  const containerRef = useCallback((element: SVGSVGElement) => {
    ref.current = element;
    setRefMounted(true);
  }, []);

  const onMouseMoveInWindow = (e: MouseEvent) => {
    e.preventDefault();
    dispatch({
      type: ActionTypes.PAN,
      payload: { offsetX: e.offsetX, offsetY: e.offsetY },
    });
  };

  const onMouseDownInWindow = useCallback((e: MouseEvent) => {
    if (isRightOrMiddleMouseClick(e)) {
      dispatch({
        type: ActionTypes.PAN_START,
        payload: {
          offsetX: e.offsetX,
          offsetY: e.offsetY,
        },
      });
      if (ref.current) {
        ref.current.addEventListener("mousemove", onMouseMoveInWindow);
      }
    }
  }, []);

  const onMouseUpInWindow = useCallback((e: MouseEvent) => {
    if (ref.current && isRightOrMiddleMouseClick(e)) {
      ref.current.removeEventListener("mousemove", onMouseMoveInWindow);
    }
  }, []);

  const onWheel = useCallback((e: WheelEvent) => {
    e.preventDefault();
    e.stopPropagation();
    if (e.deltaY !== 0 && ref.current) {
      dispatch({
        type: ActionTypes.ZOOM,
        payload: {
          deltaY: e.deltaY,
          offsetX: e.offsetX,
          offsetY: e.offsetY,
        },
      });
    }
  }, []);

  useEffect(() => {
    if (refMounted && ref.current) {
      ref.current.addEventListener("mousedown", onMouseDownInWindow);
      ref.current.addEventListener("mouseup", onMouseUpInWindow);
      ref.current.addEventListener("wheel", onWheel, {
        passive: false,
      });
    }
    return () => {
      if (refMounted && ref.current) {
        ref.current.removeEventListener("mousedown", onMouseDownInWindow);
        ref.current.removeEventListener("mouseup", onMouseUpInWindow);
        ref.current.removeEventListener("wheel", onWheel);
      }
    };
  }, [refMounted, onWheel, onMouseUpInWindow, onMouseDownInWindow]);

  return {
    ...state,
    containerRef,
  };
};
