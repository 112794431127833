// this used to work when I installed pannellum from npm
//import "pannellum";
//import "pannellum/build/pannellum.css";

// this is working when installing pannellum directly from a github branch
import "pannellum/src/css/pannellum.css";
import "pannellum/src/js/pannellum";
import "pannellum/src/js/libpannellum";

export const viewer = window.pannellum.viewer;
