import React from "react";
import { useProject } from "../../../App/state";
import { useGetBimUrnQuery } from "../../../generated/types";

export const useIsBimEnabled = () => {
  const [isBimEnabled, setIsBimEnabled] = React.useState(false);
  const project = useProject();

  const {
    data: urnData,
    loading: urnLoading,
    error: urnError,
  } = useGetBimUrnQuery({
    variables: {
      customer: project.customer,
      project: project.project,
      scope: project.scope,
    },
  });

  React.useEffect(() => {
    if (!urnLoading && !urnError && urnData && urnData.bimUrns?.length) {
      setIsBimEnabled(true);
    }
  }, [urnData, urnError, urnLoading]);

  return isBimEnabled;
};
