// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/cssWithMappingToString.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".DualViewSideDrawer-module__layout--2RWFA{display:flex;align-items:stretch;height:100%}.DualViewSideDrawer-module__layout--2RWFA .ant-layout-sider{overflow:auto;height:100%}.DualViewSideDrawer-module__card--xihDz{height:100%}", "",{"version":3,"sources":["webpack://./src/components/DualViewSideDrawer/DualViewSideDrawer.module.css"],"names":[],"mappings":"AAAA,0CACE,YAAA,CACA,mBAAA,CACA,WAAA,CAEA,4DACE,aAAA,CACA,WAAA,CAIJ,wCACE,WAAA","sourcesContent":[".layout {\n  display: flex;\n  align-items: stretch;\n  height: 100%;\n\n  :global(.ant-layout-sider) {\n    overflow: auto;\n    height: 100%;\n  }\n}\n\n.card {\n  height: 100%;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"layout": "DualViewSideDrawer-module__layout--2RWFA",
	"card": "DualViewSideDrawer-module__card--xihDz"
};
export default ___CSS_LOADER_EXPORT___;
