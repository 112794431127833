import { Button, ButtonProps, Tooltip } from "antd";
import { JiraIssueType } from "models";
import React from "react";
import styles from "./styles.module.css";
import { IssueTypeConfig } from "./types";

interface IssueButtonProps extends ButtonProps {
  issue: IssueTypeConfig;
  highlighted: boolean;
  onClickButton: (issueType: JiraIssueType) => void;
}

export const IssueButton: React.FC<IssueButtonProps> = (
  props: IssueButtonProps,
) => {
  const { highlighted, issue, onClickButton, ...buttonProps } = props;
  const tooltipMessage = buttonProps.disabled
    ? `Unable to report ${issue.label} issue`
    : `${highlighted ? "Unreport" : "Report"} ${issue.label} issue`;
  return (
    <Tooltip title={tooltipMessage}>
      <Button
        {...buttonProps}
        className={styles["issue-button"]}
        size="small"
        type={highlighted ? "primary" : "default"}
        onClick={() => onClickButton(issue.issueType)}
      >
        {issue.label}
      </Button>
    </Tooltip>
  );
};
