import React from "react";
import { SimpleLineNode } from "../types";

type SimpleLineProps = {
  node: SimpleLineNode;
};

const DEFAULT_LINE_COLOR = "red";

export const SimpleLine = ({ node }: SimpleLineProps) => {
  return (
    <line
      x1={node.x1}
      y1={node.y1}
      x2={node.x2}
      y2={node.y2}
      stroke={node.color ?? DEFAULT_LINE_COLOR}
      strokeWidth={node.strokeWidth}
      paintOrder={"stroke"}
    >
      {node.title && <title>{node.title}</title>}
    </line>
  );
};
