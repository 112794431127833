import { Col, Row, Select } from "antd";
import React, { useEffect, useReducer } from "react";
import { Project } from "../../../types";
import { SpaceCategorySelector } from "../../Selectors/SpaceCategorySelector";
import { SpaceTagsSelector } from "../../Selectors/SpaceTagsSelector";
import { SpaceTypeSelector } from "../../Selectors/SpaceTypeSelector";
import styles from "./styles.module.css";

export type SpaceSearchInput = {
  terms: string[];
  categories: string[];
  spaceTypes: string[];
  tags: string[];
};

type UpdateName = {
  type: "UPDATE_TERMS";
  payload: string[];
};

type UpdateCategories = {
  type: "UPDATE_CATEGORIES";
  payload: string[];
};

type UpdateSpaceTypes = {
  type: "UPDATE_SPACE_TYPES";
  payload: string[];
};

type UpdateTags = {
  type: "UPDATE_TAGS";
  payload: string[];
};

export const initialSearchInput: SpaceSearchInput = {
  terms: [],
  categories: [],
  spaceTypes: [],
  tags: [],
};

type UpdateSearchInput =
  | UpdateName
  | UpdateCategories
  | UpdateSpaceTypes
  | UpdateTags;

const searchInputReducer = (
  state: SpaceSearchInput,
  action: UpdateSearchInput,
): SpaceSearchInput => {
  switch (action.type) {
    case "UPDATE_TERMS":
      return { ...state, terms: action.payload };
    case "UPDATE_CATEGORIES":
      return { ...state, categories: action.payload };
    case "UPDATE_SPACE_TYPES":
      return { ...state, spaceTypes: action.payload };
    case "UPDATE_TAGS":
      return { ...state, tags: action.payload };
  }
};

type SearchBarProps = {
  project: Project;
  onChange: (value: SpaceSearchInput) => any;
};

export const SearchBar = (props: SearchBarProps) => {
  const { project, onChange } = props;
  const [searchInput, setSearchInput] = useReducer(
    searchInputReducer,
    initialSearchInput,
  );

  useEffect(() => {
    onChange(searchInput);
  }, [onChange, searchInput]);

  return (
    <>
      <Row>
        <Col span={24}>
          <SearchBox
            onChange={(name) =>
              setSearchInput({ type: "UPDATE_TERMS", payload: name })
            }
          />
        </Col>
      </Row>
      <Row>
        <Col span={8}>
          <SpaceCategorySelector
            project={project}
            placeholder="Category"
            selectedCategories={searchInput.categories}
            onChange={(values) =>
              setSearchInput({ type: "UPDATE_CATEGORIES", payload: values })
            }
          />
        </Col>
        <Col span={8}>
          <SpaceTypeSelector
            project={project}
            selectedTypes={searchInput.spaceTypes}
            placeholder="Space Type"
            onChange={(values) =>
              setSearchInput({ type: "UPDATE_SPACE_TYPES", payload: values })
            }
          />
        </Col>
        <Col span={8}>
          <SpaceTagsSelector
            project={props.project}
            selectedTags={searchInput.tags}
            placeholder="Tags"
            onChange={(values) =>
              setSearchInput({ type: "UPDATE_TAGS", payload: values })
            }
          />
        </Col>
      </Row>
    </>
  );
};

type SearchBoxProps = {
  onChange: (value: string[]) => void;
};

const SearchBox = (props: SearchBoxProps) => (
  <Select
    className={styles["search-box"]}
    mode="tags"
    onChange={props.onChange}
    placeholder="Search..."
  />
);
