import { PlusOutlined } from "@ant-design/icons";
import { ApolloError } from "@apollo/client";
import { Divider, Select, Spin, Typography } from "antd";
import classNames from "classnames";
import _ from "lodash";
import React, { useState } from "react";
import styles from "./ComponentViewer.module.css";
import {
  CreateComponentFieldModal,
  ModalOptions,
} from "./CreateComponentFieldModal";

const ERROR_TEXT = "Error fetching data";

export enum ComponentFieldSelectorType {
  Subcontractor = "subcontractor",
  ComponentType = "componentType",
  ObjectType = "objectType",
  Tags = "tags",
}

const CONTAINER_TYPE_TO_DATA_TESTID: Record<
  ComponentFieldSelectorType,
  string
> = {
  [ComponentFieldSelectorType.Subcontractor]: "subcontractor-selector",
  [ComponentFieldSelectorType.ComponentType]: "component-type-selector",
  [ComponentFieldSelectorType.ObjectType]: "object-selector",
  [ComponentFieldSelectorType.Tags]: "component-tag-selector",
};

type ComponentFieldSelectorContainerProps = {
  componentFieldSelectorType: ComponentFieldSelectorType;
  selectorValues: { name: string; id: string }[];
  loading: boolean;
  error: ApolloError | undefined;
  className?: string;
  newVal?: string;
  modalOptions?: ModalOptions;
  selected?: string | string[];
  onChange?: (value: string[]) => any;
  onBlur?: () => void;
  selectMode?: "tags" | "multiple";
};

export const ComponentFieldSelectorContainer = (
  props: ComponentFieldSelectorContainerProps,
) => {
  const {
    componentFieldSelectorType,
    selectorValues,
    selected,
    onChange,
    onBlur,
    selectMode,
    loading,
    error,
    className,
    modalOptions,
    newVal,
  } = props;

  const [isModalVisible, setIsModalVisible] = useState(false);

  const values = newVal
    ? [...(selectorValues ?? []), { id: newVal, name: newVal }]
    : selectorValues;

  const sortedValues = _.sortBy(values, (v) => v.name.toLocaleLowerCase());

  const handleOnChange = (value: string | string[]) => {
    const onChangeVal = typeof value === "string" ? [value] : value;
    onChange && onChange(onChangeVal);
  };

  if (loading) {
    return <Spin />;
  }

  if (error) {
    return <div>{ERROR_TEXT}</div>;
  }

  return (
    <>
      <div className={classNames(styles["selector-container"])}>
        <Select
          data-testid={
            CONTAINER_TYPE_TO_DATA_TESTID[componentFieldSelectorType]
          }
          className={classNames(styles.selector, className)}
          loading={_.isNil(selectorValues)}
          mode={selectMode}
          value={selected}
          placeholder="Select..."
          onChange={(value) => {
            handleOnChange(value);
          }}
          onBlur={() => onBlur?.()}
          dropdownRender={(menu) => (
            <div className={classNames(styles["dropdown-render-container"])}>
              {menu}
              <Divider className={classNames(styles.divider)} />
              <div className={classNames(styles["add-new-container"])}>
                {modalOptions && (
                  <Typography.Link
                    className={classNames(styles.typeography_link)}
                    onClick={() => {
                      setIsModalVisible(true);
                    }}
                    data-testid={"add-button"}
                  >
                    <PlusOutlined />
                    <span>Add</span>
                  </Typography.Link>
                )}
              </div>
            </div>
          )}
        >
          {sortedValues.map((val) => (
            <Select.Option key={val.id} value={val.id}>
              {val.name}
            </Select.Option>
          ))}
        </Select>
      </div>
      {modalOptions && (
        <CreateComponentFieldModal
          isVisible={isModalVisible}
          setNotVisible={() => {
            setIsModalVisible(false);
          }}
          modalOptions={modalOptions}
        />
      )}
    </>
  );
};
