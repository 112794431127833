import { Alert } from "antd";
import React from "react";
import { useGetEarliestTimestampOnWorkQueueQuery } from "../../generated/types";
import { Project } from "../../types";
import styles from "./ProjectWorkQueueStyles.module.css";

export const ProjectWorkQueueStatus = ({
  project,
  showTitle = false,
}: {
  project: Project;
  showTitle?: boolean;
}) => {
  const { data } = useGetEarliestTimestampOnWorkQueueQuery({
    variables: {
      tenant: project,
    },
    pollInterval: 3000,
  });

  const earliestTime = data?.earliestTimestampOnWorkQueue
    ? convertDate(data.earliestTimestampOnWorkQueue)
    : null;

  return (
    <div>
      {showTitle && (
        <header className={styles.header}>Reweighting Status</header>
      )}
      <div className={styles.status}>
        {earliestTime === null ? (
          <Alert type="success" showIcon message={"Weights are up to date"} />
        ) : (
          <Alert
            type="info"
            showIcon
            message={`Weights are up to date up to changes before: ${earliestTime}`}
          />
        )}
      </div>
    </div>
  );
};

// Apollo codegen doesn't handle our date scalars
// So deserialize them ourselves
const convertDate = (date: any): Date => new Date(date);
