import { Divider, Select } from "antd";
import React, { useEffect, useMemo, useState } from "react";
import { useNotification } from "../../../contexts/Notifications";
import { DisplayTreeNodeType } from "../../DisplayTree/types";
import { DrawingPath } from "../../DrawingTool/DrawingPath/DrawingPath";
import { ScenePatch } from "../hooks/useScenes";
import { Scene, SceneSubject } from "../types";
import styles from "./OptionsPanel.module.css";
import { OptionsPanelBaseRows } from "./OptionsPanelBaseRows";
import { OptionsPanelDeleteForm } from "./OptionsPanelDeleteForm";
import { OptionsPanelSaveForm } from "./OptionsPanelSaveForm";
import { SceneCoordinatesRows } from "./SceneCoordinatesRows";

const SCENE_CAPTURE_TYPE_SELECTOR_PLACEHOLDER = "Select Scene Capture type";
const MULTIPLE_SELECT_TITLE = "Multiple select";

const haveEqualSubjects = (scenes: Scene[]) => {
  const arrayOfSubjects = scenes.map((s) =>
    s.subjects ? s.subjects.sort().toString() : "",
  );
  return new Set(arrayOfSubjects).size <= 1;
};

interface SceneOptionsPanelProps {
  scenes: Scene[];
  updateScene: (sceneIds: string[], patch: ScenePatch) => Promise<void>;
  deleteScenes: (sceneIds: string[]) => Promise<void>;
  sceneAlias?: Pick<Scene, "id" | "name" | "path">;
  floorHeight: number | undefined;
  disableCreateSceneMode?: () => void;
}

const CAPTURE_TYPE_SELECTOR = "Capture type";
const ALIAS = "Alias";

export enum SceneSubjectNames {
  FIRESTOP = "Firestop",
  RISER = "Riser",
  FACADE = "Facade",
  HIGH_CEILING = "High Ceiling",
  BUILDING_FULL_HEIGHT = "Building Full Height",
}

const SELECTOR_OPTIONS = Object.values(SceneSubject).map((value) => ({
  label: SceneSubjectNames[value],
  value: value,
}));

export const SceneOptionsPanel = (props: SceneOptionsPanelProps) => {
  const {
    scenes,
    updateScene,
    deleteScenes,
    sceneAlias,
    floorHeight,
    disableCreateSceneMode,
  } = props;
  const [editMode, setEditMode] = useState(false);
  const [deleteModalVisible, setDeleteModalVisible] = useState(false);
  const [sceneName, setSceneName] = useState<string>();
  const [sceneZOffset, setSceneZOffset] = useState<number>();
  const [sceneSubjects, setSceneSubjects] = useState<
    SceneSubject[] | undefined
  >();
  const notify = useNotification();

  const sceneIds = useMemo(() => scenes.map((s) => s.id), [scenes]);
  const isSelectionSingleScene = useMemo(() => scenes.length === 1, [scenes]);
  const canEditSceneSubjects = useMemo(
    () => haveEqualSubjects(scenes),
    [scenes],
  );

  useEffect(() => {
    setSceneSubjects(scenes[0].subjects);

    const zOffsetVaries = scenes.some((s) => s.zOffset !== scenes[0].zOffset);
    setSceneZOffset(zOffsetVaries ? undefined : scenes[0].zOffset);
  }, [scenes]);

  useEffect(() => {
    setSceneName(isSelectionSingleScene ? scenes[0].name : undefined);
  }, [isSelectionSingleScene, scenes]);

  const handleSave = async () => {
    const currentSubjects =
      canEditSceneSubjects && scenes[0].subjects ? scenes[0].subjects : [];

    const subjectsToAdd: SceneSubject[] =
      sceneSubjects && canEditSceneSubjects
        ? sceneSubjects.filter((v) => !currentSubjects.includes(v))
        : [];
    const subjectsToRemove: SceneSubject[] =
      currentSubjects && sceneSubjects && canEditSceneSubjects
        ? currentSubjects.filter((s) => !sceneSubjects.includes(s))
        : [];

    if (
      subjectsToAdd.length ||
      subjectsToRemove.length ||
      scenes[0].name !== sceneName ||
      scenes.some((s) => s.zOffset !== sceneZOffset)
    ) {
      updateScene(sceneIds, {
        ...(isSelectionSingleScene && !!sceneName ? { name: sceneName } : {}),
        ...(subjectsToAdd.length ? { addSubjects: subjectsToAdd } : {}),
        ...(subjectsToRemove.length
          ? { removeSubjects: subjectsToRemove }
          : {}),
        ...(sceneZOffset !== undefined ? { mmZOffset: sceneZOffset } : {}),
      });
    } else {
      notify("Scenes already saved", "warning");
    }
    updateEditMode(false);
  };

  const updateEditMode = (value: boolean) => {
    setEditMode(() => {
      disableCreateSceneMode?.();
      return value;
    });
  };

  const updateDeleteModalVisible = (value: boolean) => {
    setDeleteModalVisible(() => {
      disableCreateSceneMode?.();
      return value;
    });
  };

  return (
    <div className={styles["options-panel"]}>
      {(isSelectionSingleScene || canEditSceneSubjects) && (
        <div className={styles["editor-box"]}>
          {isSelectionSingleScene ? (
            <OptionsPanelBaseRows
              initialName={scenes[0].name}
              name={sceneName}
              setName={setSceneName}
              editMode={editMode}
            />
          ) : (
            <div>{MULTIPLE_SELECT_TITLE}</div>
          )}
          {canEditSceneSubjects && (
            <div className={styles["row-box"]}>
              <div className={styles["row-box-name"]}>
                {CAPTURE_TYPE_SELECTOR}
              </div>
              <Select
                className={styles["row-value"]}
                mode="multiple"
                placeholder={SCENE_CAPTURE_TYPE_SELECTOR_PLACEHOLDER}
                onChange={setSceneSubjects}
                options={SELECTOR_OPTIONS}
                value={sceneSubjects}
                disabled={!editMode}
              />
            </div>
          )}

          <SceneCoordinatesRows
            sceneX={scenes[0].x}
            sceneY={scenes[0].y}
            zOffset={sceneZOffset}
            onChangeZOffset={setSceneZOffset}
            isSelectionSingleScene={isSelectionSingleScene}
            floorHeight={floorHeight}
            editMode={editMode}
          />

          <OptionsPanelSaveForm
            editMode={editMode}
            onEditButtonClick={() => updateEditMode(true)}
            onSaveButtonClick={() => handleSave()}
            saveButtonDisabled={!sceneName && isSelectionSingleScene}
          />
        </div>
      )}
      {sceneAlias && isSelectionSingleScene && (
        <div className={styles["alias-box"]}>
          <div className={styles["editor-box-item"]}>{ALIAS}</div>
          <DrawingPath path={[...sceneAlias.path, sceneAlias.name]} />
        </div>
      )}
      <Divider />

      <OptionsPanelDeleteForm
        ids={sceneIds}
        modalVisible={deleteModalVisible}
        nodeType={DisplayTreeNodeType.Scene}
        onDeleteButtonClick={() => updateDeleteModalVisible(true)}
        onClose={() => updateDeleteModalVisible(false)}
        onDelete={deleteScenes}
      />
    </div>
  );
};
