import { useState } from "react";

export const useKeyEvent = () => {
  const [keyEvent, setKeyEvent] = useState<string | null>(null);

  const onKeyDown = (e) => {
    setKeyEvent(e.key);
  };
  const onKeyUp = () => {
    setKeyEvent(null);
  };

  return {
    keyEvent,
    onKeyUp,
    onKeyDown,
  };
};
