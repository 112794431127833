import { Spin } from "antd";
import React from "react";
import { useProject } from "../../App/state";
import { useGetComponentForTreeNodeQuery } from "../../generated/types";
import { ComposedLabel } from "../ConfigurationLabelComposed";
import { CopyToClipboardButton } from "../CopyToClipboardButton";
import styles from "./styles.module.css";

type NodeProps = {
  componentId: string;
  showTags?: boolean;
};

export const ComponentNode = (props: NodeProps) => {
  const project = useProject();
  const { data, loading, error } = useGetComponentForTreeNodeQuery({
    variables: {
      ...project,
      componentId: props.componentId,
    },
  });
  if (loading) {
    return <Spin />;
  } else if (error || !data) {
    console.error(error);
    return <span>Error loading task!</span>;
  } else {
    return (
      <span className={styles.node}>
        <ComposedLabel
          focusedText={data.component.name}
          peripheryTexts={[
            data.component.subcontractor?.name || "N/A",
            data.component.object || "No Object",
            ...(props.showTags ? data.component.tags : [""]),
          ]}
        />
        <CopyToClipboardButton text={props.componentId} />
      </span>
    );
  }
};
