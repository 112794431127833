import { DeleteOutlined } from "@ant-design/icons";
import { Button, Tooltip } from "antd";
import React, { useState } from "react";
import { DeleteConfirmationModal } from "../DeleteConfirmationModal/DeleteConfirmationModal";

type PlanDeletionModalProps = {
  onDelete: () => Promise<void>;
};

const DELETE_CONFIRMATION_REQ_TEXT = "delete plan";
const DELETE_PLAN_MODAL_TITLE = "Delete Plan";
const DELETE_CONFIRMATION_ALERT_MESSAGE =
  "Are you sure you want to delete the plan?";
const DELETE_PLAN_MODAL_BODY = (
  <p>
    This action will delete the plan; any task in the plan will also be deleted,
    but their components will be unassigned and retained.
  </p>
);
const TOOLTIP_TITLE = "delete plan";

export const PlanDeletionModal = (props: PlanDeletionModalProps) => {
  const { onDelete } = props;

  const [visible, setVisible] = useState(false);

  return (
    <>
      <Tooltip title={TOOLTIP_TITLE}>
        <Button
          danger
          data-testid="delete-plan-button"
          type="primary"
          onClick={() => setVisible(true)}
          icon={<DeleteOutlined />}
        />
      </Tooltip>
      <DeleteConfirmationModal
        title={DELETE_PLAN_MODAL_TITLE}
        visible={visible}
        setVisible={setVisible}
        onOk={onDelete}
        deletionText={DELETE_CONFIRMATION_REQ_TEXT}
        modalBody={DELETE_PLAN_MODAL_BODY}
        alertText={DELETE_CONFIRMATION_ALERT_MESSAGE}
      />
    </>
  );
};
