import { UploadOutlined } from "@ant-design/icons";
import { Result, Spin } from "antd";
import React, { useCallback, useState } from "react";
import { useDropzone } from "react-dropzone";
import { useNotification } from "../../contexts/Notifications";
import { useUploadProgressFileMutation } from "../../generated/types";
import { Project } from "../../types";

type FileUploaderProps = {
  title?: string;
  onFilesSelected: (files: File[]) => Promise<void> | void;
};

export const FileUploader = (props: FileUploaderProps) => {
  const { onFilesSelected, title } = props;
  const [uploading, setUploading] = useState(false);

  const onDrop = useCallback(
    async (acceptedFiles: File[]) => {
      setUploading(true);
      await onFilesSelected(acceptedFiles);
      setUploading(false);
    },
    [onFilesSelected],
  );
  //using the drop zone from https://openbase.com/js/react-dropzone
  const { getRootProps, getInputProps, isDragActive } = useDropzone({ onDrop });

  return (
    <Spin spinning={uploading}>
      <div {...getRootProps()}>
        <input {...getInputProps()} />
        <Result
          icon={<UploadOutlined />}
          title={title || "Upload File"}
          subTitle={
            isDragActive
              ? "Drop files here"
              : "Drag 'n` drop files here, or click to select files"
          }
        />
      </div>
    </Spin>
  );
};

export type ProgressFileUploader = {
  project: Project;
};

export const ProgressFileUploader = (props: ProgressFileUploader) => {
  const { customer, project, scope } = props.project;
  const notify = useNotification();

  const [uploadFile] = useUploadProgressFileMutation({
    onError: (error) =>
      notify(`Progress file upload failed: ${error.message}`, "error"),
    onCompleted: () =>
      notify("Progress file successfully uploaded!", "success"),
  });
  const upload = useCallback(
    async (files: File[]) => {
      if (files.length !== 1) {
        notify(
          "Upload failed, we currently only support uploading one file at a time",
        );
      } else {
        await uploadFile({
          variables: {
            customer: customer,
            project: project,
            scope: scope,
            file: files[0],
          },
        });
      }
    },
    [customer, notify, project, scope, uploadFile],
  );

  return <FileUploader title="Upload Progress File" onFilesSelected={upload} />;
};
