// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/cssWithMappingToString.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".styles-module__issue-button-group-container--1hKb1{position:absolute;right:0;bottom:100%;margin-bottom:12px;display:flex;gap:3px}", "",{"version":3,"sources":["webpack://./src/components/ShotIssueButtons/styles.module.css"],"names":[],"mappings":"AAAA,oDACE,iBAAA,CACA,OAAA,CACA,WAAA,CACA,kBAAA,CACA,YAAA,CACA,OAAA","sourcesContent":[".issue-button-group-container {\n  position: absolute;\n  right: 0;\n  bottom: 100%;\n  margin-bottom: 12px;\n  display: flex;\n  gap: 3px;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"issue-button-group-container": "styles-module__issue-button-group-container--1hKb1"
};
export default ___CSS_LOADER_EXPORT___;
