import { Card, Col, Empty, Layout, Row } from "antd";
import React, { useCallback, useState } from "react";
import { useProject } from "../../App/state";
import { ConfigurationPlansBottomRibbon } from "../../components/ConfigurationPlansBottomRibbons";
import ConfigurationSpacesTreeBottomRibbon from "../../components/ConfigurationSpaceBottomRibbons/ConfigSpacesTreeBottomRibbon";
import { DisplayTreeNode } from "../../components/DisplayTree/types";
import PlansHeader from "../../components/PlansTree/PlansHeader";
import { PlansTree } from "../../components/PlansTree/PlansTree";
import { Space } from "../../components/SpacesTree/tree-nodes";
import { Plan } from "../../types";
import { NO_PLAN_SELECTED_MESSAGE } from "../PlansModePage";
import styles from "./ConfigurationModePage.module.css";
import ConfigurationSpacesTree from "./ConfigurationSpacesTree";

export const ConfigurationPage = () => {
  const project = useProject();

  const [checkedSpaceTreeNodes, setCheckedSpaceTreeNodes] = React.useState<
    DisplayTreeNode[]
  >([]);
  const [checkedComponents, setCheckedComponents] = useState<string[]>([]);
  const [spacesByIds, setSpacesByIds] = useState<Record<string, Space>>({});
  const [nodeKeysToUpdate, setNodeKeysToUpdate] = useState<string[]>();

  const [selectedPlan, setSelectedPlan] = useState<Plan>();

  const handleOnNodesDeleted = () => {
    setCheckedSpaceTreeNodes([]);
  };

  const handleOnUpdate = (keys: string[]) => {
    setNodeKeysToUpdate(keys);
  };
  const handleOnCheckNodes = useCallback(
    (checkedNodes: string[]) => setCheckedComponents(checkedNodes),
    [],
  );

  return (
    <Layout>
      <Layout.Content className={styles["content"]}>
        <div className={styles["tree-container"]}>
          <div className={styles["column"]}>
            <ConfigurationSpacesTree
              project={project}
              onCheck={setCheckedSpaceTreeNodes}
              onLoadSpacesTree={setSpacesByIds}
              nodeKeysToUpdate={nodeKeysToUpdate}
              onNodeKeysUpdated={handleOnUpdate}
            />
          </div>
          <div className={styles["column"]}>
            <Card
              className={"plans-card"}
              title={"Plans"}
              extra={
                <PlansHeader
                  project={project}
                  onSelect={setSelectedPlan}
                  selectedPlan={selectedPlan}
                />
              }
            >
              {selectedPlan?.id ? (
                <PlansTree
                  project={project}
                  checkable
                  searchable
                  planId={selectedPlan?.id}
                  includeComponents
                  onCheckNodes={handleOnCheckNodes}
                  showTagsSwitch
                />
              ) : (
                <Empty description={NO_PLAN_SELECTED_MESSAGE} />
              )}
            </Card>
          </div>
        </div>
      </Layout.Content>
      <Layout.Footer>
        <Row gutter={16}>
          <Col span={12}>
            <ConfigurationSpacesTreeBottomRibbon
              project={project}
              selectedNodes={checkedSpaceTreeNodes}
              spacesByIds={spacesByIds}
              onNodesDeleted={handleOnNodesDeleted}
            />
          </Col>
          <Col span={12}>
            <ConfigurationPlansBottomRibbon
              project={project}
              planId={selectedPlan?.id}
              checkedSpacesTreeNodes={checkedSpaceTreeNodes}
              checkedComponents={checkedComponents}
              onUpdate={handleOnUpdate}
            />
          </Col>
        </Row>
      </Layout.Footer>
    </Layout>
  );
};
