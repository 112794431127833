// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/cssWithMappingToString.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".ProjectWorkQueueStyles-module__header--1maIb{font-size:large;font-weight:bolder}.ProjectWorkQueueStyles-module__status--17xVo{width:fit-content}", "",{"version":3,"sources":["webpack://./src/components/ProjectWorkQueueStatus/ProjectWorkQueueStyles.module.css"],"names":[],"mappings":"AAAA,8CACI,eAAA,CACA,kBAAA,CAGJ,8CACI,iBAAA","sourcesContent":[".header {\n    font-size: large;\n    font-weight: bolder;\n}\n\n.status {\n    width: fit-content;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"header": "ProjectWorkQueueStyles-module__header--1maIb",
	"status": "ProjectWorkQueueStyles-module__status--17xVo"
};
export default ___CSS_LOADER_EXPORT___;
