import { Select, Tooltip } from "antd";
import React from "react";
import { Component } from "../../../types";
import styles from "./ComponentSelector.module.css";

type ComponentSelectorProps = {
  className?: string;
  components: Component[];
  onSelect?: (value: string) => void;
};

export const ComponentSelector = (props: ComponentSelectorProps) => (
  <Select
    showSearch
    placeholder="Select..."
    onChange={props.onSelect}
    filterOption={(input, option) =>
      option?.children?.props?.title
        ?.toLowerCase()
        ?.indexOf(input.toLowerCase()) >= 0
    }
    filterSort={(optionA, optionB) =>
      optionA.children.props.title
        .toLowerCase()
        .localeCompare(optionB.children.props.title.toLowerCase())
    }
  >
    {props.components.map((component) => {
      const componentInfo = `${
        component.subcontractor ? ` | ${component.subcontractor?.name}` : ""
      } | ${component.object}${
        component.tags.length > 0 ? `| ${component.tags.join(" | ")}` : ""
      }`;
      const title = `${component.name}${componentInfo}`;
      return (
        <Select.Option key={component.id} value={component.id}>
          <Tooltip title={title}>
            <span>
              {component.name}
              <span className={styles.componentInfo}>{componentInfo}</span>
            </span>
          </Tooltip>
        </Select.Option>
      );
    })}
  </Select>
);
