import { ApolloCache } from "@apollo/client";
import { Button, Tooltip } from "antd";
import gql from "graphql-tag";
import React, { useState } from "react";
import { useNotification } from "../../contexts/Notifications";
import {
  Component,
  SpaceWithMappingDetailsFragment,
  useCreateMappingsMutation,
} from "../../generated/types";
import { Project } from "../../types";
import { generateFolderName } from "../SpacesTree/tree-nodes";
import { ModalConfirmationMappingAction } from "./ModalConfirmation";
import { CREATED_MAPPINGS_ERROR, CREATED_MAPPINGS_SUCCESS } from "./messages";

type AddMappingsButtonProps = {
  project: Project;
  disabled: boolean;
  selectedSpaces: SpaceWithMappingDetailsFragment[];
  selectedComponents: Pick<Component, "id" | "name">[];
  onUpdate: (keys: string[]) => void;
};

const areAllSelectedComponentsMapped = (selectedSpaces, selectedComponents) => {
  if (selectedComponents.length === 0 || selectedSpaces.length === 0) {
    return false;
  }
  return selectedSpaces.every((space) => {
    const mappedComponents = new Set(
      space.mappings.map((mapping) => mapping.component.id),
    );
    return selectedComponents.every((component) =>
      mappedComponents.has(component.id),
    );
  });
};

export const updateCacheOnNewMappings = (
  cache: ApolloCache<any>,
  createdMappings: any[],
) => {
  const parentSpaces = createdMappings.map((mapping) => mapping.space.id);
  cache.modify({
    fields: {
      space(existingSpace, { readField }) {
        const parentSpaceId = readField("id", existingSpace);
        if (!parentSpaces?.includes(parentSpaceId)) {
          return existingSpace;
        }
        const currentNewMapping = createdMappings.find(
          (mapping) => mapping.space.id === parentSpaceId,
        );
        if (!currentNewMapping) {
          return existingSpace;
        }
        const parentSpaceMappings: readonly any[] =
          readField("mappings", existingSpace) ?? [];
        const newRef = cache.writeFragment({
          data: currentNewMapping,
          fragment: gql`
            fragment NewMapping on Mapping {
              id
              component {
                id
                name
              }
            }
          `,
        });
        cache.writeQuery({
          query: gql`
            query WriteSpace($id: UUID!) {
              space(id: $id) {
                mappings
              }
            }
          `,
          data: {
            space: {
              mappings: [...parentSpaceMappings, newRef],
            },
          },
          variables: {
            id: parentSpaceId,
          },
        });
      },
    },
  });
};

export const AddMappingsButton = (props: AddMappingsButtonProps) => {
  const { project, disabled, selectedSpaces, selectedComponents, onUpdate } =
    props;

  const [visible, setVisible] = useState(false);
  const mappingsAlreadyExist = areAllSelectedComponentsMapped(
    selectedSpaces,
    selectedComponents,
  );

  const notify = useNotification();
  const [createMappingsMutation] = useCreateMappingsMutation({
    onCompleted: () => {
      const nodesToUpdate = selectedSpaces.map((s) =>
        generateFolderName(s.id, "mappings"),
      );
      onUpdate(nodesToUpdate);
      notify(CREATED_MAPPINGS_SUCCESS, "success");
    },
    onError: (error) => notify(`${CREATED_MAPPINGS_ERROR} = ${error}`, "error"),
  });

  const onApply = async () => {
    if (selectedComponents.length === 0 || selectedSpaces.length === 0) {
      throw new Error(
        "Logical error: this button shouldn't be enabled if no spaces/components are selected.",
      );
    }
    setVisible(false);
    await createMappingsMutation({
      variables: {
        ...project,
        spaceIds: selectedSpaces?.map((s) => s.id),
        componentIds: selectedComponents.map((c) => c.id),
      },
    });
  };

  const onCancel = () => setVisible(false);

  const addButton = (
    <Button
      block
      value="add"
      disabled={disabled || mappingsAlreadyExist}
      onClick={() => setVisible(true)}
    >
      add
    </Button>
  );
  return (
    <div>
      {mappingsAlreadyExist ? (
        <Tooltip title="Mappings for the selected components already exist in selected spaces">
          {addButton}
        </Tooltip>
      ) : (
        addButton
      )}
      <ModalConfirmationMappingAction
        visible={visible}
        onCancelCallback={onCancel}
        onApllyCallback={onApply}
        affectedComponentNames={selectedComponents.map(
          (component) => component.name,
        )}
        affectedSpaces={selectedSpaces}
        notAffectedSpaces={[]}
        mappingAction="add"
      />
    </div>
  );
};
