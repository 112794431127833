import React, { PropsWithChildren } from "react";
import { DragPreviewImage, useDrag } from "react-dnd";
import { DraggableItemType } from "./types";

type DraggableItemProps<T> = {
  itemType: DraggableItemType;
  item: T;
  dragPreviewOverride?: string;
};

export const DraggableItem = <T,>(
  props: PropsWithChildren<DraggableItemProps<T>>,
) => {
  const [, drag, preview] = useDrag(() => ({
    type: props.itemType,
    item: () => props.item,
  }));

  return props.dragPreviewOverride ? (
    <>
      <DragPreviewImage connect={preview} src={props.dragPreviewOverride} />
      <div ref={drag}>{props.children}</div>
    </>
  ) : (
    <div ref={drag}>{props.children}</div>
  );
};
