import { format } from "date-fns";
import jsPDF from "jspdf";
import { getNodeColorPerScanType } from "../DrawingTool/drawing-utils";
import { colorPerSceneScanType } from "../DrawingTool/drawing-utils";
import { ScanSequenceLevelType } from "../SpatialConfigurationMode/types";
import { JsPdfGenerator, PdfGenerator } from "./pdf-generator";
import {
  SequenceMapData,
  SequenceMapFloorplan,
  SequenceMapScene,
} from "./types";
import { PT_TO_MM } from "./utils";

const TITLE_FONT_SIZE_MM = 12 * PT_TO_MM;
const LEGEND_FONT_SIZE_MM = 8 * PT_TO_MM;
const TEXT_FONT_SIZE_MM = 5 * PT_TO_MM;
const PAGE_TOP_MARGIN_PERCENTAGE = 0.05;
const PAGE_LEFT_MARGIN_PERCENTAGE = 0;
const LEGEND_LEFT_MARGIN_PERCENTAGE = 0.02;
const LEGEND_TEXT_PADDING = 5 * PT_TO_MM;
const DEFAULT_TEXT_COLOR = "black";

export const toFilename = (sequence: SequenceMapData) => {
  const dateString = format(new Date(), "yyyyMMdd");
  return `${[
    sequence.floorplan.spaceName,
    sequence.scanLevel.toString(),
    ...sequence.floorplan.ancestorSpacesNames.reverse(),
    dateString,
  ].join("-")}.pdf`;
};

const imageToBytes64 = async (url): Promise<string> => {
  const response = await fetch(url);
  const reader = new FileReader();
  reader.readAsDataURL(await response.blob());
  return new Promise((resolve) => {
    // result is always a string from readAsDataURL
    // and onload is called only when successful
    reader.onload = () => resolve(reader.result as string);
  });
};

const getBestFitScale = (
  srcWidth: number,
  srcHeight: number,
  targetWidth: number,
  targetHeight: number,
): number => {
  const scaleHeight = targetHeight / srcHeight;
  const scaleWidth = targetWidth / srcWidth;
  return Math.min(scaleHeight, scaleWidth);
};

const transformPointToPdfCoordinates = (
  srcX: number,
  srcY: number,
  scaleFloorplan: number,
  scalePdf: number,
  originXFloorplan: number,
  originYFloorplan: number,
  originXPDF: number,
  originYPDF: number,
) => {
  // coordinates relative to the image origin
  const xO = (1 / scaleFloorplan) * srcX + originXFloorplan;
  const yO = (1 / scaleFloorplan) * srcY + originYFloorplan;
  // coordinates relative to the pdf page
  const x = scalePdf * xO + originXPDF;
  const y = scalePdf * yO + originYPDF;
  return { x, y };
};

interface SequenceMapsReportGenerator {
  addSequenceMapPage(sequence: SequenceMapData): void;
  download(filename: string): void;
}

class SequenceMapsPdfReportGenerator implements SequenceMapsReportGenerator {
  private doc: PdfGenerator;
  private sequenceSize: number | undefined;

  constructor(doc: PdfGenerator, sequenceSize?: number) {
    this.doc = doc;
    this.sequenceSize = sequenceSize;
  }

  async addSequenceMapPage(sequence: SequenceMapData) {
    this.doc.addPage();
    await this.addFloorplanImage(sequence.floorplan);
    this.addFloorplanTitle(sequence.floorplan, sequence.scanLevel);
    this.addLegend();
    sequence.scenes.forEach((scene) =>
      this.addScene(scene, sequence.floorplan),
    );
  }

  private addLegend() {
    let x = LEGEND_LEFT_MARGIN_PERCENTAGE * this.doc.getPageWidth();
    Object.entries(colorPerSceneScanType).map(([name, color]) => {
      x += this.doc.getTextWidth(name.toString(), LEGEND_FONT_SIZE_MM) / 2;
      this.doc.addText(
        name.toString(),
        x,
        this.doc.getPageHeight() * PAGE_TOP_MARGIN_PERCENTAGE,
        LEGEND_FONT_SIZE_MM,
        DEFAULT_TEXT_COLOR,
        color,
      );
      x +=
        this.doc.getTextWidth(name.toString(), LEGEND_FONT_SIZE_MM) / 2 +
        LEGEND_TEXT_PADDING;
    });
  }

  private addFloorplanTitle(
    floorplan: SequenceMapFloorplan,
    scanLevel: ScanSequenceLevelType,
  ) {
    this.doc.addText(
      [
        floorplan.spaceName,
        scanLevel.toString(),
        ...floorplan.ancestorSpacesNames.reverse(),
      ].join(" - "),
      this.doc.getPageWidth() / 2, // align to the centre of the page
      this.doc.getPageHeight() * PAGE_TOP_MARGIN_PERCENTAGE,
      TITLE_FONT_SIZE_MM,
      DEFAULT_TEXT_COLOR,
    );
  }

  private async addFloorplanImage(floorplan: SequenceMapFloorplan) {
    const pageWidth = this.doc.getPageWidth();
    const pageHeight = this.doc.getPageHeight();
    const scale = getBestFitScale(
      floorplan.width,
      floorplan.height,
      pageWidth,
      pageHeight * (1 - PAGE_TOP_MARGIN_PERCENTAGE),
    );
    this.doc.addImage(
      await imageToBytes64(floorplan.url),
      "PNG",
      pageWidth * PAGE_LEFT_MARGIN_PERCENTAGE,
      pageHeight * PAGE_TOP_MARGIN_PERCENTAGE,
      floorplan.width * scale,
      floorplan.height * scale,
    );
  }

  private addScene(scene: SequenceMapScene, floorplan: SequenceMapFloorplan) {
    const pageWidth = this.doc.getPageWidth();
    const pageHeight = this.doc.getPageHeight();
    const scale = getBestFitScale(
      floorplan.width,
      floorplan.height,
      pageWidth,
      pageHeight * (1 - PAGE_TOP_MARGIN_PERCENTAGE),
    );
    const point = transformPointToPdfCoordinates(
      scene.x,
      scene.y,
      floorplan.mmScaleFactor,
      scale,
      floorplan.originX,
      floorplan.originY,
      pageWidth * PAGE_LEFT_MARGIN_PERCENTAGE,
      pageHeight * PAGE_TOP_MARGIN_PERCENTAGE,
    );
    this.doc.addText(
      scene.sequenceNumber,
      point.x,
      point.y,
      this.sequenceSize ? this.sequenceSize * PT_TO_MM : TEXT_FONT_SIZE_MM,
      DEFAULT_TEXT_COLOR,
      getNodeColorPerScanType(scene.scanType),
    );
  }

  download(filename: string) {
    this.doc.download(filename);
  }
}

export const generateSequenceMaps = async (
  sequences: SequenceMapData[],
  outputFormat: "pdf",
  sequencesSize: number | undefined,
) => {
  let sequenceMapsReportGenerator: SequenceMapsReportGenerator;
  switch (outputFormat) {
    case "pdf":
      for (const sequence of sequences) {
        sequenceMapsReportGenerator = new SequenceMapsPdfReportGenerator(
          new JsPdfGenerator(jsPDF),
          sequencesSize,
        );
        await sequenceMapsReportGenerator.addSequenceMapPage(sequence);
        sequenceMapsReportGenerator.download(toFilename(sequence));
      }
  }
};
