import {
  ScanSequenceLevelType,
  SceneScanType,
} from "../SpatialConfigurationMode/types";
import { BaseNode } from "./types";

export const SVG_CIRCLE_SIZE_MM = 350;
export const DEFAULT_STROKE_MM = 350;
export const HIGHLIGHT_STROKE_MM = 550;
export const DEFAULT_NODE_OPACITY = 0.75;
export const ALIAS_STROKE_MM = 175;
export const STROKE_SIZE_RATIO = 10;
export const DEFAULT_TEXT_COLOR = "black";

export const DEFAULT_NODE_COLOR = "#39FF14";
const CLOSEUP_COLOR = "#FF0000";
const CUPBOARD_COLOR = "#FF00FF";

const EARLY_SEQUENCE_COLOR = "#758BFE";
const MID_SEQUENCE_COLOR = "#FF8126";
const LATE_SEQUENCE_COLOR = "#0AE7F5";

export const colorPerSceneScanType: Record<SceneScanType, string> = {
  [SceneScanType.DEFAULT]: DEFAULT_NODE_COLOR,
  [SceneScanType.CLOSE_UP]: CLOSEUP_COLOR,
  [SceneScanType.CUPBOARD]: CUPBOARD_COLOR,
};

export const colorPerScanningSequence: Record<ScanSequenceLevelType, string> = {
  [ScanSequenceLevelType.Early]: EARLY_SEQUENCE_COLOR,
  [ScanSequenceLevelType.Mid]: MID_SEQUENCE_COLOR,
  [ScanSequenceLevelType.Late]: LATE_SEQUENCE_COLOR,
};

type Point = {
  x: number;
  y: number;
};

export const euclidianDistance = (p1: Point, p2: Point): number => {
  return Math.sqrt(Math.pow(p1.x - p2.x, 2) + Math.pow(p1.y - p2.y, 2));
};

export const findClosestNodeId = (
  nodes: Pick<BaseNode, "id" | "x" | "y">[],
  clickX: number,
  clickY: number,
  threshold = SVG_CIRCLE_SIZE_MM,
): string | null => {
  const dist = nodes.map((node) =>
    euclidianDistance({ x: node.x, y: node.y }, { x: clickX, y: clickY }),
  );
  const minDist = Math.min(...dist);
  const minIdx = dist.indexOf(minDist);
  return minDist <= threshold ? nodes[minIdx].id : null;
};

export const getNodeColorPerScanType = (sceneScanType: SceneScanType) =>
  colorPerSceneScanType[sceneScanType];

export const getNodeColorPerSequence = (
  sceneScanLevel: ScanSequenceLevelType,
) => colorPerScanningSequence[sceneScanLevel];
