import * as Sentry from "@sentry/react";
import { Integrations } from "@sentry/tracing";
import React from "react";
import ReactDOM from "react-dom";
import App from "./App";

import "./global-style.css";
import "antd/dist/antd.css";

import "babel-polyfill";

if (process.env.SENTRY_DSN) {
  Sentry.init({
    dsn: process.env.SENTRY_DSN,
    integrations: [new Integrations.BrowserTracing()],
    tracesSampleRate: 0.01,
  });
}

ReactDOM.render(<App />, document.getElementById("root"));
