// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/cssWithMappingToString.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".ComponentsTable-module__components-table--3OeHW .ant-table-container{overflow-x:auto}", "",{"version":3,"sources":["webpack://./src/components/ComponentViewer/ComponentsTable.module.css"],"names":[],"mappings":"AACE,sEACE,eAAA","sourcesContent":[".components-table {\n  :global(.ant-table-container) {\n    overflow-x: auto;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"components-table": "ComponentsTable-module__components-table--3OeHW"
};
export default ___CSS_LOADER_EXPORT___;
