import React, { useCallback, useState } from "react";
import { FloorplanCreateInput } from "../hooks/useFloorplan";
import { ImportFloorplanModal } from "./ImportFloorplanModal";

interface CreateFloorplanModalContainerProps {
  onCreate: (floorplanData: FloorplanCreateInput) => any;
  onClose: () => void;
  spaceId: string;
  spaceName: string;
}

export const CreateFloorplanModalContainer = (
  props: CreateFloorplanModalContainerProps,
) => {
  const { onCreate, onClose, spaceId, spaceName } = props;
  const [uploadInProgress, setUploadInProgress] = useState<boolean>(false);

  const uploadFloorplanCallback = useCallback(
    async (floorplan: FloorplanCreateInput) => {
      setUploadInProgress(true);
      onCreate(floorplan);
      setUploadInProgress(false);
      onClose();
    },
    [onCreate, onClose],
  );

  return (
    <ImportFloorplanModal
      onClose={onClose}
      spaceId={spaceId}
      spaceName={spaceName}
      createFloorplan={uploadFloorplanCallback}
      uploadInProgress={uploadInProgress}
    />
  );
};
