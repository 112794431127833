import React, { useMemo } from "react";
import { Drawing, Scene } from "../SpatialConfigurationMode/types";
import { isSceneWithCoordinates } from "../SpatialConfigurationMode/utils";
import { ColorsLegend } from "./ColorsLegend";
import { DrawingTool } from "./DrawingTool";
import {
  ALIAS_STROKE_MM,
  DEFAULT_NODE_OPACITY,
  HIGHLIGHT_STROKE_MM,
  SVG_CIRCLE_SIZE_MM,
  colorPerSceneScanType,
  getNodeColorPerScanType,
} from "./drawing-utils";
import { AliasIcon } from "./shapes/AliasIcon";
import { SimpleCircle } from "./shapes/SimpleCircle";

const SELECTED_CIRCLE_STROKE_COLOR = "#FFBF00";

type DrawingToolContainerProps = {
  scenes: Scene[];
  selectedSceneIds?: string[];
  onDrawingClick?: (x: number, y: number) => void;
  showOriginAndScale?: boolean;
  drawing: Drawing;
};

export const DrawingToolContainer = (props: DrawingToolContainerProps) => {
  const {
    scenes,
    selectedSceneIds,
    onDrawingClick,
    drawing,
    showOriginAndScale,
  } = props;

  const nodeIcons = useMemo(
    () =>
      scenes.filter(isSceneWithCoordinates).map((scene) => {
        const isSelected = selectedSceneIds?.includes(scene.id);
        const isAlias = !!scene.aliasSceneId;
        const strokeColor = isSelected
          ? SELECTED_CIRCLE_STROKE_COLOR
          : undefined;
        const strokeWidth = isSelected
          ? HIGHLIGHT_STROKE_MM
          : isAlias
          ? ALIAS_STROKE_MM
          : undefined;
        const node = {
          id: scene.id,
          x: scene.x,
          y: scene.y,
          color: getNodeColorPerScanType(scene.scanType),
          size: SVG_CIRCLE_SIZE_MM,
          strokeColor,
          opacity: isSelected ? 1 : DEFAULT_NODE_OPACITY,
          strokeWidth,
        };

        return isAlias ? (
          <AliasIcon key={node.id} node={node} />
        ) : (
          <SimpleCircle key={node.id} node={node} />
        );
      }),
    [scenes, selectedSceneIds],
  );

  return (
    <>
      <DrawingTool
        nodes={nodeIcons}
        onDrawingClick={onDrawingClick}
        drawing={drawing}
        showOriginAndScale={showOriginAndScale}
      />
      <ColorsLegend colors={colorPerSceneScanType} />
    </>
  );
};
