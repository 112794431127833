import { InfoCircleOutlined } from "@ant-design/icons";
import { Tooltip } from "antd";
import React from "react";
import styles from "./ImportFloorplanModal.module.css";

interface ImportFloorplanModalHeaderProps {
  floorName: string;
}

export const ImportFloorplanModalHeader = (
  props: ImportFloorplanModalHeaderProps,
) => {
  const { floorName } = props;
  const title = `Import Floor Plan for ${floorName}`;
  return (
    <div className={styles["modal-title"]}>
      <div>{title}</div>
      <Tooltip
        title={
          <div className={styles["tooltip-content"]}>
            <ul>
              <li>To zoom, in scroll with your mouse or trackpad</li>
              <li>To pan, right click and drag with your mouse or trackpad</li>
            </ul>
          </div>
        }
      >
        <InfoCircleOutlined />
      </Tooltip>
    </div>
  );
};
