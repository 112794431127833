// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/cssWithMappingToString.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".SceneAliasModal-module__spaces-tree-box--1Waea{overflow-y:auto;height:95%}.SceneAliasModal-module__scene-name-box--2bnbD{margin-bottom:10px;font-weight:500}", "",{"version":3,"sources":["webpack://./src/components/SceneAliasEditor/SceneAliasModal.module.css"],"names":[],"mappings":"AAAA,gDACI,eAAA,CACA,UAAA,CAGJ,+CACI,kBAAA,CACA,eAAA","sourcesContent":[".spaces-tree-box {\n    overflow-y: auto;\n    height: 95%;\n}\n\n.scene-name-box {\n    margin-bottom: 10px;\n    font-weight: 500;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"spaces-tree-box": "SceneAliasModal-module__spaces-tree-box--1Waea",
	"scene-name-box": "SceneAliasModal-module__scene-name-box--2bnbD"
};
export default ___CSS_LOADER_EXPORT___;
