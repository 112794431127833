export const CREATED_SPACES_SUCCESS = "Spaces created successfully!";
export const CREATED_SPACES_ERROR = "Failed to create spaces";
export const UPDATED_SPACES_SUCCESS = "Spaces updated!";
export const UPDATED_SPACES_ERROR = "Failed to update spaces";
export const DELETED_SPACES_SUCCESS = "Spaces deleted successfully!";
export const DELETED_SPACES_ERROR = "Failed to delete spaces";
export const UPDATED_SCENES_SUCCESS = "Scenes updated!";
export const UPDATED_SCENES_ERROR = "Failed to update scenes";
export const CREATED_SCENES_SUCCESS = "Scenes created successfully!";
export const CREATED_SCENES_ERROR = "Failed to create scenes";
export const DELETED_SCENES_SUCCESS = "Scenes deleted successfully!";
export const DELETED_SCENES_ERROR = "Failed to delete scenes";
