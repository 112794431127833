import { Button, Select } from "antd";
import React, { useMemo, useState } from "react";
import { ScanSequencePrefixType } from "../SpatialConfigurationMode/types";
import styles from "./SequenceMode.module.css";

const CREATE_SEQUENCE_POPOVER_TITLE = "Create sequence";
const SUBMIT_BUTTON = "Submit";
const CLOSE_BUTTON = "Close";

interface CreateSequencePopoverProps {
  onSubmit: (prefix: ScanSequencePrefixType) => void;
  onClose: () => void;
}

export const CreateSequencePopover = (props: CreateSequencePopoverProps) => {
  const [selectedPrefix, setSelectedPrefix] =
    useState<ScanSequencePrefixType>();
  const selectorOptions = useMemo(
    () =>
      Object.keys(ScanSequencePrefixType).map((key) => ({
        value: key,
        label: key,
      })),
    [],
  );

  return (
    <div>
      <>{CREATE_SEQUENCE_POPOVER_TITLE}</>
      <Select<ScanSequencePrefixType>
        className={styles["sequence-mode__selector"]}
        options={selectorOptions}
        onChange={(value) => setSelectedPrefix(value)}
      />
      <Button
        type="primary"
        onClick={() => selectedPrefix && props.onSubmit(selectedPrefix)}
        disabled={!selectedPrefix}
      >
        {SUBMIT_BUTTON}
      </Button>
      <Button onClick={props.onClose}>{CLOSE_BUTTON}</Button>
    </div>
  );
};
