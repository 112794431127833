import React from "react";
import { useProject } from "../../../App/state";
import { useGetSubcontractorsQuery } from "../../../generated/types";
import {
  ComponentFieldSelectorContainer,
  ComponentFieldSelectorType,
} from "../../ComponentViewer/ComponentFieldSelectorContainer";
import { ModalOptions } from "../../ComponentViewer/CreateComponentFieldModal";
import { CreateSubcontractorForm } from "./CreateSubcontractorForm";

type SubcontractorSelectorContainerProps = {
  className?: string;
  selectedSubcontractorId?: string;
  onChange?: (value: string[]) => any;
};

export const CREATE_SUBCONTRACTOR_MODAL_TITLE = "Create new subcontractor";

export const SubcontractorSelectorContainer = (
  props: SubcontractorSelectorContainerProps,
) => {
  const { className, selectedSubcontractorId, onChange } = props;

  const project = useProject();

  const { data, loading, error } = useGetSubcontractorsQuery({
    variables: {
      ...project,
    },
  });

  const modalOptions: ModalOptions = {
    type: ComponentFieldSelectorType.Subcontractor,
    title: CREATE_SUBCONTRACTOR_MODAL_TITLE,
    form: CreateSubcontractorForm,
  };

  return (
    <ComponentFieldSelectorContainer
      componentFieldSelectorType={ComponentFieldSelectorType.Subcontractor}
      className={className}
      selected={selectedSubcontractorId}
      selectorValues={data?.subcontractors ?? []}
      loading={loading}
      modalOptions={modalOptions}
      onChange={onChange}
      error={error}
    />
  );
};
