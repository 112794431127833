import { UploadOutlined } from "@ant-design/icons";
import { Button, Form, Modal, Tooltip } from "antd";
import React, { useState } from "react";
import { useNotification } from "../../contexts/Notifications";
import { useUpdateComponentsMutation } from "../../generated/types";
import { Project } from "../../types";
import { ComponentAssignmentForm } from "./ComponentAssignmentForm";
import { ASSIGN_COMPONENT_ERROR, ASSIGN_COMPONENT_SUCCESS } from "./messages";

type ComponentAssignmentModalProps = {
  project: Project;
  taskId: string;
  planId: string;
  onComponentsUpdate: () => void;
  maxGuiIndex: number;
};

export const ComponentAssigningModal = (
  props: ComponentAssignmentModalProps,
) => {
  const [form] = Form.useForm();
  const [visible, setVisible] = useState<boolean>(false);

  const showModal = () => {
    setVisible(true);
  };

  const onCancel = () => {
    setVisible(false);
  };

  const notify = useNotification();
  const [assignComponentToTask] = useUpdateComponentsMutation({
    onCompleted: () => {
      notify(ASSIGN_COMPONENT_SUCCESS, "success");
      setVisible(false);
      form.resetFields();
      props.onComponentsUpdate();
    },
    onError: () => notify(ASSIGN_COMPONENT_ERROR, "error"),
    update: (cache: any) => {
      cache.evict({ fieldName: "componentsExcludingPlan" });
      cache.evict({ fieldName: "components" });
    },
  });

  const onOk = async () => {
    const values = await form.validateFields();
    await assignComponentToTask({
      variables: {
        customer: props.project.customer,
        project: props.project.project,
        scope: props.project.scope,
        components: [
          {
            id: values.componentId.id,
            taskId: props.taskId,
            name: values.componentId.name,
            componentTypeId: values.componentId.type.id,
            subcontractorId: values.componentId.subcontractor?.id,
            weight: values.weight,
            object: values.componentId.object,
            tags: values.componentId.tags ?? [],
            guiIndex: props.maxGuiIndex + 1,
          },
        ],
      },
    });
  };

  return (
    <>
      <Tooltip title={"Assign existing component"}>
        <Button
          type="primary"
          icon={<UploadOutlined />}
          onClick={showModal}
        ></Button>
      </Tooltip>
      <Modal
        visible={visible}
        title="Assign existing component to task"
        okText="Confirm"
        cancelText="Cancel"
        onCancel={onCancel}
        onOk={onOk}
      >
        <ComponentAssignmentForm form={form} planId={props.planId} />
      </Modal>
    </>
  );
};
