import { Select } from "antd";
import React from "react";
import { MappingState } from "../../../pages/EditorPage/types";

type MappingStateSelectorProps = {
  onChange: (option?: MappingState) => any;
};

export const MappingStateSelector = (props: MappingStateSelectorProps) => {
  const options = ["Enabled", "Disabled", "Missing"];
  return (
    <Select
      placeholder="Mapping state"
      onChange={(option?: MappingState) => props.onChange(option)}
      allowClear
    >
      {options.map((option) => (
        <Select.Option key={option} value={option}>
          {option}
        </Select.Option>
      ))}
    </Select>
  );
};
