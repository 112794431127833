import { PlusOutlined } from "@ant-design/icons";
import { Button, Form, Modal, Tooltip } from "antd";
import React, { useState } from "react";
import { useNotification } from "../../contexts/Notifications";
import { useCreateComponentMutation } from "../../generated/types";
import { Project } from "../../types";
import { ComponentCreationForm } from "./ComponentCreationForm";
import { CREATE_COMPONENT_ERROR, CREATE_COMPONENT_SUCCESS } from "./messages";

type ComponentCreationModalProps = {
  project: Project;
  taskId?: string;
  onComponentsUpdate: () => void;
};

export const ComponentCreationModal = (props: ComponentCreationModalProps) => {
  const [form] = Form.useForm();
  const [visible, setVisible] = useState(false);

  const notify = useNotification();
  const [createComponent] = useCreateComponentMutation({
    onCompleted: () => {
      notify(CREATE_COMPONENT_SUCCESS, "success");
      setVisible(false);
      form.resetFields();
      props.onComponentsUpdate();
    },
    onError: () => notify(CREATE_COMPONENT_ERROR, "error"),
  });

  const onOk = async () => {
    try {
      const values = await form.validateFields();
      await createComponent({
        variables: {
          customer: props.project.customer,
          project: props.project.project,
          scope: props.project.scope,
          taskId: props.taskId,
          name: values.name,
          componentTypeId: values.type[0],
          subcontractorId: values.subcontractor[0],
          weight: values.weight,
          object: values.object[0],
          tags: values.tags ?? [],
        },
      });
    } catch (info) {
      console.warn("Component validation failed:", info);
    }
  };

  return (
    <>
      <Tooltip title={"Create new component"}>
        <Button
          data-testid="create-button"
          type="primary"
          icon={<PlusOutlined />}
          onClick={() => setVisible(true)}
        ></Button>
      </Tooltip>
      <Modal
        visible={visible}
        title="Create new component"
        okText="Create"
        cancelText="Cancel"
        onCancel={() => {
          setVisible(false);
          form.resetFields();
        }}
        onOk={onOk}
      >
        <ComponentCreationForm
          form={form}
          includeWeightInForm={props.taskId !== undefined}
        />
      </Modal>
    </>
  );
};
