import classNames from "classnames";
import React from "react";
import { useProject } from "../../../App/state";
import { useNotification } from "../../../contexts/Notifications";
import { useUpdateSpotlightMutation } from "../../../generated/types";
import SpotlightImage from "../../../statics/images/spotlight.png";
import { Batch } from "../../../types";
import { normaliseYaw } from "../../../utils/pano";
import { DraggableItem } from "../../DragAndDrop/DraggableItem";
import { SpotlightInfo } from "../../SpotlightInfo/SpotlightInfo";
import { Annotation } from "./Annotation";
import styles from "./Spotlight.module.css";
import { Spotlight as SpotlightConfig } from "./types";

type SpotlightProps = {
  spotlight: SpotlightConfig;
  viewer: Viewer;
  currentBatch: Batch;
  onUpdate?: () => void;
  yawOffset: number;
  pitchOffset: number;
  isFocused?: boolean;
};

export const Spotlight = ({
  spotlight,
  viewer,
  currentBatch,
  onUpdate,
  yawOffset,
  pitchOffset,
  isFocused,
}: SpotlightProps) => {
  const project = useProject();
  const notify = useNotification();

  const [updateSpotlight] = useUpdateSpotlightMutation({
    onCompleted: () => {
      notify("Spotlight position updated!", "success");
      onUpdate && onUpdate();
    },
    onError: () => notify("Failed to update spotlight position!", "error"),
  });

  return (
    <DraggableItem
      item={spotlight}
      itemType={"spotlight"}
      dragPreviewOverride={SpotlightImage}
    >
      <Annotation
        viewer={viewer}
        id={spotlight.id}
        yaw={normaliseYaw(spotlight.yaw + yawOffset)}
        pitch={spotlight.pitch + pitchOffset}
        className={classNames(styles.spotlight, {
          [styles["focused-spotlight"]]: isFocused,
        })}
        onPositionUpdate={(pos) => {
          const normalisedPos = {
            yaw: normaliseYaw(pos.yaw - yawOffset),
            pitch: pos.pitch - pitchOffset,
          };
          updateSpotlight({
            variables: {
              customer: project.customer,
              project: project.project,
              scope: project.scope,
              batchId: currentBatch.id,
              id: spotlight.id,
              location: normalisedPos,
            },
          });
        }}
      >
        <SpotlightInfo
          spotlight={spotlight}
          currentBatchId={currentBatch.id}
          onUpdate={onUpdate}
        />
      </Annotation>
    </DraggableItem>
  );
};
