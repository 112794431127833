import { PlusOutlined } from "@ant-design/icons";
import { Button, Form, Input, Modal, Tooltip } from "antd";
import React, { useState } from "react";

type PlanCreationModalProps = {
  onCreate: (name: string) => Promise<void>;
};

export const PlanCreationModal = ({ onCreate }: PlanCreationModalProps) => {
  const [isVisible, setIsVisible] = useState(false);
  const [newPlanForm] = Form.useForm();
  return (
    <>
      <Tooltip title="create new plan">
        <Button
          type="primary"
          icon={<PlusOutlined />}
          onClick={() => setIsVisible(true)}
        ></Button>
      </Tooltip>
      <Modal
        title="Create new Plan"
        visible={isVisible}
        onOk={async () => {
          try {
            const values = await newPlanForm.validateFields();
            newPlanForm.resetFields();
            await onCreate(values.name);
            setIsVisible(false);
          } catch (errorInfo) {
            console.warn("Validation failed", errorInfo);
          }
        }}
        onCancel={() => setIsVisible(false)}
      >
        <Form form={newPlanForm}>
          <Form.Item
            name="name"
            label="Name"
            rules={[{ required: true, min: 1 }]}
            help="Please enter a name for the plan"
          >
            <Input placeholder="insert plan name ..." />
          </Form.Item>
        </Form>
      </Modal>
    </>
  );
};
