import { useProject } from "../../../App/state";
import { useGetSceneSpatialConfigQuery } from "../../../generated/types";
import { Scene } from "../types";

interface FetchSceneProps {
  sceneId?: string | null;
  children: (scene?: Pick<Scene, "id" | "name" | "path">) => JSX.Element;
}

export const FetchScene = (props: FetchSceneProps) => {
  const { sceneId, children } = props;
  const project = useProject();

  const { data } = useGetSceneSpatialConfigQuery({
    fetchPolicy: "no-cache",
    variables: {
      tenant: project,
      sceneId,
    },
    skip: !sceneId,
  });
  return children(data?.scenesByIds[0]);
};
