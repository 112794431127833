// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/cssWithMappingToString.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".BimViewer-module__viewer--30iIR{position:relative;height:100%}.BimViewer-module__viewer-hidden--7FP9Z{visibility:hidden;z-index:-1}.BimViewer-module__bim-viewer-switch--1TbW5{position:absolute;top:43px;right:0;padding-right:8px;z-index:21}.BimViewer-module__bim-model-selector-wrapper--VnXdS{display:flex;width:50%;align-items:center;gap:20px;font-size:16px}.BimViewer-module__bim-model-selector-wrapper--VnXdS .BimViewer-module__selector-title--GYtnY{font-weight:700}.BimViewer-module__bim-model-selector-wrapper--VnXdS .BimViewer-module__bim-model-selector--1Aqrz{flex:1}", "",{"version":3,"sources":["webpack://./src/components/DualView/BimViewer/BimViewer.module.css"],"names":[],"mappings":"AAAA,iCACE,iBAAA,CACA,WAAA,CAGF,wCACE,iBAAA,CACA,UAAA,CAGF,4CACE,iBAAA,CACA,QAAA,CACA,OAAA,CACA,iBAAA,CACA,UAAA,CAGF,qDACE,YAAA,CACA,SAAA,CACA,kBAAA,CACA,QAAA,CACA,cAAA,CAEA,8FACE,eAAA,CAGF,kGACE,MAAA","sourcesContent":[".viewer {\n  position: relative;\n  height: 100%;\n}\n\n.viewer-hidden {\n  visibility: hidden;\n  z-index: -1;\n}\n\n.bim-viewer-switch {\n  position: absolute;\n  top: 43px;\n  right: 0;\n  padding-right: 8px;\n  z-index: 21;\n}\n\n.bim-model-selector-wrapper {\n  display: flex;\n  width: 50%;\n  align-items: center;\n  gap: 20px;\n  font-size: 16px;\n\n  .selector-title {\n    font-weight: 700;\n  }\n\n  .bim-model-selector {\n    flex: 1;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"viewer": "BimViewer-module__viewer--30iIR",
	"viewer-hidden": "BimViewer-module__viewer-hidden--7FP9Z",
	"bim-viewer-switch": "BimViewer-module__bim-viewer-switch--1TbW5",
	"bim-model-selector-wrapper": "BimViewer-module__bim-model-selector-wrapper--VnXdS",
	"selector-title": "BimViewer-module__selector-title--GYtnY",
	"bim-model-selector": "BimViewer-module__bim-model-selector--1Aqrz"
};
export default ___CSS_LOADER_EXPORT___;
