import { Col, Form, PageHeader, Row, Switch } from "antd";
import classNames from "classnames";
import React from "react";
import { Project, Scope } from "../../types";
import Dropdown from "./Dropdown";
import styles from "./ProjectSelector.module.css";
import ScopeTag from "./ScopeTag";
import {
  setCustomer,
  setProject,
  setScope,
  useProjectSelectorState,
} from "./state";

interface ProjectSelectorProps {
  projects: { [customer: string]: { [project: string]: Scope[] } };
  customer?: string;
  project?: string;
  scope?: Scope;
  hideCustomer?: boolean;
  hideProject?: boolean;
  hideScope?: boolean;
  onChange?: (project: Project | null) => void;
}

const isValidCustomer = (
  projects: ProjectSelectorProps["projects"],
  customer: ProjectSelectorProps["customer"],
) => customer != null && projects[customer] != null;
const isValidProject = (
  projects: ProjectSelectorProps["projects"],
  customer: ProjectSelectorProps["customer"],
  project: ProjectSelectorProps["project"],
) =>
  customer != null && project != null && projects[customer]?.[project] != null;

const ProjectSelector = ({
  projects,
  customer,
  project,
  scope,
  hideCustomer,
  hideProject,
  hideScope,
  onChange,
}: ProjectSelectorProps) => {
  const validCustomer = isValidCustomer(projects, customer)
    ? customer
    : undefined;
  const validProject = isValidProject(projects, validCustomer, project)
    ? project
    : undefined;
  const validScope = scope ?? undefined;

  const [state, dispatch] = useProjectSelectorState({
    customer: validCustomer,
    project: validProject,
    scope: validScope ?? Scope.Internal,
  });

  // If we have just selected a full project, then pass it upwards
  // Otherwise, unset the upward value
  const projectMemo = React.useMemo(() => {
    if (
      state.customer == null ||
      state.project == null ||
      state.scope == null
    ) {
      return null;
    }
    return state as Project;
  }, [state]);

  React.useEffect(() => {
    if (onChange) {
      onChange(projectMemo);
    }
  }, [onChange, project, projectMemo]);

  // Use the passed props if they exist - if we modify the internal state,
  // then the effect above will clear these props if necessary
  const selectedCustomer = validCustomer ?? state.customer;
  const selectedProject = validProject ?? state.project;
  const selectedScope = validScope ?? state.scope ?? Scope.Internal;

  return (
    <>
      <PageHeader
        data-testid="project-selector"
        ghost={false}
        className={classNames("project-selector", styles["project-selector"])}
      >
        <Form layout="horizontal">
          <Row gutter={24}>
            {hideCustomer || (
              <Col>
                <Form.Item label="Customer">
                  <Dropdown
                    placeholder="Select..."
                    defaultValue={customer}
                    options={Object.keys(projects)}
                    selectedValue={selectedCustomer}
                    onSelect={(c) => dispatch(setCustomer(c))}
                  />
                </Form.Item>
              </Col>
            )}
            {hideProject || (
              <Col>
                <Form.Item label="Project">
                  <Dropdown
                    placeholder="Select..."
                    defaultValue={customer}
                    options={
                      state.customer != null && projects[state.customer]
                        ? Object.keys(projects[state.customer])
                        : []
                    }
                    selectedValue={selectedProject}
                    onSelect={(projectName) =>
                      dispatch(setProject(projectName))
                    }
                  />
                </Form.Item>
              </Col>
            )}
            {hideScope || (
              <Col>
                <Form.Item label="Scope">
                  <ScopeTag
                    selected={selectedScope === Scope.Internal}
                    scope={Scope.Internal}
                  />
                  <Switch
                    disabled={state.customer == null || state.project == null}
                    checked={state.scope === Scope.External}
                    onChange={(checked) =>
                      dispatch(
                        setScope(checked ? Scope.External : Scope.Internal),
                      )
                    }
                  ></Switch>
                  <ScopeTag
                    selected={selectedScope === Scope.External}
                    scope={Scope.External}
                  />
                </Form.Item>
              </Col>
            )}
          </Row>
        </Form>
      </PageHeader>
    </>
  );
};

export default ProjectSelector;
