import React from "react";
import { CrosshairNode } from "../types";

type CrosshairProps = {
  node: CrosshairNode;
};

const INNER_CIRCLE_SIZE_RATIO = 5;

export const Crosshair = ({ node }: CrosshairProps) => {
  return (
    <g id={node.id}>
      <circle
        cx={node.x}
        cy={node.y}
        r={node.size}
        fill={"none"}
        stroke={node.strokeColor ?? node.color}
        strokeWidth={node.strokeWidth}
        paintOrder={"stroke"}
      >
        {node.title && <title>{node.title}</title>}
      </circle>
      <circle
        cx={node.x}
        cy={node.y}
        r={node.size / INNER_CIRCLE_SIZE_RATIO}
        fill={"none"}
        stroke={node.strokeColor ?? node.color}
        strokeWidth={node.strokeWidth}
        paintOrder={"stroke"}
      />
      <line
        x1={node.x - node.size}
        y1={node.y}
        x2={node.x + node.size}
        y2={node.y}
        stroke={node.strokeColor ?? node.color}
        strokeWidth={node.strokeWidth}
        paintOrder={"stroke"}
      />
      <line
        x1={node.x}
        y1={node.y + node.size}
        x2={node.x}
        y2={node.y - node.size}
        stroke={node.strokeColor ?? node.color}
        strokeWidth={node.strokeWidth}
        paintOrder={"stroke"}
      />
    </g>
  );
};
