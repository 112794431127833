// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/cssWithMappingToString.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".SpacesTree-module__load-more-button--2UB8U>.ant-tree-node-content-wrapper:hover{background-color:unset !important}.SpacesTree-module__search-results--3kchI{color:#8c8c8c}.SpacesTree-module__search-results--3kchI .SpacesTree-module__search-results__count--1_pID{color:#1890ff}.SpacesTree-module__disabled-mapping--1Xajo{text-decoration:line-through}.SpacesTree-module__link-container--wAYyy{display:inline}.SpacesTree-module__scrollable-container--2tKhv{flex-grow:1;height:100%;overflow-y:scroll}.SpacesTree-module__spaces-tree-options-container--2Hlfb{display:flex;justify-content:space-between;align-items:center}.SpacesTree-module__node-title--1zZ88{display:flex}.SpacesTree-module__switch-container--13veG{position:absolute}", "",{"version":3,"sources":["webpack://./src/components/SpacesTree/SpacesTree.module.css"],"names":[],"mappings":"AACE,iFACE,iCAAA,CAIJ,0CACE,aAAA,CACA,2FACE,aAAA,CAIJ,4CACE,4BAAA,CAGF,0CACE,cAAA,CAGF,gDACE,WAAA,CACA,WAAA,CACA,iBAAA,CAGF,yDACE,YAAA,CACA,6BAAA,CACA,kBAAA,CAGF,sCACE,YAAA,CAGF,4CACE,iBAAA","sourcesContent":[".load-more-button {\n  & > :global(.ant-tree-node-content-wrapper):hover {\n    background-color: unset !important;\n  }\n}\n\n.search-results {\n  color: #8c8c8c;\n  .search-results__count {\n    color: #1890ff;\n  }\n}\n\n.disabled-mapping {\n  text-decoration: line-through;\n}\n\n.link-container {\n  display: inline;\n}\n\n.scrollable-container {\n  flex-grow: 1;\n  height: 100%;\n  overflow-y: scroll;\n}\n\n.spaces-tree-options-container {\n  display: flex;\n  justify-content: space-between;\n  align-items: center;\n}\n\n.node-title {\n  display: flex;\n}\n\n.switch-container {\n  position: absolute;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"load-more-button": "SpacesTree-module__load-more-button--2UB8U",
	"search-results": "SpacesTree-module__search-results--3kchI",
	"search-results__count": "SpacesTree-module__search-results__count--1_pID",
	"disabled-mapping": "SpacesTree-module__disabled-mapping--1Xajo",
	"link-container": "SpacesTree-module__link-container--wAYyy",
	"scrollable-container": "SpacesTree-module__scrollable-container--2tKhv",
	"spaces-tree-options-container": "SpacesTree-module__spaces-tree-options-container--2Hlfb",
	"node-title": "SpacesTree-module__node-title--1zZ88",
	"switch-container": "SpacesTree-module__switch-container--13veG"
};
export default ___CSS_LOADER_EXPORT___;
