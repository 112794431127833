import { Select } from "antd";
import _ from "lodash";
import React, { useMemo } from "react";
import styles from "./ProjectSelector.module.css";

interface DropdownProps {
  defaultValue?: string;
  options: string[];
  placeholder?: string;
  selectedValue: string | undefined;
  onSelect: (value: string) => void;
}

const Dropdown = (props: DropdownProps) => {
  const sortedOptions = useMemo(
    () => _.orderBy(props.options),
    [props.options],
  );
  return (
    <Select
      className={styles.dropdown}
      showSearch
      placeholder={props.placeholder}
      defaultValue={props.defaultValue}
      disabled={props.options.length === 0}
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      value={props.selectedValue || ([] as any)}
      onChange={props.onSelect}
    >
      {sortedOptions.map((option) => (
        <Select.Option key={option} value={option}>
          {option}
        </Select.Option>
      ))}
    </Select>
  );
};

export default Dropdown;
