import { Tag } from "antd";
import React from "react";
import { Scope } from "../../types";

interface ScopeTagProps {
  scope: Scope;
  selected: boolean;
}

const ScopeTag = ({ scope, selected }: ScopeTagProps) => (
  <Tag color={selected ? "blue" : "default"}>
    {scope === Scope.Internal ? "Internal" : "External"}
  </Tag>
);

export default ScopeTag;
