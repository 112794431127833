import React from "react";
import { useProject } from "../App/state";
import { useCanEditJiraIssuesQuery } from "../generated/types";

interface UserActions {
  canEditJiraIssues: boolean;
  // Add your user action definition here, e.g.
  // canPerformUserAction: boolean;
}
const DEFAULT_CONTEXT: UserActions = {
  canEditJiraIssues: false,
  // Add your default value here, e.g.
  // canPerformUserAction: false;
};
const UserActionContext = React.createContext<UserActions>(DEFAULT_CONTEXT);

interface Props {
  children: React.ReactNode;
}
export const UserActionProvider = ({ children }: Props) => {
  const [context, setContext] = React.useState<UserActions>(DEFAULT_CONTEXT);

  const tenant = useProject();

  const canEditJiraIssues =
    useCanEditJiraIssuesQuery({ variables: { tenant } }).data
      ?.canEditJiraIssues ?? false;

  // Add your action value query here, if appropriate

  React.useEffect(() => {
    setContext((oldContext) => ({
      ...oldContext,
      canEditJiraIssues,
      // Add your updated action value here (don't forget the dependencies array)
    }));
  }, [setContext, canEditJiraIssues]);

  return (
    <UserActionContext.Provider value={context}>
      {children}
    </UserActionContext.Provider>
  );
};

export const useUserAction = () => React.useContext(UserActionContext);
