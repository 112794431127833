import { Layout } from "antd";
import React from "react";
import { useProject } from "../App/state";
import { AggServInternalLoadButton } from "../components/AggServInternalLoadButton/AggServInternalLoadButton";
import { AggservStatus } from "../components/AggServStatus";
import { ProjectReleaseButton } from "../components/ProjectReleaseButton/ProjectReleaseButton";
import { ProjectWorkQueueStatus } from "../components/ProjectWorkQueueStatus/ProjectWorkQueueStatus";
import styles from "./pageStyles.module.css";

export const ReleasePage = () => {
  const project = useProject();

  return (
    <Layout style={{ height: "100%" }}>
      <Layout.Content>
        <ProjectReleaseButton project={project} />
        <AggServInternalLoadButton project={project} />
        <AggservStatus project={project} />
        <div className={styles["project-status-container"]}>
          <ProjectWorkQueueStatus project={project} showTitle={true} />
        </div>
      </Layout.Content>
    </Layout>
  );
};
