import { InfoCircleFilled, InfoCircleOutlined } from "@ant-design/icons";
import { Button, Col, Empty, Row, Space, Tooltip } from "antd";
import React, { useCallback, useMemo, useRef, useState } from "react";
import { useUserAction } from "../../contexts/UserActions";
import { Scene } from "../../generated/types";
import { FloorplanData } from "../../pages/DualModePage/state";
import { PanoPosition, PanoView } from "../../types";
import { IssueButtons } from "../ShotIssueButtons";
import { BimViewer } from "./BimViewer";
import { BimModel, BimModelSelector } from "./BimViewer/BimModelSelector";
import { BimViewerSwitch } from "./BimViewer/BimViewerSwitch";
import {
  BimState,
  BimStateAction,
  LocationState,
  SwitchState,
} from "./BimViewer/useBimState";
import styles from "./DualViewer.module.css";
import { SingleViewer } from "./SingleViewer";
import { ViewerTitle } from "./ViewerTitle";
import { ZeroPointingControls } from "./ZeroPointingControls";
import { useIsBimEnabled } from "./hooks/useIsBimEnabled";
import { Shot, ShotViewerProps } from "./types";
import { coordinatesToMeters } from "./utils";

type SpotlightCreationToggleProps = {
  value: boolean;
  onToggle: () => void;
};

const SpotlightCreationToggle = ({
  value,
  onToggle,
}: SpotlightCreationToggleProps) => (
  <Tooltip
    placement="topRight"
    title={"turn spotlight creation mode " + (value ? "off" : "on")}
  >
    <Button
      icon={value ? <InfoCircleFilled /> : <InfoCircleOutlined />}
      onClick={onToggle}
    />
  </Tooltip>
);

type DualViewerProps = {
  view: PanoView;
  onChangeView: (view: PanoView) => any;
  comparisonShot?: Shot;
  referenceShot?: Shot;
  onSpotlightUpdate?: () => any;
  focusedSpotlightId?: string;
  onZeroPoint?: (diff: PanoPosition) => any;
  arrowSceneNavigation: (evt: KeyboardEvent) => void;
  floorPlanData?: FloorplanData;
  bimState: BimState;
  onChangeBimState: (action: BimStateAction) => void;
  sceneCoordinates: Pick<Scene, "id" | "mmX" | "mmY" | "mmZOffset"> | undefined;
};

type DualViewerMode = "default" | "spotlightCreation" | "zeroPoint";

const EMPTY_SHOT_DESCRIPTION = "No shot available";

export const DualViewer = (props: DualViewerProps) => {
  const {
    view,
    onChangeView,
    comparisonShot,
    referenceShot,
    onSpotlightUpdate,
    focusedSpotlightId,
    onZeroPoint,
    arrowSceneNavigation,
    floorPlanData,
    bimState,
    onChangeBimState,
    sceneCoordinates,
  } = props;

  const isBimEnabled = useIsBimEnabled();
  const [mode, setMode] = useState<DualViewerMode>("default");
  const [viewOnZeroPoint, setViewOnZeroPoint] = useState<PanoPosition>();
  const [selectedBimModel, setSelectedBimModel] = React.useState<BimModel>();

  const { canEditJiraIssues } = useUserAction();

  const shots: {
    key: "comparison" | "reference";
    shot: Shot | undefined;
  }[] = [
    { key: "comparison", shot: comparisonShot },
    { key: "reference", shot: referenceShot },
  ];

  const authoritativeViewer = useRef("right");
  const handleChangeView = useCallback(
    (update, viewerId) => {
      if (authoritativeViewer.current === viewerId) {
        onChangeView(update);
      }
    },
    [onChangeView],
  );

  const updateAuthoritativeViewer = useCallback(
    (viewerId) => {
      if (authoritativeViewer.current !== viewerId) {
        authoritativeViewer.current = viewerId;
      }
    },
    [authoritativeViewer],
  );

  const showBimModel = bimState.switch === SwitchState.MODEL_VIEW;
  const comparisonViewerTitle = showBimModel ? "Model View" : "comparison";

  const shotViewer = ({ key, shot }: ShotViewerProps) => {
    return shot === undefined ? (
      <Empty description={EMPTY_SHOT_DESCRIPTION} />
    ) : (
      <div
        key={key}
        className={styles["viewer-content"]}
        onMouseDown={() => updateAuthoritativeViewer(shot.title)}
        onWheel={() => updateAuthoritativeViewer(shot.title)}
      >
        <SingleViewer
          viewerId={shot.title}
          sceneId={shot.scene?.id ?? ""}
          panellumSceneId={shot.imageConfig.imageId}
          imageConfig={shot.imageConfig}
          batch={shot.batch}
          view={view}
          onChangeView={(update) => {
            if (mode === "zeroPoint") {
              setViewOnZeroPoint(update.position);
            } else {
              handleChangeView(update, shot.title);
            }
          }}
          spotlights={shot.spotlights}
          arrows={shot.arrows}
          enableSpotlightCreation={mode === "spotlightCreation"}
          onSpotlightUpdate={() => {
            setMode("default");
            onSpotlightUpdate && onSpotlightUpdate();
          }}
          focusedSpotlightId={focusedSpotlightId}
          frozen={mode === "zeroPoint" && key === "comparison"}
          showCrosshair={mode === "zeroPoint"}
          pitchBounds={mode === "zeroPoint" ? { min: 0, max: 0 } : undefined}
        />
      </div>
    );
  };

  const sceneBimCoordinates = useMemo(() => {
    const x = sceneCoordinates?.mmX ?? 0;
    const y = sceneCoordinates?.mmY ? -sceneCoordinates.mmY : 0; //negative value because the orientation of y-axis is inverted in spatial config
    const zOffset = sceneCoordinates?.mmZOffset ?? 0;
    const floorHeight = floorPlanData?.floorHeight ?? 0;

    return coordinatesToMeters(x, y, zOffset + floorHeight);
  }, [
    sceneCoordinates?.mmX,
    sceneCoordinates?.mmY,
    sceneCoordinates?.mmZOffset,
    floorPlanData?.floorHeight,
  ]);

  const bimOrigin = useMemo(
    () =>
      coordinatesToMeters(
        floorPlanData?.bimOriginX ?? 0,
        floorPlanData?.bimOriginY ?? 0,
        floorPlanData?.bimOriginZ ?? 0,
      ),
    [
      floorPlanData?.bimOriginX,
      floorPlanData?.bimOriginY,
      floorPlanData?.bimOriginZ,
    ],
  );

  const comparisonWindow = (
    <Col key={"comparison"} span={12}>
      <div className={styles["viewer-pane"]}>
        <div className={styles["title-wrapper"]}>
          <ViewerTitle title={comparisonViewerTitle} />
          <BimModelSelector
            isVisible={showBimModel}
            selectedBimModel={selectedBimModel}
            setSelectedBimModel={setSelectedBimModel}
          />
        </div>
        <div className={styles["viewer-display"]}>
          {isBimEnabled && (
            <BimViewerSwitch
              bimState={bimState}
              showBimModel={showBimModel}
              onChangeBimState={onChangeBimState}
            />
          )}
          <BimViewer
            selectedUrn={selectedBimModel?.value ?? ""}
            isVisible={showBimModel}
            sceneCoordinates={sceneBimCoordinates}
            bimOrigin={bimOrigin}
            angleToTrueNorth={floorPlanData?.angleToTrueNorth ?? 0}
            imageTarget={view.position}
            setImageTarget={(yaw, pitch, hfov) =>
              onChangeView({ ...view, position: { yaw, pitch }, hfov })
            }
            fov={view.hfov}
            isLocationLoading={bimState.location === LocationState.LOADING}
            onChangeBimState={onChangeBimState}
            setBimAsAuthoritativeViewer={() => updateAuthoritativeViewer("bim")}
          />
          {!showBimModel
            ? shots.filter((key) => key.key === "comparison").map(shotViewer)
            : null}
        </div>
      </div>
    </Col>
  );

  return (
    <div className={styles.container}>
      <div className={styles.header}>
        {referenceShot && canEditJiraIssues && (
          <IssueButtons
            arrowSceneNavigation={arrowSceneNavigation}
            shot={referenceShot}
          />
        )}
        <Space className={styles["header-button-group"]}>
          <ZeroPointingControls
            isOn={mode === "zeroPoint"}
            onEnter={() => {
              setMode("zeroPoint");
              onChangeView({
                hfov: view.hfov,
                position: { yaw: view.position.yaw, pitch: 0 },
              });
            }}
            onSave={() => {
              setMode("default");
              const panoDiff = {
                yaw: (viewOnZeroPoint?.yaw as number) - view.position.yaw,
                // do not update the pitch
                pitch: 0,
              };
              onZeroPoint && onZeroPoint(panoDiff);
              setViewOnZeroPoint(undefined);
            }}
            onDiscard={() => {
              setMode("default");
              // update the view so both sides of the viewer "jump" into sync again
              onChangeView({
                hfov: view.hfov,
                position: viewOnZeroPoint as PanoPosition,
              });
              setViewOnZeroPoint(undefined);
            }}
          />
          <SpotlightCreationToggle
            value={mode === "spotlightCreation"}
            onToggle={() =>
              setMode((prev) =>
                prev === "default" ? "spotlightCreation" : "default",
              )
            }
          />
        </Space>
      </div>
      <Row gutter={16} className={styles.viewer}>
        {comparisonWindow}
        <Col key={"reference"} span={12}>
          <div className={styles["viewer-pane"]}>
            <ViewerTitle title={"reference"} />
            {shots.filter((key) => key.key === "reference").map(shotViewer)}
          </div>
        </Col>
      </Row>
    </div>
  );
};
