import { useState } from "react";
import React from "react";
import { ScanSequenceLevelType } from "../SpatialConfigurationMode/types";
import { SequenceMode, SequenceModeProps } from "./SequenceMode";

type SequenceModeWrapperProps = Pick<
  SequenceModeProps,
  "floorplan" | "scenes" | "HeaderBarWrapper"
>;

export const SequenceModeWrapper = (props: SequenceModeWrapperProps) => {
  const [selectedScanningLevel, setSelectedScanningLevel] =
    useState<ScanSequenceLevelType>();

  const { floorplan } = props;
  return (
    <SequenceMode
      key={floorplan?.id}
      selectedScanningLevel={selectedScanningLevel}
      setSelectedScanningLevel={setSelectedScanningLevel}
      {...props}
    />
  );
};
