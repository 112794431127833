// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/cssWithMappingToString.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".DrawingPath-module__node-path--3bOZK{flex:1;justify-content:center;margin-top:10px;margin-bottom:10px}.DrawingPath-module__node-path-item--6BgAK{margin-right:5px}", "",{"version":3,"sources":["webpack://./src/components/DrawingTool/DrawingPath/DrawingPath.module.css"],"names":[],"mappings":"AAAA,sCACI,MAAA,CACA,sBAAA,CACA,eAAA,CACA,kBAAA,CAGF,2CACE,gBAAA","sourcesContent":[".node-path {\n    flex: 1;\n    justify-content: center;\n    margin-top: 10px;\n    margin-bottom: 10px;\n  }\n  \n  .node-path-item{\n    margin-right: 5px;\n  }\n  "],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"node-path": "DrawingPath-module__node-path--3bOZK",
	"node-path-item": "DrawingPath-module__node-path-item--6BgAK"
};
export default ___CSS_LOADER_EXPORT___;
