import { ApolloCache, gql } from "@apollo/client";
import { Space as AntdSpace, Col, Form, Input, Row } from "antd";
import React, { useCallback } from "react";
import { useNotification } from "../../../contexts/Notifications";
import { useCreateScenesMutation } from "../../../generated/types";
import { Project } from "../../../types";
import { Space } from "../../SpacesTree/tree-nodes";
import { CREATED_SCENES_ERROR, CREATED_SCENES_SUCCESS } from "../messages";

interface NewSceneFormProps {
  id?: string;
  project: Project;
  selectedSpaces: Space[];
  disabled?: boolean;
}

export const updateCacheOnNewScenes = (
  cache: ApolloCache<any>,
  createdScenes: any[],
) => {
  const parentSpaces = createdScenes.map((scene) => scene.space.id);
  cache.modify({
    fields: {
      space(existingSpace, { readField }) {
        const parentSpaceId = readField("id", existingSpace);
        if (!parentSpaces?.includes(parentSpaceId)) {
          return existingSpace;
        }
        const currentNewScene = createdScenes.find(
          (scene) => scene.space.id === parentSpaceId,
        );
        if (!currentNewScene) {
          return existingSpace;
        }
        const parentSpaceScenes: readonly any[] =
          readField("scenes", existingSpace) ?? [];
        const newRef = cache.writeFragment({
          data: currentNewScene,
          fragment: gql`
            fragment NewScene on Scene {
              id
              name
              guiIndex
            }
          `,
        });
        cache.writeQuery({
          query: gql`
            query WriteSpace($id: UUID!) {
              space(id: $id) {
                scenes
              }
            }
          `,
          data: {
            space: {
              scenes: [...parentSpaceScenes, newRef],
            },
          },
          variables: {
            id: parentSpaceId,
          },
        });
      },
    },
  });
};

export const NewSceneForm = (props: NewSceneFormProps) => {
  const { id, project, selectedSpaces, disabled } = props;
  const { customer, project: proj, scope } = project;
  const [form] = Form.useForm();

  const notify = useNotification();
  const [createScenes] = useCreateScenesMutation({
    onCompleted: () => {
      notify(CREATED_SCENES_SUCCESS, "success");
    },
    onError: (error) => notify(`${CREATED_SCENES_ERROR} - ${error}`, "error"),
    update: (cache, { data }) =>
      /*  TODO: updateCacheOnNewScenes not currently updating cache correctly.
    For now, evicting entire cache causes tree to reload which then loads in the new scene.
    This is fine if reloading the tree is acceptably quick. */
      data?.createScenes && cache.evict({}),
  });

  const handleSubmit = useCallback(() => {
    const name = form.getFieldValue("name");
    return createScenes({
      variables: {
        customer: customer,
        project: proj,
        scope: scope,
        scenes: selectedSpaces.map((s: Space) => ({
          name: name,
          spaceId: s.id,
        })),
      },
    });
  }, [proj, scope, customer, createScenes, form, selectedSpaces]);

  return (
    <Form id={id} form={form} onFinish={handleSubmit}>
      <AntdSpace direction={"vertical"} style={{ width: "100%" }}>
        <Row gutter={8}>
          <Col span={24}>
            <Form.Item
              label="Name"
              name="name"
              rules={[
                {
                  message: "Required",
                  required: true,
                },
              ]}
            >
              <Input placeholder="Name" disabled={disabled} />
            </Form.Item>
          </Col>
        </Row>
      </AntdSpace>
    </Form>
  );
};
