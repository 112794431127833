import { Spin } from "antd";
import _ from "lodash";
import React from "react";
import { useProject } from "../../App/state";
import { useGetAncestorsForSceneQuery } from "../../generated/types";
import { useDualModeState } from "../../pages/DualModePage/state";
import { CopyToClipboardButton } from "../CopyToClipboardButton";
import { DropdownChain } from "./DropdownChain";
import styles from "./style.module.css";

interface SceneSelectionDropdownsContainerProps {
  rootSpaceId: string;
  sceneId: string;
  onSelect: (sceneId: string) => void;
}

export const SceneSelectionDropdownsContainer = (
  props: SceneSelectionDropdownsContainerProps,
) => {
  const project = useProject();
  const dualMode = useDualModeState();

  const {
    data: newData,
    previousData,
    loading,
    error,
  } = useGetAncestorsForSceneQuery({
    variables: {
      ...project,
      sceneId: props.sceneId,
    },
  });

  // Use old results until new ones have loaded.
  const data = newData ?? previousData;

  if (error) {
    return <div>Something went wrong... {error.message}</div>;
  }

  if (data == null && loading) {
    return <Spin />;
  }

  if (data == null) {
    return <div>Something went wrong.</div>;
  }

  const selectedScene = data.scene;

  const rootSpaceIndex = selectedScene?.space.ancestors.findIndex(
    (space) => space.id === props.rootSpaceId,
  );

  const spaces =
    rootSpaceIndex !== undefined &&
    selectedScene !== undefined &&
    selectedScene !== null &&
    rootSpaceIndex >= 0
      ? selectedScene.space.ancestors.slice(rootSpaceIndex)
      : [];

  if (selectedScene !== undefined && selectedScene !== null) {
    spaces.push({ id: selectedScene.space.id, name: selectedScene.space.name });
  }
  const scene =
    selectedScene !== undefined && selectedScene !== null
      ? {
          id: selectedScene.id,
          name: selectedScene.name,
        }
      : undefined;

  const spaceText = [...spaces, scene]
    .filter((entry) => !_.isEmpty(entry))
    .map((entry) => entry?.name)
    .join(" - ");

  return (
    <div className={styles["dropdown-chain-wrapper"]}>
      <DropdownChain
        spaces={spaces}
        scene={scene}
        onChangeSpace={dualMode.loadDefaultScene}
        onChangeScene={props.onSelect}
      />
      <CopyToClipboardButton
        text={spaceText}
        className={styles["copy-button"]}
        buttonText={"Copy path"}
        buttonType="primary"
      />
    </div>
  );
};
