import { Select, Space } from "antd";
import _ from "lodash";
import React from "react";
import { useProject } from "../../App/state";
import {
  useGetScenesQuery,
  useGetSiblingSpacesQuery,
} from "../../generated/types";
import styles from "./style.module.css";

interface SpaceAndSceneDropdownProps {
  space: { id: string; name: string };
  selectedScene?: { id: string; name: string };
  disableSpaceSelection?: boolean;
  onChangeSpace: (spaceId: string) => void;
  onChangeScene: (sceneId: string) => void;
  wrapLine?: boolean;
}

export const SpaceAndSceneDropdown = (props: SpaceAndSceneDropdownProps) => {
  const project = useProject();
  const spacesResult = useGetSiblingSpacesQuery({
    variables: {
      ...project,
      spaceId: props.space.id,
    },
    skip: props.disableSpaceSelection,
  });
  const scenesResult = useGetScenesQuery({
    variables: {
      ...project,
      spaceId: props.space.id,
    },
  });

  if (spacesResult.error != null || scenesResult.error != null) {
    return <div>Something went wrong...</div>;
  }

  return (
    <Space
      size={8}
      direction="vertical"
      style={{ display: "flex", flexDirection: "column" }}
    >
      <Select
        className={
          props.wrapLine ? styles["dropdown-wrapped"] : styles.dropdown
        }
        loading={spacesResult.loading}
        disabled={props.disableSpaceSelection}
        value={props.space.id}
        onChange={props.onChangeSpace}
      >
        {spacesResult.data != null ? (
          _.sortBy(
            spacesResult.data.space.parentSpace?.subSpaces,
            "guiIndex",
          ).map((space) => (
            <Select.Option key={space.id} value={space.id}>
              {space.name}
            </Select.Option>
          ))
        ) : (
          <Select.Option value={props.space.id}>
            {props.space.name}
          </Select.Option>
        )}
      </Select>
      <Select
        className={
          props.wrapLine ? styles["dropdown-wrapped"] : styles.dropdown
        }
        loading={scenesResult.loading}
        value={props.selectedScene?.id}
        onChange={props.onChangeScene}
      >
        {scenesResult.data != null
          ? _.sortBy(scenesResult.data.space.scenes, "guiIndex").map(
              (scene) => (
                <Select.Option key={scene.id} value={scene.id}>
                  {scene.name}
                </Select.Option>
              ),
            )
          : props.selectedScene != null && (
              <Select.Option value={props.selectedScene.id}>
                {props.selectedScene.name}
              </Select.Option>
            )}
      </Select>
    </Space>
  );
};
