import { Button, Form, Input } from "antd";
import React from "react";
import { useProject } from "../../../App/state";
import { useNotification } from "../../../contexts/Notifications";
import {
  SubcontractorFragmentDoc,
  useCreateSubcontractorsMutation,
} from "../../../generated/types";
import {
  CREATE_SUBCONTRACTOR_ERROR,
  CREATE_SUBCONTRACTOR_SUCCESS,
} from "../../ComponentViewer/messages";

const MISSING_INPUT_MESSAGE = "Missing input!";

export const CreateSubcontractorForm = (props: { onSubmit: () => void }) => {
  const project = useProject();
  const notify = useNotification();
  const [createSubcontractor] = useCreateSubcontractorsMutation({
    update: (cache, { data }) => {
      cache.modify({
        fields: {
          subcontractors() {
            data?.createSubcontractors.map((newSubcontractor) =>
              cache.writeFragment({
                id: `Subcontractor:${newSubcontractor.id}`,
                data: newSubcontractor,
                fragment: SubcontractorFragmentDoc,
              }),
            ) ?? [];
          },
        },
      });
    },
    onCompleted: () => {
      notify(CREATE_SUBCONTRACTOR_SUCCESS, "success");
    },
    onError: () => {
      notify(CREATE_SUBCONTRACTOR_ERROR, "error");
    },
  });

  const [form] = Form.useForm();
  const handleOk = async () => {
    try {
      await form.validateFields();
    } catch (err) {
      return;
    }
    await createSubcontractor({
      variables: {
        ...project,
        subcontractors: [form.getFieldValue("name")],
      },
    });

    form.resetFields();
    props.onSubmit();
  };

  return (
    <>
      <Form form={form} onFinish={handleOk}>
        <Form.Item
          label="Name"
          name="name"
          rules={[{ required: true, message: MISSING_INPUT_MESSAGE }]}
        >
          <Input data-testid="form-subcontractor-name-input" />
        </Form.Item>
      </Form>
      <Button type="primary" onClick={handleOk} data-testid="form-ok-button">
        Ok
      </Button>
    </>
  );
};
