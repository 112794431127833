import { ApolloCache, NormalizedCacheObject } from "@apollo/client";
import { useProject } from "../../../App/state";
import { useNotification } from "../../../contexts/Notifications";
import {
  FloorplanIdsDocument,
  GetSpacesTreeWithDescendantSpacesDocument,
  PatchSpaceInputType,
  SpaceWithParentAndAncestorsFragmentFragment,
  SpaceWithParentAndAncestorsFragmentFragmentDoc,
  useCopySpatialDataMutation,
  useCreateSpacesSpatialConfigMutation,
  useDeleteSpacesSpatialConfigMutation,
  useGetSpaceOptionsByIdQuery,
  usePatchSpacesSpatialConfigMutation,
} from "../../../generated/types";
import { Space } from "../../../types";

export const updateCacheOnNewSpaces = (
  cache: ApolloCache<NormalizedCacheObject>,
  createdSpaces: SpaceWithParentAndAncestorsFragmentFragment[],
) => {
  createdSpaces.forEach((newSpace) => {
    const newSpaceRef = cache.writeFragment({
      data: newSpace,
      fragment: SpaceWithParentAndAncestorsFragmentFragmentDoc,
      fragmentName: "SpaceWithParentAndAncestorsFragment",
    });
    newSpace.ancestors.forEach((ancestorSpace) => {
      cache.modify({
        id: cache.identify(ancestorSpace),
        fields: {
          descendantSpaces(existingSpaces) {
            return existingSpaces
              ? [...existingSpaces, newSpaceRef]
              : existingSpaces;
          },
        },
      });
    });
  });
};

const NEW_SPACE_NAME = "New space";

export const useSpaces = (spaceId?: string) => {
  const project = useProject();
  const notify = useNotification();

  const { data: spacesData } = useGetSpaceOptionsByIdQuery({
    variables: {
      tenant: project,
      spaceId,
    },
    skip: !spaceId,
  });

  const [patchSpaces] = usePatchSpacesSpatialConfigMutation({
    onCompleted: () => {
      notify("Succesfully edited spaces", "success");
    },
    onError: (err) => notify(`Error editing spaces: ${err}`, "error"),
  });

  const [deleteSpaces] = useDeleteSpacesSpatialConfigMutation({
    onCompleted: () => {
      notify("Sucessfully deleted spaces", "success");
    },
    onError: (error) => {
      notify(`Error deleting spaces - ${error}`, "error");
    },
    update: (cache, { data }) => {
      if (data?.deleteSpaces) {
        data.deleteSpaces.forEach((id) => {
          cache.evict({ id: cache.identify({ __typename: "Space", id }) });
        });
        cache.gc();
      }
    },
  });

  const [createSpace] = useCreateSpacesSpatialConfigMutation({
    onCompleted: () => {
      notify("Space created successfully", "success");
    },
    onError: (error) => notify(`Error creating space - ${error}`, "error"),
    update: (cache, { data }) => {
      updateCacheOnNewSpaces(cache, data?.createSpaces ?? []);
    },
  });

  const [copySpatialData] = useCopySpatialDataMutation({
    refetchQueries: [
      FloorplanIdsDocument,
      GetSpacesTreeWithDescendantSpacesDocument,
    ],
    onError: (error) =>
      notify(`Spatial data copy failed: ${error.message}`, "error"),
    onCompleted: () => {
      notify("Spatial data successfully copied!", "success");
    },
  });

  return {
    spacesData,
    onPatch: async (spaceIds: string[], spacePatch: PatchSpaceInputType) => {
      await patchSpaces({
        variables: {
          tenant: project,
          ids: spaceIds,
          update: spacePatch,
        },
      });
    },
    onDelete: async (spaceIds: string[]) => {
      await deleteSpaces({
        variables: {
          tenant: project,
          spaceIds,
        },
      });
    },
    onCreate: async (
      parentSpaceId: string,
      spaceName?: string,
    ): Promise<Pick<Space, "id" | "name">> => {
      const name =
        spaceName ??
        `${NEW_SPACE_NAME} ${Math.floor(Math.random() * Date.now())}`;
      const resp = await createSpace({
        variables: {
          tenant: project,
          spaces: [
            {
              parentSpaceId,
              name,
            },
          ],
        },
      });
      if (!resp.data?.createSpaces[0]) {
        throw new Error("Failed to create space");
      }
      return resp.data.createSpaces[0];
    },
    onCopySpatialData: async (
      id: string,
      newParentId: string,
      newSpaceName?: string,
      newFloorHeight?: number,
    ) => {
      await copySpatialData({
        variables: {
          tenant: project,
          spaceId: id,
          newParentSpaceId: newParentId,
          newSpaceName,
          newFloorHeight,
        },
      });
    },
  };
};
