import { useApolloClient } from "@apollo/client";
import { Spin } from "antd";
import _ from "lodash";
import React, { useMemo } from "react";
import {
  GetComponentProgressesDocument,
  GetComponentProgressesQuery,
  GetComponentProgressesQueryVariables,
  useGetComponentProgressesQuery,
} from "../../../generated/types";
import { Filter } from "../../../pages/EditorPage/types";
import { Project, Space } from "../../../types";
import { getProgressChange } from "../progresses";
import { matchesSearchTerm } from "../table-utils";
import { ComponentInfo } from "../types";
import { ProgressTable } from "./ProgressTable";

type ProgressTableContainerProps = {
  planId: string;
  components: ComponentInfo[];
  spaces: Space[];
  project: Project;
  batchId: string;
  previousBatchId?: string;
  filter: Filter;
};

export const ProgressTableContainer = ({
  planId,
  components,
  spaces,
  project,
  batchId,
  previousBatchId,
  filter,
}: ProgressTableContainerProps) => {
  const componentIds = useMemo(() => components.map((c) => c.id), [components]);
  const spaceIds = useMemo(() => spaces.map((s) => s.id), [spaces]);

  const batchIds = useMemo(
    () => (previousBatchId ? [batchId, previousBatchId] : [batchId]),
    [batchId, previousBatchId],
  );

  const getProgressesVariables = (ids: string[]) => ({
    customer: project.customer,
    project: project.project,
    scope: project.scope,
    planId: planId,
    componentIds: ids,
    spaceIds,
    batchIds,
  });

  const { data, loading, error } = useGetComponentProgressesQuery({
    variables: getProgressesVariables(componentIds),
    skip: components.length === 0,
  });

  const client = useApolloClient();

  const refetchComponentProgresses = async (ids: string[]) =>
    await client.query<
      GetComponentProgressesQuery,
      GetComponentProgressesQueryVariables
    >({
      query: GetComponentProgressesDocument,
      variables: getProgressesVariables(ids),
    });

  const aggregatedProgressesByComponentId = useMemo(() => {
    const progresses = data?.componentsByIds.flatMap((c) =>
      c.aggregatedProgresses.map((p) => ({ ...p, id: c.id })),
    );
    const progressesByBatch = _.groupBy(progresses, "batch.id");
    const previousProgressesByComponentAndSpace = previousBatchId
      ? _(progressesByBatch[previousBatchId])
          .groupBy("id")
          .mapValues((values) => _.keyBy(values, "space.id"))
          .value()
      : undefined;

    return _(progressesByBatch[batchId])
      .groupBy("id")
      .mapValues((values) =>
        values.map((p) => ({
          spaceId: p.space.id,
          progress: p.progress ?? undefined,
          status: p.status ?? undefined,
          batchId: p.batch.id,
          progressChange: getProgressChange(
            p,
            previousProgressesByComponentAndSpace?.[p.id]?.[p.space.id],
          ),
        })),
      )
      .value();
  }, [batchId, data?.componentsByIds, previousBatchId]);

  const filteredComponents = useMemo(
    () =>
      components.filter((c) =>
        filter.name ? matchesSearchTerm(c.name, filter.name) : c,
      ),
    [components, filter.name],
  );

  if (loading) {
    return <Spin />;
  }

  if (error) {
    return <div>Error getting progress data. {error ?? ""}</div>;
  }

  return (
    <ProgressTable
      project={project}
      spaces={spaces}
      components={filteredComponents.map((c) => ({
        ...c,
        aggregatedProgresses: aggregatedProgressesByComponentId[c.id],
      }))}
      onComponentUpdated={refetchComponentProgresses}
    />
  );
};
