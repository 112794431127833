import { CheckOutlined } from "@ant-design/icons";
import { Button, Col, Form, Row, Select, Tooltip } from "antd";
import _ from "lodash";
import React, { useEffect, useMemo, useState } from "react";
import { useProject } from "../../App/state";
import {
  Batch as GQLBatch,
  SpotlightType,
  useBatchesQuery,
  useGetMappingsForSpaceLazyQuery,
  useGetSceneQuery,
} from "../../generated/types";
import { useDualModeState } from "../../pages/DualModePage/state";

import styles from "./styles.module.css";
import {
  Mapping,
  SpotlightFormValues,
  SpotlightTypes,
  getGraphqlSpotlightType,
  getSpotlightType,
} from "./types";

type Batch = Pick<GQLBatch, "id" | "weekNumber" | "timestamp">;

type SpotlightDurationFormProps = {
  batchOptionsForCreate?: Batch[];
  batchOptionsForResolve?: Batch[];
  isCreationInterface?: boolean;
  onResolve?: () => void;
  onCancel?: () => void;
};

export const SpotlightDurationForm = (props: SpotlightDurationFormProps) => {
  const batchListForCreate = props.batchOptionsForCreate || [];
  const batchListForResolve = props.batchOptionsForResolve || [];
  return (
    <Row gutter={24}>
      <Col span={10}>
        <Form.Item
          label="created in"
          name="createdIn"
          rules={[
            {
              required: true,
              message: "Please select the first week the issue appeared",
            },
          ]}
        >
          <Select loading={_.isNil(batchListForCreate)}>
            {batchListForCreate.map((batch) => (
              <Select.Option key={batch.id} value={batch.id}>
                {"Week " + batch.weekNumber}
              </Select.Option>
            ))}
          </Select>
        </Form.Item>
      </Col>
      {props.isCreationInterface ? (
        <Col span={14}>
          <Form.Item className={styles["spotlight-button"]}>
            <Button type="primary" danger onClick={props.onCancel}>
              Cancel
            </Button>

            <Button type="primary" htmlType="submit">
              Confirm
            </Button>
          </Form.Item>
        </Col>
      ) : (
        <>
          <Col span={10}>
            <Form.Item label="resolved in" name="resolvedIn">
              <Select
                loading={_.isNil(props.batchOptionsForResolve)}
                allowClear
              >
                {batchListForResolve.map((batch) => (
                  <Select.Option key={batch.id} value={batch.id}>
                    {"Week " + batch.weekNumber}
                  </Select.Option>
                ))}
              </Select>
            </Form.Item>
          </Col>
          <Col span={4}>
            <Tooltip title="resolve Spotlight">
              {/* The label is a hack to get the spacing right */}
              <Form.Item
                label=" "
                colon={false}
                className={styles["spotlight-button"]}
              >
                <Button
                  icon={<CheckOutlined />}
                  onClick={props.onResolve}
                  disabled={props.onResolve === undefined}
                />
              </Form.Item>
            </Tooltip>
          </Col>
        </>
      )}
    </Row>
  );
};

const matchesInput = (option: string, input: string) =>
  option.toLowerCase().indexOf(input.toLowerCase()) >= 0;

type MappingFormItemProps = {
  mappingChoices?: Mapping[];
};

const SpotlightMappingFormItem = (props: MappingFormItemProps) => {
  const [searchInput, setSearchInput] = useState("");

  const mappingsBySpace = useMemo(() => {
    const choices = props.mappingChoices || [];
    const filteredMappings =
      searchInput !== ""
        ? choices.filter((mapping) =>
            matchesInput(mapping.componentName, searchInput),
          )
        : choices;
    return _.groupBy(filteredMappings, "spaceName");
  }, [props.mappingChoices, searchInput]);

  return (
    <Form.Item
      label="Mapping"
      name="mapping"
      rules={[{ required: true, message: "Please select a component!" }]}
    >
      <Select
        placeholder={"Select Component ..."}
        showSearch
        filterOption={false}
        onSearch={setSearchInput}
      >
        {Object.entries(mappingsBySpace).map(([space, mappings]) => (
          <Select.OptGroup key={space} label={space}>
            {mappings.map((mapping) => (
              <Select.Option key={mapping.id} value={mapping.id}>
                {mapping.componentName}
              </Select.Option>
            ))}
          </Select.OptGroup>
        ))}
      </Select>
    </Form.Item>
  );
};

const serialiseFormValues = (values): SpotlightFormValues => ({
  mappingId: values.mapping,
  type: getGraphqlSpotlightType(values.type),
  createdInBatchId: values.createdIn,
  resolvedInBatchId: values.resolvedIn,
});

type SpotlightFormProps = {
  isCreationInterface?: boolean;
  onCancel?: () => void;
  onSubmit?: (values: SpotlightFormValues) => void;
  onResolve?: () => void;
  onChange?: (values: SpotlightFormValues) => void;
  mappingValue?: Pick<Mapping, "id" | "subcontractor"> | null;
  typeValue?: SpotlightType;
  createdInBatchId?: string;
  resolvedInBatchId?: string;
};

export const SpotlightForm = (props: SpotlightFormProps) => {
  const { customer, project: proj, scope } = useProject();
  const dualModeState = useDualModeState();

  const { data: batchData } = useBatchesQuery({
    variables: {
      customer: customer,
      project: proj,
      scope: scope,
    },
  });
  const batches = useMemo(
    () => (batchData ? _.sortBy(batchData.batches, "timestamp").reverse() : []),
    [batchData],
  );

  const { data: sceneData } = useGetSceneQuery({
    variables: {
      customer: customer,
      project: proj,
      scope: scope,
      sceneId: dualModeState.currentSceneId?.value,
    },
  });

  const [getMappings, { data: mappingsData }] =
    useGetMappingsForSpaceLazyQuery();

  useEffect(() => {
    // wrap fetching mappings in an effect so it only triggers when mappingsData changes
    if (sceneData?.scene !== undefined && sceneData?.scene !== null) {
      getMappings({
        variables: {
          customer: customer,
          project: proj,
          scope: scope,
          spaceId: sceneData.scene.space.id,
        },
      });
    }
  }, [getMappings, customer, scope, sceneData, proj]);

  const mappingChoices = useMemo(() => {
    if (mappingsData != null) {
      const spaces = [
        mappingsData.space,
        ...mappingsData.space.ancestors,
        ...mappingsData.space.siblingSpaces,
        ...mappingsData.space.descendantSpaces,
      ];
      return _.flatMap(spaces, (space) =>
        space.mappings.map((rawMapping) => ({
          id: rawMapping.id,
          componentName: rawMapping.component.name,
          subcontractor: rawMapping.component.subcontractor?.name,
          spaceName: space.name,
        })),
      );
    }
  }, [mappingsData]);

  // I need to use a form here, to update the initial values
  // for example when you update a spotlight in one shot, you want to see
  // this in the other, and the best way in antd, is to use form.setFieldsValue
  const [form] = Form.useForm();
  useEffect(() => {
    props.mappingValue &&
      form.setFieldsValue({
        mapping: props.mappingValue.id,
        subcontractor: props.mappingValue.subcontractor || "N/A",
      });
  }, [form, props.mappingValue]);
  useEffect(() => {
    props.typeValue &&
      form.setFieldsValue({ type: getSpotlightType(props.typeValue) });
  }, [form, props.typeValue]);
  useEffect(() => {
    props.createdInBatchId &&
      form.setFieldsValue({ createdIn: props.createdInBatchId });
  }, [form, props.createdInBatchId]);
  useEffect(() => {
    form.setFieldsValue({ resolvedIn: props.resolvedInBatchId });
  }, [form, props.resolvedInBatchId]);

  const [batchesForCreate, batchesForResolve] = useMemo(() => {
    const currentCreatedBatch = batches.find(
      (b) => b.id === props.createdInBatchId,
    );
    const currentResolvedBatch = batches.find(
      (b) => b.id === props.resolvedInBatchId,
    );
    const resolvedInCandidates = currentCreatedBatch
      ? batches.filter((b) => b.timestamp >= currentCreatedBatch.timestamp)
      : batches;
    const createdInCandidates = currentResolvedBatch
      ? batches.filter((b) => b.timestamp <= currentResolvedBatch.timestamp)
      : batches;
    return [createdInCandidates, resolvedInCandidates];
  }, [batches, props.createdInBatchId, props.resolvedInBatchId]);

  return (
    <Form.Provider
      onFormFinish={(_name, info) => {
        if (props.onSubmit) {
          props.onSubmit(serialiseFormValues(info.values));
        }
      }}
    >
      <Form
        form={form}
        layout="vertical"
        className={styles["spotlight-form"]}
        onValuesChange={(_changedValues, allValues) => {
          if (props.onChange) {
            props.onChange(serialiseFormValues(allValues));
          }
          // TODO: if the mapping has changed then update the subcontractor name in the form
        }}
      >
        <SpotlightMappingFormItem mappingChoices={mappingChoices} />
        <Form.Item name="subcontractor" label="Subcontractor">
          <Select disabled></Select>
        </Form.Item>
        <Form.Item
          label="Type"
          name="type"
          rules={[
            { required: true, message: "Please select a spotlight type!" },
          ]}
        >
          <Select placeholder={"Select Type ..."}>
            {SpotlightTypes.map((type) => (
              <Select.Option key={type} value={type}>
                {type}
              </Select.Option>
            ))}
          </Select>
        </Form.Item>
        <SpotlightDurationForm
          isCreationInterface={props.isCreationInterface}
          batchOptionsForCreate={batchesForCreate}
          batchOptionsForResolve={batchesForResolve}
          onResolve={props.onResolve}
          onCancel={props.onCancel}
        />
      </Form>
    </Form.Provider>
  );
};
