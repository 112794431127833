import { Spin } from "antd";
import React from "react";
import { useGetAvailableProjectsQuery } from "../../generated/types";
import { Project, Scope } from "../../types";
import ProjectSelector from "./ProjectSelector";

type ProjectSelectorContainerProps = React.PropsWithChildren<{
  customer?: string;
  project?: string;
  scope?: Scope;
  hideCustomer?: boolean;
  hideProject?: boolean;
  hideScope?: boolean;
  onChange?: (project: Project | null) => void;
}>;

const ProjectSelectorContainer = (props: ProjectSelectorContainerProps) => {
  const {
    loading,
    error,
    data: availableProjects,
  } = useGetAvailableProjectsQuery();

  const groupedProjects = React.useMemo(() => {
    if (availableProjects != null) {
      return availableProjects.projects.reduce(
        (acc, val) => ({
          ...acc,
          [val.customer]: {
            ...acc[val.customer],
            [val.project]: val.scopes,
          },
        }),
        {},
      );
    }
    return {};
  }, [availableProjects]);

  if (loading) {
    return <Spin />;
  }

  if (error) {
    return <div>Something went wrong... {error.message}</div>;
  }

  return (
    <ProjectSelector
      {...props}
      projects={groupedProjects}
      onChange={props.onChange}
    />
  );
};

export default ProjectSelectorContainer;
