import _ from "lodash";
import { ShotFragmentFragment } from "../../generated/types";
import { Batch } from "../../types";
import {
  arrowFromGraphQLArrow,
  spotlightFromGraphQlSpotlight,
} from "./Annotations";
import { ImageConfig, Shot } from "./types";

export function convertShotFragmentToShot(
  shot: ShotFragmentFragment | undefined,
  batch: Batch,
  title: string,
): Shot | undefined {
  if (shot === undefined) {
    return undefined;
  }
  return {
    title,
    shotId: shot.id,
    scene: shot.scene,
    shell360SceneId: shot.scene?.shell360Id,
    imageConfig: convertShotFragmentToImageConfig(shot),
    batch: batch,
    spotlights: shot.spotlights.map((spotlight) =>
      spotlightFromGraphQlSpotlight(spotlight),
    ),
    arrows: shot.arrows.map((arrow) => arrowFromGraphQLArrow(arrow)),
  };
}

function convertShotFragmentToImageConfig(
  shot: ShotFragmentFragment,
): ImageConfig {
  return {
    ..._.pick(shot.imageConfig, [
      "imageId",
      "initialHfov",
      "basePath",
      "cubeResolution",
      "tileResolution",
      "maxLevel",
    ]),
    uniqueImageId: shot.imageConfig.uniqueImageId ?? undefined,
    yawOffset: shot.imageConfig.initialYaw,
    pitchOffset: shot.imageConfig.initialPitch,
    fileExtension: shot.imageConfig.extension,
    pathFormat: shot.imageConfig.pathTemplate,
    authQueryStrings: shot.imageConfig.authQueryStrings.reduce((acc, val) => {
      acc[val.tileSuffix] = val.queryString;
      return acc;
    }, {}),
  };
}

export const coordinatesToMeters = (x: number, y: number, z: number) => ({
  x: x / 1000,
  y: y / 1000,
  z: z / 1000,
});
