import { EditFilled } from "@ant-design/icons";
import { Alert, Button, DatePicker, Input, Modal, Tooltip } from "antd";
import moment from "moment";
import React, { useState } from "react";
import { useNotification } from "../../../contexts/Notifications";
import { useUpdatePlanStartDateMutation } from "../../../generated/types";
import { Plan, Project } from "../../../types";
import styles from "./styles.module.css";

export type PlanStartDateInputProps = {
  project: Project;
  plan: Plan | undefined;
};

const START_DATE_TITLE = "Start date for selected plan";
const UPDATE_START_DATE_TITLE = "Update Start Date";

export const PlanStartDateInput = (props: PlanStartDateInputProps) => {
  const { project, plan } = props;
  const notify = useNotification();

  const [updatePlan] = useUpdatePlanStartDateMutation({
    onError: (error) =>
      notify(`Failed to update plan start date: ${error.message}`, "error"),
    onCompleted: () =>
      notify("Plan start date successfully updated!", "success"),
  });

  const [showModal, setShowModal] = useState(false);
  const [newDate, setNewDate] = useState<moment.Moment>();
  const [confirmationMessage, setConfirmationMessage] = useState("");

  const currentStartDate = plan?.startDate
    ? moment(plan?.startDate)
    : undefined;

  return (
    <Tooltip title={START_DATE_TITLE}>
      <DatePicker
        className={styles["date-picker"]}
        suffixIcon={""}
        value={currentStartDate}
        placeholder={"No Start Date"}
        disabled
      />
      <Button
        icon={<EditFilled />}
        disabled={!plan}
        onClick={() => {
          // when the modal is being opened we know that the current start date is loaded,
          // setting the new date to it now means that by default the selection of the new start
          // date is the current one
          setNewDate(currentStartDate);
          setShowModal(true);
        }}
      />
      <Modal
        title={UPDATE_START_DATE_TITLE}
        visible={showModal}
        onCancel={() => setShowModal(false)}
        onOk={async () => {
          if (plan) {
            await updatePlan({
              variables: {
                customer: project.customer,
                project: project.project,
                scope: project.scope,
                planId: plan.id,
                name: plan.name,
                startDate: newDate,
              },
            });
          }
          setShowModal(false);
        }}
        okButtonProps={{
          disabled: confirmationMessage !== project.project,
        }}
      >
        <Alert
          showIcon
          type="info"
          message={
            "Setting a new start date will affect the week numbers in the cilent facing viewer! Please only set this if you know what you are doing."
          }
        />
        <DatePicker
          className={styles["date-picker"]}
          value={newDate}
          placeholder={"Set Start Date"}
          onChange={(date) => {
            const roundedDate = date && date.utc(true).startOf("date");
            setNewDate(roundedDate ?? undefined);
          }}
        />
        <p>
          Please type the project name to confirm setting the start date from
          <br />
          <b>{currentStartDate?.format("ddd YYYY-MM-DD")}</b>
          <br />
          to
          <br />
          <b>{newDate?.format("ddd YYYY-MM-DD")}</b>
        </p>
        <Input onChange={(e) => setConfirmationMessage(e.target.value)} />
      </Modal>
    </Tooltip>
  );
};
