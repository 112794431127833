import { Modal } from "antd";
import React from "react";
import { IssueTypeConfig } from "./types";

const MessageSection = ({ message }: { message: string }) => {
  const lines = message.split("\n");
  return (
    <div style={{ margin: "12px", color: "GrayText" }}>
      <p>
        <b>Message</b>
      </p>
      <p>
        {lines.map((line, idx) => (
          <span key={idx}>
            {line}
            <br />
          </span>
        ))}
      </p>
    </div>
  );
};

interface RevertModalProps {
  isOpen: boolean;
  onClickConfirm: () => void;
  onClickCancel: () => void;
  loading: boolean;
  issueConfig: IssueTypeConfig | undefined;
  message: string | undefined;
}

export const RevertModal: React.FC<RevertModalProps> = (
  props: RevertModalProps,
) => {
  const {
    isOpen,
    issueConfig,
    loading,
    message,
    onClickConfirm,
    onClickCancel,
  } = props;
  return (
    <Modal
      visible={isOpen}
      title={`Unreport ${issueConfig?.label} issue`}
      confirmLoading={loading}
      okText="Confirm"
      onOk={onClickConfirm}
      onCancel={onClickCancel}
      destroyOnClose={true}
    >
      Are you sure you want to delete this ticket?
      {message !== undefined && <MessageSection message={message} />}
    </Modal>
  );
};
