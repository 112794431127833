import { UploadOutlined } from "@ant-design/icons";
import { Button, Tooltip } from "antd";
import React from "react";

interface FloorplanButtonProps {
  size: "large";
  type: "text";
  onClick: () => void;
}

const TITLE = "Open Floorplan Editor";

export const FloorplanButton = (props: FloorplanButtonProps) => {
  const { size, type, onClick } = props;
  return (
    <Tooltip title={TITLE}>
      <Button
        size={size}
        icon={<UploadOutlined />}
        type={type}
        onClick={onClick}
      />
    </Tooltip>
  );
};
