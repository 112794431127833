import {
  CaretLeftFilled,
  CaretRightFilled,
  FastForwardFilled,
} from "@ant-design/icons";
import { Button, Select } from "antd";
import classNames from "classnames";
import _ from "lodash";
import React from "react";
import { Batch } from "../../../types";
import styles from "./BatchSelector.module.css";

type DropdownProps = React.PropsWithChildren<{
  batches: Batch[];
  onChange: (batch: Batch) => void;
  className: string;
  defaultBatch?: Batch;
}>;

export const Dropdown = (props: DropdownProps) => {
  const batchesById = React.useMemo(
    () =>
      props.batches.reduce((acc, batch) => ({ ...acc, [batch.id]: batch }), {}),
    [props.batches],
  );

  return (
    <div className={classNames(props.className, styles["batch-selector"])}>
      <Select
        data-testid="select-test"
        value={props.defaultBatch?.id}
        placeholder="Select..."
        onChange={(id: string) => props.onChange(batchesById[id])}
        dropdownAlign={{
          offset: [0, 0],
        }}
      >
        {props.batches.map((batch) => (
          <Select.Option key={batch.id} value={batch.id}>
            {"Week " + batch.weekNumber}
          </Select.Option>
        ))}
      </Select>
      {props.defaultBatch && props.batches.length > 0 && (
        <>
          <Button
            icon={<CaretLeftFilled />}
            onClick={() =>
              props.onChange(
                getPrevious(props.defaultBatch as Batch, props.batches),
              )
            }
            disabled={defaultBatchIsFirst(props.defaultBatch, props.batches)}
          />
          <Button
            icon={<CaretRightFilled />}
            onClick={() =>
              props.onChange(
                getNext(props.defaultBatch as Batch, props.batches),
              )
            }
            disabled={defaultBatchIsLatest(props.defaultBatch, props.batches)}
          />
          <Button
            icon={<FastForwardFilled />}
            onClick={() => props.onChange(props.batches[0])}
            disabled={defaultBatchIsLatest(props.defaultBatch, props.batches)}
          />
        </>
      )}
    </div>
  );
};

const defaultBatchIsLatest = (defaultBatch: Batch, batches: Batch[]) =>
  batches[0].id === defaultBatch.id;

const getNext = (defaultBatch: Batch, batches: Batch[]) => {
  const index = _.findIndex(batches, (batch) => batch.id === defaultBatch.id);
  const result = batches[index - 1];
  return result;
};

const getPrevious = (defaultBatch: Batch, batches: Batch[]) => {
  const index = _.findIndex(batches, (batch) => batch.id === defaultBatch.id);
  const result = batches[index + 1];
  return result;
};

const defaultBatchIsFirst = (defaultBatch: Batch, batches: Batch[]) =>
  batches[batches.length - 1].id === defaultBatch.id;
