// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/cssWithMappingToString.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".styles-module__modal-box--3qom5{margin:8px}.styles-module__modal-box-text--3DG8d{margin-top:12px}.styles-module__modal-box-selected-item--3fJi6{color:#1890ff}.styles-module__tag-button-container--2NOY8{margin-top:6px}.styles-module__modal-select-width--u79su{width:80%}", "",{"version":3,"sources":["webpack://./src/components/ConfigurationSpaceBottomRibbons/spaces/styles.module.css"],"names":[],"mappings":"AAAA,iCACE,UAAA,CAGF,sCACE,eAAA,CAGF,+CACE,aAAA,CAGF,4CACE,cAAA,CAGF,0CACE,SAAA","sourcesContent":[".modal-box {\n  margin: 8px;\n}\n\n.modal-box-text {\n  margin-top: 12px;\n}\n\n.modal-box-selected-item {\n  color: #1890ff;\n}\n\n.tag-button-container {\n  margin-top: 6px;\n}\n\n.modal-select-width {\n  width: 80%;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"modal-box": "styles-module__modal-box--3qom5",
	"modal-box-text": "styles-module__modal-box-text--3DG8d",
	"modal-box-selected-item": "styles-module__modal-box-selected-item--3fJi6",
	"tag-button-container": "styles-module__tag-button-container--2NOY8",
	"modal-select-width": "styles-module__modal-select-width--u79su"
};
export default ___CSS_LOADER_EXPORT___;
