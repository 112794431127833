import { PartitionOutlined } from "@ant-design/icons";
import { Button, Tooltip } from "antd";
import React from "react";

interface CopySpaceButtonProps {
  isNodeSpace: boolean;
  size: "large";
  type: "text";
  onClick: () => void;
}

const SHOW_CREATE_TITLE = "Copy space";
const SHOW_HELP_TITLE = "You need to select a single space to copy";

export const CopySpaceButton = (props: CopySpaceButtonProps) => {
  const { isNodeSpace, size, type, onClick } = props;

  return (
    <Tooltip title={isNodeSpace ? SHOW_CREATE_TITLE : SHOW_HELP_TITLE}>
      <Button
        size={size}
        icon={<PartitionOutlined />}
        type={type}
        disabled={!isNodeSpace}
        onClick={onClick}
      />
    </Tooltip>
  );
};
