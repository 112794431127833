import React from "react";
import { CopySpaceModal, SpaceWithName } from "./CopySpaceModal";

interface CopySpaceEditorProps {
  space: SpaceWithName;
  onCopySpatialData: (
    id: string,
    newParentId: string,
    newSpaceName?: string,
    newFloorHeight?: number,
  ) => Promise<void>;
  onClose: () => void;
}

export const CopySpaceEditor = (props: CopySpaceEditorProps) => {
  const { space, onCopySpatialData, onClose } = props;

  const onCreateCallback = async (
    spaceId: string,
    parentSpaceId: string,
    newSpaceName?: string,
    newFloorHeight?: number,
  ) => {
    await onCopySpatialData(
      spaceId,
      parentSpaceId,
      newSpaceName,
      newFloorHeight,
    );
    onClose();
  };

  return (
    <CopySpaceModal
      space={space}
      onCreate={onCreateCallback}
      onClose={onClose}
    />
  );
};
