import React from "react";
import { Project, Scope } from "../../types";

type State = Partial<Project>;

interface SetCustomerAction {
  type: "SET_CUSTOMER";
  payload: string | undefined;
}

export const setCustomer = (
  customer: string | undefined,
): SetCustomerAction => ({
  type: "SET_CUSTOMER",
  payload: customer,
});

interface SetProjectAction {
  type: "SET_PROJECT";
  payload: string | undefined;
}

export const setProject = (project: string | undefined): SetProjectAction => ({
  type: "SET_PROJECT",
  payload: project,
});

interface SetScopeAction {
  type: "SET_SCOPE";
  payload: Scope;
}

export const setScope = (scope: Scope = Scope.Internal): SetScopeAction => ({
  type: "SET_SCOPE",
  payload: scope,
});

type Action = SetCustomerAction | SetProjectAction | SetScopeAction;

const reducer: React.Reducer<State, Action> = (
  state: State,
  action: Action,
) => {
  switch (action.type) {
    case "SET_CUSTOMER":
      return {
        ...state,
        customer: action.payload,
        project: undefined,
      };
    case "SET_PROJECT":
      return { ...state, project: action.payload };
    case "SET_SCOPE":
      return { ...state, scope: action.payload };
  }
};

export const useProjectSelectorState = (initialState: State) => {
  return React.useReducer(reducer, initialState);
};
