import { Input } from "antd";
import classNames from "classnames";
import React, { useState } from "react";
import styles from "./DeleteConfirmation.module.css";

type DeleteConfirmationTextBoxProps = {
  deletionText: string;
  onAllowDeletion: (allowDeletion: boolean) => void;
};

export const DeleteConfirmationTextBox = (
  props: DeleteConfirmationTextBoxProps,
) => {
  const { onAllowDeletion, deletionText } = props;
  const [inputValue, setInputValue] = useState<string>();

  const handleOnChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setInputValue(e.target.value);
  };

  onAllowDeletion(inputValue === deletionText);

  return (
    <>
      <span>
        Type <i>{deletionText}</i> in the field below
      </span>
      <Input
        data-testid={"deletion-input"}
        onChange={handleOnChange}
        className={classNames(styles["input-delete-field"])}
        value={inputValue}
      />
    </>
  );
};
