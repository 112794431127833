import React from "react";
import { DualColorCircleNode } from "../types";

type SimpleCircleProps = {
  node: DualColorCircleNode;
};

const circleCircumference = (radius: number) => 2 * Math.PI * radius;

export const DualColorCircle = ({ node }: SimpleCircleProps) => {
  return (
    <g id={node.id}>
      <circle
        cx={node.x}
        cy={node.y}
        r={node.size}
        fill={node.colors[0]}
        stroke={node.strokeColor}
        strokeWidth={node.strokeWidth}
        paintOrder={"stroke"}
        opacity={node.opacity}
      >
        {node.title && <title>{node.title}</title>}
      </circle>
      {node.colors[1] && (
        <circle
          r={node.size / 2}
          cx={node.x}
          cy={node.y}
          fill="transparent"
          stroke={node.colors[1]}
          strokeWidth={node.size}
          strokeDasharray={`${
            circleCircumference(node.size / 2) * 0.5
          } ${circleCircumference(node.size / 2)}`}
        />
      )}
    </g>
  );
};
