import { Switch } from "antd";
import React from "react";
import { ColorMode } from "./SequenceMode";
import styles from "./SequenceMode.module.css";

type ColorModeSwitchProps = {
  checkedValue: boolean;
  onChange: (mode: boolean) => void;
  disabled: boolean;
};

export const ColorModeSwitch = (props: ColorModeSwitchProps) => (
  <div className={styles["color-mode-switch-box"]}>
    <div className={styles["color-mode-switch-label"]}>
      {ColorMode.SCENE_TYPES}
    </div>
    <Switch
      onChange={props.onChange}
      disabled={props.disabled}
      checked={props.checkedValue}
    />
    <div className={styles["color-mode-switch-label"]}>
      {ColorMode.SCANNING_STAGE}
    </div>
  </div>
);
