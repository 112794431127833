import { Alert, Button, Form, Input, Modal, Select } from "antd";
import _ from "lodash";
import React, { useState } from "react";
import { useNotification } from "../../contexts/Notifications";
import { useCreateNewProjectMutation } from "../../generated/types";
import { Project } from "./ProjectsTableContainer";

const ADD_NEW_PROJECT_TXT = "Add New Project";
const PROJECT_NAME_FORM_FIELD = "project";
const CUSTOMER_NAME_FORM_FIELD = "customer";
const DISPLAY_NAME_FORM_FIELD = "displayName";
const DEFAULT_LANG_FORM_FIELD = "defaultLanguage";

const defaultLanguageOpts = ["en-gb", "en-us"];

const ALERT_MSG = (
  <ul>
    <li>
      You require the <b>Delivery Core Project Creation</b> permission in Janus
      to perform this action
    </li>
    <li>
      This will create the project in delivery-core and create the relevant
      janus permissions.
    </li>
    <li>
      If you need to create a new customer, put a request in{" "}
      <b>#sync-new-projects-request</b>
    </li>
    <li>
      IMPORTANT: You will need to add the following newly created janus policies
      to the correct groups:
      <ul>
        <li>
          <b>Internal [customer] [project] (Delivery Core)</b> to <b>DAPS</b>{" "}
          and <b>Architects (Delivery Core)</b>
        </li>
        <li>
          <b>View Internal Impulse Progress Reports [customer] [project]</b> to{" "}
          <b>DAPS</b> and <b>Architects (Delivery Core)</b>
        </li>
      </ul>
    </li>
  </ul>
);

type Props = {
  projects: Project[];
};

export const NewProjectButton = (props: Props) => {
  const { projects } = props;
  const notify = useNotification();
  const [modalVisible, setModalVisible] = useState(false);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [form] = Form.useForm();

  const [createNewProject] = useCreateNewProjectMutation({
    onCompleted: () => notify("Successfully created new project", "success"),
    onError: (err) =>
      notify(`Error creating new project: ${err.message}`, "error"),
    update: (cache) => {
      cache.evict({ fieldName: "projects" }); // Triggers refetch of projects.
    },
  });

  const handleOk = async () => {
    form.validateFields();
    setIsSubmitting(true);
    await createNewProject({
      variables: {
        customer: form.getFieldValue(CUSTOMER_NAME_FORM_FIELD),
        project: form.getFieldValue(PROJECT_NAME_FORM_FIELD),
        defaultLanguage: form.getFieldValue(DEFAULT_LANG_FORM_FIELD),
        displayName: form.getFieldValue(DISPLAY_NAME_FORM_FIELD),
      },
    });
    setIsSubmitting(false);
    setModalVisible(false);
    form.resetFields();
  };

  const handleCancel = () => {
    form.resetFields();
    setModalVisible(false);
  };

  const validateProjectName = (_rule, value: string) => {
    const existingProjectNames = projects.map((p) => p.project);
    if (!value) {
      return Promise.resolve();
    }
    if (existingProjectNames.includes(value)) {
      return Promise.reject(
        "Project name already exists. Please choose a different name.",
      );
    }
    const regex = /^[a-z0-9]+$/;
    if (regex.test(value)) {
      return Promise.resolve();
    }
    return Promise.reject(
      "Project name should include only lowercase letters and numbers.",
    );
  };

  const customers = _(projects)
    .map((p) => p.customer)
    .uniq()
    .value();

  return (
    <>
      <Button type="primary" onClick={() => setModalVisible(true)}>
        {ADD_NEW_PROJECT_TXT}
      </Button>
      <Modal
        visible={modalVisible}
        title={ADD_NEW_PROJECT_TXT}
        onCancel={handleCancel}
        onOk={handleOk}
        confirmLoading={isSubmitting}
        okText={"Create"}
      >
        <>
          <Alert
            style={{ marginBottom: "15px" }}
            type="info"
            showIcon
            message={ALERT_MSG}
          />
          <Form form={form} layout="vertical">
            <Form.Item
              name={CUSTOMER_NAME_FORM_FIELD}
              label="Customer"
              rules={[
                {
                  required: true,
                  message: "Please select a customer",
                },
              ]}
            >
              <Select
                placeholder="Select a customer"
                options={mapOptions(customers)}
              />
            </Form.Item>
            <Form.Item
              name={PROJECT_NAME_FORM_FIELD}
              label="Project URL Name"
              rules={[
                {
                  required: true,
                  message: "Please enter the project name",
                },
                {
                  validator: validateProjectName,
                },
              ]}
            >
              <Input placeholder="Enter project name" />
            </Form.Item>
            <Form.Item
              name={DISPLAY_NAME_FORM_FIELD}
              label="Project Display Name"
              rules={[
                {
                  required: true,
                  message: "Please enter the display name",
                },
              ]}
            >
              <Input placeholder="Enter display name" />
            </Form.Item>
            <Form.Item
              name={DEFAULT_LANG_FORM_FIELD}
              label="Default Language"
              rules={[
                {
                  required: true,
                  message: "Please select the default language",
                },
              ]}
            >
              <Select
                placeholder="Select default language"
                options={mapOptions(defaultLanguageOpts)}
              />
            </Form.Item>
          </Form>
        </>
      </Modal>
    </>
  );
};

const mapOptions = <T,>(arr: T[]): { label: T; value: T }[] =>
  arr.map((el) => ({ label: el, value: el }));
