import { Divider, Select } from "antd";
import _ from "lodash";
import React, { useEffect, useMemo, useState } from "react";
import { useProject } from "../../../App/state";
import { useNotification } from "../../../contexts/Notifications";
import { PatchSpaceInputType } from "../../../generated/types";
import { DisplayTreeNodeType } from "../../DisplayTree/types";
import { SpaceCategorySelector } from "../../Selectors/SpaceCategorySelector/SpaceCategorySelector";
import { SpaceTypeSelector } from "../../Selectors/SpaceTypeSelector/SpaceTypeSelector";
import { useSpaces } from "../hooks/useSpaces";
import styles from "./OptionsPanel.module.css";
import { OptionsPanelBaseRows } from "./OptionsPanelBaseRows";
import { OptionsPanelDeleteForm } from "./OptionsPanelDeleteForm";
import { OptionsPanelSaveForm } from "./OptionsPanelSaveForm";

const CATEGORY_SELECTOR = "Category";
const TYPE_SELECTOR = "Space type";
const TAG_SELECTOR = "Tags";

interface SpaceOptionsPanelProps {
  spaceId: string;
  numScenes: number;
  disableCreateSceneMode?: () => boolean;
}

export const SpaceOptionsPanel = (props: SpaceOptionsPanelProps) => {
  const { spaceId, numScenes, disableCreateSceneMode } = props;
  const notify = useNotification();
  const project = useProject();
  const { spacesData, onPatch: patchSpaces, onDelete } = useSpaces(spaceId);

  const [deleteModalVisible, setDeleteModalVisible] = useState(false);
  const [editMode, setEditMode] = useState(false);

  const [name, setName] = useState<string>();
  const [category, setCategory] = useState<string>();
  const [type, setType] = useState<string>();
  const [tags, setTags] = useState<string[]>([]);

  const space = useMemo(() => spacesData?.spacesByFilter[0], [spacesData]);

  useEffect(() => {
    setName(space?.name);
    setCategory(space?.category ?? undefined);
    setType(space?.type ?? undefined);
    setTags(space?.tags ?? []);
  }, [space]);

  const handleSave = async () => {
    const removeTags =
      space && space.tags.filter((existingTag) => !tags.includes(existingTag));
    const addTags = space
      ? tags.filter((newTag) => !space.tags.includes(newTag))
      : tags;

    const patch: PatchSpaceInputType = {
      ...(space?.name !== name && !!name
        ? { setName: { newValue: name } }
        : {}),
      ...(space?.category !== category && !!category
        ? { setCategory: { newValue: category } }
        : {}),
      ...(space?.type !== type && !!type
        ? { setType: { newValue: type } }
        : {}),
      ...(removeTags?.length ? { removeTags } : {}),
      ...(addTags?.length ? { addTags } : {}),
    };

    if (_.isEmpty(patch)) {
      notify("Space already saved");
    } else {
      await patchSpaces([spaceId], patch);
    }
    updateEditMode(false);
  };

  const onDeleteButton = () => {
    if (!numScenes) {
      updateDeleteModalVisible(true);
    } else {
      notify(
        `Selected space has ${numScenes} scenes. Delete scenes first to delete the space.`,
        "error",
      );
    }
  };

  const updateEditMode = (value: boolean) => {
    setEditMode(() => {
      disableCreateSceneMode?.();
      return value;
    });
  };

  const updateDeleteModalVisible = (value: boolean) => {
    setDeleteModalVisible(() => {
      disableCreateSceneMode?.();
      return value;
    });
  };

  return (
    <div className={styles["options-panel"]}>
      {space && (
        <>
          <div className={styles["editor-box"]}>
            <OptionsPanelBaseRows
              initialName={space.name}
              name={name}
              setName={setName}
              editMode={editMode}
            />

            <div className={styles["row-box"]}>
              <div className={styles["row-box-name"]}>{CATEGORY_SELECTOR}</div>
              <div className={styles["row-value"]}>
                <SpaceCategorySelector
                  selectedCategories={category != null ? [category] : []}
                  project={project}
                  disabled={!editMode}
                  placeholder={category}
                  onChange={(values) => {
                    setCategory(values[0]);
                  }}
                  allowCreatingNewValue
                />
              </div>
            </div>

            <div className={styles["row-box"]}>
              <div className={styles["row-box-name"]}>{TYPE_SELECTOR}</div>
              <div className={styles["row-value"]}>
                <SpaceTypeSelector
                  selectedTypes={type != null ? [type] : []}
                  project={project}
                  disabled={!editMode}
                  placeholder={type}
                  onChange={(values) => {
                    setType(values[0]);
                  }}
                  allowCreatingNewValue
                />
              </div>
            </div>
            <div className={styles["row-box"]}>
              <div className={styles["row-box-name"]}>{TAG_SELECTOR}</div>
              <Select
                className={styles["row-value"]}
                mode={"tags"}
                onChange={setTags}
                value={tags}
                disabled={!editMode}
              >
                {space?.tags.map((tag) => (
                  <Select.Option key={tag} value={tag}>
                    {tag}
                  </Select.Option>
                ))}
              </Select>
            </div>
            <OptionsPanelSaveForm
              editMode={editMode}
              onEditButtonClick={() => updateEditMode(true)}
              onSaveButtonClick={handleSave}
              saveButtonDisabled={!name}
            />
          </div>
          <Divider />
          <OptionsPanelDeleteForm
            ids={[spaceId]}
            modalVisible={deleteModalVisible}
            nodeType={DisplayTreeNodeType.Space}
            onDeleteButtonClick={onDeleteButton}
            onClose={() => updateDeleteModalVisible(false)}
            onDelete={onDelete}
          />
        </>
      )}
    </div>
  );
};
