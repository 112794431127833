export const reorderNodes = <T>(
  draggedNodeId: string,
  nodesInInitialOrder: (T & { key: string })[],
  dropPosition: number,
) => {
  const draggedNode = nodesInInitialOrder.filter(
    (n) => n.key === draggedNodeId,
  )[0];

  return [
    ...nodesInInitialOrder
      .slice(0, dropPosition)
      .filter((node) => node.key !== draggedNode.key),
    draggedNode,
    ...nodesInInitialOrder
      .slice(dropPosition)
      .filter((node) => node.key !== draggedNode.key),
  ];
};
