import { Spin } from "antd";
import React, { useCallback, useEffect, useMemo, useState } from "react";
import { useNotification } from "../../contexts/Notifications";
import {
  useCreateTaskMutation,
  useGetTasksByParentPlanQuery,
  useUpdateTasksMutation,
} from "../../generated/types";
import { Plan, Project } from "../../types";
import { DisplayTree } from "../DisplayTree/DisplayTree";
import { TreeWithTagsSwitch } from "../DisplayTree/TreeWithTagsSwitch";
import { ROOT_PARENT_KEY, findSearchHits } from "../DisplayTree/search-utils";
import { DisplayTreeNode } from "../DisplayTree/types";
import { SearchBar, SearchInput, matches, searchIsEmpty } from "./SearchBar";
import {
  addNodeToTree,
  buildTreeDataNodes as buildPlansTreeDataNodes,
  createTaskNode,
  findSiblingIndex,
  findSiblings,
  getTasksAndComponentsByIds,
  isRealNode,
  nodeHasChildComponents,
  removeNodeFromTree,
  taskToDisplayTreeNode,
} from "./task-tree-utils";

interface PlansTreeProps {
  project: Project;
  planId: Plan["id"];
  onSelectTask?: (selectedTaskNode: DisplayTreeNode) => any;
  checkable?: boolean;
  editable?: boolean;
  searchable?: boolean;
  includeComponents?: boolean;
  onCheckNodes?: (nodes: string[]) => void;
  showTagsSwitch?: boolean;
}

const ERROR_FETCHING_PLAN_MESSAGE = "Error fetching tasks";

export const PlansTree = (props: PlansTreeProps) => {
  const {
    project,
    planId,
    onSelectTask,
    includeComponents,
    checkable,
    editable,
    searchable,
    onCheckNodes,
    showTagsSwitch = false,
  } = props;

  const notify = useNotification();
  const [treeDataMap, setTreeDataMap] = useState<
    Record<string, DisplayTreeNode[]>
  >({});

  const [searchInput, setSearchInput] = useState<SearchInput>();

  const [createTask] = useCreateTaskMutation({
    onCompleted: (creationResult) =>
      notify(`Task ${creationResult.createTask.name} created!`, "success"),
    onError: () => notify(`Failed to create task!`, "error"),
    update: (cache) => {
      cache.evict({ fieldName: "plan" });
      cache.evict({ fieldName: "tasksByParentPlan" });
    },
  });

  const [updateTasks] = useUpdateTasksMutation({
    onCompleted: (updateResult) =>
      notify(
        `${updateResult.updateTasks.length} tasks updated successfully!`,
        "success",
      ),
    onError: (err) => notify(`Failed to update task!. ${err.message}`, "error"),
  });

  const onNodesUpdated = useCallback(
    async (nodes: DisplayTreeNode[]) => {
      const tasksToUpdate = nodes.map((n, index) => ({
        id: n.key,
        name: n.name as string,
        planId: planId,
        guiIndex: index,
        /* The DisplayTreeNode has a parentKey of "root" for nodes without a parent 
        (i.e. nodes at the top of the tree) but dc requires parent to be undefined if
        at the top of the tree */
        parentTaskId: n.parentKey === ROOT_PARENT_KEY ? undefined : n.parentKey,
      }));

      const { errors } = await updateTasks({
        variables: {
          ...project,
          tasks: tasksToUpdate,
        },
      });
      return errors;
    },
    [planId, project, updateTasks],
  );

  const onDropNode = useCallback(
    async ({
      dragNode: draggedNode,
      dropToGap: targetIsSibling,
      node: nodeTarget,
    }: {
      dragNode: DisplayTreeNode;
      dropToGap: boolean;
      node: DisplayTreeNode;
    }): Promise<void> => {
      const oldTree = treeDataMap[planId];
      const treeWithoutMovedNode = removeNodeFromTree(oldTree, draggedNode.key);
      const parentId = targetIsSibling
        ? nodeTarget.parentKey?.toString()
        : nodeTarget.key.toString();
      const insertionIndex = targetIsSibling
        ? findSiblingIndex(treeWithoutMovedNode, nodeTarget.key) + 1
        : 0;

      const newTree = addNodeToTree(
        treeWithoutMovedNode,
        { ...draggedNode, parentKey: parentId },
        insertionIndex,
        parentId,
      );
      const alteredNodes = findSiblings(newTree, draggedNode.key) ?? [];
      const errors = await onNodesUpdated(alteredNodes.filter(isRealNode));
      if (!errors) {
        setTreeDataMap((map) => ({
          ...map,
          [planId]: newTree,
        }));
      }
    },
    [onNodesUpdated, planId, treeDataMap],
  );

  const { data, loading, error } = useGetTasksByParentPlanQuery({
    variables: {
      ...project,
      planId: planId,
    },
  });

  const [showTags, setShowTags] = useState(showTagsSwitch);

  const tasksAndComponentsByIds = useMemo(() => {
    return data ? getTasksAndComponentsByIds(data?.tasksByParentPlan) : {};
  }, [data]);

  const taskTreeData = useMemo(() => {
    if (!data) {
      return [];
    }
    const treeDataRoots = Object.values(tasksAndComponentsByIds).filter(
      (task) => task.parentId === ROOT_PARENT_KEY,
    );
    return buildPlansTreeDataNodes(
      treeDataRoots,
      tasksAndComponentsByIds,
      includeComponents,
      undefined,
      checkable ?? false,
      showTagsSwitch && showTags,
    );
  }, [
    data,
    includeComponents,
    showTagsSwitch,
    checkable,
    showTags,
    tasksAndComponentsByIds,
  ]);

  const createNewNode = useCallback(
    async (name: string, parentTaskId: string) => {
      const newTask = await createTask({
        variables: {
          ...project,
          name: name,
          planId: planId,
          parentTaskId: parentTaskId === ROOT_PARENT_KEY ? null : parentTaskId,
        },
      });
      if (newTask.data) {
        return taskToDisplayTreeNode(newTask.data?.createTask, parentTaskId);
      }
    },
    [createTask, planId, project],
  );

  const onSaveTask = useCallback(
    async (name: string, parentKey: string) => {
      const newNode = await createNewNode(name, parentKey);
      if (newNode) {
        setTreeDataMap((state) => {
          const newTree = addNodeToTree(
            state[planId],
            newNode,
            -1, //insert as last but one element
            parentKey,
          );
          return {
            ...state,
            // guiIndex by default increases automatically
            // So we know this will place it at the end of the
            // list of siblings
            [planId]: newTree,
          };
        });
      }
    },
    [createNewNode, planId],
  );

  const addCreateNewTaskNodesToTree = useCallback(
    (treeNodes: DisplayTreeNode[], parentKey?: string): DisplayTreeNode[] => {
      return [
        ...treeNodes.map((node) => ({
          ...node,
          children: !nodeHasChildComponents(node.key, tasksAndComponentsByIds)
            ? addCreateNewTaskNodesToTree(node.children ?? [], node.key)
            : undefined,
        })),
        createTaskNode(onSaveTask, parentKey),
      ];
    },
    [onSaveTask, tasksAndComponentsByIds],
  );

  // need to update this when we create a task
  useEffect(() => {
    setTreeDataMap((state) => {
      const newTreeData = editable
        ? addCreateNewTaskNodesToTree(taskTreeData)
        : taskTreeData;
      return { ...state, [planId]: newTreeData };
    });
  }, [taskTreeData, planId, editable, addCreateNewTaskNodesToTree]);

  const searchHits = useMemo(() => {
    if (searchInput && !searchIsEmpty(searchInput)) {
      return findSearchHits(
        treeDataMap[planId],
        searchInput,
        tasksAndComponentsByIds,
        matches,
      );
    }
  }, [planId, searchInput, tasksAndComponentsByIds, treeDataMap]);

  if (loading) {
    return <Spin />;
  }

  if (error) {
    return <span>{ERROR_FETCHING_PLAN_MESSAGE}</span>;
  }

  return (
    <>
      {searchable && (
        <SearchBar
          allowSearchByTags={showTagsSwitch ?? false}
          project={project}
          planId={planId}
          onChange={setSearchInput}
        />
      )}
      {showTagsSwitch ? (
        <TreeWithTagsSwitch
          treeData={treeDataMap[planId]}
          showTags={showTags}
          setShowTags={setShowTags}
          onSelectNode={onSelectTask}
          onCheckNodes={onCheckNodes}
          onDropNode={onDropNode}
          editable={editable}
          checkable={checkable}
          searchHits={searchHits}
        />
      ) : (
        <DisplayTree
          treeData={treeDataMap[planId]}
          onSelectNode={onSelectTask}
          onCheckNodes={onCheckNodes}
          onDropNode={onDropNode}
          editable={editable}
          checkable={checkable}
          searchHits={searchHits}
        />
      )}
    </>
  );
};
