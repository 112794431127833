import { LeftOutlined, RightOutlined } from "@ant-design/icons";
import { Button, Divider, Empty, InputNumber, Space } from "antd";
import React, { useCallback, useEffect, useMemo, useState } from "react";
import { SpotlightInfo } from "../SpotlightInfo/SpotlightInfo";
import { SpotlightFilters } from "./SpotlightFilters";
import { Spotlight } from "./types";

type SpotlightQAProps = {
  spotlights: Spotlight[];
  batchId: string;
  onSelect?: (spotlight: Spotlight) => void;
};

export const SpotlightQA = (props: SpotlightQAProps) => {
  const { spotlights, batchId, onSelect } = props;
  const [selectedSpotlightIds, setSelectedSpotlightIds] = useState(
    new Set(spotlights.map((sp) => sp.id)),
  );
  const [spotlightIndex, setSpotlightIndex] = useState(0);

  const selectedSpotlights = useMemo(
    () => spotlights.filter((spot) => selectedSpotlightIds.has(spot.id)),
    // do not include spotlights in here
    // because they will change when a spotlight changes position (for example by drag'n'drop)
    // and in that case we don't want to re-trigger a new spotlight selection
    [spotlights, selectedSpotlightIds],
  );

  const currentSpotlight = useMemo(
    () => selectedSpotlights[spotlightIndex],
    [selectedSpotlights, spotlightIndex],
  );

  useEffect(() => {
    if (onSelect) {
      onSelect(currentSpotlight);
    }
  }, [onSelect, currentSpotlight]);

  const onFilterChange = useCallback(
    (filteredSpotlightIds) => {
      setSelectedSpotlightIds(new Set(filteredSpotlightIds));
      // prevent out of bounds errors on a new filter selection
      if (filteredSpotlightIds.length <= spotlightIndex) {
        setSpotlightIndex(0);
      }
    },
    [spotlightIndex, setSelectedSpotlightIds, setSpotlightIndex],
  );

  if (spotlights.length === 0) {
    return <Empty description="Could not find any spotlights!" />;
  }

  return (
    <>
      <Divider orientation="center">Navigation</Divider>
      <Space direction="horizontal" align="center">
        <Button
          type="text"
          disabled={spotlightIndex <= 0}
          icon={<LeftOutlined />}
          onClick={() => {
            setSpotlightIndex((prev) => Math.max(prev - 1, 0));
          }}
        ></Button>
        <InputNumber
          min={1}
          max={selectedSpotlights.length}
          value={spotlightIndex + 1}
          onChange={(index) => {
            if (index > 0 && index <= selectedSpotlights.length) {
              setSpotlightIndex(index - 1);
            }
          }}
        />
        {` /${selectedSpotlights.length}`}
        <Button
          type="text"
          disabled={spotlightIndex + 1 >= selectedSpotlights.length}
          icon={<RightOutlined />}
          onClick={() => {
            setSpotlightIndex((prev) =>
              Math.min(prev + 1, selectedSpotlights.length - 1),
            );
          }}
        ></Button>
      </Space>
      <Divider orientation="center">Filters</Divider>
      <SpotlightFilters
        spotlights={spotlights}
        batchId={batchId}
        onChange={onFilterChange}
      ></SpotlightFilters>
      <Divider orientation="center">Spotlight</Divider>
      <SpotlightInfo spotlight={currentSpotlight} currentBatchId={batchId} />
    </>
  );
};
