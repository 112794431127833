import { notification } from "antd";
import { IconType, NotificationPlacement } from "antd/lib/notification";
import React, { PropsWithChildren } from "react";

export type Notify = (message: string, type?: IconType) => void;

export const Context = React.createContext<Notify | null>(null);

type Props = PropsWithChildren<{
  placement: NotificationPlacement;
}>;

export const NotificationProvider = (props: Props) => {
  const { placement, children } = props;
  const [notifier, contextHolder] = notification.useNotification();
  const notify = React.useCallback(
    (message: string, type: IconType = "info") => {
      notifier[type]({
        message,
        placement: placement,
      });
    },
    [notifier, placement],
  );

  return (
    <>
      {contextHolder}
      <Context.Provider value={notify}>{children}</Context.Provider>
    </>
  );
};

export const useNotification = () => {
  const notify = React.useContext(Context);
  if (notify == null) {
    throw new Error(
      "Cannot use notifications outside of a NotificationProvider.",
    );
  }
  return notify;
};
