import { Modal, Select } from "antd";
import React from "react";
import { Space } from "../../SpacesTree/tree-nodes";
import { SpacesWithAncestors } from "../../SpacesWithAncestors";
import styles from "./styles.module.css";

interface EditSpaceTagsModalProps {
  title: string;
  actionText: string;
  visible: boolean;
  onCancel: () => void;
  onSubmit: (tags: string[]) => void;
  spaces: Space[];
  allSpaceTags: string[];
  allowNewTagCreation: boolean;
}

export const EditSpaceTagsModal = (props: EditSpaceTagsModalProps) => {
  const {
    title,
    actionText,
    visible,
    onCancel,
    onSubmit,
    spaces,
    allSpaceTags,
    allowNewTagCreation,
  } = props;
  const [tags, setTags] = React.useState<string[]>([]);

  const handleChange = (value: string[]) => {
    setTags(value);
  };

  return (
    <Modal
      title={title}
      visible={visible}
      onOk={() => onSubmit(tags)}
      width="70%"
      destroyOnClose
      onCancel={onCancel}
    >
      <Select
        mode={allowNewTagCreation ? "tags" : "multiple"}
        className={styles["modal-select-width"]}
        onChange={handleChange}
      >
        {allSpaceTags.map((tag) => (
          <Select.Option key={tag} value={tag}>
            {tag}
          </Select.Option>
        ))}
      </Select>
      <div className={styles["modal-box"]}>
        <div className={styles["modal-box-text"]}>
          You are <b>{actionText}</b> the selected tags for the following
          spaces:
        </div>
        <div className={styles["modal-box-selected-item"]}>
          <SpacesWithAncestors spaces={spaces} />
        </div>
      </div>
    </Modal>
  );
};
