import { ArrowUpOutlined } from "@ant-design/icons";
import { Result } from "antd";
import React from "react";
import { Route } from "react-router";
import ProjectSelector from "../components/ProjectSelector";
import { useAuth } from "../contexts/Auth";
import { UserActionProvider } from "../contexts/UserActions";
import { Scope } from "../generated/types";
import ErrorPage from "../pages/ErrorPage";
import HomePage from "../pages/HomePage";
import LoadingPage from "../pages/LoadingPage";
import SignInPage from "../pages/SignInPage";
import apps from "./apps";
import { useAppState } from "./state";
import styles from "./styles.module.css";

const SelectProjectPrompt = () => (
  <div className={styles["select-project-prompt"]}>
    <Result
      icon={<ArrowUpOutlined />}
      title="Please select a project to continue..."
    />
  </div>
);

const RequireProject = (props: React.PropsWithChildren<any>) => {
  const appState = useAppState();

  if (appState.project == null) {
    return <SelectProjectPrompt />;
  }

  return props.children;
};

const AppContent = () => {
  const auth = useAuth();
  const appState = useAppState();

  if (auth.status === "loading") {
    return <LoadingPage />;
  }

  if (auth.status === "error") {
    return <ErrorPage error={auth.error} />;
  }

  if (auth.status === "unauthenticated") {
    return <SignInPage />;
  }

  if (appState.app == null) {
    return <HomePage />;
  }

  const app = apps[appState.app];

  return (
    // Include a Route so that we can use useRouteMatch etc
    <Route
      path={app.selectProject ? `${app.route}/:customer?/:project?` : app.route}
    >
      {app.selectProject ? (
        <>
          <ProjectSelector
            customer={appState.project?.customer}
            project={appState.project?.project}
            scope={Scope.Internal}
            hideScope
            onChange={appState.setProject}
          />

          <RequireProject>
            <UserActionProvider>
              <app.Page />
            </UserActionProvider>
          </RequireProject>
        </>
      ) : (
        <app.Page />
      )}
    </Route>
  );
};

export default AppContent;
