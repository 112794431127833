import { ColumnsType } from "antd/lib/table";
import _ from "lodash";
import React, { useMemo } from "react";
import { Batch, Project } from "../../../types";
import { EditableProgressCellContainer } from "../EditableProgressCell/EditableProgressCellContainer";
import { ScrollableTable } from "../ScrollableTable";
import {
  ancestorToRow,
  getComponentColumn,
  getProgressColumn,
} from "../table-utils";
import { ComponentWithProgresses, RecordType } from "../types";

type ProgressHistoryTableProps = {
  project: Project;
  batches: Batch[];
  components: ComponentWithProgresses[];
  onComponentUpdated: (componentIds: string[]) => any;
};

const componentToRow = (
  c: ComponentWithProgresses,
  onComponentUpdated: (componentIds: string[]) => any,
  project: Project,
) => ({
  key: c.id,
  component: c.name,
  type: RecordType.component,
  ..._.zipObject(
    c.aggregatedProgresses.map((r) => r.batchId),
    c.aggregatedProgresses.map((r) => (
      <EditableProgressCellContainer
        key={r.spaceId}
        project={project}
        progress={r.progress}
        status={r.status}
        componentId={c.id}
        spaceId={r.spaceId}
        batchId={r.batchId}
        onComponentUpdated={onComponentUpdated}
      />
    )),
  ),
});

export const ProgressHistoryTable = (props: ProgressHistoryTableProps) => {
  const { project, batches, components, onComponentUpdated } = props;
  // The type of the column set depends on the week numbers of the selected project.
  const columns: ColumnsType<any> = useMemo(
    () => [
      getComponentColumn(),
      // All other columns, representing week numbers.
      ...batches.map((b, idx) =>
        getProgressColumn(`Week ${b.weekNumber}`, b.id, idx, batches.length),
      ),
    ],
    [batches],
  );

  const tableData = useMemo(() => {
    const componentsByAncestor = _.groupBy(components, "ancestorPath");
    return Object.entries(componentsByAncestor).flatMap(([, cs]) => [
      ancestorToRow(cs[0].ancestorPath),
      ...cs.map((component) =>
        componentToRow(component, onComponentUpdated, project),
      ),
    ]);
  }, [components, onComponentUpdated, project]);

  return <ScrollableTable columns={columns} dataSource={tableData} />;
};
