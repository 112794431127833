import { Divider, Empty, Layout } from "antd";
import React, { useState } from "react";
import { useProject } from "../App/state";
import { ComponentViewer } from "../components/ComponentViewer";
import { DisplayTreeNode } from "../components/DisplayTree/types";
import PlansHeader from "../components/PlansTree/PlansHeader";
import { PlansTree } from "../components/PlansTree/PlansTree";
import { Plan } from "../types";
import styles from "./Page.module.css";

export const NO_PLAN_SELECTED_MESSAGE = "No plan selected!";

export const PlansModePage = () => {
  const [taskId, setTask] = useState<string>();
  const [plan, setPlan] = useState<Plan>();
  // When adjacent components pass a task deletion upwards, we pass it down to the tree
  // Swap to a proper state context if we have to do this again

  const project = useProject();

  const handleSelectTask = (selectedTaskNode: DisplayTreeNode) => {
    setTask(selectedTaskNode.key);
  };

  return (
    <Layout className={styles.layout}>
      <Layout.Sider
        className={styles["side-layout"]}
        width={"30%"} // not trivial to set via css because of antd layout magic
        theme="light"
      >
        <div className={"plans-mode-page-header"}>
          <PlansHeader
            title={"Plans"}
            project={project}
            onSelect={setPlan}
            selectedPlan={plan}
            editable
          />
        </div>
        <Divider />
        {plan?.id ? (
          <PlansTree
            project={project}
            onSelectTask={handleSelectTask}
            planId={plan.id}
            editable
            searchable
          />
        ) : (
          <Empty description={NO_PLAN_SELECTED_MESSAGE} />
        )}
      </Layout.Sider>
      <Layout.Content>
        <ComponentViewer project={project} taskId={taskId} planId={plan?.id} />
      </Layout.Content>
    </Layout>
  );
};
