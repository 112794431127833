// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/cssWithMappingToString.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".ConfigurationModePage-module__button-group--3lLEf{padding:4px;margin:8px 4px}.ConfigurationModePage-module__content--3c25s{overflow-y:hidden !important}.ConfigurationModePage-module__column--ZazXs{float:left;width:50%;height:100%;margin:8px;overflow-y:auto}.ConfigurationModePage-module__tree-container--22QGK{overflow-y:hidden !important;height:100%;width:100%;display:flex}.ConfigurationModePage-module__tree-node--20uOO{display:flex}.ConfigurationModePage-module__faded--3wKXg{color:#8c8c8c !important}.ConfigurationModePage-module__faded--3wKXg *{color:#8c8c8c !important}.ConfigurationModePage-module__highlighted--xzG1c{font-weight:bold}", "",{"version":3,"sources":["webpack://./src/pages/ConfigurationModePage/ConfigurationModePage.module.css"],"names":[],"mappings":"AAAA,mDACE,WAAA,CACA,cAAA,CAGF,8CACE,4BAAA,CAGF,6CACE,UAAA,CACA,SAAA,CACA,WAAA,CACA,UAAA,CACA,eAAA,CAGF,qDACE,4BAAA,CACA,WAAA,CACA,UAAA,CACA,YAAA,CAGF,gDACE,YAAA,CAGF,4CACE,wBAAA,CACA,8CACE,wBAAA,CAIJ,kDACE,gBAAA","sourcesContent":[".button-group {\n  padding: 4px;\n  margin: 8px 4px;\n}\n\n.content {\n  overflow-y: hidden !important;\n}\n\n.column {\n  float: left;\n  width: 50%;\n  height: 100%;\n  margin: 8px;\n  overflow-y: auto;\n}\n\n.tree-container {\n  overflow-y: hidden !important;\n  height: 100%;\n  width: 100%;\n  display: flex;\n}\n\n.tree-node {\n  display: flex;\n}\n\n.faded {\n  color: #8c8c8c !important;\n  * {\n    color: #8c8c8c !important;\n  }\n}\n\n.highlighted {\n  font-weight: bold;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"button-group": "ConfigurationModePage-module__button-group--3lLEf",
	"content": "ConfigurationModePage-module__content--3c25s",
	"column": "ConfigurationModePage-module__column--ZazXs",
	"tree-container": "ConfigurationModePage-module__tree-container--22QGK",
	"tree-node": "ConfigurationModePage-module__tree-node--20uOO",
	"faded": "ConfigurationModePage-module__faded--3wKXg",
	"highlighted": "ConfigurationModePage-module__highlighted--xzG1c"
};
export default ___CSS_LOADER_EXPORT___;
