// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/cssWithMappingToString.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".selectorStyles-module__selector--2bIDG{width:100%;margin:0 2px !important}", "",{"version":3,"sources":["webpack://./src/components/Selectors/selectorStyles.module.css"],"names":[],"mappings":"AAAA,wCACE,UAAA,CACA,uBAAA","sourcesContent":[".selector {\n  width: 100%;\n  margin: 0 2px !important;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"selector": "selectorStyles-module__selector--2bIDG"
};
export default ___CSS_LOADER_EXPORT___;
