import { Alert, Modal } from "antd";
import React, { useEffect, useState } from "react";

type SpacesTreeNodeReparentingModalProps = {
  isVisible: boolean;
  onReparentNode: () => any;
  onCancelReparentNode: () => any;
  nodeType?: "scene" | "space";
  draggedNodeName?: string;
  targetNodeName?: string;
};

export const SpacesTreeNodeReparentingModal = (
  props: SpacesTreeNodeReparentingModalProps,
) => {
  const [visible, setVisible] = useState(props.isVisible);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    setVisible(props.isVisible);
  }, [props.isVisible]);

  const onCancel = () => {
    props.onCancelReparentNode();
    setVisible(false);
  };

  const onOk = async () => {
    setLoading(true);
    await props.onReparentNode();
    setLoading(false);
    setVisible(false);
  };

  return (
    <Modal
      visible={visible}
      title={<div>Reparent {props.nodeType}?</div>}
      okText="OK"
      okButtonProps={{ danger: true }}
      cancelText="Cancel"
      onCancel={onCancel}
      onOk={onOk}
      confirmLoading={loading}
    >
      <Alert
        type="info"
        showIcon
        message={`Are you sure you want to reparent ${props.draggedNodeName} under ${props.targetNodeName}?`}
      />
    </Modal>
  );
};
