import { ArrowRightOutlined } from "@ant-design/icons";
import React from "react";
import styles from "./DrawingPath.module.css";

interface DrawingPathProps {
  path: string[];
}

export const DrawingPath = ({ path }: DrawingPathProps) => (
  <div className={styles["node-path"]}>
    {path.map((a, index) => (
      <span key={index}>
        <span className={styles["node-path-item"]}>{a}</span>
        <span className={styles["node-path-item"]}>
          {index < path.length - 1 && <ArrowRightOutlined />}
        </span>
      </span>
    ))}
  </div>
);
