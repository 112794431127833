import { DeleteOutlined } from "@ant-design/icons";
import { Button, Tooltip } from "antd";
import React, { useState } from "react";
import { DeleteConfirmationModal } from "../DeleteConfirmationModal/DeleteConfirmationModal";

type ClearHolidaysModalProps = {
  onDelete: () => Promise<any>;
};

const DELETE_CONFIRMATION_REQ_TEXT = "delete all holidays";
const DELETE_DATES_MODAL_TITLE = "Clear All Holiday Dates";
const DELETE_CONFIRMATION_ALERT_MESSAGE =
  "Are you sure you want to delete all holidays?";
const DELETE_DATES_MODAL_BODY = <p>This action will delete all holidays!</p>;

export const ClearHolidaysModal = (props: ClearHolidaysModalProps) => {
  const { onDelete } = props;

  const [visible, setVisible] = useState(false);

  return (
    <>
      <Tooltip title={"Delete all holidays"}>
        <Button
          icon={<DeleteOutlined />}
          type="primary"
          danger
          onClick={() => setVisible(true)}
        />
      </Tooltip>
      <DeleteConfirmationModal
        title={DELETE_DATES_MODAL_TITLE}
        visible={visible}
        setVisible={setVisible}
        onOk={onDelete}
        deletionText={DELETE_CONFIRMATION_REQ_TEXT}
        modalBody={DELETE_DATES_MODAL_BODY}
        alertText={DELETE_CONFIRMATION_ALERT_MESSAGE}
      />
    </>
  );
};
