// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/cssWithMappingToString.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".style-module__dropdown--1YWJN{width:100%}.style-module__dropdown-wrapped--31XMG{width:100%}.style-module__dropdown-wrapped--31XMG .ant-select-selection-item{word-break:break-all}.style-module__dropdown-wrapped--31XMG .ant-select-selector{height:auto !important}.style-module__label-container--qvLBl{height:100%;text-transform:uppercase;font-weight:500}.style-module__label-container--qvLBl>*{flex-basis:50%;flex-grow:1}.style-module__label--1PMGt{height:100%;display:flex;align-items:center}.style-module__dropdown-chain-wrapper--3anON{display:flex;align-items:flex-end}.style-module__dropdown-chain-wrapper--3anON .style-module__copy-button--3g0FF{padding-bottom:3px}", "",{"version":3,"sources":["webpack://./src/components/SceneSelectionDropdowns/style.module.css"],"names":[],"mappings":"AAAA,+BACE,UAAA,CAGF,uCACE,UAAA,CACA,kEACE,oBAAA,CAEF,4DACE,sBAAA,CAIJ,sCACE,WAAA,CAEA,wBAAA,CACA,eAAA,CAEA,wCACE,cAAA,CACA,WAAA,CAIJ,4BACE,WAAA,CACA,YAAA,CACA,kBAAA,CAGF,6CACE,YAAA,CACA,oBAAA,CAEA,+EACE,kBAAA","sourcesContent":[".dropdown {\n  width: 100%;\n}\n\n.dropdown-wrapped {\n  width: 100%;\n  :global(.ant-select-selection-item) {\n    word-break: break-all;\n  }\n  :global(.ant-select-selector) {\n    height: auto !important;\n  }\n}\n\n.label-container {\n  height: 100%;\n\n  text-transform: uppercase;\n  font-weight: 500;\n\n  & > * {\n    flex-basis: 50%;\n    flex-grow: 1;\n  }\n}\n\n.label {\n  height: 100%;\n  display: flex;\n  align-items: center;\n}\n\n.dropdown-chain-wrapper {\n  display: flex;\n  align-items: flex-end;\n\n  .copy-button {\n    padding-bottom: 3px;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"dropdown": "style-module__dropdown--1YWJN",
	"dropdown-wrapped": "style-module__dropdown-wrapped--31XMG",
	"label-container": "style-module__label-container--qvLBl",
	"label": "style-module__label--1PMGt",
	"dropdown-chain-wrapper": "style-module__dropdown-chain-wrapper--3anON",
	"copy-button": "style-module__copy-button--3g0FF"
};
export default ___CSS_LOADER_EXPORT___;
