import { EditOutlined } from "@ant-design/icons";
import { Button, Form, Input, Modal, Tooltip } from "antd";
import React, { useEffect, useState } from "react";
import { Plan } from "../../types";

type PlanEditModalProps = {
  plan: Plan;
  onSubmitUpdate: (plan: Plan) => Promise<void>;
};

const TOOLTIP_TITLE = "Edit Plan";
const MODAL_TITLE = "Update Plan";
const FORM_HELP_MESSAGE = "Please enter a name for the plan";

export const PlanEditModal = (props: PlanEditModalProps) => {
  const { plan, onSubmitUpdate } = props;
  const [isVisible, setIsVisible] = useState(false);
  const [newPlanForm] = Form.useForm();

  useEffect(() => {
    // because the form survives a switch between plans,
    // we have to set the value of the name here
    newPlanForm.setFieldsValue({ name: plan.name });
  }, [newPlanForm, plan.name]);

  return (
    <>
      <Tooltip title={TOOLTIP_TITLE}>
        <Button
          type="primary"
          icon={<EditOutlined />}
          onClick={() => setIsVisible(true)}
          disabled={!plan}
        ></Button>
      </Tooltip>
      <Modal
        title={MODAL_TITLE}
        visible={isVisible}
        onOk={async () => {
          try {
            const values = await newPlanForm.validateFields();
            newPlanForm.resetFields();
            await onSubmitUpdate({
              ...(plan as Plan),
              name: values.name,
            });
            setIsVisible(false);
          } catch (errorInfo) {
            console.warn("Validation failed", errorInfo);
          }
        }}
        onCancel={() => setIsVisible(false)}
      >
        <Form form={newPlanForm}>
          <Form.Item
            name="name"
            label="Name"
            rules={[{ required: true, min: 1 }]}
            initialValue={plan.name}
            help={FORM_HELP_MESSAGE}
          >
            <Input />
          </Form.Item>
        </Form>
      </Modal>
    </>
  );
};
