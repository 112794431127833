import { Input, Tooltip } from "antd";
import classnames from "classnames";
import cx from "classnames";
import React, { ReactNode } from "react";
import { useIsBimEnabled } from "../../DualView/hooks/useIsBimEnabled";
import styles from "./OptionsPanel.module.css";

const SCENE_Z_OFFSET_TITLE = "Offset (mm)";
const MULTIPLE_VALUES = "Multiple values";
const X_AXIS_TITLE = "X Axis (mm)";
const Y_AXIS_TITLE = "Y Axis (mm)";
const Z_AXIS_TITLE = "Z Axis (mm)";
const Z_AXIS_DESCRIPTION =
  "Z axis can only be edited by typing in  offset value.";
const FLOOR_HEIGHT = "Floor height (mm)";
const SCANNERS_HEIGHT_TITLE = "Scanner's height (mm)";
const SCANNERS_HEIGHT_MM = 1850;

const OFFSET_TOOLTIP =
  "If the Z coordinate of the scene does not match the sum of the floor and the scanner's height, enter the offset value in mm to edit it (e.g. stair landings, auditorium tribune… )";
const X_AXIS_TOOLTIP =
  "Move the scene on the floorplan to edit its X coordinate";
const Y_AXIS_TOOLTIP =
  "Move the scene on the floorplan to edit its Y coordinate";
const Z_AXIS_TOOLTIP =
  "The default Z coordinate of the scene is the sum of the floor, the scanner's height and the offset";
const FLOOR_HEIGHT_TOOLTIP = "Floor Height";
const SCANNERS_HEIGHT_TOOLTIP = "Scanner's Height";

interface SceneCoordinatesRowsProps {
  sceneX: number | undefined;
  sceneY: number | undefined;
  zOffset: number | undefined;
  onChangeZOffset: (value: number) => void;
  floorHeight: number | undefined;
  isSelectionSingleScene: boolean;
  editMode: boolean;
}

export const SceneCoordinatesRows = (props: SceneCoordinatesRowsProps) => {
  const {
    sceneX,
    sceneY,
    zOffset,
    onChangeZOffset,
    floorHeight,
    isSelectionSingleScene,
    editMode,
  } = props;

  const isBimEnabled = useIsBimEnabled();

  const zAxis = (floorHeight ?? 0) + SCANNERS_HEIGHT_MM + (zOffset ?? 0);

  const bimConfig = React.useMemo(() => {
    if (!isBimEnabled) {
      return null;
    }
    return (
      <React.Fragment>
        <div>
          <Row title={Z_AXIS_TITLE} tooltip={Z_AXIS_TOOLTIP}>
            {zAxis}
          </Row>
          <div className={styles["description"]}>{Z_AXIS_DESCRIPTION}</div>

          <div
            className={cx(
              styles["left-margin__medium"],
              styles["list__compact"],
              styles["text__auxilliary"],
            )}
          >
            <Row title={FLOOR_HEIGHT} tooltip={FLOOR_HEIGHT_TOOLTIP}>
              {floorHeight}
            </Row>
            <Row
              title={SCANNERS_HEIGHT_TITLE}
              tooltip={SCANNERS_HEIGHT_TOOLTIP}
            >
              {SCANNERS_HEIGHT_MM}
            </Row>

            <Row
              title={SCENE_Z_OFFSET_TITLE}
              isEditable={true}
              tooltip={OFFSET_TOOLTIP}
            >
              <Input
                type={"number"}
                className={styles["input-box"]}
                value={zOffset}
                placeholder={MULTIPLE_VALUES}
                onChange={(e) => onChangeZOffset(parseFloat(e.target.value))}
                disabled={!editMode}
              />
            </Row>
          </div>
        </div>
      </React.Fragment>
    );
  }, [editMode, floorHeight, isBimEnabled, onChangeZOffset, zAxis, zOffset]);

  return (
    <div className={styles["scene-coordinates-box"]}>
      <Row title={X_AXIS_TITLE} tooltip={X_AXIS_TOOLTIP}>
        {isSelectionSingleScene ? sceneX : MULTIPLE_VALUES}
      </Row>

      <Row title={Y_AXIS_TITLE} tooltip={Y_AXIS_TOOLTIP}>
        {isSelectionSingleScene ? sceneY : MULTIPLE_VALUES}
      </Row>
      {bimConfig}
    </div>
  );
};

interface ReadOnlyRowProps {
  title: string;
  tooltip?: string;
  isEditable?: boolean;
  children: ReactNode;
}

const Row = ({ title, tooltip, isEditable, children }: ReadOnlyRowProps) => (
  <Tooltip title={tooltip} mouseEnterDelay={1}>
    <div className={styles["row-box"]}>
      <div className={styles["row-box-name"]}>{title}</div>
      <div
        className={classnames([
          styles["row-value"],
          {
            [styles["greyed-out"]]: !isEditable,
          },
        ])}
      >
        {children}
      </div>
    </div>
  </Tooltip>
);
