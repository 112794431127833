import React from "react";
import { useProject } from "../../../App/state";
import { useNotification } from "../../../contexts/Notifications";
import {
  useDeleteArrowMutation,
  useUpdateArrowMutation,
} from "../../../generated/types";
import { useDualModeState } from "../../../pages/DualModePage/state";
import ValidArrowImage from "../../../statics/images/valid-arrow.svg";
import { Batch } from "../../../types";
import { normaliseYaw } from "../../../utils/pano";
import { DraggableItem } from "../../DragAndDrop/DraggableItem";
import { Annotation } from "./Annotation";
import { DeleteButton } from "./AnnotationDeletionModal";
import styles from "./Spotlight.module.css";
import { Arrow as ArrowConfig } from "./types";

type ArrowProps = {
  arrow: ArrowConfig;
  viewer: Viewer;
  yawOffset: number;
  pitchOffset: number;
  currentBatch: Batch;
  sceneId: string;
};

export const Arrow = ({
  arrow,
  viewer,
  yawOffset,
  pitchOffset,
  currentBatch,
  sceneId,
}: ArrowProps) => {
  const project = useProject();
  const notify = useNotification();

  const dualModeState = useDualModeState();

  const [updateArrow] = useUpdateArrowMutation({
    onCompleted: () => notify("Arrow position updated!", "success"),
    onError: () => notify("Failed to update arrow position!", "error"),
    update: (cache: any) => {
      Object.keys(cache.data.data).forEach((key) => {
        if (key.match(/^Shot:/)) {
          cache.evict({ id: key, fieldName: "arrows" });
        }
      });
    },
  });

  const [deleteArrow] = useDeleteArrowMutation({
    onCompleted: () => {
      notify("Arrow deleted!", "success");
    },
    onError: () => notify("Failed to delete arrow!", "error"),
    update: (cache: any) => {
      Object.keys(cache.data.data).forEach((key) => {
        if (key.match(/^Shot:/)) {
          cache.evict({ id: key, fieldName: "arrows" });
        }
      });
    },
  });

  const onDeleteArrow = (arrowId: string) =>
    deleteArrow({
      variables: {
        ...project,
        id: arrowId,
      },
    });

  return (
    <DraggableItem
      item={arrow}
      itemType={"arrow"}
      dragPreviewOverride={ValidArrowImage}
    >
      <Annotation
        key={arrow.id}
        viewer={viewer}
        id={arrow.id}
        yaw={normaliseYaw(arrow.yaw + yawOffset)}
        pitch={arrow.pitch + pitchOffset}
        className={
          arrow.missing ? styles["invalid-arrow"] : styles["valid-arrow"]
        }
        onPositionUpdate={(pos) => {
          const normalisedPos = {
            yaw: normaliseYaw(pos.yaw - yawOffset),
            pitch: pos.pitch - pitchOffset,
          };
          updateArrow({
            variables: {
              customer: project.customer,
              project: project.project,
              scope: project.scope,
              id: arrow.id,
              sceneId: sceneId,
              targetSceneId: arrow.targetSceneId,
              batchId: currentBatch.id,
              yaw: normalisedPos.yaw,
              pitch: normalisedPos.pitch,
            },
          });
        }}
        onClick={() => {
          if (arrow.targetSceneId) {
            dualModeState.setCurrentScene(arrow.targetSceneId);
          }
        }}
      >
        {arrow.missing && "Missing: "}
        {arrow.name}
        <DeleteButton
          id={arrow.id}
          onDelete={onDeleteArrow}
          warningMessage={"Delete arrow?"}
        />
      </Annotation>
    </DraggableItem>
  );
};
