import React from "react";
import {
  DEFAULT_NODE_OPACITY,
  DEFAULT_STROKE_MM,
  DEFAULT_TEXT_COLOR,
  STROKE_SIZE_RATIO,
} from "../drawing-utils";
import { Crosshair } from "./Crosshair";
import { SimpleLine } from "./SimpleLine";
import { SimpleText } from "./SimpleText";

const ORIGIN_X = 0;
const ORIGIN_Y = 0;
const ORIGIN_SIZE_MM = 1000;
const ORIGIN_AND_SCALE_COLOR = "orange";
const SCALE_LENGTH_MM = 10000;
const TEXT_OFFSET_X_MM = 1000;
const TEXT_OFFSET_Y_MM = 1000;
const TOOLTIP_TITLE =
  "Drawing need to be properly scaled in mm for the scale bar to be correct";

const ORIGIN_NODE = {
  id: "FloorplanOriginNode",
  x: ORIGIN_X,
  y: ORIGIN_Y,
  color: ORIGIN_AND_SCALE_COLOR,
  size: ORIGIN_SIZE_MM,
  strokeWidth: ORIGIN_SIZE_MM / STROKE_SIZE_RATIO,
  opacity: DEFAULT_NODE_OPACITY,
};

const SCALE_NODE = {
  id: "FloorplanScaleNode",
  x1: ORIGIN_X,
  y1: ORIGIN_Y,
  x2: SCALE_LENGTH_MM,
  y2: ORIGIN_Y,
  color: ORIGIN_AND_SCALE_COLOR,
  strokeWidth: DEFAULT_STROKE_MM,
  opacity: DEFAULT_NODE_OPACITY,
};

const SCALE_NODE_TEXT = {
  id: "FloorplanScaleNodeText",
  x: TEXT_OFFSET_X_MM,
  y: ORIGIN_Y + TEXT_OFFSET_Y_MM,
  label: `Scale bar ${SCALE_LENGTH_MM.toLocaleString()} mm`,
  title: TOOLTIP_TITLE,
  opacity: DEFAULT_NODE_OPACITY,
  color: DEFAULT_TEXT_COLOR,
};

export const OriginAndScale = () => {
  return (
    <>
      <Crosshair node={ORIGIN_NODE} />
      <SimpleLine node={SCALE_NODE} />
      <SimpleText node={SCALE_NODE_TEXT} />
    </>
  );
};
