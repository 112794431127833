import { ProgressStatus } from "../../generated/types";
import { ProgressChange } from "../../types";

type Progress = { progress?: number | null; status: ProgressStatus };

const STATUS_VALUE = {
  [ProgressStatus.Finished]: 2,
  [ProgressStatus.InProgress]: 1,
  [ProgressStatus.NotStarted]: 0,
};

export const getProgressChange = (
  current: Progress,
  previous?: Progress,
): ProgressChange => {
  if (!previous) {
    return ProgressChange.NoChange;
  }

  const statusDiff =
    STATUS_VALUE[current.status] - STATUS_VALUE[previous.status];
  if (statusDiff > 0) {
    // current status is more advanced than previous one
    return ProgressChange.Up;
  }
  if (statusDiff < 0) {
    // current status is less advanced than previous one
    return ProgressChange.Down;
  }
  // from here on we know the status of previous and current are the same
  if (
    current.status === ProgressStatus.Finished ||
    current.status === ProgressStatus.NotStarted
  ) {
    return ProgressChange.NoChange;
  }
  // from here we know both previous and current are InProgress
  const diff = (current.progress ?? 0) - (previous?.progress ?? 0);
  if (Math.abs(diff) < 0.001) {
    return ProgressChange.NoChange;
  }
  return diff > 0 ? ProgressChange.Up : ProgressChange.Down;
};

export const roundProgress = (
  progress: number,
  status: ProgressStatus,
): number => {
  if (status === "FINISHED") {
    return 100;
  } else if (status === "NOT_STARTED") {
    return 0;
  } else if (progress > 99) {
    return 99;
  } else if (progress < 1) {
    return 1;
  } else {
    return Math.round(progress);
  }
};
