import { Empty, Spin } from "antd";
import _ from "lodash";
import React, { useMemo } from "react";
import { useProject } from "../../App/state";
import {
  useGetBatchesQuery,
  useGetSpaceByIdQuery,
} from "../../generated/types";
import { Filter } from "../../pages/EditorPage/types";
import { ProgressHistoryTableContainer } from "./ProgressHistoryTable/ProgressHistoryTableContainer";
import { ProgressTableContainer } from "./ProgressTable/ProgressTableContainer";
import { sortComponents } from "./table-utils";
import { TableName, componentFromGraphQlComponent } from "./types";

type TablesContainerProps = {
  planId: string;
  spaceId: string;
  tableType: TableName;
  filter: Filter;
};

export const TablesContainer = (props: TablesContainerProps) => {
  const project = useProject();

  const spacesResponse = useGetSpaceByIdQuery({
    variables: {
      ...project,
      spaceId: props.spaceId,
      planId: props.planId,
      includeSelf: true,
      includeComponentsUnderSpace: true,
    },
  });

  const batchesResponse = useGetBatchesQuery({
    variables: {
      ...project,
    },
  });

  const childSpaces = useMemo(
    () =>
      spacesResponse.data
        ? [
            _.pick(spacesResponse.data.spacesByIds[0], ["id", "name"]),
            ..._.sortBy(
              spacesResponse.data.spacesByIds[0].subSpaces,
              "guiIndex",
            ),
          ]
        : [],
    [spacesResponse.data],
  );

  const componentsforSpace = useMemo(() => {
    if (
      !spacesResponse?.data ||
      !spacesResponse.data.spacesByIds[0].componentsUnderSpace
    ) {
      return [];
    }
    const components =
      spacesResponse.data.spacesByIds[0].componentsUnderSpace?.map((m) =>
        componentFromGraphQlComponent(m),
      );

    return sortComponents(components);
  }, [spacesResponse.data]);

  const sortedBatches = useMemo(
    () =>
      batchesResponse.data
        ? _.sortBy(batchesResponse.data.batches, "timestamp")
        : undefined,
    [batchesResponse.data],
  );

  if (spacesResponse.loading || batchesResponse.loading) {
    return <Spin />;
  }

  if (spacesResponse.error || !spacesResponse.data || !sortedBatches) {
    return <div> Error getting spaces. ${spacesResponse.error ?? ""} </div>;
  }
  if (batchesResponse.error || !batchesResponse.data) {
    return (
      <div>Error getting latest batch. ${batchesResponse.error ?? ""} </div>
    );
  }

  if (sortedBatches.length === 0) {
    return (
      <Empty description="No batches for this project. Cannot show progress table." />
    );
  }

  return props.tableType === "progresses" ? (
    <ProgressTableContainer
      spaces={childSpaces}
      components={componentsforSpace}
      batchId={sortedBatches[sortedBatches.length - 1].id}
      previousBatchId={
        sortedBatches.length > 1
          ? sortedBatches[sortedBatches.length - 2].id
          : undefined
      }
      project={project}
      planId={props.planId}
      filter={props.filter}
    />
  ) : (
    <ProgressHistoryTableContainer
      batches={batchesResponse.data.batches}
      components={componentsforSpace}
      project={project}
      spaceId={props.spaceId}
      planId={props.planId}
      filter={props.filter}
    />
  );
};
