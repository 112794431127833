import { Select, Spin } from "antd";
import _ from "lodash";
import React, { useEffect } from "react";
import { usePlansQuery } from "../../../generated/types";
import { Plan, Project } from "../../../types";
import styles from "./planSelectorStyles.module.css";
type PlanSelectorProps = {
  project: Project;
  onSelect: (plan: Plan | undefined) => void;
  selectedId?: string;
};

export const PlanSelector = (props: PlanSelectorProps) => {
  const { project, selectedId, onSelect } = props;
  const { data, loading, error } = usePlansQuery({
    variables: {
      ...project,
    },
  });

  useEffect(() => {
    if (selectedId && data) {
      const selectedPlan = _.find(data.plans, (plan) => plan.id === selectedId);
      onSelect(selectedPlan);
    } else {
      onSelect(data?.plans[0]);
    }
  }, [data, onSelect, selectedId]);

  if (loading) {
    return <Spin />;
  }

  if (error || !data) {
    return <div> Error fetching plans </div>;
  }

  const plans = data.plans;
  const defaultPlanValue = plans.length > 0 ? plans[0].id : undefined;
  const handleOnChange = (value: string) => {
    const selectedPlan = _.find(plans, (plan) => plan.id === value);
    onSelect(selectedPlan);
  };

  return (
    <Select
      className={styles.selector}
      placeholder="Select plan..."
      value={selectedId}
      defaultValue={defaultPlanValue}
      onChange={handleOnChange}
    >
      {plans.map((plan) => (
        <Select.Option key={plan.id} value={plan.id}>
          {plan.name}
        </Select.Option>
      ))}
    </Select>
  );
};
