import { Layout } from "antd";
import React, { PropsWithChildren, ReactNode, useState } from "react";
import styles from "./DualViewSideDrawer.module.css";
import { DualViewSideDrawerContent } from "./DualViewSideDrawerContent";

const { Content, Sider } = Layout;

type SideDrawerTabProps = PropsWithChildren<{
  spotlightQAContent: ReactNode;
  arrowsContent: ReactNode;
  onCollapse?: (collapsed: boolean) => void;
}>;

export const LayoutWithSideDrawer = (props: SideDrawerTabProps) => {
  const [collapsed, setCollapsed] = useState(true);

  return (
    <Layout className={styles.layout}>
      <Content>{props.children}</Content>
      <Sider
        collapsible
        collapsed={collapsed}
        onCollapse={(isCollapsed) => {
          setCollapsed(isCollapsed);
          props.onCollapse && props.onCollapse(isCollapsed);
        }}
        width={"30%"}
        reverseArrow
        theme="light"
      >
        <DualViewSideDrawerContent
          spotlightQAContent={props.spotlightQAContent}
          arrowsContent={props.arrowsContent}
          onClickMenuItem={() => {
            if (collapsed) {
              setCollapsed(false);
              props.onCollapse && props.onCollapse(collapsed);
            }
          }}
          isCollapsed={collapsed}
        />
      </Sider>
    </Layout>
  );
};
