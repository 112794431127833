import { Button, Tooltip } from "antd";
import _ from "lodash";
import React, { useState } from "react";
import { useNotification } from "../../contexts/Notifications";
import {
  Component,
  SpaceWithMappingDetailsFragment,
  useDeleteMappingsMutation,
} from "../../generated/types";
import { Project } from "../../types";
import { generateFolderName } from "../SpacesTree/tree-nodes";
import { ModalConfirmationMappingAction } from "./ModalConfirmation";
import { getMappings } from "./mappings-utility";
import { DELETED_MAPPINGS_ERROR, DELETED_MAPPINGS_SUCCESS } from "./messages";

type DeleteMappingsButtonProps = {
  project: Project;
  selectedSpaces: SpaceWithMappingDetailsFragment[];
  selectedComponents: Pick<Component, "id" | "name">[];
  onUpdate: (keys: string[]) => void;
};

export const DeleteMappingsButton = (props: DeleteMappingsButtonProps) => {
  const { project, selectedSpaces, selectedComponents, onUpdate } = props;

  const [visible, setVisible] = useState(false);
  const selectedMappings = getMappings(selectedSpaces, selectedComponents);

  const notify = useNotification();
  const [deleteMappingsMutation] = useDeleteMappingsMutation({
    onCompleted: () => {
      const nodesToUpdate = selectedSpaces.map((s) =>
        generateFolderName(s.id, "mappings"),
      );
      onUpdate(nodesToUpdate);
      notify(DELETED_MAPPINGS_SUCCESS, "success");
    },
    onError: (error) => {
      notify(`${DELETED_MAPPINGS_ERROR} = ${error}`, "error");
    },
  });

  const onApply = async () => {
    if (selectedComponents.length === 0 || selectedSpaces.length === 0) {
      throw new Error(
        "Logical error: this button shouldn't be enabled if no spaces/components are selected.",
      );
    }
    setVisible(false);
    await deleteMappingsMutation({
      variables: {
        ...project,
        mappingIds: selectedMappings.map((m) => m.id),
      },
    });
  };

  const onCancel = () => setVisible(false);

  const removeButton = (
    <Button
      block
      value="remove"
      disabled={selectedMappings.length === 0}
      onClick={() => setVisible(true)}
    >
      remove
    </Button>
  );
  return (
    <div>
      {selectedMappings.length === 0 ? (
        <Tooltip title="No mappings for the selected components exist in the selected spaces.">
          {removeButton}
        </Tooltip>
      ) : (
        removeButton
      )}
      <ModalConfirmationMappingAction
        visible={visible}
        onCancelCallback={onCancel}
        onApllyCallback={onApply}
        affectedComponentNames={_.uniq(
          selectedMappings.map((m) => m.component.name),
        )}
        affectedSpaces={_.uniqBy(
          selectedMappings.map((mapping) => mapping.space),
          "id",
        )}
        notAffectedSpaces={_.differenceBy(
          selectedSpaces,
          _.uniq(selectedMappings.map((m) => m.space)),
          "id",
        )}
        mappingAction="delete"
      />
    </div>
  );
};
