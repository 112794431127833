import React from "react";
import styles from "./DrawingTool.module.css";

type ColorsLegendProps = {
  colors: { [key: string]: string };
};

export const ColorsLegend = (props: ColorsLegendProps) => (
  <div className={styles["colors-legend-box"]}>
    {Object.entries(props.colors).map(([name, color]) => (
      <div key={name} className={styles["colors-legend-row"]}>
        <div
          className={styles["colors-legend-circle"]}
          style={{ backgroundColor: color }}
        />
        <div className={styles["colors-legend-name"]}>{name}</div>
      </div>
    ))}
  </div>
);
