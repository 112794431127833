import { UploadOutlined } from "@ant-design/icons";
import { Alert, Button, Form, Modal, Select } from "antd";
import React, { useState } from "react";
import { useNotification } from "../../contexts/Notifications";
import { useGetAllPlansQuery } from "../../generated/types";
import { Project } from "../../types";
import { FileUploader } from "../FileUploader";

type PlanUploadModalProps = {
  onUpload: (planId: string, file: File) => Promise<void>;
  project: Project;
};

export const PlanUploadModal = (props: PlanUploadModalProps) => {
  const [isVisible, setIsVisible] = useState(false);
  const [newPlanUploadForm] = Form.useForm();
  const [uploadedFile, setUploadedFile] = useState<File>();
  const [uploadInProgress, setUploadInProgress] = useState(false);

  const { data } = useGetAllPlansQuery({
    variables: {
      customer: props.project.customer,
      project: props.project.project,
      scope: props.project.scope,
    },
  });

  const allPlans = data?.plans;

  const notify = useNotification();

  const onUpload = async () => {
    if (uploadedFile) {
      const values = await newPlanUploadForm.validateFields();
      newPlanUploadForm.resetFields();
      setUploadInProgress(true);
      await props.onUpload(values.planId, uploadedFile);
      setUploadInProgress(false);
      setIsVisible(false);
    }
  };

  return (
    <>
      <Button
        type="primary"
        icon={<UploadOutlined />}
        onClick={() => setIsVisible(true)}
      >
        Upload Task Breakdown
      </Button>
      <Modal
        title="Upload Task Beakdown"
        visible={isVisible}
        onCancel={() => setIsVisible(false)}
        footer={
          <span>
            <Button danger onClick={() => setIsVisible(false)}>
              Cancel
            </Button>
            <Button
              disabled={!uploadedFile}
              type={"primary"}
              icon={<UploadOutlined />}
              loading={uploadInProgress}
              onClick={onUpload}
            >
              Upload
            </Button>
          </span>
        }
      >
        <Form form={newPlanUploadForm}>
          <Form.Item
            name="planId"
            label="Plan"
            rules={[{ required: true, min: 1 }]}
          >
            <Select placeholder="Select a plan">
              {allPlans?.map((plan) => (
                <Select.Option key={plan.id} value={plan.id}>
                  {plan.name}
                </Select.Option>
              ))}
            </Select>
          </Form.Item>
          <Alert
            message="If you are uploading a task breakdown to a plan with existing tasks, it will be added to the root of the plan."
            type="info"
            showIcon
          />
          <Form.Item name="file">
            <FileUploader
              title="Select Task Breakdown File"
              onFilesSelected={async (files) => {
                if (files.length !== 1) {
                  notify(
                    "Upload failed, only one file can be uploaded at a time.",
                  );
                } else {
                  setUploadedFile(files[0]);
                }
              }}
            />
          </Form.Item>
        </Form>
        {uploadedFile && (
          <p>
            Upload <b>{uploadedFile.name}</b>
          </p>
        )}
      </Modal>
    </>
  );
};
