import { Input } from "antd";
import React from "react";
import styles from "./OptionsPanel.module.css";

interface OptionsPanelBaseRowsProps {
  initialName: string;
  name: string | undefined;
  setName: (name: string | undefined) => void;
  editMode: boolean;
}

export const OptionsPanelBaseRows = (props: OptionsPanelBaseRowsProps) => {
  const { initialName, name, setName, editMode } = props;

  return (
    <div>
      {editMode ? (
        <Input
          className={styles["header-row"]}
          defaultValue={name}
          onChange={(e) => setName(e.target.value)}
        />
      ) : (
        <div className={styles["header-row"]}>{initialName}</div>
      )}
    </div>
  );
};
