// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/cssWithMappingToString.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".Page-module__page--28gwS{height:100%;display:flex;flex-direction:column;justify-content:center;align-items:center}.Page-module__layout--2yzDY{height:100%}.Page-module__side-layout--2C77d{background-color:#fff !important}", "",{"version":3,"sources":["webpack://./src/pages/Page.module.css"],"names":[],"mappings":"AAAA,0BACE,WAAA,CACA,YAAA,CACA,qBAAA,CACA,sBAAA,CACA,kBAAA,CAGF,4BACE,WAAA,CAGF,iCACE,gCAAA","sourcesContent":[".page {\n  height: 100%;\n  display: flex;\n  flex-direction: column;\n  justify-content: center;\n  align-items: center;\n}\n\n.layout {\n  height: 100%;\n}\n\n.side-layout {\n  background-color: white !important;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"page": "Page-module__page--28gwS",
	"layout": "Page-module__layout--2yzDY",
	"side-layout": "Page-module__side-layout--2C77d"
};
export default ___CSS_LOADER_EXPORT___;
