import React, { useState } from "react";
import { useProject } from "../../../App/state";
import { useNotification } from "../../../contexts/Notifications";
import { useCreateSpotlightMutation } from "../../../generated/types";
import { useDualModeState } from "../../../pages/DualModePage/state";
import { PanoPosition } from "../../../types";
import { Batch } from "../../../types";
import { SpotlightForm } from "../../SpotlightInfo/SpotlightForm";
import { Annotation } from "./Annotation";
import styles from "./Spotlight.module.css";

type CreationProps = {
  viewer: Viewer;
  position: PanoPosition;
  currentBatch: Batch;
  onClose: () => void;
  yawOffset: number;
  pitchOffset: number;
};

export const SpotlightCreationContainer = (props: CreationProps) => {
  const { viewer, position, currentBatch, onClose } = props;
  const project = useProject();
  const dualModeState = useDualModeState();
  const notify = useNotification();

  const [spotlightToBe, setSpotlightToBe] = useState<PanoPosition>({
    yaw: position.yaw,
    pitch: position.pitch,
  });

  const [createSpotlight] = useCreateSpotlightMutation({
    onCompleted: () => notify("Spotlight created!", "success"),
    onError: () => notify("Failed to create spotlight!", "error"),
    update: (cache: any) => {
      Object.keys(cache.data.data).forEach((key) => {
        if (key.match(/^Shot:/)) {
          cache.evict({ id: key, fieldName: "spotlights" });
        }
      });
    },
  });

  return (
    <Annotation
      key="newSpotlight"
      viewer={viewer}
      id="newSpotlight"
      yaw={spotlightToBe.yaw + props.yawOffset}
      pitch={spotlightToBe.pitch + props.pitchOffset}
      className={styles.spotlight}
      onPositionUpdate={({ yaw: newYaw, pitch: newPitch }) => {
        setSpotlightToBe({
          yaw: newYaw - props.yawOffset,
          pitch: newPitch - props.pitchOffset,
        });
      }}
      alwaysVisible
    >
      <SpotlightForm
        isCreationInterface
        onSubmit={(values) => {
          createSpotlight({
            variables: {
              customer: project.customer,
              project: project.project,
              scope: project.scope,
              sceneId: dualModeState.currentSceneId?.value,
              mappingId: values.mappingId,
              type: values.type,
              createdInBatchId: values.createdInBatchId,
              batchId: dualModeState.referenceBatch?.id as string,
              yaw: spotlightToBe.yaw,
              pitch: spotlightToBe.pitch,
            },
          });
          onClose();
        }}
        onCancel={() => {
          onClose();
        }}
        createdInBatchId={currentBatch.id}
      />
    </Annotation>
  );
};
