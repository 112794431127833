// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/cssWithMappingToString.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".styles-module__selector-container--fVuph{width:100%}.styles-module__delete-warning--1-ABj{color:#ff4500}.styles-module__scrollable-container--3A6HE{flex-grow:1;overflow-y:scroll;height:100%}.styles-module__node--qQfsg{display:flex}.styles-module__tree-search-value-hit--B26w6{font-weight:bold;background-color:#c7e1f9}.styles-module__search-bar-multi-select--2QlQ_{margin-bottom:8;width:100%}.styles-module__tasks-tree-options-container--Nbxrs{display:flex;justify-content:space-between;align-items:center}.styles-module__switch-container--1KuMk{position:absolute}", "",{"version":3,"sources":["webpack://./src/components/PlansTree/styles.module.css"],"names":[],"mappings":"AAAA,0CACE,UAAA,CAGF,sCACE,aAAA,CAGF,4CACE,WAAA,CACA,iBAAA,CACA,WAAA,CAGF,4BACE,YAAA,CAGF,6CACE,gBAAA,CACA,wBAAA,CAGF,+CACE,eAAA,CACA,UAAA,CAGF,oDACE,YAAA,CACA,6BAAA,CACA,kBAAA,CAGF,wCACE,iBAAA","sourcesContent":[".selector-container {\n  width: 100%;\n}\n\n.delete-warning {\n  color: orangered;\n}\n\n.scrollable-container {\n  flex-grow: 1;\n  overflow-y: scroll;\n  height: 100%;\n}\n\n.node {\n  display: flex;\n}\n\n.tree-search-value-hit {\n  font-weight: bold;\n  background-color: rgb(199, 225, 249);\n}\n\n.search-bar-multi-select {\n  margin-bottom: 8;\n  width: 100%;\n}\n\n.tasks-tree-options-container {\n  display: flex;\n  justify-content: space-between;\n  align-items: center;\n}\n\n.switch-container {\n  position: absolute;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"selector-container": "styles-module__selector-container--fVuph",
	"delete-warning": "styles-module__delete-warning--1-ABj",
	"scrollable-container": "styles-module__scrollable-container--3A6HE",
	"node": "styles-module__node--qQfsg",
	"tree-search-value-hit": "styles-module__tree-search-value-hit--B26w6",
	"search-bar-multi-select": "styles-module__search-bar-multi-select--2QlQ_",
	"tasks-tree-options-container": "styles-module__tasks-tree-options-container--Nbxrs",
	"switch-container": "styles-module__switch-container--1KuMk"
};
export default ___CSS_LOADER_EXPORT___;
