import { Alert, Button, Modal, Space } from "antd";
import React, { useState } from "react";
import { DeleteConfirmationTextBox } from "./DeleteConfirmationTextBox";

type DeleteConfirmationModalProps = {
  title: string;
  visible: boolean;
  setVisible: (visible: boolean) => void;
  onOk: () => Promise<void>;
  deletionText?: string;
  modalBody?: React.ReactNode;
  alertText?: string;
};

export const DeleteConfirmationModal = (
  props: DeleteConfirmationModalProps,
) => {
  const {
    title,
    visible,
    setVisible,
    onOk,
    deletionText,
    modalBody,
    alertText,
  } = props;
  const [allowDeletion, setAllowDeletion] = useState<boolean>(!deletionText);
  const [isLoading, setIsLoading] = useState<boolean>(false);

  const handleCancel = () => {
    setVisible(false);
  };

  const handleOk = async () => {
    setIsLoading(true);
    await onOk();
    setIsLoading(false);
    setVisible(false);
  };

  return (
    <Modal
      visible={visible}
      title={title}
      destroyOnClose
      onCancel={handleCancel}
      footer={
        <>
          <Button
            key="ok"
            danger
            type="primary"
            onClick={handleOk}
            loading={isLoading}
            disabled={!allowDeletion}
          >
            Ok
          </Button>
          <Button key="cancel" onClick={handleCancel} disabled={isLoading}>
            Cancel
          </Button>
        </>
      }
    >
      <Space />
      {modalBody}
      {alertText && <Alert message={alertText} type="warning" showIcon />}
      <Space direction="vertical" />
      {deletionText && (
        <DeleteConfirmationTextBox
          onAllowDeletion={(allowDelete: boolean) => {
            setAllowDeletion(allowDelete);
          }}
          deletionText={deletionText}
        />
      )}
    </Modal>
  );
};
