import jsPDF from "jspdf";
import { PT_TO_MM } from "./utils";

type ImageType = string | HTMLImageElement | HTMLCanvasElement | Uint8Array;

export interface PdfGenerator {
  addPage(): void;
  getPageWidth(): number;
  getPageHeight(): number;
  getTextWidth(text: string, fontHeightMM: number): number;
  addText(
    text: string,
    x: number,
    y: number,
    fontHeightMM: number,
    textColor: string,
    fillColor?: string,
  ): void;
  addImage(
    image: ImageType,
    imgFormat: "PNG",
    x: number,
    y: number,
    width: number,
    height: number,
  ): void;
  download(filename): void;
}

export class JsPdfGenerator implements PdfGenerator {
  private doc: jsPDF;
  private orientation: "landscape" = "landscape";
  private textAlignment: "center" = "center";
  private compression: "FAST" = "FAST";

  constructor(PDF: typeof jsPDF) {
    this.doc = new PDF({
      orientation: this.orientation,
      unit: "mm",
    });
    // start without a page by default
    this.doc.deletePage(1);
  }

  getPageHeight(): number {
    // Supports only landscape A4 in MM
    return 210;
  }

  getPageWidth(): number {
    // Supports only landscape A4 in MM
    return 297;
  }

  getTextWidth(text: string, fontHeightMM: number): number {
    this.doc.setFontSize(fontHeightMM / PT_TO_MM);
    return this.doc.getTextWidth(text);
  }

  addText(
    text: string,
    x: number,
    y: number,
    fontHeightMM: number,
    textColor: string,
    fillColor?: string,
  ) {
    this.doc.setFontSize(fontHeightMM / PT_TO_MM); // mm to pt
    if (fillColor) {
      const textWidth = this.doc.getTextWidth(text);
      this.doc.setFillColor(fillColor);
      this.doc.rect(
        x - textWidth * 0.51,
        y - fontHeightMM * 0.3,
        textWidth * 1.02,
        fontHeightMM * 0.9,
        "F",
      );
    }
    this.doc.setTextColor(textColor);
    this.doc.text(text, x, y + fontHeightMM * 0.5, {
      align: this.textAlignment,
    });
  }

  addPage() {
    this.doc.addPage("orientation", this.orientation);
  }

  addImage(
    image: ImageType,
    imgFormat: "PNG",
    x: number,
    y: number,
    width: number,
    height: number,
  ) {
    this.doc.addImage(
      image,
      imgFormat,
      x,
      y,
      width,
      height,
      undefined,
      this.compression,
    );
  }

  download(filename: string) {
    this.doc.save(filename);
  }
}
