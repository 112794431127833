import { Modal } from "antd";
import React from "react";
import { ComponentFieldSelectorType } from "./ComponentFieldSelectorContainer";

const UNKNOW_MODAL_TYPE_TEXT_ERROR = "Error: Unknown Modal Type provided";

type BaseModalOptions = {
  type: ComponentFieldSelectorType;
  title: string;
};

interface ObjectModalOptions extends BaseModalOptions {
  type: ComponentFieldSelectorType.ObjectType;
  form: (props: {
    onSubmit: () => void;
    setNewVal: (newVal: string) => void;
  }) => React.ReactNode;
  setNewVal: (newVal: string) => void;
}
interface SubcontractorModalOptions extends BaseModalOptions {
  type: ComponentFieldSelectorType.Subcontractor;
  form: (props: { onSubmit: () => void }) => React.ReactNode;
}
interface ComponentTypeModalOptions extends BaseModalOptions {
  type: ComponentFieldSelectorType.ComponentType;
  form: (props: { onSubmit: () => void }) => React.ReactNode;
}

export type ModalOptions =
  | ComponentTypeModalOptions
  | SubcontractorModalOptions
  | ObjectModalOptions;

type CreateComponentFieldModalProps = {
  isVisible: boolean;
  setNotVisible: () => any;
  modalOptions: ModalOptions;
};

export const CreateComponentFieldModal = (
  props: CreateComponentFieldModalProps,
) => {
  const { isVisible, setNotVisible, modalOptions } = props;

  const getForm = (): React.ReactNode => {
    if (modalOptions.type === "objectType") {
      return modalOptions.form({
        onSubmit: () => {
          setNotVisible();
        },
        setNewVal: (newVal: string) => {
          modalOptions.setNewVal(newVal);
        },
      });
    } else if (
      modalOptions.type === "subcontractor" ||
      modalOptions.type === "componentType"
    ) {
      return modalOptions.form({
        onSubmit: () => {
          setNotVisible();
        },
      });
    } else {
      return <div>{UNKNOW_MODAL_TYPE_TEXT_ERROR}</div>;
    }
  };

  return (
    <Modal
      title={modalOptions.title}
      visible={isVisible}
      destroyOnClose={true}
      onCancel={() => {
        setNotVisible();
      }}
      footer={false}
      data-testid={"create-component-field-modal"}
    >
      {getForm()}
    </Modal>
  );
};
