import { Modal, Select } from "antd";
import React from "react";
import { ComponentsWithAncestors } from "../ComponentsWithAncestors";
import styles from "./ConfigPlansBottomRibbon.module.css";

interface Component {
  name: string;
  id: string;
  tags: string[];
  guiIndex: number | null;
  ancestors: { name: string; guiIndex: number }[];
}

interface EditComponentTagsModalProps {
  title: string;
  actionText: string;
  allowNewTagCreation: boolean;
  visible: boolean;
  onCancel: () => void;
  onSubmit: (tags: string[]) => void;
  components: Component[];
  allComponentTags: string[];
}

export const EditComponentTagsModal = (props: EditComponentTagsModalProps) => {
  const [tags, setTags] = React.useState<string[]>([]);

  const handleChange = (value: string[]) => {
    setTags(value);
  };

  return (
    <Modal
      title={props.title}
      width="70%"
      destroyOnClose
      visible={props.visible}
      onOk={() => props.onSubmit(tags)}
      onCancel={props.onCancel}
    >
      <Select
        mode={props.allowNewTagCreation ? "tags" : "multiple"}
        className={styles["modal-select-width"]}
        onChange={handleChange}
      >
        {props.allComponentTags.map((tag) => (
          <Select.Option key={tag} value={tag}>
            {tag}
          </Select.Option>
        ))}
      </Select>
      <div className={styles["modal-box"]}>
        <div className={styles["modal-box-text"]}>
          You are <b>{props.actionText}</b> the selected tags for the following
          components:
        </div>
        <ComponentsWithAncestors components={props.components} />
      </div>
    </Modal>
  );
};
