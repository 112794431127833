import { Modal } from "antd";
import React, { useCallback, useState } from "react";
import { useNotification } from "../../contexts/Notifications";
import { Space } from "../../types";
import { DisplayTreeNode } from "../DisplayTree/types";
import { InputRow } from "../FloorplanEditor/ImportFloorplanModal/SetPointRow";
import { SpacesTree } from "../SpacesTree/SpacesTree";
import { isNodeSpace } from "../SpatialConfigurationMode/utils";
import styles from "./CopySpaceModal.module.css";

const SPACE_LABEL = "Space";
const PARENT_SPACE_LABEL = "New Parent Space";
const FLOOR_HEIGHT = "Floor Height";
const NEW_SPACE_NAME = "New Space Name";

export type SpaceWithName = Pick<Space, "id" | "name">;

interface CopySpaceModalProps {
  space: SpaceWithName;
  onCreate: (
    spaceId: string,
    parentSpaceId: string,
    newSpaceName?: string,
    newFloorHeight?: number,
  ) => void;
  onClose: () => void;
}

enum CopyStep {
  SelectParent,
  SetNewFloorHeight,
}

type CopyStepToComponent = Record<CopyStep, JSX.Element>;

export const CopySpaceModal = (props: CopySpaceModalProps) => {
  const { space, onCreate, onClose } = props;
  const notify = useNotification();
  const [parentSpace, setParentSpace] = useState<SpaceWithName>();
  const [floorHeight, setFloorHeight] = useState<number>(0);
  const [newSpaceName, setNewSpaceName] = useState<string>();
  const [copyStep, setCopyStep] = React.useState<CopyStep>(
    CopyStep.SelectParent,
  );

  const selectParentSpace = (node: DisplayTreeNode) => {
    isNodeSpace(node)
      ? setParentSpace({ id: node.key, name: node.name })
      : notify("Select a space node", "warning");
  };

  const copySpaceCallback = useCallback(
    async (newParentId: string) => {
      onCreate(space.id, newParentId, newSpaceName, floorHeight);
      onClose();
    },
    [onCreate, space.id, newSpaceName, floorHeight, onClose],
  );

  const steps: CopyStepToComponent = {
    [CopyStep.SelectParent]: (
      <React.Fragment>
        <div className={styles["space-name-box"]}>
          {`${SPACE_LABEL}: ${space.name}`}
        </div>
        <div className={styles["space-name-box"]}>
          {`${PARENT_SPACE_LABEL}: ${parentSpace?.name ?? ""}`}
        </div>
        <div className={styles["spaces-tree-box"]}>
          <SpacesTree onSelect={selectParentSpace} />
        </div>
      </React.Fragment>
    ),
    [CopyStep.SetNewFloorHeight]: (
      <React.Fragment>
        <InputRow
          name={NEW_SPACE_NAME}
          value={newSpaceName}
          placeholder="Enter a name for the new space..."
          onSet={setNewSpaceName}
          type="string"
        />
        <InputRow
          name={FLOOR_HEIGHT}
          value={floorHeight}
          onSet={setFloorHeight}
          unit={"mm"}
        />
      </React.Fragment>
    ),
  };

  const onOk = React.useCallback(() => {
    if (parentSpace === undefined) {
      notify("Select a parent space", "warning");
      return;
    }
    copyStep === CopyStep.SelectParent
      ? setCopyStep(CopyStep.SetNewFloorHeight)
      : copySpaceCallback(parentSpace?.id);
  }, [copyStep, copySpaceCallback, parentSpace, notify]);

  return (
    <Modal
      bodyStyle={{
        height: copyStep === CopyStep.SelectParent ? "80vh" : "30vh",
      }}
      closable={false}
      visible={true}
      onCancel={onClose}
      destroyOnClose={true}
      onOk={onOk}
      okButtonProps={{ disabled: !parentSpace?.id }}
      okText={copyStep === CopyStep.SelectParent ? "Next" : "Submit"}
    >
      {steps[copyStep]}
    </Modal>
  );
};
