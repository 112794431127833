import { Breadcrumb } from "antd";
import classNames from "classnames";
import _ from "lodash";
import React, { ReactNode } from "react";
import styles from "./styles.module.css";
import { ComponentInfo, RecordType } from "./types";

export const getComponentColumn = () => ({
  // First column, representing either the component name or its list of ancestors
  dataIndex: "component",
  key: "component",
  width: 400,
  fixed: "left" as const,
});

export const renderCell = (
  content: ReactNode,
  record: { type: RecordType; key: string; component: string },
  ColumnIndex: number,
  numberOfColums: number,
  renderFirstAncestorsColumn?: boolean,
): ReactNode => {
  if (record.type === RecordType.ancestors) {
    // Disable all coumns except the first 1 or 2 for rows that list the ancestors
    let colSpan = ColumnIndex === 0 ? numberOfColums : 0;
    if (renderFirstAncestorsColumn) {
      if (ColumnIndex === 0) {
        colSpan = 1;
      } else if (ColumnIndex === 1) {
        colSpan = numberOfColums - 1;
      }
    }

    return {
      children: content,
      props: {
        colSpan: colSpan,
      },
    };
  }

  // Needed when data is missing from a cell (i.e there is no mapping)
  return (
    content ?? (
      <div
        data-testid={`missing-mapping-${record.key}`}
        className={classNames(styles.mapping, styles.missing)}
      />
    )
  );
};

export const getProgressColumn = (
  title: string,
  key: string,
  idx: number,
  noOfColumns: number,
  renderFirstAncestorsColumn?: boolean,
) => ({
  title,
  width: 130,
  dataIndex: key,
  key,
  render: (content, record) =>
    renderCell(content, record, idx, noOfColumns, renderFirstAncestorsColumn),
});

export const ancestorToRow = (ancestorPath: string[]) => ({
  key: ancestorPath.join(),
  component: (
    <Breadcrumb
      separator={<span className={styles["ancestors-list"]}>{">"}</span>}
    >
      {ancestorPath.map((ancestor) => (
        <Breadcrumb.Item key={ancestor} className={styles["ancestors-list"]}>
          {ancestor}
        </Breadcrumb.Item>
      ))}
    </Breadcrumb>
  ),
  type: RecordType.ancestors,
});

export const matchesSearchTerm = (text: string, searchTerm: string) =>
  text.toLowerCase().includes(searchTerm.toLowerCase().trim());

export const sortComponents = <T extends ComponentInfo>(components: T[]): T[] =>
  // Ok, so this is a little weird.
  // JS sorting biases very heavily towards 'cast this to string'
  // Lodash does not (for scalars), so we sort by each array entry
  // in order (the sort is stable). 15 is picked (a number
  // well above the max array length) as comparing 'undefined'
  // is harmless, performance is fine & using a _.tap stage to get the
  // actual max array length is harder to read + didn't work as expected
  _.sortBy(
    components,
    _.times(15, (num) => `guiIndicesPath[${num}]`),
  );
