import { Spin } from "antd";
import React from "react";
import { useGetComponentsByTaskQuery } from "../../generated/types";
import { Project } from "../../types";
import { ComponentsTable } from "./ComponentsTable";
import { getComponentFromGraphQlResponse } from "./types";

type ContainerProps = {
  project: Project;
};

export const UnassignedComponentsTableContainer = ({
  project,
}: ContainerProps) => {
  const {
    data,
    loading,
    error,
    refetch: refetchComponents,
  } = useGetComponentsByTaskQuery({
    variables: {
      ...project,
      taskId: null,
    },
  });

  const components = data?.components ?? [];

  if (loading) {
    return <Spin />;
  }

  if (error) {
    return <div> Error fetching components </div>;
  }
  return (
    <ComponentsTable
      project={project}
      components={components.map((c) => getComponentFromGraphQlResponse(c))}
      onComponentsUpdate={refetchComponents}
      tableColumns={["name", "typeId", "tags", "object", "subcontractorId"]}
    />
  );
};
