import _ from "lodash";
import { DisplayTreeNode, DisplayTreeNodeType } from "./types";

export const ROOT_PARENT_KEY = "root";

export const findSearchHits = <T, S>(
  tree: DisplayTreeNode[],
  searchInput: T,
  objByIds: Record<string, S>,
  matches: (obj: S, searchInput: T) => boolean,
) => {
  const parentsOfMatches: string[] = [];
  const searchHits: string[] = [];
  tree.forEach((node) => {
    if (node.nodeType === DisplayTreeNodeType.Folder) {
      return { keys: [], parentKeys: [] };
    }
    const obj: S | undefined = objByIds[node.key];
    const isSearchHit = obj && matches(obj, searchInput);
    isSearchHit && searchHits.push(node.key);
    if (isSearchHit && node.parentKey !== ROOT_PARENT_KEY) {
      parentsOfMatches.push(...(node.ancestorKeys ?? []));
    }
    if (node.children) {
      const childHits = findSearchHits(
        node.children,
        searchInput,
        objByIds,
        matches,
      );
      searchHits.push(...childHits.keys);
      parentsOfMatches.push(...childHits.parentKeys);
    }
  });
  return {
    keys: _.uniq(searchHits),
    parentKeys: _.uniq(parentsOfMatches),
  };
};
