import React from "react";
import { Batch } from "../../../types";
import styles from "./BatchSelector.module.css";
import { Dropdown } from "./Dropdown";

type ReferenceBatchDropdownProps = React.PropsWithChildren<{
  batches: Batch[];
  referenceBatch: Batch;
  onChange: (referenceBatch: Batch) => void;
}>;

const ReferenceBatchDropdown = (props: ReferenceBatchDropdownProps) => (
  <Dropdown
    className={
      props.referenceBatch?.id === props.batches?.[0].id
        ? styles["default-selected-item"]
        : styles["warning-selected-item"]
    }
    defaultBatch={{
      id: props.referenceBatch.id,
      weekNumber: props.referenceBatch.weekNumber,
      timestamp: props.referenceBatch.timestamp,
    }}
    batches={props.batches}
    onChange={props.onChange}
  ></Dropdown>
);

export default ReferenceBatchDropdown;
