import { Alert, Card } from "antd";
import _ from "lodash";
import React, { useMemo } from "react";
import { Project } from "../../types";
import { DisplayTreeNode, DisplayTreeNodeType } from "../DisplayTree/types";
import { Space, nodeFolderKeyToId } from "../SpacesTree/tree-nodes";
import ConfigurationScenesBottomRibbon from "./ConfigScenesBottomRibbon";
import ConfigurationSpacesBottomRibbon from "./ConfigSpacesBottomRibbon";
import styles from "./ConfigurationBottomRibbons.module.css";
import { ConfigurationRootSpacesBottomRibbon } from "./ConfigurationRootSpacesBottomRibbon";

type SelectedNodeTypes = "spaces" | "scenes" | "multiple" | "none";
interface ConfigSpacesTreeBottomRibbonProps {
  project: Project;
  selectedNodes: DisplayTreeNode[];
  spacesByIds: Record<string, Space>;
  onNodesDeleted: () => void;
}

const getSelectedNodeTypes = (nodes: DisplayTreeNode[]): SelectedNodeTypes => {
  const selectedNodeTypes = _(nodes)
    .map((node) => node.nodeType)
    .uniq()
    .value();
  if (selectedNodeTypes.length === 0) {
    return "none";
  }
  if (selectedNodeTypes.length > 1) {
    return "multiple";
  }
  if (selectedNodeTypes[0] === "space") {
    return "spaces";
  }
  if (selectedNodeTypes[0] === "scene") {
    return "scenes";
  }
  return "multiple";
};

const ConfigurationSpacesTreeBottomRibbon = (
  props: ConfigSpacesTreeBottomRibbonProps,
) => {
  const { project, selectedNodes, spacesByIds, onNodesDeleted } = props;

  const selectedSpaces = useMemo(
    () =>
      selectedNodes
        .filter((node) => node.nodeType === DisplayTreeNodeType.Space)
        .map((n) => spacesByIds[n.key]),
    [selectedNodes, spacesByIds],
  );

  const selectedScenes = useMemo(
    () =>
      selectedNodes
        .filter((node) => node.nodeType === DisplayTreeNodeType.Scene)
        .map((n) => {
          const allScenesOnParentSpace =
            spacesByIds[nodeFolderKeyToId(n.parentKey, "scenes")].scenes;
          const selectedScene = allScenesOnParentSpace.filter(
            (s) => s.id === n.key,
          )[0];
          return selectedScene;
        }),
    [selectedNodes, spacesByIds],
  );

  switch (getSelectedNodeTypes(selectedNodes)) {
    case "spaces":
      return (
        <ConfigurationSpacesBottomRibbon
          project={project}
          selectedSpaces={selectedSpaces}
          onNodesDeleted={onNodesDeleted}
        />
      );
    case "scenes":
      return (
        <ConfigurationScenesBottomRibbon
          project={project}
          selectedScenes={selectedScenes}
          onNodesDeleted={onNodesDeleted}
        />
      );
    case "none":
      return <ConfigurationRootSpacesBottomRibbon project={project} />;
    default:
      return <ErrorConfigurationBottomRibbon />;
  }
};
export default ConfigurationSpacesTreeBottomRibbon;

const ErrorConfigurationBottomRibbon = () => {
  return (
    <Card className={styles["bottom-ribbon"]}>
      <Alert
        message="Please select either only spaces or only scenes."
        type="error"
        showIcon
      />
    </Card>
  );
};
