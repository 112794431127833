import { Button, Card, Col, Row, Space, Spin } from "antd";
import React from "react";
import { useNotification } from "../../contexts/Notifications";
import { useGetSpacesAndComponentsDataQuery } from "../../generated/types";
import { Project } from "../../types";
import { DisplayTreeNode, DisplayTreeNodeType } from "../DisplayTree/types";
import { AddMappingsButton } from "./AddMappingsButton";
import styles from "./ConfigPlansBottomRibbon.module.css";
import { DeleteMappingsButton } from "./DeleteMappingsButton";
import { EditComponentTagsContainer } from "./EditComponentTagsContainer";
import { EnableDisableMappingsButton } from "./EnableDisableMappingsButton";

import { SPACE_INFO_LOADING_ERROR } from "./messages";

interface ConfigPlansBottomRibbonProps {
  project: Project;
  planId: string | undefined;
  checkedSpacesTreeNodes: DisplayTreeNode[];
  checkedComponents: string[];
  onUpdate: (keys: string[]) => void;
}

export const ConfigurationPlansBottomRibbon = (
  props: ConfigPlansBottomRibbonProps,
) => {
  const {
    project,
    planId,
    checkedSpacesTreeNodes,
    checkedComponents,
    onUpdate,
  } = props;

  const preventMappingAction =
    checkedComponents.length === 0 ||
    checkedSpacesTreeNodes.length === 0 ||
    !checkedSpacesTreeNodes.every(
      (node) => node.nodeType === DisplayTreeNodeType.Space,
    );

  const {
    data: nodesData,
    error,
    loading,
  } = useGetSpacesAndComponentsDataQuery({
    skip: preventMappingAction,
    variables: {
      ...project,
      spaceIds: checkedSpacesTreeNodes.map((space) => space.key),
      componentIds: checkedComponents,
    },
  });

  const notify = useNotification();
  if (loading) {
    return <Spin />;
  }
  if (error) {
    notify(`${SPACE_INFO_LOADING_ERROR} - ${error}`, "error");
  }

  return (
    <Card className={styles["bottom-ribbon"]}>
      <Row gutter={8}>
        <Col span={22}>
          <Space
            direction={"vertical"}
            className={styles["mapping-action-box"]}
          >
            <Row className={styles["buttons-section"]}>
              <Col span={6}>
                <AddMappingsButton
                  project={project}
                  selectedComponents={nodesData?.componentsByIds ?? []}
                  selectedSpaces={nodesData?.spacesByIds ?? []}
                  disabled={preventMappingAction}
                  onUpdate={onUpdate}
                />
              </Col>
              <Col span={6}>
                <EnableDisableMappingsButton
                  project={project}
                  selectedComponents={nodesData?.componentsByIds ?? []}
                  selectedSpaces={nodesData?.spacesByIds ?? []}
                  updateAction="enable"
                  onUpdate={onUpdate}
                />
              </Col>
              <Col span={6}>
                <EnableDisableMappingsButton
                  project={project}
                  selectedComponents={nodesData?.componentsByIds ?? []}
                  selectedSpaces={nodesData?.spacesByIds ?? []}
                  updateAction="disable"
                  onUpdate={onUpdate}
                />
              </Col>
              <Col span={6}>
                <DeleteMappingsButton
                  project={project}
                  selectedComponents={nodesData?.componentsByIds ?? []}
                  selectedSpaces={nodesData?.spacesByIds ?? []}
                  onUpdate={onUpdate}
                />
              </Col>
            </Row>
            <Row>
              <Col span={24}>
                <Button
                  block
                  className={styles["arrow-left"]}
                  type="primary"
                  disabled={true}
                >
                  Apply mapping action
                </Button>
              </Col>
            </Row>
          </Space>
        </Col>
        <Col span={2}>
          <Space className={styles.tags} direction="vertical">
            <EditComponentTagsContainer
              selectedComponents={checkedComponents}
              project={project}
              planId={planId}
            />
          </Space>
        </Col>
      </Row>
    </Card>
  );
};
