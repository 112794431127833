import { Form, Select } from "antd";
import _ from "lodash";
import React, { useCallback, useEffect, useRef, useState } from "react";
import { Spotlight } from "./types";

// these types might be not quite what we get from the backend
// for example type might be an enum, or new a boolean, but keeping
// them all as strings makes the filtering magic work in a more reusable way
export type SpotlightForFilter = {
  id: string;
  subcontractor: string;
  component: string;
  type: string;
  new: "new" | "old";
};

const { Option } = Select;

type FilterSelectProps = {
  spotlights: SpotlightForFilter[];
  fieldName: "subcontractor" | "component" | "type" | "new";
  placeHolder: string;
  onChange: (values: string[]) => void;
  multiple?: boolean;
};

const FilterSelect = (props: FilterSelectProps) => {
  return (
    <Form.Item>
      <Select
        mode={props.multiple ? "multiple" : undefined}
        key={props.fieldName}
        allowClear
        showArrow
        style={{ width: "100%" }}
        placeholder={props.placeHolder}
        defaultValue={[]}
        // if this is not a multiple select then the value can be undefined
        // so to keep a consistent interface it is turned into an empty list
        onChange={(val) => props.onChange(val ? val : [])}
        data-testid={`select-${props.fieldName}`}
      >
        {_(props.spotlights)
          .map((sp) => sp[props.fieldName])
          .uniq()
          .map((val) => (
            <Option key={val} value={val}>
              {val}
            </Option>
          ))
          .value()}
      </Select>
    </Form.Item>
  );
};

// a Spotlight matches a filter field if the spotlights
// value for that field is in the set of values of the filter
// if the filter set is empty then it doesn't get applied
export type SpotlightFilter = {
  subcontractor: string[];
  componentType: string[];
  type: string[];
  new: string[];
};

const emptyFilter = {
  subcontractor: [],
  componentType: [],
  type: [],
  new: [],
};

export const applyFilterOnSpotlight = (
  spotlight: SpotlightForFilter,
  filters: SpotlightFilter,
  skipped: string[],
): boolean => {
  // apply all the filters on a single spotlight for all fields that are not skipped
  return Object.entries(filters).every(
    ([fieldname, filterValues]) =>
      skipped.includes(fieldname) ||
      filterValues.length === 0 ||
      filterValues.includes(spotlight[fieldname]),
  );
};

export const applyFilters = (
  spotlights: SpotlightForFilter[],
  filters: SpotlightFilter,
  skippedFilters: string[] = [],
): SpotlightForFilter[] => {
  return spotlights.filter((spotlight) =>
    applyFilterOnSpotlight(spotlight, filters, skippedFilters),
  );
};

function setsAreEqual<Typ>(set1: Set<Typ>, set2: Set<Typ>): boolean {
  if (set1.size !== set2.size) {
    return false;
  }
  return Array.from(set1).every((elem) => set2.has(elem));
}

const spotlightsHaveChanged = (spotlights, oldSpotlights) => {
  const newFiltered = new Set(spotlights.map((sp) => sp.id));
  const oldFiltered = new Set(oldSpotlights.map((sp) => sp.id));
  return !setsAreEqual(newFiltered, oldFiltered);
};

const convertSpotlight = (
  spotlight: Spotlight,
  batchId: string,
): SpotlightForFilter => ({
  id: spotlight.id,
  type: spotlight.type,
  subcontractor: spotlight.mapping?.subcontractor || "N/A",
  component: spotlight.mapping?.componentName || "N/A",
  new: spotlight.createdInBatchId === batchId ? "new" : "old",
});

// a wrapper for the actual tSpotlightFilter Component
// it exposes an interface with a more generic spotlight type
// and translates it to  one that is useful for filtering
//(with only the fields needed for filtering, and all of them turned into strings)
type SpotlightFiltersProps = {
  spotlights: Spotlight[];
  batchId: string;
  onChange: (filteredIds: string[]) => void;
};

export const SpotlightFilters = (props: SpotlightFiltersProps) => (
  <SpotlightFiltersInternal
    spotlights={props.spotlights.map((s) => convertSpotlight(s, props.batchId))}
    onChange={(spotlights) => {
      const filteredIds = spotlights.map((spot) => spot.id);
      props.onChange(filteredIds);
    }}
  />
);

type InternalProps = {
  spotlights: SpotlightForFilter[];
  onChange: (filtered: SpotlightForFilter[]) => void;
};

const SpotlightFiltersInternal = ({ spotlights, onChange }: InternalProps) => {
  const [filters, setFilters] = useState(emptyFilter);
  const filteredSpotlights = useRef(spotlights);

  const updateFilters = useCallback((field, value) => {
    setFilters((oldFilters) => {
      if (oldFilters[field] === value) {
        return oldFilters;
      } else {
        return { ...oldFilters, [field]: value };
      }
    });
  }, []);

  useEffect(() => {
    const newFiltered = applyFilters(spotlights, filters);
    // only trigger onChange if the filters have changed in a way that leads
    // to a new output, otherwise we get an infinite rendering loop
    if (spotlightsHaveChanged(newFiltered, filteredSpotlights.current)) {
      filteredSpotlights.current = newFiltered;
      onChange(newFiltered);
    }
  }, [filters, filteredSpotlights, spotlights, onChange]);

  return (
    <Form layout="vertical" role="form">
      <FilterSelect
        key={"subcontractor"}
        spotlights={applyFilters(spotlights, filters, ["subcontractor"])}
        multiple
        fieldName="subcontractor"
        placeHolder="Select subcontractor ..."
        onChange={(val) => updateFilters("subcontractor", val)}
      />
      <FilterSelect
        key={"component"}
        spotlights={applyFilters(spotlights, filters, ["component"])}
        multiple
        fieldName="component"
        placeHolder="Select component type ..."
        onChange={(val) => updateFilters("component", val)}
      />
      <FilterSelect
        key={"type"}
        spotlights={applyFilters(spotlights, filters, ["type"])}
        multiple
        fieldName="type"
        placeHolder="Select issue type ..."
        onChange={(val) => updateFilters("type", val)}
      />
      <FilterSelect
        key={"new"}
        spotlights={applyFilters(spotlights, filters, ["new"])}
        fieldName="new"
        placeHolder="Select new/old ..."
        onChange={(val) => updateFilters("new", val)}
      />
    </Form>
  );
};
