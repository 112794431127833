import { Modal, Spin } from "antd";
import React, { useCallback, useState } from "react";
import { FloorplanByIdQuery } from "../../generated/types";
import {
  BIM_ORIGIN_X_TOOLTIP_TEXT,
  BIM_ORIGIN_Y_TOOLTIP_TEXT,
  BIM_ORIGIN_Z_TOOLTIP_TEXT,
  FLOOR_HEIGHT_TOOLTIP_TEXT,
  TRUE_NORTH_TOOLTIP_TEXT,
} from "./ImportFloorplanModal/ImportFloorplanModal";
import { InputRow } from "./ImportFloorplanModal/SetPointRow";
import { FloorplanEditPropertiesInput } from "./hooks/useFloorplan";

const BIM_ORIGIN_X = "BIM Origin X";
const BIM_ORIGIN_Y = "BIM Origin Y";
const BIM_ORIGIN_Z = "BIM Origin Z";
const FLOOR_HEIGHT = "Floor Height";
const ANGLE_TO_TRUE_NORTH = "Angle to True North";

interface EditPropertiesModalProps {
  floorplan: FloorplanByIdQuery["floorplans"][0];
  onClose: () => void;
  editFloorplanProperties: (
    floorplan: Omit<FloorplanEditPropertiesInput, "id">,
  ) => Promise<void>;
  spaceName: string;
  uploadInProgress: boolean;
}

export const EditPropertiesModal = (props: EditPropertiesModalProps) => {
  const {
    onClose,
    editFloorplanProperties,
    spaceName,
    uploadInProgress,
    floorplan,
  } = props;

  const {
    bimOriginX: currentBimOriginX,
    bimOriginY: currentBimOriginY,
    bimOriginZ: currentBimOriginZ,
    floorHeight: currentFloorHeight,
    angleToTrueNorth: currentAngleToTrueNorth,
  } = floorplan;
  const [bimOriginX, setBimOriginX] = useState<number | undefined>(
    currentBimOriginX ?? undefined,
  );
  const [bimOriginY, setBimOriginY] = useState<number | undefined>(
    currentBimOriginY ?? undefined,
  );
  const [bimOriginZ, setBimOriginZ] = useState<number | undefined>(
    currentBimOriginZ ?? undefined,
  );
  const [floorHeight, setFloorHeight] = useState<number | undefined>(
    currentFloorHeight ?? undefined,
  );
  const [angleToTrueNorth, setAngleToTrueNorth] = useState<number>(
    currentAngleToTrueNorth ?? 0,
  );

  const onConfirm = useCallback(() => {
    const editedFloorplan = {
      bimOriginX,
      bimOriginY,
      bimOriginZ:
        bimOriginZ === undefined &&
        bimOriginY !== undefined &&
        bimOriginX !== undefined
          ? 0
          : bimOriginZ,
      floorHeight,
      angleToTrueNorth,
    };
    editFloorplanProperties(editedFloorplan);
  }, [
    bimOriginX,
    bimOriginY,
    bimOriginZ,
    floorHeight,
    angleToTrueNorth,
    editFloorplanProperties,
  ]);

  return (
    <Modal
      bodyStyle={{ height: "45vh", overflowY: "auto" }}
      width={"30vw"}
      title={`Edit floorplan for ${spaceName}`}
      closable={false}
      visible={true}
      onCancel={onClose}
      destroyOnClose={true}
      onOk={onConfirm}
      okText={"Confirm"}
    >
      <Spin spinning={uploadInProgress} tip="Upload in progress...">
        <InputRow
          name={BIM_ORIGIN_X}
          value={bimOriginX}
          onSet={setBimOriginX}
          unit={"mm"}
          tooltipText={BIM_ORIGIN_X_TOOLTIP_TEXT}
        />
        <InputRow
          name={BIM_ORIGIN_Y}
          value={bimOriginY}
          onSet={setBimOriginY}
          unit={"mm"}
          tooltipText={BIM_ORIGIN_Y_TOOLTIP_TEXT}
        />
        <InputRow
          name={BIM_ORIGIN_Z}
          value={bimOriginZ}
          onSet={setBimOriginZ}
          unit={"mm"}
          tooltipText={BIM_ORIGIN_Z_TOOLTIP_TEXT}
        />
        <InputRow
          name={FLOOR_HEIGHT}
          value={floorHeight}
          onSet={setFloorHeight}
          unit={"mm"}
          tooltipText={FLOOR_HEIGHT_TOOLTIP_TEXT}
        />
        <InputRow
          name={ANGLE_TO_TRUE_NORTH}
          value={angleToTrueNorth}
          onSet={setAngleToTrueNorth}
          unit={"degrees"}
          tooltipText={TRUE_NORTH_TOOLTIP_TEXT}
        />
      </Spin>
    </Modal>
  );
};
