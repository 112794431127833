import { CameraOutlined, ShareAltOutlined } from "@ant-design/icons";
import classNames from "classnames";
import _ from "lodash";
import React, { ReactNode } from "react";
import { SpaceWithScenesAndParentFragment } from "../../generated/types";
import { ComposedLabel } from "../ConfigurationLabelComposed";
import { CopyToClipboardButton } from "../CopyToClipboardButton";
import { DeleteShotButton } from "../DeleteShotButton";
import { ROOT_PARENT_KEY } from "../DisplayTree/search-utils";
import { DisplayTreeNode, DisplayTreeNodeType } from "../DisplayTree/types";
import { DraggableItem } from "../DragAndDrop/DraggableItem";
import { sortByGuiIndex } from "../PlansTree/task-tree-utils";
import styles from "./SpacesTree.module.css";

export const MAPPINGS_FOLDER_NAME = "Mappings";
export const MAPPINGS_KEY = "mappings";
export const SCENES_FOLDER_NAME = "Scenes";
const SCENES_KEY = "scenes";
const FOLDER_KEY_SEPARATOR = "-";

const HOIST_TAG_NAME = "hoist";

export const generateFolderName = (
  id: string,
  folderKey: "scenes" | "mappings",
): string => id + FOLDER_KEY_SEPARATOR + folderKey;

export const find = (
  treeData: DisplayTreeNode[],
  key: string,
): DisplayTreeNode | undefined => {
  for (const node of treeData) {
    if (node.key === key) {
      return node;
    }
    if (node.children != null) {
      const found = find(node.children, key);
      if (found) {
        return found;
      }
    }
  }
  return undefined;
};

export const update = (
  node: DisplayTreeNode,
  targetKey: string,
  updateFn: (node: DisplayTreeNode) => DisplayTreeNode,
) => {
  if (node.key === targetKey) {
    return updateFn(node);
  } else if (node.children != null) {
    return {
      ...node,
      children: node.children.map((child) =>
        update(child, targetKey, updateFn),
      ),
    };
  } else {
    return node;
  }
};

// The only place where things need to be checkable for now is the configuration spaces tree.
// If this is ever not the case then we can expose this as an extra prop
const CHECKABLE_NODES = new Set([
  DisplayTreeNodeType.Space,
  DisplayTreeNodeType.Scene,
]);

type Mapping = {
  id: string;
  disabled: boolean;
  component: {
    id: string;
    name: string;
  };
};

export type Scene = {
  id: string;
  name: string;
  guiIndex: number;
  spaceId: string;
};

export type Space = {
  id: string;
  parentId: string;
  name: string;
  guiIndex: number;
  tags: string[];
  scenes: Scene[];
  type: string | undefined;
  category: string | undefined;
  mappings?: Mapping[];
  childSpaces: SpaceWithoutAncestorsAndChildSpaces[];
  ancestors: SpaceWithoutAncestorsAndChildSpaces[];
};

type SpaceWithoutAncestorsAndChildSpaces = Omit<
  Space,
  "ancestors" | "childSpaces"
>;

export const getAncestors = (
  space: Omit<Space, "ancestors">,
  spacesByIds: Record<string, Omit<Space, "ancestors">>,
): SpaceWithoutAncestorsAndChildSpaces[] => {
  let parent = spacesByIds[space.parentId];
  const ancestors: SpaceWithoutAncestorsAndChildSpaces[] = [];
  while (parent) {
    ancestors.push(parent);
    parent = spacesByIds[parent.parentId];
  }
  return ancestors;
};

const getParentIdToChildSpaces = (
  spaces: SpaceWithScenesAndParentFragment[],
): Record<string, SpaceWithoutAncestorsAndChildSpaces[]> => {
  const parentIdToChildSpaces: Record<
    string,
    SpaceWithoutAncestorsAndChildSpaces[]
  > = {};
  spaces.forEach((space) => {
    const parentId = space.parentSpace?.id ?? ROOT_PARENT_KEY;
    parentIdToChildSpaces[parentId] = [
      ...(parentIdToChildSpaces[parentId] ?? []),
      {
        id: space.id,
        parentId: parentId,
        guiIndex: space.guiIndex,
        name: space.name,
        scenes: space.scenes.map((s) => ({ ...s, spaceId: space.id })),
        tags: space.tags ?? [],
        type: space.type ?? undefined,
        category: space.category ?? undefined,
      },
    ];
  });
  return parentIdToChildSpaces;
};

export const getSpacesByIds = (
  spaces: SpaceWithScenesAndParentFragment[],
): Record<string, Space> => {
  const spacesByIds: Record<string, Space> = {};
  const parentIdToChildSpaces = getParentIdToChildSpaces(spaces);
  spaces.forEach((space) => {
    spacesByIds[space.id] = {
      id: space.id,
      parentId: space.parentSpace?.id ?? ROOT_PARENT_KEY,
      guiIndex: space.guiIndex,
      name: space.name,
      scenes: space.scenes.map((s) => ({ ...s, spaceId: space.id })),
      tags: space.tags ?? [],
      type: space.type ?? undefined,
      category: space.category ?? undefined,
      childSpaces: parentIdToChildSpaces[space.id] ?? [],
      ancestors: [],
    };
  });
  for (const spaceId in spacesByIds) {
    const ancestors = getAncestors(spacesByIds[spaceId], spacesByIds);
    spacesByIds[spaceId] = { ...spacesByIds[spaceId], ancestors: ancestors };
  }

  return spacesByIds;
};

const getMappingFolderDisplayTreeNode = (spaceId: string): DisplayTreeNode => {
  const folderKey = generateFolderName(spaceId, MAPPINGS_KEY);
  return {
    nodeType: DisplayTreeNodeType.Folder,
    loadAsync: true,
    name: MAPPINGS_FOLDER_NAME,
    checkable: false,
    title: (
      <>
        <ShareAltOutlined /> {MAPPINGS_FOLDER_NAME}
      </>
    ),
    key: folderKey,
    parentKey: spaceId,
  };
};

export const transformMappingsToDisplayTreeNodes = (
  mappings: Mapping[],
  spaceId: string,
): DisplayTreeNode[] =>
  _.sortBy(mappings, (m) => m.component.name).map((mapping) => ({
    nodeType: DisplayTreeNodeType.Mapping,
    isLeaf: true,
    key: mapping.id,
    disabled: mapping.disabled,
    name: mapping.component.name,
    checkable: false,
    title: mapping.component.name,
    parentKey: generateFolderName(spaceId, MAPPINGS_KEY),
  }));

const transformSceneToDisplayTreeNode = (
  scenes: Scene[],
  spaceId: string,
  ancestors: string[],
  ancestorNames: string[],
  checkable?: boolean,
  showShots?: boolean,
  draggable?: boolean,
): DisplayTreeNode => {
  const folderKey = generateFolderName(spaceId, SCENES_KEY);
  return {
    nodeType: DisplayTreeNodeType.Folder,
    name: SCENES_FOLDER_NAME,
    checkable: false,
    title: (
      <>
        <CameraOutlined /> {SCENES_FOLDER_NAME}
      </>
    ),
    key: folderKey,
    parentKey: spaceId,
    ancestorKeys: ancestors,
    ancestorNames: ancestorNames,
    children: _.sortBy(scenes, ["guiIndex"]).map((s) => {
      return {
        nodeType: DisplayTreeNodeType.Scene,
        title: draggable ? (
          <DraggableItem item={{ id: s.id }} itemType={"scene"}>
            {s.name}
          </DraggableItem>
        ) : (
          <i>{s.name}</i>
        ),
        checkable: CHECKABLE_NODES.has(DisplayTreeNodeType.Scene),
        name: s.name,
        key: s.id,
        checked: checkable,
        parentKey: folderKey,
        isLeaf: !showShots,
        loadAsync: showShots,
        ancestorKeys: ancestors,
        ancestorNames: ancestorNames,
      };
    }),
  };
};

export const buildSpacesTree = (
  spaces: SpaceWithoutAncestorsAndChildSpaces[],
  spacesByIds: Record<string, Space>,
  ancestorIds: string[] = [ROOT_PARENT_KEY],
  ancestorNames: string[],
  showMappings = false,
  showScenes = false,
  showShots = false,
  version: number,
  checkable?: boolean,
  showTags?: boolean,
  showMetadata?: boolean,
  draggableScenes?: boolean,
): DisplayTreeNode[] => {
  return sortByGuiIndex(spaces).map((space) => {
    const childSpaces = spacesByIds[space.id].childSpaces ?? [];

    let children: DisplayTreeNode[] = [];
    if (childSpaces.length) {
      children = buildSpacesTree(
        childSpaces,
        spacesByIds,
        [...ancestorIds, space.id],
        [...ancestorNames, space.name],
        showMappings,
        showScenes,
        showShots,
        version,
        checkable,
        showTags,
        showMetadata,
        draggableScenes,
      );
    }

    if (showMappings) {
      children.unshift(getMappingFolderDisplayTreeNode(space.id));
    }
    if (space.scenes.length > 0 && showScenes) {
      children.unshift(
        transformSceneToDisplayTreeNode(
          space.scenes,
          space.id,
          [...ancestorIds, space.id],
          [...ancestorNames, space.name],
          checkable,
          showShots,
          draggableScenes,
        ),
      );
    }

    return {
      nodeType: DisplayTreeNodeType.Space,
      key: space.id,
      name: space.name,
      parentKey: space.parentId,
      ancestorKeys: ancestorIds,
      ancestorNames: ancestorNames,
      children: children,
      version: version,
      checkable: checkable,
      title: generateSpaceTreeTitle(
        space,
        showTags ? space.tags : [],
        showMetadata ? [space.category, space.type] : [],
        space.tags.includes(HOIST_TAG_NAME),
      ),
    };
  });
};

const generateSpaceTreeTitle = (
  { id, name }: Pick<Space, "id" | "name">,
  tags: string[],
  metaData: (string | undefined)[],
  hoist: boolean,
) => {
  const peripheryTexts = [...metaData, ...tags];
  return (
    <span className={styles["node-title"]}>
      <ComposedLabel
        focusedText={name}
        peripheryTexts={peripheryTexts.filter((x) => !_.isNil(x)) as string[]}
        hoist={hoist}
      />
      <CopyToClipboardButton text={id} />
    </span>
  );
};

export const nodeFolderKeyToId = (
  sceneKey: string,
  folderKey: "mappings" | "scenes" | "shots",
) => sceneKey.split(FOLDER_KEY_SEPARATOR + folderKey)[0];

export const updateTree = (
  tree: DisplayTreeNode[],
  key: string,
  newChildren: DisplayTreeNode[],
): DisplayTreeNode[] =>
  tree.map((node) => {
    if (node.key === key) {
      return {
        ...node,
        children: newChildren,
      };
    }
    if (node.children) {
      return {
        ...node,
        children: updateTree(node.children, key, newChildren),
      };
    }
    return node;
  });

export type Shot = {
  id: string;
  batch: {
    id: string;
    weekNumber: number;
    timestamp: string;
  };
};

export const transformShotsToDisplayTreeNode = (
  shots: Shot[],
  sceneId: string,
  sceneName: string,
  fetch: (props: any) => any,
): DisplayTreeNode[] =>
  _.sortBy(shots, (s: Shot) => s.batch.timestamp)
    .reverse()
    .map((s) => ({
      nodeType: DisplayTreeNodeType.Shot,
      name: s.batch.timestamp,
      title: (
        <span>
          {s.batch.timestamp.slice(0, 10)}
          <DeleteShotButton
            shotId={s.id}
            sceneName={sceneName}
            batch={s.batch}
            refetchShots={fetch}
            sceneId={sceneId}
          />
        </span>
      ),
      key: s.id,
      parentKey: sceneId,
      checkable: false,
      children: [],
      isLeaf: true,
    }));

// TODO: To use in follow up PR
type RenderFunction = (
  space: DisplayTreeNode,
  index: number,
  showTags?: boolean,
) => React.ReactNode | { title: React.ReactNode; icon: React.ReactNode };

export const defaultRenderFn: RenderFunction = (
  node: DisplayTreeNode,
): ReactNode | { title: ReactNode; icon?: ReactNode } => {
  if (node.nodeType === DisplayTreeNodeType.Scene) {
    return <i>{node.name}</i>;
  }
  if (node.nodeType === DisplayTreeNodeType.Mapping) {
    return (
      <i
        className={classNames({
          [styles["disabled-mapping"]]: node.disabled,
        })}
      >
        {node.name}
      </i>
    );
  } else if (node.nodeType === DisplayTreeNodeType.Folder) {
    switch (node.name) {
      case SCENES_FOLDER_NAME:
        return {
          title: node.name,
          icon: <CameraOutlined />,
        };
      case MAPPINGS_FOLDER_NAME:
        return {
          title: node.name,
          icon: <ShareAltOutlined />,
        };
    }
  }
  return node.name;
};

export const deleteNodes = (tree: DisplayTreeNode[], deletedIds: string[]) =>
  tree
    .filter((node) => !deletedIds.includes(node.key))
    .map((filteredNode) =>
      filteredNode.children
        ? {
            ...filteredNode,
            children: deleteNodes(filteredNode.children, deletedIds),
          }
        : filteredNode,
    );
