import { Switch } from "antd";
import classNames from "classnames";
import React, { useState } from "react";
import { Project } from "../../../types";
import styles from "../styles.module.css";
import { AddMappingModal } from "./AddMappingModal";
import { RemoveMappingModal } from "./RemoveMappingModal";

export const MappingCell = (props: {
  project: Project;
  disabled?: boolean;
  missing: boolean;
  space: { id: string; name: string };
  component: { id: string; name: string };
  weight?: number;
  mappingId?: string;
  onDisableMapping?: (id: string, disabled: boolean) => Promise<any>;
}) => {
  const [disableMappingLoading, setDisableMappingLoading] = useState(false);
  const onChangeDisabledMapping = async (checked: boolean) => {
    setDisableMappingLoading(true);
    // checked is true when mapping is enabled
    if (props.mappingId && props.onDisableMapping) {
      await props.onDisableMapping(props.mappingId, !checked);
    }
    setDisableMappingLoading(false);
  };

  return (
    <>
      <div
        data-testid={
          props.mappingId
            ? `mapping-${props.component.id}-${props.space.id}`
            : `missing-mapping-${props.component.id}`
        }
        className={classNames(styles.mapping, {
          [styles.disabled]: props.disabled,
          [styles.missing]: !props.mappingId,
        })}
      >
        {props.mappingId ? (
          <>
            <span>{props.weight?.toFixed(1) ?? "-"}</span>
            <Switch
              className={classNames(styles["disable-mapping-switch"], {
                [styles.disabled]: props.disabled,
                [styles.enabled]: !props.disabled,
              })}
              checked={!props.disabled}
              loading={disableMappingLoading}
              onChange={onChangeDisabledMapping}
            />
            <RemoveMappingModal
              space={props.space}
              component={props.component}
              mappingId={props.mappingId}
              project={props.project}
            />
          </>
        ) : (
          <AddMappingModal
            space={props.space}
            component={props.component}
            project={props.project}
          />
        )}
      </div>
    </>
  );
};
