import { Button, Input, InputNumber, InputProps } from "antd";
import React, { useEffect, useState } from "react";
import styles from "./styles.module.css";

const DEFAULT_CONFIRM_BUTTON_TXT = "Confirm";

type Props<T> = Pick<
  InputProps,
  "className" | "bordered" | "allowClear" | "disabled"
> & {
  onConfirm: (value: T) => Promise<void>;
  value: T;
  confirmButtonText?: string;
  confirmButtonPositionLeft?: boolean;
  className?: string;
  bordered?: boolean;
  min?: T extends number ? number : never;
};

export const InputWithAsyncConfirm = <T extends string | number>(
  props: Props<T>,
) => {
  const {
    onConfirm,
    value,
    confirmButtonText = DEFAULT_CONFIRM_BUTTON_TXT,
    confirmButtonPositionLeft = false,
    disabled,
    min = 0,
    ...inputProps
  } = props;

  const [isUpdating, setIsUpdating] = useState(false);
  // newValue is null if typeof value is number and you clear the input
  const [newValue, setNewValue] = useState<T | null>();

  useEffect(() => {
    setNewValue(value);
  }, [value]);

  const isNumberInput = typeof value === "number";

  const showConfirm =
    newValue !== undefined &&
    newValue !== null &&
    newValue !== value &&
    (isNumberInput
      ? (newValue as number) >= min
      : (newValue as string).length > 0);

  const handleOnConfirm = async () => {
    setIsUpdating(true);
    showConfirm && newValue && (await onConfirm(newValue));
    setIsUpdating(false);
  };

  const input = isNumberInput ? (
    <InputNumber
      {...inputProps}
      style={{ width: "100%" }} // Set inline due to antd weirdness.
      value={newValue ?? undefined}
      disabled={disabled}
      onChange={(val: T | null) => setNewValue(val)}
      onPressEnter={handleOnConfirm}
    />
  ) : (
    <Input
      {...inputProps}
      style={{ width: "100%" }} // Set inline due to antd weirdness.
      disabled={isUpdating || disabled}
      value={newValue || undefined}
      onChange={(e) => setNewValue(e.target.value as T)} // TODO: any way to avoid casting here
      onPressEnter={handleOnConfirm}
    />
  );

  const confirmButton = (
    <Button loading={isUpdating} onClick={handleOnConfirm} type={"primary"}>
      {confirmButtonText}
    </Button>
  );

  return (
    <div className={styles["input-container"]}>
      {confirmButtonPositionLeft && showConfirm && confirmButton}
      {input}
      {!confirmButtonPositionLeft && showConfirm && confirmButton}
    </div>
  );
};
