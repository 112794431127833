import { Button, Tooltip } from "antd";
import classNames from "classnames";
import React from "react";
import { SimpleCircleNode } from "../../DrawingTool/types";
import { InputMode } from "./ImportFloorplanModal";
import styles from "./ImportFloorplanModal.module.css";

const SET_BUTTON_TEXT = "SET";

interface SetPointRowProps {
  value?: SimpleCircleNode;
  tooltipText?: string;
  onSet: (inputMode: InputMode) => void;
  inputMode: InputMode;
  activeInputMode: InputMode | undefined;
}

export const SetPointRow = (props: SetPointRowProps) => {
  const { value, tooltipText, onSet, inputMode, activeInputMode } = props;

  const x = value && value.x.toFixed(2);
  const y = value && value.y.toFixed(2);

  return (
    <Tooltip mouseEnterDelay={1} title={tooltipText}>
      <div className={styles["row-box"]}>
        <div className={styles["row-name"]}>{inputMode}</div>
        <div
          className={classNames(styles["row-value"], {
            [styles["value-highlight"]]: activeInputMode === inputMode,
          })}
        >
          {value && `(${x}, ${y})`}
        </div>
        <Button
          className={styles["row-button"]}
          onClick={() => {
            onSet(inputMode);
          }}
        >
          {SET_BUTTON_TEXT}
        </Button>
      </div>
    </Tooltip>
  );
};

interface InputRowProps<T> {
  name: string;
  onSet: (value: T) => void;
  tooltipText?: string;
  placeholder?: string;
  value?: T | undefined;
  unit?: string;
  type?: "number" | "string";
}

export const InputRow = <T extends string | number>(
  props: InputRowProps<T>,
) => {
  const {
    name,
    value,
    tooltipText,
    onSet,
    unit,
    placeholder,
    type = "number",
  } = props;

  const handleOnSet = (input: string) => {
    if (type === "number") {
      onSet(parseFloat(input) as T);
    } else {
      onSet(input as T);
    }
  };

  return (
    <Tooltip mouseEnterDelay={1} title={tooltipText}>
      <div className={styles["row-box"]}>
        <div className={styles["row-name"]}>{name}</div>
        <div className={styles["row-value-item"]}>
          <input
            type={type}
            className={styles["row-value"]}
            value={value}
            placeholder={placeholder}
            onChange={(e) => handleOnSet(e.target.value)}
          />
          {unit && <div className={styles["row-item"]}>{unit}</div>}
        </div>
      </div>
    </Tooltip>
  );
};
