import { SisternodeOutlined } from "@ant-design/icons";
import { Button, Tooltip } from "antd";
import React from "react";

interface CopySpaceUnderSameParentButtonProps {
  isNodeSpaceWithoutFloorplan: boolean;
  size: "large";
  type: "text";
  onClick: () => void;
}

const SHOW_CREATE_TITLE = "Copy space under the same parent";
const SHOW_HELP_TITLE =
  "You need to select a single space to copy under the same parent";

export const CopySpaceUnderParentButton = (
  props: CopySpaceUnderSameParentButtonProps,
) => {
  const { isNodeSpaceWithoutFloorplan, size, type, onClick } = props;

  return (
    <Tooltip
      title={isNodeSpaceWithoutFloorplan ? SHOW_CREATE_TITLE : SHOW_HELP_TITLE}
    >
      <Button
        size={size}
        icon={<SisternodeOutlined />}
        type={type}
        disabled={!isNodeSpaceWithoutFloorplan}
        onClick={onClick}
      />
    </Tooltip>
  );
};
