import { DeleteOutlined } from "@ant-design/icons";
import { Button, Modal, Space } from "antd";
import { format } from "date-fns";
import React, { useState } from "react";
import { useProject } from "../../App/state";
import { useNotification } from "../../contexts/Notifications";
import {
  useDeleteShotMutation,
  useMarkShotMissingMutation,
} from "../../generated/types";
import { Batch } from "../../types";
interface IndexProps {
  shotId: string;
  sceneName: string;
  batch: Batch;
  refetchShots: (props: any) => any;
  sceneId: string;
}

export const DeleteShotButton = (props: IndexProps) => {
  const notify = useNotification();
  const project = useProject();
  const [visible, setVisible] = useState(false);
  const { sceneId } = props;

  const shotTimestamp = format(new Date(props.batch.timestamp), "yyyy-MM-dd");

  const [deleteShot] = useDeleteShotMutation({
    onCompleted: () => {
      notify(
        `Deleted shot on scene ${props.sceneName} and batch ${shotTimestamp}!`,
        "success",
      );
      props.refetchShots({
        variables: {
          ...project,
          sceneId,
        },
      });
    },
    onError: (err) =>
      notify(
        `Failed to delete shot on scene ${props.sceneName} and batch ${shotTimestamp} - err: ${err}`,
        "error",
      ),
    update: (cache) => {
      cache.evict({ id: `Shot:${props.shotId}` });
      cache.gc();
    },
  });

  const [markMissing] = useMarkShotMissingMutation({
    onCompleted: () => {
      notify(
        `Marked shot missing on scene ${props.sceneName} and batch ${shotTimestamp}!`,
        "success",
      );
      props.refetchShots({
        variables: {
          ...project,
          sceneId,
        },
      });
    },
    onError: (err) =>
      notify(
        `Failed to mark shot missing on scene ${props.sceneName} and batch ${shotTimestamp} - err: ${err}`,
        "error",
      ),
    update: (cache) => {
      cache.evict({ id: `Shot:${props.shotId}` });
      cache.gc();
    },
  });

  const onDelete = async () => {
    await deleteShot({
      variables: {
        ...project,
        shotId: props.shotId,
      },
    });
    setVisible(false);
  };

  const onMarkMissing = async () => {
    await markMissing({
      variables: {
        ...project,
        shotId: props.shotId,
      },
    });
    setVisible(false);
  };

  return (
    <>
      <Button
        icon={<DeleteOutlined />}
        danger
        type="text"
        onClick={() => setVisible(true)}
      />
      <Modal
        visible={visible}
        title={"Delete Shot?"}
        okText="OK"
        okButtonProps={{ danger: true }}
        cancelText="Cancel"
        onCancel={() => setVisible(false)}
        footer={
          <Space>
            <Button
              key="Cancel"
              onClick={() => {
                setVisible(false);
              }}
            >
              Cancel
            </Button>
            <Button danger type="primary" key="Delete" onClick={onDelete}>
              Delete
            </Button>
            <Button
              danger
              type="primary"
              key="MarkMissing"
              onClick={onMarkMissing}
            >
              Mark Missing
            </Button>
          </Space>
        }
      >
        <p>
          Do you want to delete the shot on scene <b>{props.sceneName}</b> in
          batch <b>{shotTimestamp}</b>, or mark it missing?
        </p>
        <p>
          <b>Deleting</b> will remove the shot from the backend, and it will not
          show up in the viewer. Do this when the shot was created by accident,
          like a wrong mapping, and was not supposed to be scanned for this
          batch.
        </p>
        <p>
          <b>Marking a shot missing</b> will remove the image from the shot and
          the viewer will display the default image with the disperse logo. Do
          this when the shot was supposed to be scanned, but we mapped the wrong
          image to it.
        </p>
      </Modal>
    </>
  );
};
