import { PlusCircleOutlined } from "@ant-design/icons";
import { Button, Tooltip } from "antd";
import React from "react";

type ZeroPointingControlProps = {
  isOn: boolean;
  onEnter: () => any;
  onSave: () => any;
  onDiscard: () => any;
};

export const ZeroPointingControls = ({
  isOn,
  onEnter,
  onSave,
  onDiscard,
}: ZeroPointingControlProps) => {
  if (isOn) {
    return (
      <>
        <Button type={"primary"} icon={<PlusCircleOutlined />} onClick={onSave}>
          Save
        </Button>
        <Button
          type={"primary"}
          icon={<PlusCircleOutlined />}
          danger
          onClick={onDiscard}
        >
          Cancel
        </Button>
      </>
    );
  } else {
    return (
      <Tooltip placement="topRight" title={"enter zero-pointing mode"}>
        <Button icon={<PlusCircleOutlined />} onClick={onEnter} />
      </Tooltip>
    );
  }
};
