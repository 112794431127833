// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/cssWithMappingToString.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".styles-module__link--5A3DZ{cursor:pointer;color:#1890ff}.styles-module__link--5A3DZ:hover{color:#40a9ff}.styles-module__disabledLink--39UhY{cursor:not-allowed;color:gray}", "",{"version":3,"sources":["webpack://./src/components/Link/styles.module.css"],"names":[],"mappings":"AAAA,4BACE,cAAA,CACA,aAAA,CAEA,kCACE,aAAA,CAIJ,oCACE,kBAAA,CACA,UAAA","sourcesContent":[".link {\n  cursor: pointer;\n  color: #1890ff;\n\n  &:hover {\n    color: #40a9ff;\n  }\n}\n\n.disabledLink {\n  cursor: not-allowed;\n  color: gray;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"link": "styles-module__link--5A3DZ",
	"disabledLink": "styles-module__disabledLink--39UhY"
};
export default ___CSS_LOADER_EXPORT___;
