import { DeleteOutlined } from "@ant-design/icons";
import { Alert, Button } from "antd";
import Modal from "antd/lib/modal/Modal";
import React, { useCallback, useState } from "react";
import { useNotification } from "../../../contexts/Notifications";
import { useDeleteSpacesMutation } from "../../../generated/types";
import { Project } from "../../../types";
import { Space } from "../../SpacesTree/tree-nodes";
import { SpacesWithAncestors } from "../../SpacesWithAncestors";
import styles from "../ConfigurationBottomRibbons.module.css";
import { DELETED_SPACES_ERROR, DELETED_SPACES_SUCCESS } from "../messages";

interface DeleteSpacesButtonProps {
  project: Project;
  disabled?: boolean;
  selectedSpaces: Space[];
  onDelete: () => void;
}

export const DeleteSpacesButton = (props: DeleteSpacesButtonProps) => {
  const { project, disabled, selectedSpaces, onDelete } = props;
  const { customer, project: proj, scope } = project;

  const [showModal, setShowModal] = useState(false);

  const notify = useNotification();
  const [deleteSpaces] = useDeleteSpacesMutation({
    onCompleted: () => {
      notify(DELETED_SPACES_SUCCESS, "success");
    },
    onError: (error) => {
      notify(`${DELETED_SPACES_ERROR} - ${error}`, "error");
    },
    update: (cache: any, { data }) => {
      onDelete();
      if (data?.deleteSpaces) {
        data.deleteSpaces.forEach((spaceId) => {
          cache.evict({ id: `Space:${spaceId}` });
        });
      }
    },
  });

  const handleSubmit = useCallback(() => {
    setShowModal(false);
    return deleteSpaces({
      variables: {
        customer: customer,
        project: proj,
        scope: scope,
        spaceIds: selectedSpaces.map((s) => s.id),
      },
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [disabled, selectedSpaces]);

  return (
    <div>
      <Button
        icon={<DeleteOutlined />}
        disabled={disabled}
        onClick={() => setShowModal(true)}
        title="Delete Spaces"
      ></Button>
      <Modal
        visible={showModal}
        title="Delete Spaces?"
        onOk={handleSubmit}
        onCancel={() => setShowModal(false)}
        okText="Delete"
        width="70%"
      >
        <div className={styles["modal-container"]}>
          <Alert
            type="warning"
            showIcon
            message={`Are you sure you want to delete ${
              selectedSpaces.length
            } ${selectedSpaces.length === 1 ? "space" : "spaces"}?`}
          />
          <div className={styles["scrollable-container"]}>
            <SpacesWithAncestors spaces={selectedSpaces} />
          </div>
        </div>
      </Modal>
    </div>
  );
};
