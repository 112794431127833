import { Select } from "antd";
import React, { useEffect, useMemo } from "react";
import { useProject } from "../../../App/state";
import { useGetBimUrnQuery } from "../../../generated/types";
import styles from "./BimViewer.module.css";
import { BimStateAction, useBimState } from "./useBimState";

export type BimModel = {
  value: string;
  label: string;
};

interface BimModelSelectorProps {
  isVisible: boolean;
  selectedBimModel: BimModel | undefined;
  setSelectedBimModel: (selectedBimModel: BimModel | undefined) => void;
}

export const BimModelSelector = ({
  isVisible,
  selectedBimModel,
  setSelectedBimModel,
}: BimModelSelectorProps) => {
  const project = useProject();
  const { onChangeBimState } = useBimState();

  const {
    data: urnData,
    loading: urnLoading,
    error: urnError,
  } = useGetBimUrnQuery({
    variables: {
      customer: project.customer,
      project: project.project,
      scope: project.scope,
    },
  });

  const urnDataUrns = useMemo(
    () =>
      urnData?.bimUrns?.map(({ title, urn }) => ({
        value: urn,
        label: title,
      })),
    [urnData],
  );

  useEffect(() => {
    if (urnLoading) {
      onChangeBimState(BimStateAction.MODEL_RESET);
    }
    if (urnError || !urnData) {
      onChangeBimState(BimStateAction.MODEL_ERROR);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [urnData, urnLoading, urnError, urnData]);

  useEffect(() => {
    if (urnDataUrns && urnDataUrns.length) {
      setSelectedBimModel(urnDataUrns[0]);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [urnDataUrns]);

  const onChangeModel = (modelUrn: string) => {
    const selectedModel = urnDataUrns?.find(({ value }) => modelUrn === value);
    setSelectedBimModel(selectedModel);
  };

  if (!isVisible) {
    return null;
  }

  return (
    <div className={styles["bim-model-selector-wrapper"]}>
      <div className={styles["selector-title"]}>Model:</div>
      <Select
        className={styles["bim-model-selector"]}
        value={selectedBimModel?.value ?? ""}
        onChange={(modelUrn) => onChangeModel(modelUrn)}
      >
        {(urnDataUrns ?? []).map((model) => (
          <Select.Option key={model.value} value={model.value}>
            {model.label}
          </Select.Option>
        ))}
      </Select>
    </div>
  );
};
