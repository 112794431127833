import { DisplayTreeNodeType } from "../../DisplayTree/types";
import { findClosestNodeId } from "../../DrawingTool/drawing-utils";
import {
  CreateSceneInput,
  SceneIdToScene,
  UpdateSceneInput,
} from "../hooks/useScenes";
import { Scene, SpatialConfigTreeNode } from "../types";
import { isSceneWithCoordinates, multiSelectSelection } from "../utils";

type DrawingClick = (x: number, y: number) => void;

export const createSceneDrawingStrategy =
  (
    selection: SpatialConfigTreeNode[],
    onSelectScene: (nodes: SpatialConfigTreeNode[]) => void,
    onCreateScene: ({
      spaceId,
      mmX,
      mmY,
    }: CreateSceneInput) => Promise<Pick<Scene, "id" | "name">>,
  ): DrawingClick =>
  async (x: number, y: number): Promise<void> => {
    if (selection.length === 1) {
      const { key, nodeType, parentSpaceId } = selection[0];
      const spaceId: string =
        nodeType === DisplayTreeNodeType.Scene ||
        nodeType === DisplayTreeNodeType.Folder
          ? parentSpaceId ?? key
          : key;

      const newScene = await onCreateScene({
        spaceId,
        mmX: x,
        mmY: y,
      });
      onSelectScene([
        {
          key: newScene.id,
          nodeType: DisplayTreeNodeType.Scene,
          name: newScene.name,
          parentSpaceId: spaceId,
        },
      ]);
    }
  };

export const moveScenesDrawingStrategy = (
  scenes: Scene[],
  unsetMode: () => void,
  onMoveScenes: (scenes: UpdateSceneInput[]) => void,
): DrawingClick => {
  return (x: number, y: number): void => {
    if (scenes.length) {
      //the translation is based on the first selected node
      const deltaX = x - (scenes[0].x ?? 0);
      const deltaY = y - (scenes[0].y ?? 0);
      const moveScenesInput: UpdateSceneInput[] = scenes.map((scene) => ({
        id: scene.id,
        name: scene.name,
        guiIndex: scene.guiIndex,
        spaceId: scene.parentId,
        mmX: (scene.x ?? 0) + deltaX,
        mmY: (scene.y ?? 0) + deltaY,
        mmZOffset: scene.zOffset,
      }));
      onMoveScenes(moveScenesInput);
      unsetMode();
    }
  };
};

export const selectSceneDrawingStrategy = (
  scenes: SceneIdToScene,
  onSelectScene: (scene: Scene) => void,
): DrawingClick => {
  return (x: number, y: number): void => {
    const nodeId = findClosestNodeId(
      Object.values(scenes).filter(isSceneWithCoordinates),
      x,
      y,
    );
    nodeId && onSelectScene(scenes[nodeId]);
  };
};

export const selectSceneNodeDrawingStrategy = (
  scenes: SceneIdToScene,
  onSelectScene: (nodes: SpatialConfigTreeNode[]) => void,
  selectedNodes: SpatialConfigTreeNode[],
  isMultiSelectMode: boolean,
): DrawingClick => {
  return (x: number, y: number): void => {
    const nodeId = findClosestNodeId(
      Object.values(scenes).filter(isSceneWithCoordinates),
      x,
      y,
    );
    if (nodeId) {
      const sceneNode: SpatialConfigTreeNode = {
        key: nodeId,
        nodeType: DisplayTreeNodeType.Scene,
        name: scenes[nodeId].name,
        parentSpaceId: scenes[nodeId].parentId,
      };
      if (isMultiSelectMode) {
        const selection = multiSelectSelection(selectedNodes, sceneNode);
        selection && onSelectScene(selection);
      } else {
        onSelectScene([sceneNode]);
      }
    }
  };
};
