import React from "react";
import { useProject } from "../../../App/state";
import { useGetComponentTypesQuery } from "../../../generated/types";
import {
  ComponentFieldSelectorContainer,
  ComponentFieldSelectorType,
} from "../../ComponentViewer/ComponentFieldSelectorContainer";
import { ModalOptions } from "../../ComponentViewer/CreateComponentFieldModal";
import { CreateComponentTypeForm } from "./CreateComponentTypeForm";

export const CREATE_COMPONENT_TYPE_MODAL_TITLE = "Create Component Type";

type ComponentTypeSelectorContainerProps = {
  className?: string;
  selectedTypeId?: string;
  onChange?: (value: string[]) => any;
};

export const ComponentTypeSelectorContainer = (
  props: ComponentTypeSelectorContainerProps,
) => {
  const { className, selectedTypeId, onChange } = props;

  const project = useProject();

  const {
    data: componentTypeData,
    loading,
    error,
  } = useGetComponentTypesQuery({
    variables: {
      ...project,
    },
  });

  const modalOptions: ModalOptions = {
    type: ComponentFieldSelectorType.ComponentType,
    title: CREATE_COMPONENT_TYPE_MODAL_TITLE,
    form: CreateComponentTypeForm,
  };

  return (
    <>
      <ComponentFieldSelectorContainer
        componentFieldSelectorType={ComponentFieldSelectorType.ComponentType}
        selected={selectedTypeId}
        className={className}
        selectorValues={componentTypeData?.componentTypes ?? []}
        loading={loading}
        modalOptions={modalOptions}
        onChange={onChange}
        error={error}
      />
    </>
  );
};
