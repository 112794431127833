import { Select } from "antd";
import React from "react";
import { useProject } from "../../App/state";
import { useGetSubSpacesQuery } from "../../generated/types";
import styles from "./style.module.css";

interface SubspaceDropdownProps {
  spaceId: string;
  onChangeSpace: (spaceId: string) => void;
}

export const SubSpaceDropdown = (props: SubspaceDropdownProps) => {
  const project = useProject();
  const subSpacesResult = useGetSubSpacesQuery({
    variables: {
      ...project,
      spaceId: props.spaceId,
    },
  });

  if (
    subSpacesResult.data == null ||
    subSpacesResult.data.space.subSpaces.length === 0
  ) {
    return null;
  }
  return (
    <div>
      <Select
        className={styles.dropdown}
        loading={subSpacesResult.loading}
        onChange={props.onChangeSpace}
      >
        {subSpacesResult.data != null &&
          subSpacesResult.data.space.subSpaces.map((subSpace) => (
            <Select.Option key={subSpace.id} value={subSpace.id}>
              {subSpace.name}
            </Select.Option>
          ))}
      </Select>
    </div>
  );
};
