import _ from "lodash";
import { Component } from "../../types";

export type ComponentData = {
  name?: string;
  typeId?: string;
  weight?: string;
  subcontractorId?: string;
  object?: string;
  tags?: string[];
};

export interface ComponentRecord extends Component {
  key: string;
}

export const getComponentFromGraphQlResponse = (
  graphQlComponent,
): Component => ({
  id: graphQlComponent.id,
  name: graphQlComponent.name,
  subcontractor: graphQlComponent.subcontractor ?? undefined,
  weight: _.isNil(graphQlComponent.weight)
    ? undefined
    : graphQlComponent.weight,
  guiIndex: graphQlComponent.guiIndex,
  type: graphQlComponent.componentType,
  object: graphQlComponent.object,
  tags: graphQlComponent.tags,
});

type BaseUpdateCell = {
  type: keyof ComponentData;
};

interface UpdateCellName extends BaseUpdateCell {
  type: "name";
  value: string;
}

interface UpdateCellTypeId extends BaseUpdateCell {
  type: "typeId";
  value: string;
}

interface UpdateCellSubcontractorId extends BaseUpdateCell {
  type: "subcontractorId";
  value: string;
}

interface UpdateCellWeight extends BaseUpdateCell {
  type: "weight";
  value: number;
}

interface UpdateCellObject extends BaseUpdateCell {
  type: "object";
  value: string;
}

interface UpdateCellTags extends BaseUpdateCell {
  type: "tags";
  value: string[];
}

export type UpdateCell =
  | UpdateCellName
  | UpdateCellTypeId
  | UpdateCellSubcontractorId
  | UpdateCellWeight
  | UpdateCellObject
  | UpdateCellTags;
