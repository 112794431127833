import { Col, Row, Space } from "antd";
import React from "react";
import { useNotification } from "../../contexts/Notifications";
import {
  useCreatePlanMutation,
  useDeletePlanMutation,
  useUpdatePlanMutation,
  useUploadPlansTreeMutation,
} from "../../generated/types";
import { Plan, Project } from "../../types";
import { PlanSelector } from "../Selectors/PlanSelector/PlanSelector";
import { PlanCreationModal } from "./PlanCreationModal";
import { PlanDeletionModal } from "./PlanDeletionModal";
import { PlanEditModal } from "./PlanEditModal";
import { PlanUploadModal } from "./PlanUploadModal";
import {
  PLAN_CREATED_FAILURE_MESSAGE,
  PLAN_CREATED_SUCCESS_MESSAGE,
  PLAN_DELETED_FAILURE_MESSAGE,
  PLAN_DELETED_SUCCESS_MESSAGE,
  PLAN_UPDATED_FAILURE_MESSAGE,
  PLAN_UPDATED_SUCCESS_MESSAGE,
} from "./messages";
import styles from "./styles.module.css";

type PlansHeaderProps = {
  project: Project;
  title?: string;
  selectedPlan?: Plan;
  onSelect: (plan: Plan | undefined) => void;
  editable?: boolean;
};

export const PlansHeader = (props: PlansHeaderProps) => {
  const { project, title, selectedPlan, onSelect, editable } = props;

  const notify = useNotification();
  const [createPlan] = useCreatePlanMutation({
    onCompleted: () => notify(PLAN_CREATED_SUCCESS_MESSAGE, "success"),
    onError: () => notify(PLAN_CREATED_FAILURE_MESSAGE, "error"),
    update: (cache: any) => {
      cache.evict({ fieldName: "plans" });
      cache.gc();
    },
  });
  const [updatePlan] = useUpdatePlanMutation({
    onCompleted: () => notify(PLAN_UPDATED_SUCCESS_MESSAGE, "success"),
    onError: () => notify(PLAN_UPDATED_FAILURE_MESSAGE, "error"),
  });
  const [deletePlan] = useDeletePlanMutation({
    onCompleted: () => notify(PLAN_DELETED_SUCCESS_MESSAGE, "success"),
    onError: () => notify(PLAN_DELETED_FAILURE_MESSAGE, "error"),
    update: (cache: any, { data }) => {
      if (data?.deletePlan) {
        // deletePlan here holds the id of the deleted plan
        cache.evict({ id: `Plan:${data.deletePlan}` });
        // we need to force a refetch of components without a plan
        // because that set might have changed after deleting a plan
        cache.evict({ fieldName: "componentsExcludingPlan" });
        cache.evict({ fieldName: "components" });
        cache.gc();
      }
    },
  });
  const [uploadFile] = useUploadPlansTreeMutation({
    onCompleted: () => {
      notify("Plans Tree was uploaded successfully", "success");
    },
    onError: (error) =>
      notify(`Failed to upload Plans Tree - ${error}`, "error"),
  });

  const onPlanCreation = async (planName) => {
    const result = await createPlan({
      variables: {
        ...project,
        name: planName,
      },
    });
    if (result.data?.createPlan) {
      onSelect(result.data?.createPlan);
    }
  };
  const onPlanUpload = async (planId: string, file: File) => {
    await uploadFile({
      variables: {
        ...project,
        file: file,
        planId: planId,
      },
    });
  };
  const onSubmitUpdate = async (updatedPlan: Plan) => {
    await updatePlan({
      variables: {
        ...project,
        planId: updatedPlan.id,
        name: updatedPlan.name,
        startDate: updatedPlan.startDate,
      },
    });
    onSelect(updatedPlan);
  };
  const onPlanDeletion = async (planId: string) => {
    const result = await deletePlan({
      variables: {
        ...project,
        planId: planId,
      },
    });
    const deleteSuccesfull = result.data?.deletePlan;
    if (deleteSuccesfull) {
      onSelect(undefined);
    }
  };

  return (
    <Row>
      <Col style={{ position: "relative", width: "100%" }}>
        {title && <h2>{title}</h2>}
        {editable && (
          <Space style={{ position: "absolute", right: "12px", top: "0" }}>
            {selectedPlan && (
              <>
                <PlanEditModal
                  plan={selectedPlan}
                  onSubmitUpdate={onSubmitUpdate}
                />
                <PlanDeletionModal
                  onDelete={() => onPlanDeletion(selectedPlan.id)}
                />
              </>
            )}
            <PlanCreationModal onCreate={onPlanCreation} />
            <PlanUploadModal onUpload={onPlanUpload} project={project} />
          </Space>
        )}
      </Col>
      <Col className={styles["selector-container"]}>
        <PlanSelector
          project={project}
          selectedId={selectedPlan?.id}
          onSelect={onSelect}
        />
      </Col>
    </Row>
  );
};

export default PlansHeader;
