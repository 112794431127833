import { Spin } from "antd";
import _ from "lodash";
import React, { useMemo } from "react";
import { useNotification } from "../../../contexts/Notifications";
import {
  useGetComponentsForPlanQuery,
  useGetSpaceWithMappingsQuery,
  useUpdateEnabledDisabledMappingsMutation,
} from "../../../generated/types";
import { Filter } from "../../../pages/EditorPage/types";
import { Project } from "../../../types";
import { sortComponents } from "../table-utils";
import {
  componentFromGraphQlComponent,
  mappingFromGraphQlMapping,
  spacesWithMappingsFromGraphQlSpaces,
} from "../types";
import { MappingsTable } from "./MappingsTable";
import { applyFilter } from "./filter-utils";

type MappingsTableContainerProps = {
  planId: string;
  project: Project;
  filter: Filter;
  spaceId: string;
};

export const MappingsTableContainer = (props: MappingsTableContainerProps) => {
  const componentsResponse = useGetComponentsForPlanQuery({
    variables: {
      customer: props.project.customer,
      project: props.project.project,
      scope: props.project.scope,
      planId: props.planId,
      includeSelf: true,
    },
  });

  const spacesResponse = useGetSpaceWithMappingsQuery({
    variables: {
      customer: props.project.customer,
      project: props.project.project,
      scope: props.project.scope,
      spaceId: props.spaceId,
      planId: props.planId,
    },
  });

  const notify = useNotification();

  const [updateMappings] = useUpdateEnabledDisabledMappingsMutation({
    onError: (err) => notify(`Error updating mapping - ${err}`, "error"),
  });

  const childSpaces = useMemo(
    () =>
      spacesResponse.data
        ? [
            _.pick(spacesResponse.data.spacesByIds[0], ["id", "name"]),
            ...spacesResponse.data.spacesByIds[0].subSpaces,
          ]
        : [],
    [spacesResponse.data],
  );

  const componentsToMappings = useMemo(
    () =>
      _(spacesWithMappingsFromGraphQlSpaces(spacesResponse?.data))
        .flatMap((s) =>
          s.mappings.map((m) => mappingFromGraphQlMapping(m, s.id, s.name)),
        )
        .groupBy("component.id")
        .mapValues((values) =>
          values.map((m) =>
            _.pick(m, [
              "spaceId",
              "spaceName",
              "disabled",
              "mappingId",
              "weight",
            ]),
          ),
        )
        .value(),
    [spacesResponse.data],
  );

  const componentsWithMappedSpaces = useMemo(() => {
    if (!componentsResponse.data?.plan.components) {
      return [];
    }
    const componentsWithMappings = componentsResponse.data?.plan.components.map(
      (c) => ({
        ...componentFromGraphQlComponent(c),
        componentMappings: componentsToMappings[c.id] ?? [],
      }),
    );

    return sortComponents(componentsWithMappings);
  }, [componentsResponse.data?.plan.components, componentsToMappings]);

  const filteredComponentsWithMappedSpaces = useMemo(
    () => applyFilter(componentsWithMappedSpaces, props.filter),
    [componentsWithMappedSpaces, props.filter],
  );

  if (componentsResponse.loading || spacesResponse.loading) {
    return <Spin />;
  }

  if (
    componentsResponse.error ||
    spacesResponse.error ||
    !componentsResponse.data ||
    !spacesResponse.data
  ) {
    return <div>Error getting components.</div>;
  }

  return (
    <MappingsTable
      project={props.project}
      spaces={childSpaces}
      components={filteredComponentsWithMappedSpaces}
      onDisableMapping={async (id: string, disabled: boolean) => {
        await updateMappings({
          variables: {
            customer: props.project.customer,
            project: props.project.project,
            scope: props.project.scope,
            mappings: [{ id: id, disabled: disabled }],
          },
        });
      }}
    />
  );
};
