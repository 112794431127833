import { Space } from "../tree-nodes";
import { SpaceSearchInput } from "./SearchBar";

export const matches = (space: Space, searchInput: SpaceSearchInput) => {
  // if we don't have a searchInput we are not searching
  if (searchIsEmpty(searchInput)) {
    return false;
  }

  const name = space.name;
  const inputTerms = searchInput.terms.map((input) =>
    input.toLocaleLowerCase(),
  );
  const nameMatch =
    !inputTerms.length ||
    inputTerms.some((input) => name?.toLocaleLowerCase().indexOf(input) > -1);

  const tagsInputTerms = (searchInput.tags as string[]).map((input) =>
    input.toLocaleLowerCase(),
  );

  const tagsMatch =
    !tagsInputTerms.length ||
    space.tags.some((tag) =>
      tagsInputTerms.some(
        (inputTerm) => tag.toLocaleLowerCase().indexOf(inputTerm) > -1,
      ),
    );

  const categoryMatch =
    !searchInput.categories.length ||
    searchInput.categories.some((inputTerm) => inputTerm === space.category);

  const typeMatch =
    !searchInput.spaceTypes.length ||
    searchInput.spaceTypes.some((inputTerm) => inputTerm === space.type);

  const result =
    nameMatch &&
    tagsMatch &&
    categoryMatch &&
    typeMatch &&
    (inputTerms.length ||
      tagsInputTerms.length ||
      searchInput.spaceTypes.length ||
      searchInput.categories.length);

  return !!result;
};

export const searchIsEmpty = (input: SpaceSearchInput): boolean =>
  input.terms.length === 0 &&
  input.categories.length === 0 &&
  input.spaceTypes.length === 0 &&
  input.tags.length === 0;
