import { ArrowUpOutlined } from "@ant-design/icons";
import { Card, Layout, Menu } from "antd";
import React, { ReactNode, useState } from "react";
import styles from "./DualViewSideDrawer.module.css";

type DualViewSideDrawerContentProps = {
  spotlightQAContent: ReactNode;
  arrowsContent: ReactNode;
  onClickMenuItem: () => void;
  isCollapsed: boolean;
};

export type DrawerMenuItem = "QA" | "Arrows";

export const DualViewSideDrawerContent = (
  props: DualViewSideDrawerContentProps,
) => {
  const [menuItem, setMenuItem] = useState<DrawerMenuItem>("QA");

  const onClickMenuItem = (itemKey: DrawerMenuItem) => {
    setMenuItem(itemKey);
    props.onClickMenuItem();
  };

  return (
    <Layout className={styles.layout}>
      <Layout.Content>
        {!props.isCollapsed && (
          <Card bordered={false} className={styles.card}>
            {menuItem === "QA" ? props.spotlightQAContent : props.arrowsContent}
          </Card>
        )}
      </Layout.Content>
      <Layout.Sider defaultCollapsed={true} theme="light">
        <Menu>
          <Menu.Item
            key="Spotlight QA"
            title="Spotlight QA"
            icon={<div>QA</div>}
            onClick={() => onClickMenuItem("QA")}
          />
          <Menu.Item
            key="Arrows"
            title="Arrows"
            icon={<ArrowUpOutlined />}
            onClick={() => onClickMenuItem("Arrows")}
          />
        </Menu>
      </Layout.Sider>
    </Layout>
  );
};
