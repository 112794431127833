// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/cssWithMappingToString.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".NavMenu-module__white-circle--azlnK{border-radius:50%;width:calc(32px + (2 * 4px));height:calc(32px + (2 * 4px));background-color:#fff;margin:auto;margin-top:12px;margin-bottom:12px}.NavMenu-module__white-circle--azlnK .ant-image{width:100%;height:100%;margin:auto;display:block}.NavMenu-module__white-circle--azlnK .ant-image img{width:32px;display:inline-block;margin:4px;margin-top:calc(4px - (32px * 0.075))}.NavMenu-module__nav-menu--KtPxp{height:100%;display:flex;flex-direction:column;justify-content:space-between}", "",{"version":3,"sources":["webpack://./src/components/NavMenu.module.css"],"names":[],"mappings":"AAGA,qCACE,iBAAA,CACA,4BAAA,CACA,6BAAA,CACA,qBAAA,CACA,WAAA,CACA,eAAA,CACA,kBAAA,CAEA,gDACE,UAAA,CACA,WAAA,CACA,WAAA,CACA,aAAA,CAEA,oDACE,UAAA,CACA,oBAAA,CACA,UAAA,CAGA,qCAAA,CAKN,iCACE,WAAA,CACA,YAAA,CACA,qBAAA,CACA,6BAAA","sourcesContent":["@value logo-size: 32px;\n@value logo-margin: 4px;\n\n.white-circle {\n  border-radius: 50%;\n  width: calc(logo-size + (2 * logo-margin));\n  height: calc(logo-size + (2 * logo-margin));\n  background-color: white;\n  margin: auto;\n  margin-top: 12px;\n  margin-bottom: 12px;\n\n  :global(.ant-image) {\n    width: 100%;\n    height: 100%;\n    margin: auto;\n    display: block;\n\n    img {\n      width: logo-size;\n      display: inline-block;\n      margin: logo-margin;\n\n      //Centers the logo in the white circle. Calculating by eye, due to irregular image borders\n      margin-top: calc(logo-margin - (logo-size * 0.075));\n    }\n  }\n}\n\n.nav-menu {\n  height: 100%;\n  display: flex;\n  flex-direction: column;\n  justify-content: space-between;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"logo-size": "32px",
	"logo-margin": "4px",
	"white-circle": "NavMenu-module__white-circle--azlnK",
	"nav-menu": "NavMenu-module__nav-menu--KtPxp"
};
export default ___CSS_LOADER_EXPORT___;
