// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/cssWithMappingToString.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".DualModePage-module__content--3wN3f{display:flex;flex-direction:column;height:100%;padding:16px}.DualModePage-module__content--3wN3f>:not(:last-child){margin-bottom:16px}.DualModePage-module__viewer-pane--35cWv{flex-grow:1;display:flex;flex-direction:column}.DualModePage-module__viewer--2XlmD{height:100%;flex-grow:1}", "",{"version":3,"sources":["webpack://./src/pages/DualModePage/DualModePage.module.css"],"names":[],"mappings":"AAAA,qCACE,YAAA,CACA,qBAAA,CACA,WAAA,CACA,YAAA,CAEA,uDACE,kBAAA,CAIJ,yCACE,WAAA,CAEA,YAAA,CACA,qBAAA,CAGF,oCACE,WAAA,CACA,WAAA","sourcesContent":[".content {\n  display: flex;\n  flex-direction: column;\n  height: 100%;\n  padding: 16px;\n\n  & > :not(:last-child) {\n    margin-bottom: 16px;\n  }\n}\n\n.viewer-pane {\n  flex-grow: 1;\n\n  display: flex;\n  flex-direction: column;\n}\n\n.viewer {\n  height: 100%;\n  flex-grow: 1;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"content": "DualModePage-module__content--3wN3f",
	"viewer-pane": "DualModePage-module__viewer-pane--35cWv",
	"viewer": "DualModePage-module__viewer--2XlmD"
};
export default ___CSS_LOADER_EXPORT___;
