import { UploadOutlined } from "@ant-design/icons";
import { Button, Modal } from "antd";
import React, { useCallback, useState } from "react";
import { FileUploader } from "../../../components/FileUploader";
import { useNotification } from "../../../contexts/Notifications";
import { useUploadProgressFileMutation } from "../../../generated/types";
import { Project } from "../../../types";

export type ProgressUploadModalProps = {
  project: Project;
};

export const ProgressUploadModal = (props: ProgressUploadModalProps) => {
  const [visible, setVisible] = useState<boolean>(false);
  return (
    <>
      <Button
        type="primary"
        icon={<UploadOutlined />}
        onClick={() => setVisible(true)}
      >
        Upload Progress
      </Button>
      <Modal
        visible={visible}
        title="Upload Progress sheet"
        onCancel={() => setVisible(false)}
        footer={
          <Button key="back" onClick={() => setVisible(false)}>
            Return
          </Button>
        }
      >
        <ProgressFileUploader project={props.project} />
      </Modal>
    </>
  );
};

export type ProgressFileUploaderProps = {
  project: Project;
};

const ProgressFileUploader = (props: ProgressFileUploaderProps) => {
  const { project } = props;
  const { customer, project: proj, scope } = project;
  const notify = useNotification();

  const [uploadFile] = useUploadProgressFileMutation({
    onError: (error) =>
      notify(`Progress file upload failed: ${error.message}`, "error"),
    onCompleted: () =>
      notify("Progress file successfully uploaded!", "success"),
  });
  const upload = useCallback(
    async (files) => {
      if (files.length !== 1) {
        notify(
          "Upload failed, we currently only support uploading one file at a time",
        );
      } else {
        await uploadFile({
          variables: {
            customer: customer,
            project: proj,
            scope: scope,
            file: files[0],
          },
        });
      }
    },
    [customer, notify, proj, scope, uploadFile],
  );

  return <FileUploader title="Upload Progress File" onFilesSelected={upload} />;
};
