// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/cssWithMappingToString.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".styles-module__edit-batches-alert-msg--3TQTG{margin:0;margin-top:0;margin-bottom:0}.styles-module__edit-batches-alert--1jtXW{margin-bottom:20px}.styles-module__add-new-project-button-container--rsm2w{padding-bottom:10px}", "",{"version":3,"sources":["webpack://./src/components/ProjectsTable/styles.module.css"],"names":[],"mappings":"AAAA,8CACI,QAAA,CACA,YAAA,CACA,eAAA,CAGJ,0CACI,kBAAA,CAGJ,wDACI,mBAAA","sourcesContent":[".edit-batches-alert-msg {\n    margin: 0;\n    margin-top: 0;\n    margin-bottom: 0;\n}\n\n.edit-batches-alert {\n    margin-bottom: 20px;\n}\n\n.add-new-project-button-container {\n    padding-bottom: 10px;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"edit-batches-alert-msg": "styles-module__edit-batches-alert-msg--3TQTG",
	"edit-batches-alert": "styles-module__edit-batches-alert--1jtXW",
	"add-new-project-button-container": "styles-module__add-new-project-button-container--rsm2w"
};
export default ___CSS_LOADER_EXPORT___;
