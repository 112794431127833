import { Breadcrumb, List } from "antd";
import React from "react";

type SpaceWithAncestors = {
  name: string;
  ancestors: { name: string; guiIndex: number }[];
  guiIndex: number;
};

type SpacesWithAncestorProps = {
  spaces: SpaceWithAncestors[];
  heading?: string;
};

const lexicographicalArrayCompare = (a, b) => {
  if (a.length === 0 || b.length === 0) {
    return a.length - b.length;
  }
  return a[0] - b[0] || lexicographicalArrayCompare(a.slice(1), b.slice(1));
};

const sortSpacesByGuiIndex = (
  spaces: SpaceWithAncestors[],
): SpaceWithAncestors[] =>
  [...spaces].sort((a, b) =>
    lexicographicalArrayCompare(
      [...a.ancestors.map((an) => an.guiIndex), a.guiIndex],
      [...b.ancestors.map((an) => an.guiIndex), b.guiIndex],
    ),
  );

export const SpacesWithAncestors = (props: SpacesWithAncestorProps) => {
  const sortedSpaces = sortSpacesByGuiIndex(props.spaces);
  return (
    <>
      {props.heading && <h3>{props.heading}</h3>}
      <List
        size="small"
        dataSource={sortedSpaces}
        renderItem={(item) => (
          <List.Item>
            <Breadcrumb>
              {item.ancestors?.map((ancestor) => (
                <Breadcrumb.Item key={ancestor.name}>
                  {ancestor.name}
                </Breadcrumb.Item>
              ))}
              <Breadcrumb.Item>{item.name}</Breadcrumb.Item>
            </Breadcrumb>
          </List.Item>
        )}
      />
    </>
  );
};
