import classNames from "classnames";
import React, { useCallback } from "react";
import { useDrag, useDrop } from "react-dnd";
import styles from "./ComponentViewer.module.css";
import { ComponentRecord } from "./types";

const DRAGGABLE_TYPE = "DraggableRow";

type DraggableRowProps = React.PropsWithChildren<{
  index: number;
  record: ComponentRecord;
  onComponentDrop: (componentId: string, newIndex: number) => any;
}>;

export const DraggableRow = (props: DraggableRowProps) => {
  const [{ isOver, dropLocationClassName }, drop] = useDrop({
    accept: DRAGGABLE_TYPE,
    collect: (monitor: any) => {
      const { index: dragIndex } = monitor.getItem() || {};
      return {
        isOver: monitor.isOver(),
        dropLocationClassName:
          dragIndex < props.index ? "drop-over-downward" : "drop-over-upward",
      };
    },
    drop: async (item: any) => {
      await props.onComponentDrop(
        item.record.id,
        item.index > props.index ? props.index : props.index + 1,
      );
    },
  });
  const [, drag] = useDrag({
    type: DRAGGABLE_TYPE,
    item: { index: props.index, record: props.record },
  });
  const handleMount = useCallback(
    (element) => {
      drag(element);
      drop(element);
    },
    [drag, drop],
  );

  return (
    <tr
      ref={handleMount}
      className={classNames({ [styles[dropLocationClassName]]: isOver })}
    >
      {props.children}
    </tr>
  );
};
