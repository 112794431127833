import {
  DEFAULT_NODE_COLOR,
  getNodeColorPerSequence,
} from "../DrawingTool/drawing-utils";
import {
  ScanSequenceLevelType,
  Scene,
  Sequence,
} from "../SpatialConfigurationMode/types";

export const getSceneScanningLevels = (
  scenesIds: string[],
  sequencesPerScanningLevel: { [key: string]: Sequence[] },
) => {
  const sceneIdsPerPhase: { [key: string]: Set<string | undefined> } = {};
  Object.entries(sequencesPerScanningLevel).map(
    ([scanLevel, sequences]) =>
      (sceneIdsPerPhase[scanLevel] = new Set(
        sequences.flatMap((s) => s.sceneIds),
      )),
  );

  const sceneScanningLevels: { [key: string]: ScanSequenceLevelType[] } = {};
  scenesIds.map((sceneId) => {
    const phases: ScanSequenceLevelType[] = [];
    Object.entries(sceneIdsPerPhase).map(
      ([currentPhase, currentPhaseSceneIds]) => {
        if (currentPhaseSceneIds.has(sceneId)) {
          phases.push(currentPhase as ScanSequenceLevelType);
        }
      },
    );
    sceneScanningLevels[sceneId] = phases;
  });
  return sceneScanningLevels;
};

export const getNodeLabel = (node: Scene, sequences: Sequence[]) => {
  const nodeSequence = sequences.find(
    (s) => s.sceneIds && s.sceneIds.indexOf(node.id) !== -1,
  );

  return nodeSequence && nodeSequence.sceneIds
    ? nodeSequence.sequencePrefix + (nodeSequence.sceneIds.indexOf(node.id) + 1)
    : undefined;
};

export const getNodeColorsPerSequence = (
  sceneScanningPhases?: ScanSequenceLevelType[],
) => {
  const colors: string[] = [];

  if (sceneScanningPhases) {
    sceneScanningPhases.forEach((s) => colors.push(getNodeColorPerSequence(s)));
  }
  if (colors.length) {
    return colors;
  }
  return [DEFAULT_NODE_COLOR];
};
