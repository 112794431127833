import _ from "lodash";
import React, { useCallback } from "react";
import { useProject } from "../../../App/state";
import { useGetBimTokenLazyQuery } from "../../../generated/types";
import { BimViewer, BimViewerProps } from "./BimViewer";
import { BimStateAction } from "./useBimState";

type BimViewerContainerProps = Omit<
  BimViewerProps,
  "token" | "onGetBimAccessToken"
>;

export const BimViewerContainer = (props: BimViewerContainerProps) => {
  const { onChangeBimState } = props;
  const project = useProject();

  const [getBimTokenQuery] = useGetBimTokenLazyQuery({
    fetchPolicy: "no-cache",
    variables: {
      customer: project.customer,
      project: project.project,
      scope: project.scope,
    },
  });

  const onGetBimAccessToken = useCallback(async () => {
    const { data: bimTokenData, error: bimTokenError } =
      await getBimTokenQuery();

    if (bimTokenError || !bimTokenData?.bimToken.token) {
      onChangeBimState(BimStateAction.MODEL_ERROR);
      return null;
    }
    return _.pick(bimTokenData.bimToken, ["token", "expiresIn"]);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [getBimTokenQuery]);

  return <BimViewer onGetBimAccessToken={onGetBimAccessToken} {...props} />;
};
