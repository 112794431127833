// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/cssWithMappingToString.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".DeleteConfirmation-module__input-delete-field--1jSBj{font-style:italic}", "",{"version":3,"sources":["webpack://./src/components/DeleteConfirmationModal/DeleteConfirmation.module.css"],"names":[],"mappings":"AAAA,sDACI,iBAAA","sourcesContent":[".input-delete-field {\n    font-style: italic;\n}\n  "],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"input-delete-field": "DeleteConfirmation-module__input-delete-field--1jSBj"
};
export default ___CSS_LOADER_EXPORT___;
