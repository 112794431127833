import { DeleteOutlined, MinusCircleOutlined } from "@ant-design/icons";
import { Button, Modal, Space } from "antd";
import React, { useState } from "react";
import { useNotification } from "../../contexts/Notifications";
import {
  useDeleteComponentsMutation,
  useRemoveComponentsFromTaskMutation,
} from "../../generated/types";
import { Project } from "../../types";
import styles from "./ComponentViewer.module.css";
import {
  DELETE_COMPONENTS_ERROR,
  DELETE_COMPONENTS_SUCCESS,
  REMOVE_COMPONENTS_FROM_TASK_ERROR,
  REMOVE_COMPONENTS_FROM_TASK_SUCCESS,
} from "./messages";

type ComponentDeletionModalProps = {
  project: Project;
  selectedComponentIds: string[];
  taskId?: string;
  onComponentsUpdate: () => void;
};

export const ComponentDeletionModal = (props: ComponentDeletionModalProps) => {
  const notify = useNotification();
  const [deleteComponents] = useDeleteComponentsMutation({
    onCompleted: () => notify(DELETE_COMPONENTS_SUCCESS, "success"),
    onError: () => notify(DELETE_COMPONENTS_ERROR, "error"),
  });
  const [removeComponentFromTasks] = useRemoveComponentsFromTaskMutation({
    onCompleted: () => notify(REMOVE_COMPONENTS_FROM_TASK_SUCCESS, "success"),
    onError: () => notify(REMOVE_COMPONENTS_FROM_TASK_ERROR, "error"),
    update: (cache: any) => {
      cache.evict({ fieldName: "componentsExcludingPlan" });
      cache.evict({ fieldName: "components" });
    },
  });

  const onRemoveComponentClick = async () => {
    await removeComponentFromTasks({
      variables: {
        customer: props.project.customer,
        project: props.project.project,
        scope: props.project.scope,
        componentIds: props.selectedComponentIds,
        taskId: props.taskId,
      },
    });
    props.onComponentsUpdate();
  };

  const [visible, setVisible] = useState(false);

  const onCancelDeletion = () => {
    setVisible(false);
  };

  const onOkDeletion = async () => {
    await deleteComponents({
      variables: {
        customer: props.project.customer,
        project: props.project.project,
        scope: props.project.scope,
        ids: props.selectedComponentIds,
      },
    });
    props.onComponentsUpdate();
    setVisible(false);
  };

  const showModal = () => {
    setVisible(true);
  };

  return (
    <Space>
      {props.taskId && (
        <Button
          data-testid="remove-component-button"
          type="primary"
          onClick={() => onRemoveComponentClick()}
          icon={<MinusCircleOutlined />}
        />
      )}
      <Button
        data-testid="delete-button"
        type="primary"
        onClick={showModal}
        icon={<DeleteOutlined />}
      />
      <Modal
        visible={visible}
        title={
          <div className={styles["delete-warning"]}>Delete component?</div>
        }
        okText="OK"
        okButtonProps={{ danger: true }}
        cancelText="Cancel"
        onCancel={onCancelDeletion}
        onOk={onOkDeletion}
      >
        <p>This action will delete the component and all of its mappings.</p>
        <Space></Space>
        <p>Are you sure you want to delete?</p>
      </Modal>
    </Space>
  );
};
