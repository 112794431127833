import { Space, Table } from "antd";
import { ColumnType } from "antd/es/table";
import _ from "lodash";
import React, { useEffect, useMemo, useState } from "react";
import { useImmer } from "use-immer";
import { Component, ComponentType, Project, Subcontractor } from "../../types";
import { reorderNodes } from "../../utils/list-utils";
import { ComponentAssigningModal } from "./ComponentAssignmentModal";
import { ComponentCreationModal } from "./ComponentCreationModal";
import { ComponentDeletionModal } from "./ComponentDeletionModal";
import { ComponentUpdateButton } from "./ComponentUpdateButton";
import styles from "./ComponentsTable.module.css";
import { ComponentsTableCell } from "./ComponentsTableCell";
import { DraggableRow } from "./DraggableRow";
import { ComponentData, ComponentRecord, UpdateCell } from "./types";

type TableColumnName = keyof ComponentData;

const dataIndexToComponentKeyMapping: Record<
  TableColumnName,
  keyof Omit<ComponentRecord, "id" | "guiIndex" | "key">
> = {
  name: "name",
  typeId: "type",
  weight: "weight",
  subcontractorId: "subcontractor",
  object: "object",
  tags: "tags",
};
const TABLE_COLUMN_TO_TITLE_MAPPING: [TableColumnName, string][] = [
  ["name", "Name"],
  ["typeId", "Type"],
  ["weight", "Weight"],
  ["subcontractorId", "Subcontractor"],
  ["object", "Object"],
  ["tags", "Tags"],
];

// If we have made changes but not confirmed them, we want to display those changes.
// Otherwise we show the original data (which is refetched if we confirm changes)
const getMaybeUpdatedCell = (
  cellsToUpdate: Record<string, ComponentData>,
  record: ComponentRecord,
  tableColumnName: TableColumnName,
): UpdateCell => {
  const { key } = record;
  const recordKey = dataIndexToComponentKeyMapping[tableColumnName];

  let recordValue;
  if (tableColumnName === "subcontractorId" || tableColumnName === "typeId") {
    recordValue = (record[recordKey] as Subcontractor | ComponentType)?.id;
  } else {
    recordValue = record[recordKey];
  }

  return {
    type: tableColumnName,
    value:
      (cellsToUpdate[key] && cellsToUpdate[key][tableColumnName]) ??
      recordValue,
  };
};

type ComponentsTableProps = {
  project: Project;
  components: Component[];
  taskId?: string;
  planId?: string;
  // TODO: Remove this property. Handle refetching the components by applying
  // changes to the apollo cache rather than calling refetch.
  onComponentsUpdate: () => void;
  // only show certain columns, show all if not set
  tableColumns?: TableColumnName[];
  updateComponents?: (componentsForUpdate: Component[]) => any;
  draggingRowsEnabled?: boolean;
};

export const ComponentsTable = (props: ComponentsTableProps) => {
  const {
    project,
    components,
    taskId,
    planId,
    onComponentsUpdate,
    tableColumns,
    updateComponents,
    draggingRowsEnabled,
  } = props;
  const dataSource = useMemo(
    () => components.map((c) => ({ ...c, key: c.id })),
    [components],
  );
  const [deleteMode, setDeleteMode] = useState<boolean>(false);
  const [componentIdsSelectedForDelete, setComponentIdsSelectedForDelete] =
    useState<string[]>([]);
  const [cellsToUpdate, setCellsToUpdate] = useImmer<
    Record<string, ComponentData>
  >({});
  const [updateMode, setUpdateMode] = useState<boolean>(false);

  useEffect(() => {
    if (componentIdsSelectedForDelete.length === 0 && deleteMode) {
      setDeleteMode(false);
    } else if (componentIdsSelectedForDelete.length > 0 && !deleteMode) {
      setDeleteMode(true);
    }
  }, [setDeleteMode, componentIdsSelectedForDelete, deleteMode]);

  const rowSelection = {
    onChange: (selectedRowKeys) =>
      setComponentIdsSelectedForDelete(selectedRowKeys),
  };

  const onFinishUpdating = () => {
    setCellsToUpdate({});
    setUpdateMode(false);
  };

  const componentTableColumns: ColumnType<ComponentRecord>[] = useMemo(() => {
    const onCellEdit = (
      componentId: string,
      columnName: string,
      value: string | number | string[],
    ) => {
      setCellsToUpdate((cells) => {
        cells[componentId] = cells[componentId] ?? {};
        cells[componentId][columnName] = value;
        return cells;
      });
      if (!updateMode) {
        setUpdateMode(true);
      }
    };

    const allColumns = TABLE_COLUMN_TO_TITLE_MAPPING.map(([col, title]) => ({
      title: title,
      dataIndex: col,
      // eslint-disable-next-line react/display-name
      render: (_value, record) => (
        <ComponentsTableCell
          record={record}
          updatedCell={getMaybeUpdatedCell(cellsToUpdate, record, col)}
          editing={updateMode}
          onCellEdit={onCellEdit}
        />
      ),
    }));
    const tableCols =
      tableColumns !== undefined
        ? allColumns.filter((column) => tableColumns.includes(column.dataIndex))
        : allColumns;
    return tableCols;
  }, [updateMode, tableColumns, cellsToUpdate, setCellsToUpdate]);

  const onComponentDrop = async (componentId: string, newIndex: number) => {
    const ordered = reorderNodes(componentId, dataSource, newIndex);
    const updated = ordered.map((c, index) => (c = { ...c, guiIndex: index }));
    await updateComponents?.(updated);
  };

  return (
    <div className={styles["components-table"]}>
      <Table<ComponentRecord>
        pagination={false}
        components={{
          body: {
            row: draggingRowsEnabled ? DraggableRow : undefined,
          },
        }}
        columns={componentTableColumns}
        onRow={(record: ComponentRecord, index?: number): any =>
          draggingRowsEnabled
            ? {
                index,
                record,
                onComponentDrop,
              }
            : undefined
        }
        rowSelection={rowSelection}
        bordered
        dataSource={dataSource}
        footer={() =>
          deleteMode ? (
            <ComponentDeletionModal
              project={project}
              selectedComponentIds={componentIdsSelectedForDelete}
              taskId={taskId}
              onComponentsUpdate={() => {
                onComponentsUpdate();
                setDeleteMode(false);
              }}
            />
          ) : updateMode ? (
            <ComponentUpdateButton
              project={project}
              taskId={taskId}
              existingComponents={components}
              componentDataToUpdate={cellsToUpdate}
              onComponentsUpdate={() => {
                onComponentsUpdate();
                onFinishUpdating();
              }}
              onCancelUpdate={onFinishUpdating}
            />
          ) : (
            <Space>
              <ComponentCreationModal
                project={project}
                taskId={taskId}
                onComponentsUpdate={onComponentsUpdate}
              />
              {taskId && planId && (
                <ComponentAssigningModal
                  project={project}
                  taskId={taskId}
                  planId={planId}
                  onComponentsUpdate={onComponentsUpdate}
                  maxGuiIndex={_.maxBy(components, "guiIndex")?.guiIndex ?? 0}
                />
              )}
            </Space>
          )
        }
      />
    </div>
  );
};
