import {
  Component,
  SpaceWithMappingDetailsFragment,
} from "../../generated/types";

export const getMappings = (
  selectedSpaces: SpaceWithMappingDetailsFragment[],
  selectedComponents: Pick<Component, "id" | "name" | "guiIndex">[],
) => {
  if (selectedComponents.length === 0 || selectedSpaces.length === 0) {
    return [];
  }
  const components = new Set(selectedComponents.map((c) => c.id));
  return selectedSpaces.flatMap((space) =>
    space.mappings
      .filter((mapping) => components.has(mapping.component.id))
      .map((mapping) => ({
        ...mapping,
        space: {
          id: space.id,
          name: space.name,
          ancestors: space.ancestors,
          guiIndex: space.guiIndex,
        },
      })),
  );
};
