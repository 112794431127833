import { Empty, Spin } from "antd";
import _ from "lodash";
import React, { useCallback, useEffect, useState } from "react";
import { useProject } from "../../App/state";
import { DisplayTreeNode } from "../../components/DisplayTree/types";
import { DualViewer } from "../../components/DualView";
import { LayoutWithSideDrawer } from "../../components/DualViewSideDrawer";
import SceneSelectionDropdowns from "../../components/SceneSelectionDropdowns";
import { BatchSelector } from "../../components/Selectors/BatchSelector";
import { SpaceSelector } from "../../components/Selectors/SpaceSelector";
import { SpaceSelectionModal } from "../../components/SpaceSelectionModal";
import { SpacesTree } from "../../components/SpacesTree/SpacesTree";
import { Shot } from "../../components/SpacesTree/tree-nodes";
import { SpotlightQA } from "../../components/SpotlightQA";
import styles from "./DualModePage.module.css";
import { Provider, useDualModeState } from "./state";

const DualModeContent = () => {
  const project = useProject();
  const {
    rootSpaceId,
    referenceBatch,
    loadDefaultScene,
    setNextScene,
    setPreviousScene,
    moveSceneUp,
    moveSceneDown,
    currentSceneId,
    setCurrentScene,
    focusOnSpotlight,
    setReferenceBatch,
    setComparisonBatch,
    setRootSpace,
    view,
    setView,
    comparisonBatch,
    focusedSpotlightId,
  } = useDualModeState();

  const [shotByIds, setShotByIds] = useState<Record<string, Shot>>({});
  const [version, setVersion] = useState<number>(1);
  useEffect(() => {
    if (rootSpaceId != null && referenceBatch != null) {
      loadDefaultScene(rootSpaceId);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [loadDefaultScene, rootSpaceId]);

  const arrowSceneNavigation = React.useCallback(
    (event: KeyboardEvent) => {
      if (event.key === "ArrowRight") {
        setNextScene();
      } else if (event.key === "ArrowLeft") {
        setPreviousScene();
      } else if (event.key === "ArrowUp") {
        moveSceneUp();
      } else if (event.key === "ArrowDown") {
        moveSceneDown();
      }
    },
    [moveSceneDown, moveSceneUp, setNextScene, setPreviousScene],
  );
  useEffect(() => {
    if (currentSceneId != null) {
      addEventListener("keydown", arrowSceneNavigation);
      return () => {
        removeEventListener("keydown", arrowSceneNavigation);
      };
    }
  }, [currentSceneId, arrowSceneNavigation]);

  const handleOnSelect = useCallback(
    (node: DisplayTreeNode) => {
      setVersion((prev) => prev + 1);

      setCurrentScene(node.parentKey);
      const batch = shotByIds[node.key]?.batch;
      if (batch === undefined) {
        return;
      }
      setReferenceBatch(batch);
      // // hack to set it to something rather than nothing
      setComparisonBatch(batch);
    },
    [
      setComparisonBatch,
      setCurrentScene,
      setReferenceBatch,
      shotByIds,
      setVersion,
    ],
  );

  const handleOnLoadShots = useCallback((shots: Shot[]) => {
    setShotByIds((s) => ({ ...s, ..._.keyBy(shots, "id") }));
  }, []);

  if (rootSpaceId === undefined) {
    return (
      <Empty>
        <SpaceSelectionModal
          buttonContent="Select A root space"
          onSelect={setRootSpace}
        />
      </Empty>
    );
  }

  return (
    <LayoutWithSideDrawer
      spotlightQAContent={
        referenceBatch != null && (
          <SpotlightQA
            batchId={referenceBatch.id}
            rootSpaceId={rootSpaceId}
            project={project}
            onSpotlightSelect={focusOnSpotlight}
          />
        )
      }
      arrowsContent={
        <SpacesTree
          showScenes
          showShots
          draggableScenes
          onSelect={handleOnSelect}
          onLoadShots={handleOnLoadShots}
          version={version}
          setVersion={setVersion}
        />
      }
      onCollapse={(isCollapsed) => {
        if (isCollapsed) {
          focusOnSpotlight(undefined);
        }
      }}
    >
      <div className={styles.content}>
        <SpaceSelector
          project={project}
          rootSpaceId={rootSpaceId}
          onSelect={setRootSpace}
        />
        <div className={styles["viewer-pane"]}>
          <div className={styles.viewer}>
            {referenceBatch != null &&
              (currentSceneId?.loading ? (
                <Spin />
              ) : currentSceneId?.value != null ? (
                <DualViewer
                  sceneId={currentSceneId.value}
                  referenceBatch={referenceBatch}
                  comparisonBatch={comparisonBatch}
                  view={view}
                  onChangeView={setView}
                  focusedSpotlightId={focusedSpotlightId}
                  arrowSceneNavigation={arrowSceneNavigation}
                />
              ) : (
                <Empty description="No scenes available"></Empty>
              ))}
          </div>
          <BatchSelector
            referenceBatch={referenceBatch}
            comparisonBatch={comparisonBatch}
            onChangeReferenceBatch={(refId) => {
              const oldReferenceBatch = referenceBatch;
              setReferenceBatch(refId);
              // if the referenceBatch changes from null to a non-null
              // value then this might change the default scene, so load it again
              // But we don't want to do this on every referenceBatch change,
              // because then it would always jump back to the default scene
              if (!oldReferenceBatch && rootSpaceId) {
                loadDefaultScene(rootSpaceId);
              }
            }}
            onChangeComparisonBatch={setComparisonBatch}
          />
        </div>
        {currentSceneId?.value != null && (
          <SceneSelectionDropdowns
            rootSpaceId={rootSpaceId}
            sceneId={currentSceneId.value}
            onSelect={setCurrentScene}
          />
        )}
      </div>
    </LayoutWithSideDrawer>
  );
};

export const DualModePage = () => (
  <Provider>
    <DualModeContent />
  </Provider>
);
