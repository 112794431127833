import { Form, FormInstance, Input, InputNumber } from "antd";
import React from "react";
import { ComponentTypeSelectorContainer } from "../Selectors/ComponentTypeSelector/ComponentTypeSelectorContainer";
import { SubcontractorSelectorContainer } from "../Selectors/SubcontractorSelector/SubcontractorSelectorContainer";
import { ComponentTagSelectorContainer } from "./ComponentTagSelector/ComponentTagSelectorContainer";
import { ObjectSelectorContainer } from "./ObjectSelector/ObjectSelectorContainer";

type ComponentCreationFormProps = {
  form: FormInstance;
  includeWeightInForm: boolean;
};

export const ComponentCreationForm = (props: ComponentCreationFormProps) => {
  return (
    <Form form={props.form}>
      <Form.Item
        name="name"
        label="Name"
        rules={[
          {
            required: true,
            message: "Please provide a name for the component!",
          },
        ]}
      >
        <Input data-testid="form-input-name" />
      </Form.Item>
      <Form.Item
        name="type"
        label="Type"
        rules={[
          {
            required: true,
            message: "Please select a type for the component!",
          },
        ]}
      >
        <ComponentTypeSelectorContainer />
      </Form.Item>
      <Form.Item
        name="object"
        label="Object"
        rules={[
          {
            required: true,
            message: "Please select an object for the component!",
          },
        ]}
      >
        <ObjectSelectorContainer />
      </Form.Item>
      {props.includeWeightInForm && (
        <Form.Item
          name="weight"
          label="Weight in task"
          initialValue={1}
          rules={[
            {
              required: true,
              message: "Please specify a weight between 0 and 100!",
            },
          ]}
        >
          <InputNumber data-testid="form-input-weight" min={0} max={100} />
        </Form.Item>
      )}
      <Form.Item
        name="subcontractor"
        label="Subcontractor"
        rules={[
          {
            required: true,
            message:
              "Please select a subcontractor. If you are unsure which subcontractor to choose, you can select 'no subcontractor' as an option.",
          },
        ]}
      >
        <SubcontractorSelectorContainer />
      </Form.Item>
      <Form.Item name="tags" label="Tags" required={false}>
        <ComponentTagSelectorContainer />
      </Form.Item>
    </Form>
  );
};
