import { Button, Modal } from "antd";
import _ from "lodash";
import React, { useState } from "react";
import { useNotification } from "../../contexts/Notifications";
import { Space } from "../../types";
import { DisplayTreeNode } from "../DisplayTree/types";
import { useIsBimEnabled } from "../DualView/hooks/useIsBimEnabled";
import { SpacesTree } from "../SpacesTree/SpacesTree";
import { FloorplanIdToSpaceId } from "../SpatialConfigurationMode/types";
import { isNodeSpace } from "../SpatialConfigurationMode/utils";
import { DeleteFloorplanContainer } from "./DeleteFloorplan";
import { EditProperties } from "./EditProperties";
import styles from "./FloorplanEditor.module.css";
import {
  CreateFloorplanModal,
  PatchFloorplanModal,
} from "./ImportFloorplanModal";
import {
  FloorplanCreateInput,
  FloorplanPatchInput,
} from "./hooks/useFloorplan";

interface FloorplanEditorProps {
  floorplanIdToSpaceId: FloorplanIdToSpaceId;
  onCreate: (floorplanData: FloorplanCreateInput) => Promise<void>;
  onPatch: (floorplanData: FloorplanPatchInput) => Promise<void>;
  onDelete: (id: string) => Promise<void>;
  onClose: () => void;
}

const CREATE_FLOORPLAN_TEXT = "Create Floorplan";
const UPDATE_FLOORPLAN_TEXT = "Update Floorplan";
const DELETE_FLOORPLAN_TEXT = "Delete Floorplan";
const EDIT_FLOORPLAN_TEXT = "Edit Floorplan";

enum FloorplanState {
  "CREATE" = 1,
  "PATCH" = 2,
  "DELETE" = 3,
  "EDIT" = 4,
}

type StateToComponent = Record<FloorplanState, JSX.Element> | undefined;

export const FloorplanEditor = (props: FloorplanEditorProps) => {
  const { floorplanIdToSpaceId, onCreate, onPatch, onDelete, onClose } = props;
  const [currentSpace, setCurrentSpace] = useState<Space | null>();
  const [activeModal, setActiveModal] = useState<FloorplanState>();
  const notify = useNotification();
  const isBimEnabled = useIsBimEnabled();

  const onTreeSelect = (node: DisplayTreeNode) => {
    isNodeSpace(node)
      ? setCurrentSpace({ id: node.key, name: node.name })
      : notify("Select a space node", "warning");
  };

  const spaceIdToFloorplanId = _.invert(floorplanIdToSpaceId);

  const hasCurrentSpaceFloorplan =
    currentSpace && spaceIdToFloorplanId[currentSpace.id];

  const activeModalTypeToComponent: StateToComponent = currentSpace
    ? {
        [FloorplanState.CREATE]: (
          <CreateFloorplanModal
            spaceId={currentSpace.id}
            spaceName={currentSpace.name}
            onCreate={onCreate}
            onClose={onClose}
          />
        ),
        [FloorplanState.PATCH]: (
          <PatchFloorplanModal
            floorplanId={spaceIdToFloorplanId[currentSpace.id]}
            spaceId={currentSpace.id}
            spaceName={currentSpace.name}
            onPatch={onPatch}
            onClose={onClose}
          />
        ),
        [FloorplanState.DELETE]: (
          <DeleteFloorplanContainer
            floorplanId={spaceIdToFloorplanId[currentSpace.id]}
            onDelete={onDelete}
            onClose={onClose}
          />
        ),
        [FloorplanState.EDIT]: (
          <EditProperties
            floorplanId={spaceIdToFloorplanId[currentSpace.id]}
            spaceName={currentSpace.name}
            onEdit={onPatch}
            onClose={() => setActiveModal(undefined)}
          />
        ),
      }
    : undefined;

  return (
    <>
      <Modal
        bodyStyle={{ height: "80vh" }}
        closable={true}
        visible={true}
        onCancel={onClose}
        footer={null}
        destroyOnClose={true}
      >
        <div className={styles["spaces-tree-box"]}>
          <SpacesTree showScenes onSelect={onTreeSelect} />
        </div>
        <div className={styles["buttons-box"]}>
          <Button
            size="large"
            className={styles["button"]}
            disabled={!currentSpace}
            onClick={() =>
              hasCurrentSpaceFloorplan
                ? setActiveModal(FloorplanState.PATCH)
                : setActiveModal(FloorplanState.CREATE)
            }
          >
            {hasCurrentSpaceFloorplan
              ? UPDATE_FLOORPLAN_TEXT
              : CREATE_FLOORPLAN_TEXT}
          </Button>
          <Button
            size="large"
            className={styles["button"]}
            disabled={!hasCurrentSpaceFloorplan}
            onClick={() => setActiveModal(FloorplanState.DELETE)}
          >
            {DELETE_FLOORPLAN_TEXT}
          </Button>
          {isBimEnabled && (
            <Button
              size="large"
              className={styles["button"]}
              disabled={!hasCurrentSpaceFloorplan}
              onClick={() => setActiveModal(FloorplanState.EDIT)}
            >
              {EDIT_FLOORPLAN_TEXT}
            </Button>
          )}
        </div>
      </Modal>
      {activeModal && activeModalTypeToComponent?.[activeModal]}
    </>
  );
};
