"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.SpotlightType = exports.SpotlightStatus = exports.SpotlightPriority = exports.Scope = exports.SceneSubject = exports.ScanSequencePrefixType = exports.ScanSequenceLevelType = exports.ProgressStatus = exports.P2SpotlightType = exports.JiraIssueType = exports.DocumentType = exports.ChangeState = exports.Action = void 0;
var Action;
(function (Action) {
    Action["Write"] = "write";
    Action["Read"] = "read";
})(Action = exports.Action || (exports.Action = {}));
var ChangeState;
(function (ChangeState) {
    ChangeState["Change"] = "CHANGE";
    ChangeState["NoChange"] = "NO_CHANGE";
})(ChangeState = exports.ChangeState || (exports.ChangeState = {}));
var DocumentType;
(function (DocumentType) {
    DocumentType["DefaultDeliverable"] = "defaultDeliverable";
    DocumentType["DeveloperDeliverable"] = "developerDeliverable";
    DocumentType["Configuration"] = "configuration";
})(DocumentType = exports.DocumentType || (exports.DocumentType = {}));
var JiraIssueType;
(function (JiraIssueType) {
    JiraIssueType["Level"] = "LEVEL";
    JiraIssueType["Arrow"] = "ARROW";
    JiraIssueType["Map"] = "MAP";
    JiraIssueType["FaceBlur"] = "FACE_BLUR";
    JiraIssueType["ChangeDetection"] = "CHANGE_DETECTION";
})(JiraIssueType = exports.JiraIssueType || (exports.JiraIssueType = {}));
var P2SpotlightType;
(function (P2SpotlightType) {
    P2SpotlightType["PotentialRework"] = "PotentialRework";
    P2SpotlightType["PotentialDelay"] = "PotentialDelay";
    P2SpotlightType["ReworkNeeded"] = "ReworkNeeded";
    P2SpotlightType["ReworkInProgress"] = "ReworkInProgress";
    P2SpotlightType["NonConformance"] = "NonConformance";
    P2SpotlightType["Unknown"] = "Unknown";
})(P2SpotlightType = exports.P2SpotlightType || (exports.P2SpotlightType = {}));
var ProgressStatus;
(function (ProgressStatus) {
    ProgressStatus["NotStarted"] = "NOT_STARTED";
    ProgressStatus["InProgress"] = "IN_PROGRESS";
    ProgressStatus["Finished"] = "FINISHED";
})(ProgressStatus = exports.ProgressStatus || (exports.ProgressStatus = {}));
var ScanSequenceLevelType;
(function (ScanSequenceLevelType) {
    ScanSequenceLevelType["Early"] = "EARLY";
    ScanSequenceLevelType["Mid"] = "MID";
    ScanSequenceLevelType["Late"] = "LATE";
})(ScanSequenceLevelType = exports.ScanSequenceLevelType || (exports.ScanSequenceLevelType = {}));
var ScanSequencePrefixType;
(function (ScanSequencePrefixType) {
    ScanSequencePrefixType["A"] = "A";
    ScanSequencePrefixType["B"] = "B";
    ScanSequencePrefixType["C"] = "C";
    ScanSequencePrefixType["D"] = "D";
    ScanSequencePrefixType["E"] = "E";
    ScanSequencePrefixType["F"] = "F";
    ScanSequencePrefixType["G"] = "G";
    ScanSequencePrefixType["H"] = "H";
    ScanSequencePrefixType["I"] = "I";
    ScanSequencePrefixType["J"] = "J";
    ScanSequencePrefixType["K"] = "K";
    ScanSequencePrefixType["L"] = "L";
    ScanSequencePrefixType["M"] = "M";
    ScanSequencePrefixType["N"] = "N";
    ScanSequencePrefixType["O"] = "O";
    ScanSequencePrefixType["P"] = "P";
    ScanSequencePrefixType["Q"] = "Q";
    ScanSequencePrefixType["R"] = "R";
    ScanSequencePrefixType["S"] = "S";
    ScanSequencePrefixType["T"] = "T";
    ScanSequencePrefixType["U"] = "U";
    ScanSequencePrefixType["V"] = "V";
    ScanSequencePrefixType["W"] = "W";
    ScanSequencePrefixType["X"] = "X";
    ScanSequencePrefixType["Y"] = "Y";
    ScanSequencePrefixType["Z"] = "Z";
})(ScanSequencePrefixType = exports.ScanSequencePrefixType || (exports.ScanSequencePrefixType = {}));
var SceneSubject;
(function (SceneSubject) {
    SceneSubject["Firestop"] = "FIRESTOP";
    SceneSubject["Riser"] = "RISER";
    SceneSubject["Facade"] = "FACADE";
    SceneSubject["HighCeiling"] = "HIGH_CEILING";
    SceneSubject["BuildingFullHeight"] = "BUILDING_FULL_HEIGHT";
})(SceneSubject = exports.SceneSubject || (exports.SceneSubject = {}));
var Scope;
(function (Scope) {
    Scope["Internal"] = "internal";
    Scope["External"] = "external";
})(Scope = exports.Scope || (exports.Scope = {}));
var SpotlightPriority;
(function (SpotlightPriority) {
    SpotlightPriority["High"] = "HIGH";
    SpotlightPriority["Medium"] = "MEDIUM";
    SpotlightPriority["Low"] = "LOW";
})(SpotlightPriority = exports.SpotlightPriority || (exports.SpotlightPriority = {}));
var SpotlightStatus;
(function (SpotlightStatus) {
    SpotlightStatus["Open"] = "OPEN";
    SpotlightStatus["ActionList"] = "ACTION_LIST";
    SpotlightStatus["Resolved"] = "RESOLVED";
    SpotlightStatus["Incorrect"] = "INCORRECT";
})(SpotlightStatus = exports.SpotlightStatus || (exports.SpotlightStatus = {}));
var SpotlightType;
(function (SpotlightType) {
    SpotlightType["MakeGood"] = "MAKE_GOOD";
    SpotlightType["NotCompleteAndSoonInaccessible"] = "NOT_COMPLETE_AND_SOON_INACCESSIBLE";
    SpotlightType["LocationMismatch"] = "LOCATION_MISMATCH";
    SpotlightType["PartiallyIncomplete"] = "PARTIALLY_INCOMPLETE";
    SpotlightType["PartiallyRemoved"] = "PARTIALLY_REMOVED";
    SpotlightType["SequenceMismatch"] = "SEQUENCE_MISMATCH";
    SpotlightType["ElementMismatch"] = "ELEMENT_MISMATCH";
    SpotlightType["DoubleCheck"] = "DOUBLE_CHECK";
    SpotlightType["LeaveDown"] = "LEAVE_DOWN";
    SpotlightType["Obsolete"] = "OBSOLETE";
    SpotlightType["ExternalSystemSpotlightType"] = "EXTERNAL_SYSTEM_SPOTLIGHT_TYPE";
})(SpotlightType = exports.SpotlightType || (exports.SpotlightType = {}));
