import { Button } from "antd";
import classNames from "classnames";
import React from "react";
import styles from "./OptionsPanel.module.css";

interface OptionsPanelSaveFormProps {
  editMode: boolean;
  saveButtonDisabled: boolean;
  onSaveButtonClick: () => void;
  onEditButtonClick: () => void;
}

const EDIT_BUTTON = "Edit";
const SAVE_BUTTON = "Save";

export const OptionsPanelSaveForm = (props: OptionsPanelSaveFormProps) => {
  const { onSaveButtonClick, onEditButtonClick, saveButtonDisabled, editMode } =
    props;

  return (
    <div className={styles["save-row"]}>
      {editMode ? (
        <Button
          className={classNames([styles["save-row-button"], styles["save"]])}
          onClick={onSaveButtonClick}
          disabled={saveButtonDisabled}
        >
          {SAVE_BUTTON}
        </Button>
      ) : (
        <Button
          className={classNames([styles["save-row-button"], styles["edit"]])}
          onClick={onEditButtonClick}
        >
          {EDIT_BUTTON}
        </Button>
      )}
    </div>
  );
};
