// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/cssWithMappingToString.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".styles-module__spotlight-form--3Jtv-{min-width:300px}.styles-module__spotlight-form--3Jtv- .ant-form-item{font-size:12px;line-height:20px;margin-bottom:12px}.styles-module__spotlight-form--3Jtv- .ant-form-item-label{line-height:1;padding:0 0 2px}.styles-module__spotlight-button--RP6Fk{position:absolute;bottom:0;right:12px}", "",{"version":3,"sources":["webpack://./src/components/SpotlightInfo/styles.module.css"],"names":[],"mappings":"AAAA,sCACE,eAAA,CACA,qDACE,cAAA,CACA,gBAAA,CACA,kBAAA,CAEF,2DACE,aAAA,CACA,eAAA,CAIJ,wCACE,iBAAA,CACA,QAAA,CACA,UAAA","sourcesContent":[".spotlight-form {\n  min-width: 300px;\n  :global(.ant-form-item) {\n    font-size: 12px;\n    line-height: 20px;\n    margin-bottom: 12px;\n  }\n  :global(.ant-form-item-label) {\n    line-height: 1;\n    padding: 0 0 2px;\n  }\n}\n\n.spotlight-button {\n  position: absolute;\n  bottom: 0;\n  right: 12px;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"spotlight-form": "styles-module__spotlight-form--3Jtv-",
	"spotlight-button": "styles-module__spotlight-button--RP6Fk"
};
export default ___CSS_LOADER_EXPORT___;
