import immutable from "immutable";
import React, { useCallback, useState } from "react";

export const useExpandable = () => {
  const [keys, setKeys] = useState<immutable.Set<string>>(immutable.Set([]));
  // This doesn't appear to do anything useful but not sure if I can remove...
  const [autoExpandParent, setAutoExpandParent] = useState(true);

  const keyArray = React.useMemo(() => {
    return keys.toArray();
  }, [keys]);

  const onExpandNode = useCallback(
    (newExpandedKeys: string[], appendToExisting: boolean) => {
      const newKeysCallback = appendToExisting
        ? (e) => immutable.Set(newExpandedKeys.concat(e.toArray()))
        : () => immutable.Set(newExpandedKeys);
      setKeys(newKeysCallback);
      setAutoExpandParent(false);
    },
    [],
  );

  return {
    keys: keyArray,
    onExpandNode,
    autoExpandParent,
  };
};
