import _ from "lodash";

import React from "react";
import { useProject } from "../../App/state";
import { useNotification } from "../../contexts/Notifications";
import { useUpdateSpotlightMutation } from "../../generated/types";
import { SpotlightForm } from "./SpotlightForm";
import { Spotlight } from "./types";

type SpotlightInfoProps = {
  spotlight: Spotlight;
  currentBatchId: string;
  onUpdate?: () => void;
};

export const SpotlightInfo = (props: SpotlightInfoProps) => {
  const project = useProject();
  const notify = useNotification();

  const [updateSpotlight] = useUpdateSpotlightMutation({
    onCompleted: () => {
      notify("Spotlight updated!", "success");
      props.onUpdate && props.onUpdate();
    },
    onError: () => notify("Failed to update spotlight!", "error"),
    update: (cache: any) => {
      Object.keys(cache.data.data).forEach((key) => {
        if (key.match(/^Shot:/)) {
          cache.evict({ id: key, fieldName: "spotlights" });
        }
      });
    },
  });

  const onResolve = () => {
    updateSpotlight({
      variables: {
        ...project,
        batchId: props.currentBatchId,
        id: props.spotlight.id,
        resolvedInBatchId: props.currentBatchId,
      },
    });
  };

  return (
    <SpotlightForm
      mappingValue={props.spotlight.mapping}
      typeValue={props.spotlight.type}
      createdInBatchId={props.spotlight.createdInBatchId}
      resolvedInBatchId={props.spotlight.resolvedInBatchId}
      onChange={(values) => {
        updateSpotlight({
          variables: {
            customer: project.customer,
            project: project.project,
            scope: project.scope,
            batchId: props.currentBatchId,
            id: props.spotlight.id,
            mappingId: values.mappingId,
            type: values.type,
            createdInBatchId: values.createdInBatchId,
            // turn undefined into null, to make sure we "unset" the value
            resolvedInBatchId: values.resolvedInBatchId ?? null,
          },
        });
      }}
      onResolve={
        _.isNil(props.spotlight.resolvedInBatchId) ? onResolve : undefined
      }
    />
  );
};
