import _ from "lodash";
import { GetAllFloorplansScanSequencesAndScenesQuery } from "../../generated/types";
import { getSceneScanType } from "../SpatialConfigurationMode/hooks/useScenes";
import {
  ScanSequenceLevelType,
  ScanSequencePrefixType,
  SceneScanType,
  SceneSubject,
} from "../SpatialConfigurationMode/types";
import { SequenceMapData, SequenceMapFloorplan } from "./types";

export const PT_TO_MM = 0.3527777777778;

type ScanSequencesByFilter = {
  spaceScenes: {
    id: string;
    mmX?: number | null;
    mmY?: number | null;
    mmZOffset: number;
    subjects: SceneSubject[];
    scanType: SceneScanType;
  }[];
  floorplan: SequenceMapFloorplan;
  scanLevel: ScanSequenceLevelType;
  scenes: {
    id: string;
    rank: number;
    sequencePrefix: ScanSequencePrefixType;
  }[];
};

export const gqlToSequenceMapsData = (
  data: GetAllFloorplansScanSequencesAndScenesQuery,
): SequenceMapData[] => {
  const scanSequencesByFilter: ScanSequencesByFilter[] =
    data.scanSequencesByFilter.map((sequence) => ({
      spaceScenes: _.flatten(
        sequence.floorplan.space.descendantSpaces
          .map((descenantSpace) =>
            descenantSpace.scenes.map((s) => ({
              ..._.pick(s, "id", "mmX", "mmY", "mmZOffset", "subjects"),
              scanType: getSceneScanType(descenantSpace.category, s.subjects),
            })),
          )
          .concat(
            sequence.floorplan.space.scenes.map((s) => ({
              ..._.pick(s, "id", "mmX", "mmY", "mmZOffset", "subjects"),
              scanType: getSceneScanType(
                sequence.floorplan.space.category,
                s.subjects,
              ),
            })),
          ),
      ),
      scanLevel: sequence.scanLevel,
      floorplan: {
        ..._.pick(
          sequence.floorplan,
          "id",
          "width",
          "height",
          "originX",
          "originY",
          "mmScaleFactor",
        ),
        url: sequence.floorplan.signedUrl,
        spaceName: sequence.floorplan.space.name,
        ancestorSpacesNames: sequence.floorplan.space.ancestors.map(
          (a) => a.name,
        ),
      },
      scenes: sequence.scenes.map((s, idx) => ({
        ...s,
        sequencePrefix: sequence.sequencePrefix,
        rank: idx + 1,
      })),
    }));

  const scanSequencesByScanLevel = _(scanSequencesByFilter)
    .groupBy((s) => `${s.floorplan.id}_${s.scanLevel}`)
    .values()
    .map((s) =>
      _.reduce(
        s.slice(1),
        (acc, n: ScanSequencesByFilter) => ({
          ...acc,
          scenes: [...acc.scenes, ...n.scenes],
        }),
        s[0],
      ),
    )
    .value();

  return scanSequencesByScanLevel
    .map((scanSequence) => {
      const floorplanScenes = _(scanSequence.spaceScenes)
        .keyBy("id")
        .merge(_.keyBy(scanSequence.scenes, "id"))
        .values()
        .reject((s) => s.rank == null || s.mmX == null || s.mmY == null)
        .map((s) => ({
          ...s,
          sequenceNumber: `${s.sequencePrefix}${s.rank}`,
        }))
        .value();
      return {
        floorplan: scanSequence.floorplan,
        scanLevel: scanSequence.scanLevel,
        scenes: floorplanScenes.map((scene) => ({
          ...scene,
          x: scene.mmX as number, // we already rejected undefined or null
          y: scene.mmY as number, // we already rejected undefined or null
          zOffset: scene.mmZOffset,
        })),
      };
    })
    .filter((s) => s.scenes.length > 0);
};
