import React from "react";
import { Link } from "../Link";
import styles from "./DisplayTreeStyles.module.css";

type SelectAllNoneLinkProps = {
  selectExpandedCallback: () => void;
  selectNoneCallback: () => void;
  selectSearchHitsCallback?: () => void;
};

export const SelectAllNoneLink = (props: SelectAllNoneLinkProps) => {
  const {
    selectExpandedCallback,
    selectNoneCallback,
    selectSearchHitsCallback,
  } = props;

  return (
    <div className={styles["link-container"]}>
      Select: <Link onClick={selectExpandedCallback}>All Visible</Link> |{" "}
      <Link onClick={selectSearchHitsCallback}>Visible Search Results</Link> |{" "}
      <Link onClick={selectNoneCallback}>None</Link>
    </div>
  );
};
