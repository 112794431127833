import { DeleteOutlined } from "@ant-design/icons";
import { Button } from "antd";
import React, { useState } from "react";
import { DeleteConfirmationModal } from "../../../components/DeleteConfirmationModal/DeleteConfirmationModal";
import { useNotification } from "../../../contexts/Notifications";
import { useClearDatesMutation } from "../../../generated/types";
import { Plan, Project } from "../../../types";

const DELETE_DATES_MODAL_TITLE = "Clear Dates";
const DELETE_CONFIRMATION_ALERT_MESSAGE =
  "Are you sure you want to delete all the dates?";
const DELETE_CONFIRMATION_REQ_TEXT = "delete all dates";

export type Props = {
  project: Project;
  plan: Plan;
};

export const ClearPlanDatesModal = (props: Props) => {
  const { project, plan } = props;
  const notify = useNotification();
  const [visible, setVisible] = useState(false);

  const [clearDates] = useClearDatesMutation({
    onCompleted: (result) =>
      notify(
        `Deleted ${result.clearDates} dates for plan ${plan?.name}`,
        "success",
      ),
    onError: (error) =>
      notify(`Dates file upload failed: ${error.message}`, "error"),
  });

  const onOk = async () => {
    await clearDates({
      variables: {
        ...project,
        planId: plan.id as string,
      },
    });
  };

  const deleteDatesModalBody = (
    <p>
      This action will delete all dates for the plan <b>{plan.name}</b>, and
      clear all the weights used in aggregations.
    </p>
  );

  return (
    <>
      <Button
        data-testid="delete-button"
        type="primary"
        danger
        onClick={() => setVisible(true)}
        icon={<DeleteOutlined />}
      >
        Clear Dates
      </Button>
      <DeleteConfirmationModal
        title={DELETE_DATES_MODAL_TITLE}
        visible={visible}
        setVisible={setVisible}
        onOk={onOk}
        deletionText={DELETE_CONFIRMATION_REQ_TEXT}
        modalBody={deleteDatesModalBody}
        alertText={DELETE_CONFIRMATION_ALERT_MESSAGE}
      />
    </>
  );
};
