import { Alert, Button, Card, Modal } from "antd";
import React, { useState } from "react";
import { Project } from "../../types";
import { useRandomId } from "../../utils/react-utils";
import styles from "./ConfigurationBottomRibbons.module.css";
import { NewSpaceForm } from "./spaces/NewSpaceForm";

const MODAL_TITLE = "Add root-level space";

interface ConfigurationRootSpacesBottomRibbonProps {
  project: Project;
}

export const ConfigurationRootSpacesBottomRibbon = (
  props: ConfigurationRootSpacesBottomRibbonProps,
) => {
  const { project } = props;
  const formId = useRandomId();
  const [isModalVisible, setIsModalVisible] = useState(false);

  return (
    <Card size="small" className={styles["bottom-ribbon"]}>
      <div className={styles["add-space-button-row"]}>
        <Button
          className={styles["add-space-button"]}
          onClick={() => setIsModalVisible(true)}
        >
          {MODAL_TITLE}
        </Button>
      </div>
      <Alert
        message="Please select one or multiple scenes/spaces to edit them"
        type="info"
        showIcon
      />
      <Modal
        visible={isModalVisible}
        title={MODAL_TITLE}
        footer={null}
        onCancel={() => setIsModalVisible(false)}
      >
        <>
          <NewSpaceForm id={formId} project={project} selectedSpaces={[]} />
          <div className={styles["submit-button-box"]}>
            <Button
              className={styles["submit-button"]}
              form={formId}
              block
              type="primary"
              htmlType="submit"
            >
              Submit
            </Button>
          </div>
        </>
      </Modal>
    </Card>
  );
};
