import classNames from "classnames";
import React from "react";
import styles from "./ConfigurationLabelComposed.module.css";

type ConfigurationLabelComposedProps = {
  focusedText: string;
  peripheryTexts: string[];
  hoist?: boolean;
  className?: string;
};

const HoistMarker = () => <span className={styles["hoist-marker"]}>H</span>;

export const ComposedLabel = (props: ConfigurationLabelComposedProps) => (
  <span className={classNames(props.className, styles["tree-container"])}>
    <span className={styles["focus-text"]}>
      {props.hoist && <HoistMarker />}
      {props.focusedText}
    </span>

    {props.peripheryTexts.map((text, idx) => (
      <span key={[idx, text].join("_")} className={styles["periphery-text"]}>
        {text}
      </span>
    ))}
  </span>
);
