import { ApolloClient, useApolloClient } from "@apollo/client";
import React, { PropsWithChildren, useEffect } from "react";
import { useProject } from "../../App/state";
import { Notify, useNotification } from "../../contexts/Notifications";
import {
  FloorplansWithDescendantsDocument,
  FloorplansWithDescendantsQuery,
  GetDefaultSceneDocument,
  GetDefaultSceneQuery,
  GetNextIdenticalSceneDocument,
  GetNextIdenticalSceneQuery,
  GetNextSceneDocument,
  GetNextSceneQuery,
  GetPreviousIdenticalSceneDocument,
  GetPreviousIdenticalSceneQuery,
  GetPreviousSceneDocument,
  GetPreviousSceneQuery,
} from "../../generated/types";
import { Batch } from "../../types";
import { PanoView, Project } from "../../types";
import { normaliseYaw, samePositions } from "../../utils/pano";
import {
  ThunkCreatorWithContext,
  bindActionCreators,
  bindThunks,
  useThunkReducer,
} from "../../utils/react-utils";

export const DEFAULT_HFOV = 120;

export interface FloorplanWithDescendantSpaces {
  id: string;
  bimOriginX?: number | null | undefined;
  bimOriginY?: number | null | undefined;
  bimOriginZ?: number | null | undefined;
  floorHeight?: number | null | undefined;
  angleToTrueNorth?: number | null | undefined;
  space: { id: string; descendantSpaces: string[] };
}
export interface FloorplanData {
  bimOriginX?: number | null | undefined;
  bimOriginY?: number | null | undefined;
  bimOriginZ?: number | null | undefined;
  floorHeight?: number | null | undefined;
  angleToTrueNorth?: number | null | undefined;
}

export interface DualModeState {
  rootSpaceId?: string;
  floorplans: FloorplanWithDescendantSpaces[];
  currentSceneFloorplanData?: FloorplanData;
  currentSceneId?:
    | { loading: true; value?: never }
    | { loading: false; value?: string };
  referenceBatch?: Batch;
  comparisonBatch?: Batch;
  view: PanoView;
  focusedSpotlightId?: string;
}

interface ThunkContext {
  project: Project;
  // eslint-disable-next-line @typescript-eslint/ban-types
  client: ApolloClient<object>;
  notify: Notify;
}

type DualModeThunk<Args extends any[]> = ThunkCreatorWithContext<
  Args,
  DualModeState,
  Action,
  ThunkContext
>;

type Spotlight = {
  id: string;
  sceneId: string;
  yaw: number;
  pitch: number;
};

export interface DualModeActions {
  setRootSpace: (spaceId: string) => void;
  setCurrentScene: (sceneId?: string) => void;
  setReferenceBatch: (batch: Batch) => void;
  setComparisonBatch: (batch?: Batch) => void;
  setView: (view: PanoView) => void;
  setNextScene: () => void;
  setPreviousScene: () => void;
  moveSceneUp: () => void;
  moveSceneDown: () => void;
  loadDefaultScene: (spaceId: string) => void;
  focusOnSpotlight: (spotlight: Spotlight | undefined) => void;
}

export interface DualModeContextValue extends DualModeState, DualModeActions {}

export const initialState: DualModeState = {
  view: {
    position: {
      pitch: 0,
      yaw: 0,
    },
    hfov: DEFAULT_HFOV,
  },
  floorplans: [],
};

interface SetRootSpaceAction {
  type: "set_root_space";
  payload: string;
}

const setRootSpace = (spaceId: string): SetRootSpaceAction => ({
  type: "set_root_space",
  payload: spaceId,
});

interface SetCurrentSceneLoadingAction {
  type: "set_current_scene_loading";
}

const setCurrentSceneLoading = (): SetCurrentSceneLoadingAction => ({
  type: "set_current_scene_loading",
});

interface SetCurrentSceneAction {
  type: "set_current_scene";
  payload?: string;
}

const setCurrentScene = (sceneId?: string): SetCurrentSceneAction => ({
  type: "set_current_scene",
  payload: sceneId,
});

interface SetCurrentSceneFloorPlanDataAction {
  type: "set_current_scene_floorplan_data";
  payload?: FloorplanData;
}

const setCurrentSceneFloorPlanData = (
  floorplanData?: FloorplanData,
): SetCurrentSceneFloorPlanDataAction => ({
  type: "set_current_scene_floorplan_data",
  payload: floorplanData,
});

interface SetFloorPlansAction {
  type: "set_floorplans";
  payload?: FloorplanWithDescendantSpaces[];
}

const setFloorPlans = (
  floorplans?: FloorplanWithDescendantSpaces[],
): SetFloorPlansAction => ({
  type: "set_floorplans",
  payload: floorplans,
});
interface SetReferenceBatchAction {
  type: "set_reference_batch";
  payload: Batch;
}

const setReferenceBatch = (batch: Batch): SetReferenceBatchAction => ({
  type: "set_reference_batch",
  payload: batch,
});

interface SetComparisonBatchAction {
  type: "set_comparison_batch";
  payload?: Batch;
}

const setComparisonBatch = (batch?: Batch): SetComparisonBatchAction => ({
  type: "set_comparison_batch",
  payload: batch,
});

interface SetViewAction {
  type: "set_view";
  payload: PanoView;
}

const setView = (view: PanoView): SetViewAction => ({
  type: "set_view",
  payload: {
    hfov: view.hfov,
    position: {
      pitch: view.position.pitch,
      yaw: normaliseYaw(view.position.yaw),
    },
  },
});

interface FocusOnSpotlightAction {
  type: "focus_on_spotlight";
  payload: Spotlight | undefined;
}

const focusOnSpotlight = (
  spotlight: Spotlight | undefined,
): FocusOnSpotlightAction => ({
  type: "focus_on_spotlight",
  payload: spotlight
    ? { ...spotlight, yaw: normaliseYaw(spotlight?.yaw) }
    : undefined,
});

type Action =
  | SetRootSpaceAction
  | SetCurrentSceneLoadingAction
  | SetCurrentSceneAction
  | SetCurrentSceneFloorPlanDataAction
  | SetFloorPlansAction
  | SetReferenceBatchAction
  | SetComparisonBatchAction
  | SetViewAction
  | FocusOnSpotlightAction;

export const DualModeContext = React.createContext<DualModeContextValue | null>(
  null,
);

export const useDualModeState = () => {
  const contextValue = React.useContext(DualModeContext);
  if (contextValue == null) {
    throw new Error("Cannot useDualModeState outside of a Provider");
  }
  return contextValue;
};

const reducer = (state: DualModeState, action: Action): DualModeState => {
  switch (action.type) {
    case "set_root_space":
      return action.payload !== state.rootSpaceId
        ? { ...state, rootSpaceId: action.payload }
        : state;
    case "set_current_scene":
      return action.payload !== state.currentSceneId?.value
        ? {
            ...state,
            currentSceneId: { loading: false, value: action.payload },
          }
        : state;
    case "set_current_scene_floorplan_data":
      return action.payload !== state.currentSceneFloorplanData
        ? {
            ...state,
            currentSceneFloorplanData: action.payload,
          }
        : state;
    case "set_floorplans":
      return action.payload !== state.floorplans && action.payload
        ? {
            ...state,
            floorplans: action.payload,
          }
        : state;
    case "set_reference_batch":
      return action.payload !== state.referenceBatch
        ? { ...state, referenceBatch: action.payload }
        : state;
    case "set_comparison_batch":
      return action.payload !== state.comparisonBatch
        ? { ...state, comparisonBatch: action.payload }
        : state;
    case "set_view":
      return !samePositions(action.payload, state.view)
        ? {
            ...state,
            view: action.payload,
          }
        : state;
    case "focus_on_spotlight":
      if (!action.payload) {
        return { ...state, focusedSpotlightId: undefined };
      }
      return {
        ...state,
        focusedSpotlightId: action.payload.id,
        currentSceneId: { loading: false, value: action.payload.sceneId },
        view: {
          hfov: DEFAULT_HFOV,
          position: { pitch: action.payload.pitch, yaw: action.payload.yaw },
        },
      };
    default:
      return state;
  }
};

const loadDefaultScene =
  ({ project, client, notify }: ThunkContext) =>
  (spaceId: string) =>
  async (dispatch: React.Dispatch<Action>, getState: () => DualModeState) => {
    dispatch(setCurrentSceneLoading());
    const state = getState();
    const defaultScene = await client.query<GetDefaultSceneQuery>({
      query: GetDefaultSceneDocument,
      variables: {
        ...project,
        spaceId,
        batchId: state.referenceBatch?.id,
      },
    });
    if (defaultScene.data.space.defaultScene != null) {
      const scene = defaultScene.data.space.defaultScene;
      dispatch(setCurrentScene(scene.id));
      dispatch(
        setCurrentSceneFloorPlanData(
          state.floorplans.find((fp) =>
            fp.space.descendantSpaces.includes(scene.space.id),
          ),
        ),
      );
    } else {
      dispatch(setCurrentScene(undefined));
      notify(
        `No scene available for space ${spaceId}. You may not have the necessary permissions to view this space and scene.`,
        "warning",
      );
    }
  };

const setNextScene =
  ({ project, client, notify }: ThunkContext) =>
  () =>
  async (dispatch: React.Dispatch<Action>, getState: () => DualModeState) => {
    const state = getState();
    const { data: nextSceneData } = await client.query<GetNextSceneQuery>({
      query: GetNextSceneDocument,
      variables: {
        ...project,
        sceneId: state.currentSceneId?.value,
        rootSpaceId: state.rootSpaceId,
        batchId: state.referenceBatch?.id,
      },
    });
    const nextScene = nextSceneData.scene?.nextScene;
    if (nextScene !== null && nextScene !== undefined) {
      dispatch(setCurrentScene(nextScene.id));
      dispatch(
        setCurrentSceneFloorPlanData(
          state.floorplans.find((fp) =>
            fp.space.descendantSpaces.includes(nextScene.space.id),
          ),
        ),
      );
    } else {
      notify("No next scene available");
    }
  };

const setPreviousScene =
  ({ project, client, notify }: ThunkContext) =>
  () =>
  async (dispatch: React.Dispatch<Action>, getState: () => DualModeState) => {
    const state = getState();
    const { data: previousSceneData } =
      await client.query<GetPreviousSceneQuery>({
        query: GetPreviousSceneDocument,
        variables: {
          ...project,
          sceneId: state.currentSceneId?.value,
          rootSpaceId: state.rootSpaceId,
          batchId: state.referenceBatch?.id,
        },
      });
    const previousScene = previousSceneData.scene?.previousScene;
    if (previousScene !== null && previousScene !== undefined) {
      dispatch(setCurrentScene(previousScene.id));
      dispatch(
        setCurrentSceneFloorPlanData(
          state.floorplans.find((fp) =>
            fp.space.descendantSpaces.includes(previousScene.space.id),
          ),
        ),
      );
    } else {
      notify("No previous scene available");
    }
  };

const moveSceneUp =
  ({ project, client, notify }: ThunkContext) =>
  () =>
  async (dispatch: React.Dispatch<Action>, getState: () => DualModeState) => {
    const state = getState();
    const { data: nextIdenticalSceneData } =
      await client.query<GetNextIdenticalSceneQuery>({
        query: GetNextIdenticalSceneDocument,
        variables: {
          ...project,
          sceneId: state.currentSceneId?.value,
          rootSpaceId: state.rootSpaceId,
          batchId: state.referenceBatch?.id,
        },
      });
    const nextIdenticalScene = nextIdenticalSceneData.scene?.nextIdenticalScene;
    if (nextIdenticalScene !== null && nextIdenticalScene !== undefined) {
      dispatch(setCurrentScene(nextIdenticalScene.id));
      dispatch(
        setCurrentSceneFloorPlanData(
          state.floorplans.find((fp) =>
            fp.space.descendantSpaces.includes(nextIdenticalScene.space.id),
          ),
        ),
      );
    } else {
      notify("No next identical scene available");
    }
  };

const moveSceneDown =
  ({ project, client, notify }: ThunkContext) =>
  () =>
  async (dispatch: React.Dispatch<Action>, getState: () => DualModeState) => {
    const state = getState();
    const { data: previousSceneData } =
      await client.query<GetPreviousIdenticalSceneQuery>({
        query: GetPreviousIdenticalSceneDocument,
        variables: {
          ...project,
          sceneId: state.currentSceneId?.value,
          rootSpaceId: state.rootSpaceId,
          batchId: state.referenceBatch?.id,
        },
      });
    const previousIdenticalScene =
      previousSceneData.scene?.previousIdenticalScene;
    if (
      previousIdenticalScene !== undefined &&
      previousIdenticalScene !== null
    ) {
      dispatch(setCurrentScene(previousIdenticalScene.id));
      dispatch(
        setCurrentSceneFloorPlanData(
          state.floorplans.find((fp) =>
            fp.space.descendantSpaces.includes(previousIdenticalScene.space.id),
          ),
        ),
      );
    } else {
      notify("No previous identical scene available");
    }
  };

export const Provider = (props: PropsWithChildren<any>) => {
  const [state, dispatch] = useThunkReducer<DualModeState, Action>(
    reducer,
    initialState,
  );

  const project = useProject();
  const notify = useNotification();
  const client = useApolloClient();

  useEffect(() => {
    const loadFloorplans = async () => {
      const { data } = await client.query<FloorplansWithDescendantsQuery>({
        query: FloorplansWithDescendantsDocument,
        variables: {
          tenant: project,
        },
        context: {
          clientName: "delivery-core",
        },
      });

      dispatch(
        setFloorPlans(
          data.floorplans.length
            ? data.floorplans.map((fp) => ({
                ...fp,
                space: {
                  id: fp.space.id,
                  descendantSpaces: fp.space.descendantSpaces.map((s) => s.id),
                },
              }))
            : [],
        ),
      );
    };

    loadFloorplans();
  }, [client, dispatch, project]);

  const thunkContext = React.useMemo(
    () => ({ project, notify, client }),
    [project, notify, client],
  );

  const thunks = React.useMemo(
    () =>
      bindThunks<
        DualModeState,
        Action,
        ThunkContext,
        {
          loadDefaultScene: DualModeThunk<[string]>;
          setPreviousScene: DualModeThunk<[]>;
          setNextScene: DualModeThunk<[]>;
          moveSceneUp: DualModeThunk<[]>;
          moveSceneDown: DualModeThunk<[]>;
        }
      >(
        {
          loadDefaultScene,
          setPreviousScene,
          setNextScene,
          moveSceneUp,
          moveSceneDown,
        },
        thunkContext,
      ),
    [thunkContext],
  );

  const actions = React.useMemo(
    () =>
      bindActionCreators(
        {
          setRootSpace,
          setCurrentScene,
          setComparisonBatch,
          setReferenceBatch,
          setView,
          focusOnSpotlight,
          ...thunks,
        },
        dispatch,
      ),
    [dispatch, thunks],
  );
  const context = React.useMemo(
    () => ({
      ...state,
      ...actions,
    }),
    [state, actions],
  );

  return (
    <DualModeContext.Provider value={context}>
      {props.children}
    </DualModeContext.Provider>
  );
};
