import React from "react";
import { SVG_CIRCLE_SIZE_MM } from "../drawing-utils";
import { SimpleTextNode } from "../types";

type SimpleTextProps = {
  node: SimpleTextNode;
};

export const SimpleText = ({ node }: SimpleTextProps) => {
  return (
    <text
      x={node.x}
      y={node.y}
      fontSize={SVG_CIRCLE_SIZE_MM * 1.5}
      textAnchor="middle"
      dominantBaseline="middle"
      fontWeight="bold"
      fill={node.color}
      stroke={node.strokeColor}
      strokeWidth={node.strokeWidth}
      strokeLinecap="round"
      strokeLinejoin="round"
      paintOrder="stroke"
    >
      {node.label}
      <title>{node.title}</title>
    </text>
  );
};
