import { Card } from "antd";
import React from "react";
import { BuildingStructureUploadButton } from "../../components/BuildingStructureUploader";
import { DisplayTreeNode } from "../../components/DisplayTree/types";
import { SpacesTree } from "../../components/SpacesTree/SpacesTree";
import { Space } from "../../components/SpacesTree/tree-nodes";
import { Project } from "../../types";

export interface Props {
  project: Project;
  onCheck: (selectedNodes: DisplayTreeNode[]) => void;
  onLoadSpacesTree: (spacesByIds: Record<string, Space>) => void;
  nodeKeysToUpdate?: string[];
  onNodeKeysUpdated?: (keys: string[]) => void;
}

const SPACES_TREE_TITLE = "Spaces";

const ConfigurationSpacesTree = (props: Props) => {
  const { project, ...spacesTreeProps } = props;

  return (
    <Card
      title={SPACES_TREE_TITLE}
      extra={<BuildingStructureUploadButton project={project} />}
    >
      <SpacesTree
        editable
        checkable
        searchable
        showTagsSwitch
        showMetadata
        showMappings
        showScenes
        {...spacesTreeProps}
      />
    </Card>
  );
};

export default ConfigurationSpacesTree;
