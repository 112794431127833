import { Modal } from "antd";
import React, { useCallback, useState } from "react";
import { useNotification } from "../../contexts/Notifications";
import { DisplayTreeNode } from "../DisplayTree/types";
import { SpacesTree } from "../SpacesTree/SpacesTree";
import { Scene } from "../SpatialConfigurationMode/types";
import { isNodeScene } from "../SpatialConfigurationMode/utils";
import styles from "./SceneAliasModal.module.css";

const SCENE_LABEL = "Scene";
const ALIAS_LABEL = "Alias";

type SceneWithName = Pick<Scene, "id" | "name">;

interface SceneAliasModalProps {
  scene: SceneWithName;
  onCreate: (id: string, aliasId: string) => Promise<void>;
  onClose: () => void;
}

export const SceneAliasModal = (props: SceneAliasModalProps) => {
  const { scene, onCreate, onClose } = props;
  const notify = useNotification();
  const [aliasScene, setAliasScene] = useState<SceneWithName>();

  const selectSceneAlias = (node: DisplayTreeNode) => {
    isNodeScene(node)
      ? setAliasScene({ id: node.key, name: node.name })
      : notify("Select a scene node", "warning");
  };

  const createSceneAliasCallback = useCallback(
    async (id: string, aliasId?: string) => {
      if (aliasId) {
        await onCreate(id, aliasId);
      }
      onClose();
    },
    [onCreate, onClose],
  );

  return (
    <Modal
      bodyStyle={{ height: "80vh" }}
      closable={false}
      visible={true}
      onCancel={onClose}
      destroyOnClose={true}
      onOk={() => createSceneAliasCallback(scene.id, aliasScene?.id)}
      okButtonProps={{ disabled: !aliasScene }}
      okText={"Confirm"}
    >
      <div className={styles["scene-name-box"]}>
        {`${SCENE_LABEL}: ${scene.name}`}
      </div>
      <div className={styles["scene-name-box"]}>
        {`${ALIAS_LABEL}: ${aliasScene?.name ?? ""}`}
      </div>
      <div className={styles["spaces-tree-box"]}>
        <SpacesTree showScenes onSelect={selectSceneAlias} />
      </div>
    </Modal>
  );
};
