import React from "react";
import sceneAliasIcon from "../../../statics/images/scene-alias.png";
import { IconNode } from "../types";

type AliasIconNodeProps = {
  node: IconNode;
};

const ALIAS_CLASS_NAME = "alias";

export const AliasIcon = ({ node }: AliasIconNodeProps) => {
  return (
    <g id={node.id} className={ALIAS_CLASS_NAME}>
      <circle
        cx={node.x}
        cy={node.y}
        id={node.id}
        r={node.size}
        fill={"none"}
        stroke={node.strokeColor ?? node.color}
        strokeWidth={node.strokeWidth}
        opacity={node.opacity}
      />
      <image
        width={node.size}
        x={node.x - node.size / 2}
        y={node.y - node.size / 2}
        height={node.size}
        href={sceneAliasIcon}
      />
      {node.title && <title>{node.title}</title>}
    </g>
  );
};
