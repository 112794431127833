import {
  ApolloCache,
  DocumentNode,
  NormalizedCacheObject,
} from "@apollo/client";
import _ from "lodash";
import { useProject } from "../../../App/state";
import { useNotification } from "../../../contexts/Notifications";
import {
  SceneWithSpacesAliasesAndSubjectsFragmentFragment,
  SceneWithSpacesAliasesAndSubjectsFragmentFragmentDoc,
  SceneWithSpacesFragmentFragment,
  SceneWithSpacesFragmentFragmentDoc,
  useCreateSceneUnderSpaceMutation,
  useDeleteScenesSpatialConfigMutation,
  useGetScenesUnderSpaceQuery,
  usePatchScenesSpatialConfigMutation,
  useUpdateScenesSpatialConfigMutation,
} from "../../../generated/types";
import { Scene, SceneScanType, SceneSubject } from "../types";

export const updateCacheOnNewScenes = (
  cache: ApolloCache<NormalizedCacheObject>,
  createdScenes: SceneWithSpacesAliasesAndSubjectsFragmentFragment[],
) => {
  createdScenes.forEach((newScene) => {
    cache.modify({
      id: cache.identify(newScene.space),
      fields: {
        scenes(existingScenes) {
          const newSceneRef = cache.writeFragment({
            data: newScene,
            fragment: SceneWithSpacesAliasesAndSubjectsFragmentFragmentDoc,
            fragmentName: "SceneWithSpacesAliasesAndSubjectsFragment",
          });
          return [...existingScenes, newSceneRef];
        },
      },
    });
  });
};

export const updateCacheOnPatchedOrUpdatedScenes = (
  cache: ApolloCache<NormalizedCacheObject>,
  prevScenes: SceneIdToScene,
  modifiedScenes: (
    | SceneWithSpacesAliasesAndSubjectsFragmentFragment
    | SceneWithSpacesFragmentFragment
  )[],
  fragment: DocumentNode,
  fragmentName: string,
) => {
  modifiedScenes.forEach((modifiedScene) => {
    const prevSpaceId = prevScenes[modifiedScene.id].parentId;
    const newSpaceId = modifiedScene.space.id;
    // create updated ref
    const updatedSceneRef = cache.writeFragment({
      data: modifiedScene,
      fragment,
      fragmentName,
    });
    if (prevSpaceId !== newSpaceId) {
      // remove refs from old parent
      cache.modify({
        id: cache.identify({ __typename: "Space", id: prevSpaceId }),
        fields: {
          scenes(existingScenesRef, { readField }) {
            return existingScenesRef.filter(
              (ref) => readField("id", ref) !== modifiedScene.id,
            );
          },
        },
      });
      // add refs to new parent
      cache.modify({
        id: cache.identify({ __typename: "Space", id: newSpaceId }),
        fields: {
          scenes(existingScenesRef, { readField }) {
            if (
              existingScenesRef.some(
                (ref) => readField("id", ref) === modifiedScene.id,
              )
            ) {
              return existingScenesRef;
            }
            return [...existingScenesRef, updatedSceneRef];
          },
        },
      });
    }
  });
};

const NEW_SCENE_NAME = "New scene";

const CUPBOARD_CATEGORY = "CUPBOARD";
const CUPBOARD_CATEGORY_STRINGS = ["cupboard", "utility cupboard"];

export const sceneToSceneScanType: {
  [key in SceneSubject | typeof CUPBOARD_CATEGORY]: SceneScanType;
} = {
  [SceneSubject.Firestop]: SceneScanType.CLOSE_UP,
  [SceneSubject.Riser]: SceneScanType.CUPBOARD,
  [SceneSubject.Facade]: SceneScanType.DEFAULT,
  [SceneSubject.HighCeiling]: SceneScanType.DEFAULT,
  [SceneSubject.BuildingFullHeight]: SceneScanType.DEFAULT,
  [CUPBOARD_CATEGORY]: SceneScanType.CUPBOARD,
};

export const getSceneScanType = (
  spaceCategory?: string | null,
  subjects?: SceneSubject[],
) => {
  if (
    spaceCategory &&
    CUPBOARD_CATEGORY_STRINGS.some(
      (cupboardCategory) =>
        spaceCategory.toLocaleLowerCase() === cupboardCategory,
    )
  ) {
    return sceneToSceneScanType.CUPBOARD;
  } else if (subjects?.length) {
    return subjects.includes(SceneSubject.Riser)
      ? sceneToSceneScanType[SceneSubject.Riser]
      : sceneToSceneScanType[subjects[0]];
  }
  return SceneScanType.DEFAULT;
};

export type ScenePatch = {
  spaceId?: string;
  name?: string;
  aliasId?: string | null;
  addSubjects?: SceneSubject[];
  removeSubjects?: SceneSubject[];
  mmZOffset?: number;
};

export type UpdateSceneInput = {
  id: string;
  name: string;
  guiIndex: number;
  spaceId: string;
  mmX: number;
  mmY: number;
  mmZOffset: number;
};

export type CreateSceneInput = {
  spaceId: string;
  mmX?: number;
  mmY?: number;
};

export type SceneIdToScene = {
  [key: string]: Scene;
};

type SpaceIdToSpace = {
  [key: string]: {
    id: string;
    name: string;
    parentId?: string;
    category?: string | null;
  };
};

export type ScenesEditor = {
  scenes: SceneIdToScene;
  onPatch: (ids: string[], scene: ScenePatch) => Promise<void>;
  onDelete: (sceneIds: string[]) => Promise<void>;
  onCreate: (scene: CreateSceneInput) => Promise<Pick<Scene, "id" | "name">>;
  onUpdate: (scenesToUpdate: UpdateSceneInput[]) => Promise<void>;
};

const getSpaceAncestorNames = (
  spacesById: SpaceIdToSpace,
  currentId?: string,
) => {
  if (!currentId) {
    return [];
  }
  return [
    ...getSpaceAncestorNames(spacesById, spacesById[currentId].parentId),
    spacesById[currentId].name,
  ];
};

export const useScenes = (rootSpaceId?: string): ScenesEditor => {
  const project = useProject();
  const notify = useNotification();

  const { data: scenesUnderSpaceData } = useGetScenesUnderSpaceQuery({
    variables: {
      tenant: project,
      spaceId: rootSpaceId,
    },
    skip: !rootSpaceId,
  });

  const rootSpaceData = scenesUnderSpaceData?.spacesByFilter[0];
  const spacesById = _.keyBy(
    rootSpaceData
      ? [
          {
            id: rootSpaceData.id,
            name: rootSpaceData.name,
            parentId: null,
            category: rootSpaceData.category,
          },
          ...rootSpaceData.descendantSpaces.map((s) => ({
            id: s.id,
            name: s.name,
            parentId: s.parentSpace?.id,
            category: s.category,
          })),
        ]
      : [],
    "id",
  );

  const scenesForSpace = _.flatMapDeep(
    scenesUnderSpaceData?.spacesByFilter,
    ({ id, scenes: spaceScenes, descendantSpaces }) =>
      descendantSpaces
        .map((descenantSpace) =>
          descenantSpace.scenes.map((s) => ({
            ...s,
            parentId: descenantSpace.id,
          })),
        )
        .concat(spaceScenes.map((s) => ({ ...s, parentId: id }))),
  );
  const scenes: SceneIdToScene = _.keyBy(
    scenesForSpace.map((scene) => ({
      ...scene,
      x: scene.mmX ? scene.mmX : undefined,
      y: scene.mmY ? scene.mmY : undefined,
      zOffset: scene.mmZOffset,
      aliasSceneId: scene.aliasScene?.id,
      subjects: scene.subjects,
      parentId: scene.parentId,
      guiIndex: scene.guiIndex,
      path: getSpaceAncestorNames(spacesById, scene.parentId),
      scanType: getSceneScanType(
        spacesById[scene.parentId].category,
        scene.subjects,
      ),
    })),
    "id",
  );

  const [patchScene] = usePatchScenesSpatialConfigMutation({
    onError: (error) =>
      notify(`Scene update failed: ${error.message}`, "error"),
    onCompleted: () => {
      notify("Scene successfully patched!", "success");
    },
    update: (cache, { data }) => {
      updateCacheOnPatchedOrUpdatedScenes(
        cache,
        scenes,
        data?.patchScenes ?? [],
        SceneWithSpacesAliasesAndSubjectsFragmentFragmentDoc,
        "SceneWithSpacesAliasesAndSubjectsFragment",
      );
    },
  });

  const [updateScenes] = useUpdateScenesSpatialConfigMutation({
    onError: (error) =>
      notify(`Scenes update failed: ${error.message}`, "error"),
    onCompleted: () => {
      notify("Scenes successfully updated!", "success");
    },
    update: (cache, { data }) => {
      updateCacheOnPatchedOrUpdatedScenes(
        cache,
        scenes,
        data?.updateScenes ?? [],
        SceneWithSpacesFragmentFragmentDoc,
        "SceneWithSpacesFragment",
      );
    },
  });

  const [deleteScenes] = useDeleteScenesSpatialConfigMutation({
    onCompleted: () => {
      notify("Scenes successfully deleted", "success");
    },
    onError: (error) => notify(`Error deleting scenes - ${error}`, "error"),
    update: (cache, { data }) => {
      if (data?.deleteScenes) {
        data.deleteScenes.forEach((id) => {
          cache.evict({
            id: cache.identify({ __typename: "Scene", id }),
          });
        });
        cache.gc();
      }
    },
  });

  const [createScene] = useCreateSceneUnderSpaceMutation({
    onCompleted: () => {
      notify("Scene created successfully", "success");
    },
    onError: (error) => notify(`Error creating scene - ${error}`, "error"),
    update: (cache, { data }) =>
      data?.createScenes &&
      updateCacheOnNewScenes(cache, data?.createScenes ?? []),
  });

  return {
    scenes,
    onPatch: async (ids: string[], scene: ScenePatch) => {
      const addSubjects = scene.addSubjects ?? [];
      const removeSubjects = scene.removeSubjects ?? [];
      await patchScene({
        variables: {
          tenant: project,
          sceneIds: ids,
          update: {
            ...(scene.spaceId
              ? { setSpaceId: { newValue: scene.spaceId } }
              : {}),
            ...(scene.name ? { setName: { newValue: scene.name } } : {}),
            ...(scene.aliasId !== undefined
              ? { setAliasSceneId: { newValue: scene.aliasId } }
              : {}),
            ...(scene.mmZOffset !== undefined
              ? { setMMZOffset: { newValue: scene.mmZOffset } }
              : {}),
            addSubjects,
            removeSubjects,
          },
        },
      });
    },
    onDelete: async (sceneIds: string[]) => {
      await deleteScenes({
        variables: {
          tenant: project,
          sceneIds,
        },
      });
    },
    onCreate: async (
      scene: CreateSceneInput,
    ): Promise<Pick<Scene, "id" | "name">> => {
      const resp = await createScene({
        variables: {
          tenant: project,
          scene: {
            ...scene,
            name: `${NEW_SCENE_NAME} ${Math.floor(Math.random() * Date.now())}`,
          },
        },
      });
      if (!resp.data?.createScenes[0]) {
        throw new Error("Failed to create scene");
      }
      return resp.data.createScenes[0];
    },
    onUpdate: async (scenesToUpdate: UpdateSceneInput[]) => {
      await updateScenes({
        variables: {
          tenant: project,
          scenes: scenesToUpdate,
        },
      });
    },
  };
};
