import { Result, Spin } from "antd";
import _ from "lodash";
import React from "react";
import { useNotification } from "../../contexts/Notifications";
import {
  useGetAllProjectsQuery,
  useUpdateProjectStartDateMutation,
} from "../../generated/types";
import { ProjectsTable } from "./ProjectsTable";

export type Project = {
  customer: string;
  project: string;
  displayName: string | undefined;
  defaultLanguage: string;
  startDate: Date | undefined;
};

export const ProjectsTableContainer = () => {
  const { data, loading, error } = useGetAllProjectsQuery();

  const projects = React.useMemo(() => {
    if (!data) {
      return [];
    }
    const unsortedProjects = data.projects.map((p) => ({
      customer: p.customer,
      project: p.project,
      displayName: p.config.displayName ?? undefined,
      defaultLanguage: p.config.defaultLanguage,
      startDate: p.config.startDate,
    }));

    return _.sortBy(unsortedProjects, ["customer", "project"]);
  }, [data]);

  const notify = useNotification();

  const [updateProjectStartDate] = useUpdateProjectStartDateMutation({
    onCompleted: (updateResult) =>
      notify(
        `${updateResult.updateProjectStartDate.project} project start date updated successfully!`,
        "success",
      ),
    onError: (err) =>
      notify(`Failed to update project start date! ${err.message}`, "error"),
  });

  if (error) {
    return (
      <Result
        status="error"
        title="Error projects table"
        subTitle="Please refresh, try later again or escalate to the support team"
      />
    );
  }

  return (
    <div style={{ height: "100%" }}>
      <Spin spinning={loading}>
        <ProjectsTable
          projects={projects}
          onCellValueChange={async (row) => {
            await updateProjectStartDate({
              variables: {
                customer: row.customer,
                project: row.project,
                startDate: row.startDate,
              },
            });
          }}
        />
      </Spin>
    </div>
  );
};
