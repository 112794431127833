import { Button, Form, Input } from "antd";
import React from "react";
import { useProject } from "../../../App/state";
import { useNotification } from "../../../contexts/Notifications";
import {
  ComponentTypeFragmentDoc,
  useCreateComponentTypeMutation,
} from "../../../generated/types";
import {
  CREATE_COMPONENT_TYPE_ERROR,
  CREATE_COMPONENT_TYPE_SUCCESS,
} from "../../ComponentViewer/messages";

const MISSING_INPUT_MESSAGE = "Missing input!";

export const CreateComponentTypeForm = (props: { onSubmit: () => void }) => {
  const notify = useNotification();
  const project = useProject();
  const [createComponentType] = useCreateComponentTypeMutation({
    update: (cache, { data }) => {
      cache.modify({
        fields: {
          componentTypes() {
            data?.createComponentType
              ? cache.writeFragment({
                  id: `ComponentType:${data.createComponentType.id}`,
                  data: data.createComponentType,
                  fragment: ComponentTypeFragmentDoc,
                })
              : [];
          },
        },
      });
    },
    onCompleted: () => {
      notify(CREATE_COMPONENT_TYPE_SUCCESS, "success");
    },
    onError: () => {
      notify(CREATE_COMPONENT_TYPE_ERROR, "error");
    },
  });

  const [form] = Form.useForm();
  const handleOk = async () => {
    try {
      await form.validateFields();
    } catch (err) {
      return;
    }
    await createComponentType({
      variables: {
        ...project,
        name: form.getFieldValue("name"),
        description: form.getFieldValue("description"),
      },
    });

    form.resetFields();
    props.onSubmit();
  };

  return (
    <>
      <Form form={form} onFinish={handleOk}>
        <Form.Item
          label="Name"
          name="name"
          rules={[{ required: true, message: MISSING_INPUT_MESSAGE }]}
        >
          <Input data-testid="form-component-type-name-input" />
        </Form.Item>
        <Form.Item
          label="Description"
          name="description"
          rules={[
            {
              required: true,
              message: MISSING_INPUT_MESSAGE,
            },
          ]}
        >
          <Input data-testid="form-component-type-description-input" />
        </Form.Item>
      </Form>
      <Button type="primary" onClick={handleOk} data-testid="form-ok-button">
        Ok
      </Button>
    </>
  );
};
