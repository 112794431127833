import { gql } from "@apollo/client";
import { Button } from "antd";
import React, { useState } from "react";
import { EditBatchesModal } from "./EditBatchesModal";

export const EDIT_BATCHES_TXT = "Edit Week Numbers";

export const UpdateBatchFragmentDoc = gql`
  fragment UpdateBatch on Batch {
    weekNumber
  }
`;

type Props = {
  customer: string;
  project: string;
};

export const EditBatchesButton = (props: Props) => {
  const { customer, project } = props;
  const [modalVisible, setModalVisible] = useState(false);

  return (
    <>
      <Button onClick={() => setModalVisible(true)}>{EDIT_BATCHES_TXT}</Button>
      <EditBatchesModal
        customer={customer}
        project={project}
        visible={modalVisible}
        setVisible={setModalVisible}
      />
    </>
  );
};
