import { PlusCircleOutlined } from "@ant-design/icons";
import { Button, Popover, Select } from "antd";
import _ from "lodash";
import React, { useCallback, useEffect, useMemo, useState } from "react";
import { useNotification } from "../../contexts/Notifications";
import {
  ScanSequenceLevelType,
  ScanSequencePrefixType,
  Scene,
  Sequence,
} from "../SpatialConfigurationMode/types";
import { CreateSequencePopover } from "./CreateSequencePopover";
import styles from "./SequenceMode.module.css";
import { Card, SequencesTable } from "./SequencesTable";

const SCAN_LEVEL_SELECTOR_PLACEHOLDER = "Scanning stage: ";
const PREFIXES_SELECTOR_PLACEHOLDER = "Prefix: ";
const NEW_SEQUENCE_POPOVER_TITLE = "Choose a new prefix to create a sequence";

type SequenceModePanelProps = {
  editMode: boolean;
  onSetEditMode: (editMode: boolean) => void;
  selectedSequence?: Sequence;
  onSelectSequence: (sequence: Sequence | undefined) => void;
  sequences: Sequence[];
  onCreate: (
    prefix: ScanSequencePrefixType,
    scanLevel: ScanSequenceLevelType,
  ) => void;
  onSave: () => Promise<void>;
  onSequenceScenesChange: (sceneIds: string[]) => void;
  scenes?: { [id: string]: Scene };
  selectedScanLevel?: ScanSequenceLevelType;
  setScanLevel: (scanLevel: ScanSequenceLevelType) => void;
};

export const SequenceModePanel = (props: SequenceModePanelProps) => {
  const notify = useNotification();
  const {
    editMode,
    onSetEditMode,
    selectedSequence,
    onSelectSequence,
    sequences,
    onCreate,
    onSave,
    onSequenceScenesChange,
    scenes,
    setScanLevel,
    selectedScanLevel,
  } = props;

  const [selectedPrefix, setSelecedPrefix] = useState<ScanSequencePrefixType>();
  const [popoverVisible, setPopoverVisible] = useState<boolean>();

  const filteredSequencePerPrefix = useMemo(
    () =>
      _.keyBy(
        sequences.filter((s) => s.scanLevel === selectedScanLevel),
        "sequencePrefix",
      ),
    [sequences, selectedScanLevel],
  );

  useEffect(() => {
    setSelecedPrefix(undefined);
  }, [sequences]);

  const onSelectScanLevel = useCallback(
    (scanLevel: ScanSequenceLevelType) => {
      setScanLevel(ScanSequenceLevelType[scanLevel]);
      onSelectSequence(undefined);
      setSelecedPrefix(undefined);
    },
    [onSelectSequence, setScanLevel],
  );

  const onCreateSequence = useCallback(
    (prefix: ScanSequencePrefixType) => {
      selectedScanLevel
        ? onCreate(prefix, selectedScanLevel)
        : notify("Select a scanning phase to create a new sequence", "warning");
      setPopoverVisible(false);
      setSelecedPrefix(prefix);
    },
    [selectedScanLevel, onCreate, notify],
  );

  useEffect(() => {
    if (selectedPrefix && selectedPrefix in filteredSequencePerPrefix) {
      onSelectSequence(filteredSequencePerPrefix[selectedPrefix]);
    }
  }, [selectedPrefix, filteredSequencePerPrefix, onSelectSequence]);

  const scanLevelSelectorOptions = useMemo(
    () =>
      Object.keys(ScanSequenceLevelType).map((key) => ({
        value: key,
        label: SCAN_LEVEL_SELECTOR_PLACEHOLDER + ScanSequenceLevelType[key],
      })),
    [],
  );

  const prefixesSelectorOptions = useMemo(
    () =>
      Object.keys(filteredSequencePerPrefix)
        .sort()
        .map((key) => ({
          value: key,
          label: `${PREFIXES_SELECTOR_PLACEHOLDER} ${key}`,
        })),
    [filteredSequencePerPrefix],
  );

  const [sequenceCards, setSequenceCards] = useState<Card[]>([]);

  useEffect(() => {
    setSequenceCards(
      selectedSequence?.sceneIds && scenes
        ? selectedSequence?.sceneIds.map((s, index) => ({
            id: s,
            text: scenes[s].path.toString() + "," + scenes[s].name,
            prefix: selectedSequence.sequencePrefix,
            order: index + 1,
          }))
        : [],
    );
  }, [selectedSequence, scenes]);

  return (
    <div className={styles["sequence-mode__box"]}>
      <Select
        className={styles["sequence-mode__selector"]}
        options={scanLevelSelectorOptions}
        value={selectedScanLevel}
        onChange={onSelectScanLevel}
        placeholder={SCAN_LEVEL_SELECTOR_PLACEHOLDER}
      />
      <div className={styles["sequence-mode__prefixes-bar"]}>
        <Select<ScanSequencePrefixType>
          className={styles["sequence-mode__prefixes-selector"]}
          options={prefixesSelectorOptions}
          value={selectedPrefix}
          onChange={(value) => {
            setSelecedPrefix(value);
          }}
          disabled={!selectedScanLevel}
          placeholder={PREFIXES_SELECTOR_PLACEHOLDER}
        />
        <Popover
          content={
            <CreateSequencePopover
              onSubmit={onCreateSequence}
              onClose={() => setPopoverVisible(false)}
            />
          }
          title={NEW_SEQUENCE_POPOVER_TITLE}
          visible={popoverVisible}
          placement="bottom"
        >
          <Button
            size="large"
            type="text"
            icon={<PlusCircleOutlined />}
            onClick={() => setPopoverVisible(true)}
          />
        </Popover>
      </div>
      <div className={styles["droppable-container-button-bar"]}>
        {sequenceCards.length ? (
          <SequencesTable
            cards={sequenceCards}
            onCardsEdit={onSequenceScenesChange}
            isEditMode={editMode}
          />
        ) : (
          <></>
        )}
        <div className={styles["sequence-mode__buttons-bar"]}>
          {editMode ? (
            <div>
              <SequenceModeButton
                onClick={() => {
                  onSave(), onSetEditMode(false);
                }}
                title="Save"
                disabled={!selectedSequence}
              />
              <SequenceModeButton
                onClick={() => onSetEditMode(false)}
                title="Cancel Editing"
              />
            </div>
          ) : (
            <SequenceModeButton
              onClick={() => onSetEditMode(true)}
              title="Edit"
            />
          )}
        </div>
      </div>
    </div>
  );
};

interface SequenceModeButtonProps {
  title: string;
  onClick: () => void;
  disabled?: boolean;
}

const SequenceModeButton = ({
  title,
  onClick,
  disabled,
}: SequenceModeButtonProps) => {
  return (
    <Button
      className={styles["sequence-mode__buttons-bar-button"]}
      onClick={() => onClick()}
      disabled={disabled}
    >
      {title}
    </Button>
  );
};
