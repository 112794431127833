import classNames from "classnames";
import React, { useCallback, useEffect, useRef, useState } from "react";
import { useProject } from "../../App/state";
import { useNotification } from "../../contexts/Notifications";
import { useCreateArrowMutation } from "../../generated/types";
import { PanoPosition, PanoView } from "../../types";
import { Batch } from "../../types";
import { normaliseYaw, samePositions } from "../../utils/pano";
import { DropTarget } from "../DragAndDrop/DropTarget";
import {
  Arrow,
  ArrowConfig,
  Spotlight,
  SpotlightConfig,
  SpotlightCreation,
} from "./Annotations";
import styles from "./DualViewer.module.css";

import { viewer as pannellumViewer } from "./pannellumViewer";

import { ImageConfig } from "./types";

const createScene = (
  imageConfig: ImageConfig,
  view: PanoView,
  frozen: boolean,
) => ({
  type: "multires",
  multiRes: {
    basePath: imageConfig.basePath,
    path: imageConfig.pathFormat,
    extension: imageConfig.fileExtension,
    tileResolution: imageConfig.tileResolution,
    maxLevel: imageConfig.maxLevel,
    cubeResolution: imageConfig.cubeResolution,
    queryStrings: imageConfig.authQueryStrings,
  },
  pitch: view.position.pitch + imageConfig.pitchOffset,
  yaw: normaliseYaw(view.position.yaw + imageConfig.yawOffset),
  hfov: view.hfov,
  draggable: !frozen,
});

// Used inside Pannellum to append auth querystrings to template tile URLs
const imageSrcFormatter = (src: string, image: any) => {
  const key = src.split("?")[0].split("/").slice(-2).join("/");
  return image.queryStrings[key] != null
    ? `${src}?${image.queryStrings[key]}`
    : src;
};

const createViewer = (viewerId: string) => {
  const scenesOptions = {
    default: {
      sceneFadeDuration: 100,
      doubleClickZoom: false, // disabled because it stutters in dual view
      showFullscreenCtrl: false,
      disableKeyboardCtrl: true,
      autoload: false,
    },
    scenes: {},
    imageSrcFormatter,
  };
  return pannellumViewer(viewerId, scenesOptions);
};

type SingleViewerProps = {
  viewerId: string;
  imageConfig: ImageConfig;
  batch: Batch;
  sceneId: string;
  panellumSceneId: string;
  view: PanoView;
  onChangeView: (view: PanoView) => void;
  spotlights: SpotlightConfig[];
  arrows: ArrowConfig[];
  // for now we don't need any data back, just a hook
  // to possibly refetch, and change state
  onSpotlightUpdate?: () => void;
  enableSpotlightCreation: boolean;
  focusedSpotlightId?: string;
  frozen?: boolean;
  showCrosshair?: boolean;
  pitchBounds?: { min: number; max: number };
};

export const SingleViewer = ({
  viewerId,
  imageConfig,
  batch,
  sceneId,
  panellumSceneId,
  view,
  onChangeView,
  spotlights,
  arrows,
  enableSpotlightCreation,
  onSpotlightUpdate,
  focusedSpotlightId,
  frozen,
  showCrosshair,
  pitchBounds,
}: SingleViewerProps) => {
  const { yawOffset, pitchOffset } = imageConfig;
  const project = useProject();
  const notify = useNotification();

  const [viewer, setViewer] = useState<Viewer | null>(null);
  const [viewerElementId, setViewerElementId] = useState<string | null>(null);
  const [renderedSceneId, setRenderedSceneId] = useState<string>();
  const [newSpotlightPosition, setNewSpotlightPosition] =
    useState<PanoPosition>();
  const newMousePosition = useRef<PanoPosition>();

  const containerRef = useCallback((containerElement) => {
    if (containerElement !== null) {
      setViewerElementId(containerElement.id);
    }
  }, []);

  useEffect(() => {
    if (viewer === null && viewerElementId !== null) {
      setViewer(createViewer(viewerElementId));
    }
    return () => {
      viewer?.destroy();
    };
  }, [viewer, viewerElementId]);

  useEffect(() => {
    const onPanoUpdate = (update) => {
      const { pitch, yaw, hfov } = update;
      const newView = {
        position: {
          pitch: pitch - pitchOffset,
          yaw: normaliseYaw(yaw - yawOffset),
        },
        hfov: hfov,
      };
      if (!samePositions(view, newView)) {
        onChangeView(newView);
      }
    };
    // after a scene has finished loading trigger a state-update
    // to force that spotlights and arrows are added to the correct scene,
    // and after it has been loaded
    const onSceneLoad = () => {
      setRenderedSceneId(panellumSceneId);
    };
    if (viewer !== null) {
      viewer.on("renderFinished", onPanoUpdate);
      viewer.on("load", onSceneLoad);
      return () => {
        viewer.off("renderFinished", onPanoUpdate);
        viewer.off("load", onSceneLoad);
      };
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [viewer, onChangeView, yawOffset, pitchOffset]);

  useEffect(() => {
    if (viewer !== null && viewer.getScene() !== panellumSceneId) {
      const sceneConfig = createScene(imageConfig, view, frozen ?? false);
      viewer.addScene(panellumSceneId, sceneConfig);
      viewer.loadScene(panellumSceneId);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [viewer, panellumSceneId, imageConfig]);

  useEffect(() => {
    if (viewer !== null) {
      viewer.setPitchBounds([pitchBounds?.min, pitchBounds?.max]);
    }
  }, [viewer, pitchBounds]);

  useEffect(() => {
    if (viewer !== null && viewer.getScene()) {
      viewer.lookAt(
        view.position.pitch + pitchOffset,
        normaliseYaw(view.position.yaw + yawOffset),
        view.hfov,
        false,
      );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [view, yawOffset, pitchOffset]);

  useEffect(() => {
    if (viewer !== null) {
      const config = viewer.getConfig();
      config.draggable = !frozen;
    }
  }, [viewer, frozen]);

  const [createArrow] = useCreateArrowMutation({
    onCompleted: () => notify("Arrow created!", "success"),
    onError: () => notify("Failed to create arrow!", "error"),
    update: (cache: any) => {
      Object.keys(cache.data.data).forEach((key) => {
        if (key.match(/^Shot:/)) {
          cache.evict({ id: key, fieldName: "arrows" });
        }
      });
    },
  });

  const onItemDropped = ({ id }) =>
    newMousePosition.current &&
    createArrow({
      variables: {
        ...project,
        sceneId: sceneId,
        targetSceneId: id,
        batchId: batch.id,
        yaw: newMousePosition.current.yaw,
        pitch: newMousePosition.current.pitch,
      },
    });

  return (
    // TODO: Refactor and unify the logic for dragging scenes, arrows and spotlights, as they are currently
    // all handled in different places.
    <DropTarget<{ id: string } | ArrowConfig | SpotlightConfig>
      onItemDropped={{ scene: onItemDropped }}
      itemTypes={["scene", "arrow", "spotlight"]}
      className={styles["pano-container"]}
    >
      <div
        id={viewerId}
        ref={containerRef}
        data-testid="single-viewer"
        // to allow drag and drop of spotlights
        onDragOver={(event) => event.preventDefault()}
        onDrop={(event) => {
          if (viewer) {
            const coords = viewer.mouseEventToCoords(event);
            newMousePosition.current = {
              yaw: normaliseYaw(coords[1] - yawOffset),
              pitch: coords[0] - pitchOffset,
            };
          }
        }}
        onClick={(event) => {
          if (viewer && enableSpotlightCreation && !newSpotlightPosition) {
            const coords = viewer.mouseEventToCoords(event);
            setNewSpotlightPosition({
              yaw: normaliseYaw(coords[1] - yawOffset),
              pitch: coords[0] - pitchOffset,
            });
          }
        }}
      >
        <div className={classNames({ [styles.crosshair]: showCrosshair })} />
        {/* add arrows*/}
        {viewer !== null &&
          renderedSceneId === panellumSceneId &&
          arrows.map((arrow) => (
            <Arrow
              key={arrow.id}
              viewer={viewer}
              arrow={arrow}
              yawOffset={yawOffset}
              pitchOffset={pitchOffset}
              currentBatch={batch}
              sceneId={sceneId}
            />
          ))}
        {/* add existing spotlights */}
        {viewer !== null &&
          renderedSceneId === panellumSceneId &&
          spotlights.map((spot) => (
            <Spotlight
              key={spot.id}
              spotlight={spot}
              viewer={viewer}
              currentBatch={batch}
              onUpdate={onSpotlightUpdate}
              yawOffset={yawOffset}
              pitchOffset={pitchOffset}
              isFocused={spot.id === focusedSpotlightId}
            />
          ))}
        {/* add spotlight that is in the process of being created */}
        {viewer !== null &&
          renderedSceneId === panellumSceneId &&
          newSpotlightPosition && (
            <SpotlightCreation
              viewer={viewer}
              currentBatch={batch}
              position={newSpotlightPosition}
              onClose={() => {
                setNewSpotlightPosition(undefined);
                if (onSpotlightUpdate) {
                  onSpotlightUpdate();
                }
              }}
              yawOffset={yawOffset}
              pitchOffset={pitchOffset}
            />
          )}
      </div>
    </DropTarget>
  );
};
