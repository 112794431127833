import _ from "lodash";
import { Coordinates } from "./types";

const PITCH_BOUNDS_DEGREES = { min: -89, max: 89 };

const degreesToRadians = (deg: number) => deg * (Math.PI / 180);
/**
 *
 * @param cx - camera position x coordinates
 * @param cy - camera position y coordinates
 * @param cz - camera position z coordinates
 * @param yaw - spherical coordinate yaw
 * @param pitch - spherical coordinate yaw
 * @param radius - camera radius (set as 50m default forge camera radius)
 * @returns vector of cartesian coordinates of the target view
 */
export const yawPitchToCartesian = (
  cx: number,
  cy: number,
  cz: number,
  yaw: number,
  pitch: number,
  radius = 50,
) => {
  // Convert degrees to radians
  const yawRad = degreesToRadians(-yaw); // 3D model and 360 image are rotating in opposite directions, multiply with -1 to change that
  const pitchRad = degreesToRadians(
    _.clamp(pitch, PITCH_BOUNDS_DEGREES.min, PITCH_BOUNDS_DEGREES.max),
  );

  // Convert yaw and pitch to Cartesian coordinates
  const x = radius * Math.cos(pitchRad) * Math.cos(yawRad) + cx;
  const y = radius * Math.cos(pitchRad) * Math.sin(yawRad) + cy;
  const z = radius * Math.sin(pitchRad) + cz;

  return new THREE.Vector3(x, y, z);
};

/**
 * Converts an angle from radians to degrees.
 * @param rad The angle in radians.
 * @returns The angle in degrees.
 */
const radiansToDegrees = (rad: number) => rad * (180 / Math.PI);

/**
 * @param cx - camera position x coordinates
 * @param cy - camera position y coordinates
 * @param cz - camera position z coordinates
 * @param x - target position x coordinates
 * @param y - target position y coordinates
 * @param z - target position z coordinates
 * @returns yaw and pitch of the target view
 * @see https://stackoverflow.com/questions/1568568/how-to-convert-euler-angles-to-directional-vector
 */
export const cartesianToYawPitch = (
  cx: number,
  cy: number,
  cz: number,
  x: number,
  y: number,
  z: number,
) => {
  // Translate the target coordinates to the camera's coordinate system
  const tx = x - cx;
  const ty = y - cy;
  const tz = z - cz;

  // Calculate yaw (azimuth)
  const yawRad = Math.atan2(ty, tx);

  // Calculate pitch (elevation)
  const pitchRad = Math.atan2(tz, Math.sqrt(tx * tx + ty * ty));

  // Convert radians to degrees
  const yaw = -radiansToDegrees(yawRad);
  const pitch = radiansToDegrees(pitchRad);

  return { yaw, pitch };
};

export const rotateCoordinates = (coords: Coordinates, deg: number) => {
  const rad = degreesToRadians(deg);
  const rotatedX = coords.x * Math.cos(rad) - coords.y * Math.sin(rad);
  const rotatedY = coords.x * Math.sin(rad) + coords.y * Math.cos(rad);

  return { x: rotatedX, y: rotatedY, z: coords.z };
};

export const translateCoordinates = (
  coordinates: Coordinates,
  newOrigin: Coordinates,
) => {
  const x = coordinates.x + newOrigin.x;
  const y = coordinates.y + newOrigin.y;
  const z = coordinates.z + newOrigin.z;
  return { x, y, z };
};
