import _ from "lodash";
import React from "react";

export type ActionCreator<Args extends any[], Action> = (
  ...args: Args
) => Action;

type ArgsOf<T> = T extends (...args: infer Args) => any ? Args : never;

export const bindActionCreator =
  <Args extends any[], Action>(
    actionCreator: ActionCreator<Args, Action>,
    dispatch: React.Dispatch<Action>,
  ) =>
  (...args: Args) =>
    dispatch(actionCreator(...args));

export function bindActionCreators<
  ActionCreators extends Record<string, ActionCreator<any, Action>>,
  Action,
>(
  actionCreators: ActionCreators,
  dispatch: React.Dispatch<Action>,
): {
  [K in keyof ActionCreators as K]: (
    ...args: ArgsOf<ActionCreators[K]>
  ) => void;
} {
  return _.mapValues(
    actionCreators,
    (actionCreator: ActionCreator<any, Action>) =>
      bindActionCreator(actionCreator, dispatch),
  ) as any;
}

export const useThunkReducer = <State, Action>(
  reducer: React.Reducer<State, Action>,
  initialState: State,
) => {
  const [state, setState] = React.useState(initialState);

  const stateRef = React.useRef(state);

  const dispatch: React.Dispatch<Action | ThunkAction<State, Action>> =
    React.useCallback(
      (action: Action | ThunkAction<State, Action>) => {
        if (typeof action === "function") {
          (action as ThunkAction<State, Action>)(
            dispatch,
            () => stateRef.current,
          );
        } else {
          const nextState = reducer(stateRef.current, action);
          stateRef.current = nextState;
          setState(nextState);
        }
      },
      [reducer],
    );

  return [state, dispatch] as const;
};

export type ThunkAction<S, A> = (
  dispatch: React.Dispatch<A>,
  getState: () => S,
) => any;

export type ThunkCreatorWithContext<Args extends any[], S, A, C = undefined> = (
  context: C,
) => ThunkCreator<Args, S, A>;

export type ThunkCreator<Args extends any[], S, A> = (
  ...args: Args
) => ThunkAction<S, A>;

export const bindThunk = <Args extends any[], S, A, C>(
  thunk: ThunkCreatorWithContext<Args, S, A, C>,
  context: C,
): ThunkCreator<Args, S, A> => thunk(context);

type ArgsOfThunkCreatorWithContext<T> = T extends ThunkCreatorWithContext<
  infer A,
  any,
  any,
  any
>
  ? A
  : never;

export function bindThunks<
  S,
  A,
  C,
  Thunks extends Record<string, ThunkCreatorWithContext<any, S, A, C>>,
>(
  thunks: Thunks,
  context: C,
): {
  [K in keyof Thunks]: ThunkCreator<
    ArgsOfThunkCreatorWithContext<Thunks[K]>,
    S,
    A
  >;
} {
  return _.mapValues(thunks, (thunk) => bindThunk(thunk, context)) as any;
}

export const useDebounce = <T>(value: T, delay: number): T => {
  const [debouncedValue, setDebouncedValue] = React.useState(value);
  React.useEffect(() => {
    const timeout = setTimeout(() => {
      setDebouncedValue(value);
    }, delay);

    return () => {
      clearTimeout(timeout);
    };
  }, [value, delay]);
  return debouncedValue;
};

export const useDebouncedEffect = <T>(
  value: T,
  delay: number,
  callback: (t: T) => void | (() => void),
): T => {
  const debounced = useDebounce(value, delay);
  // eslint-disable-next-line react-hooks/exhaustive-deps
  React.useEffect(() => callback(debounced), [debounced]);
  return debounced;
};

export const useRandomId = () => {
  const randomId = React.useRef((Math.random() + 1).toString(36).substring(7));
  return randomId.current;
};
