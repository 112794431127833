import { Card, Form, Input, Popconfirm, Table } from "antd";
import type { FormInstance } from "antd/es/form";
import React from "react";
import { EDIT_BATCHES_TXT, EditBatchesButton } from "./EditBatchesButton";
import { NewProjectButton } from "./NewProjectButton";
import { Project } from "./ProjectsTableContainer";
import styles from "./styles.module.css";

type TableData = {
  startDate: string | Date;
  customer: string;
  project: string;
  displayName: string | undefined;
  defaultLanguage: string;
  key: string;
};

const COLUMNS = [
  {
    title: "Customer",
    dataIndex: "customer",
    key: "customer",
  },
  {
    title: "Project",
    dataIndex: "project",
    key: "project",
  },
  {
    title: "Display Name",
    dataIndex: "displayName",
    key: "displayName",
  },
  {
    title: "Default Language",
    dataIndex: "defaultLanguage",
    key: "defaultLanguage",
  },
  {
    title: "Start Date",
    dataIndex: "startDate",
    key: "startDate",
    editable: true,
  },
  {
    title: EDIT_BATCHES_TXT,
    dataIndex: "batches",
    key: "batches",
    render: (_, data: TableData) => (
      <EditBatchesButton customer={data.customer} project={data.project} />
    ),
  },
];

const NO_START_DATE = "None";

const EditableContext = React.createContext<FormInstance<any> | null>(null);

interface Item {
  key: string;
  customer: string;
  project: string;
  displayName: string;
  defaultLanguage: string;
  startDate: Date;
}

const EditableRow = (props) => {
  const [form] = Form.useForm();
  return (
    <Form form={form} component={false}>
      <EditableContext.Provider value={form}>
        <tr {...props} />
      </EditableContext.Provider>
    </Form>
  );
};

interface EditableCellProps {
  title: React.ReactNode;
  editable: boolean;
  children: React.ReactNode;
  dataIndex: keyof Item;
  record: Item;
  handleSave: (record: Item) => void;
}

const EditableCell: React.FC<EditableCellProps> = (
  props: EditableCellProps,
) => {
  const { title, editable, children, dataIndex, record, handleSave } = props;
  const [editing, setEditing] = React.useState(false);
  const inputRef = React.useRef<Input>(null);
  const form = React.useContext(EditableContext)!;

  React.useEffect(() => {
    if (editing) {
      inputRef.current!.focus();
    }
  }, [editing]);

  const toggleEdit = () => {
    setEditing(!editing);
    form.setFieldsValue({ [dataIndex]: record[dataIndex] });
  };

  const cancel = () => {
    setTimeout(() => toggleEdit(), 1000);
  };

  const save = async () => {
    try {
      const values = await form.validateFields();

      toggleEdit();
      handleSave({ ...record, ...values });
    } catch (errInfo) {
      console.log(`${title} save failed:`, errInfo);
    }
  };

  let childNode = children;

  if (editable) {
    childNode = editing ? (
      <div onBlur={cancel}>
        <Popconfirm
          title={`Are you sure want to save changes to ${record.displayName} ${title}？`}
          okText="Yes"
          cancelText="No"
          onConfirm={save}
          onCancel={toggleEdit}
          destroyTooltipOnHide={false}
        >
          <Form.Item style={{ margin: 0 }} name={dataIndex}>
            <Input ref={inputRef} />
          </Form.Item>
        </Popconfirm>
      </div>
    ) : (
      <div onClick={toggleEdit}>{children}</div>
    );
  }
  return <td>{childNode}</td>;
};

type ProjectsTableProps = {
  projects: Project[];
  onCellValueChange: (project: Project) => Promise<any>;
};

interface TableRow {
  key: string;
  customer: string;
  project: string;
  displayName: string | undefined;
  defaultLanguage: string;
  startDate: Date | string | undefined;
}

export const ProjectsTable = (props: ProjectsTableProps) => {
  const { onCellValueChange, projects } = props;
  const handleSave = async (row: TableRow) => {
    await onCellValueChange({ ...row, startDate: row.startDate as Date });
  };

  const dataSource: TableData[] = projects.map((project) => {
    return {
      key: project.project,
      ...project,
      startDate: project.startDate ?? NO_START_DATE,
    };
  });

  const components = {
    body: {
      row: EditableRow,
      cell: EditableCell,
    },
  };

  const columns = COLUMNS.map((col) => {
    if (!col.editable) {
      return col;
    }
    return {
      ...col,
      onCell: (record: TableRow) => ({
        record,
        editable: col.editable,
        dataIndex: col.dataIndex,
        title: col.title,
        handleSave,
      }),
    };
  });

  return (
    <Card title="Projects Overview">
      <div className={styles["add-new-project-button-container"]}>
        <NewProjectButton projects={projects} />
      </div>
      <Table
        dataSource={dataSource}
        components={components}
        columns={columns}
        pagination={false}
        rowKey={(project) => `${project.customer}-${project.project}`}
      />
    </Card>
  );
};
