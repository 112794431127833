// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/cssWithMappingToString.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".styles-module__input-container--1Sc7P{display:flex;margin-right:10px;width:100%}", "",{"version":3,"sources":["webpack://./src/components/InputWithAsyncConfirm/styles.module.css"],"names":[],"mappings":"AAAA,uCACI,YAAA,CACA,iBAAA,CACA,UAAA","sourcesContent":[".input-container {\n    display: flex;\n    margin-right: 10px;\n    width: 100%;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"input-container": "styles-module__input-container--1Sc7P"
};
export default ___CSS_LOADER_EXPORT___;
