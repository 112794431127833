import React, {
  Reducer,
  createContext,
  useContext,
  useEffect,
  useMemo,
  useReducer,
} from "react";

export interface Props {
  children: React.ReactNode;
}

export interface AuthDetails {
  username: string;
}

export type AuthState =
  | {
      status: "loading";
    }
  | {
      status: "unauthenticated";
    }
  | {
      status: "error";
      error: Error;
    }
  | {
      status: "authenticated";
      auth: AuthDetails;
    };

export interface AuthCallbacks {
  login: () => void;
  logout: () => void;
  refresh: () => Promise<void>;
}

export type Auth = AuthState & AuthCallbacks;

const login = () => {
  window.location.href = `${
    process.env.JANUS_URL
  }/login?redirect_url=${encodeURIComponent(window.location.href)}`;
};

const logout = () => {
  window.location.href = `${
    process.env.JANUS_URL
  }/logout?redirect_url=${encodeURIComponent(window.location.origin)}`;
};

const AuthContext = createContext<Auth>({
  status: "loading",
  login,
  logout,
  refresh: () =>
    Promise.reject(
      new Error("Cannot refresh token - auth has not initialised."),
    ),
});

type Action =
  | { type: "loading" }
  | { type: "logged-in"; payload: AuthDetails }
  | { type: "logged-out" }
  | { type: "error"; payload: Error };

const reducer = (_state: AuthState, action: Action): AuthState => {
  switch (action.type) {
    case "loading":
      return { status: "loading" };
    case "logged-out":
      return { status: "unauthenticated" };
    case "logged-in":
      return { status: "authenticated", auth: action.payload };
    case "error":
      return { status: "error", error: action.payload };
  }
};

type AuthResult =
  | { type: "refresh" }
  | { type: "valid"; username: string }
  | { type: "error"; error: Error };

const whoami = async (): Promise<AuthResult> => {
  const whoamiUrl = `/api/whoami`;
  const res = await fetch(whoamiUrl, {
    credentials: "include",
  });
  try {
    if (res.status === 401) {
      return { type: "refresh" };
    }
    if (res.status < 200 || res.status >= 400) {
      return {
        type: "error",
        error: new Error(
          `Error contacting authentication server (${whoamiUrl}): received HTTP status code ${res.status}`,
        ),
      };
    }
    const username = await res.text();
    if (res.status === 204 || username == null || username.length === 0) {
      return { type: "refresh" };
    }
    return { type: "valid", username };
  } catch (error) {
    return { type: "error", error: error as Error };
  }
};

export const AuthProvider = ({ children }: Props) => {
  const [authState, dispatch] = useReducer<Reducer<AuthState, Action>>(
    reducer,
    {
      status: "loading",
    },
  );

  const refresh = React.useCallback(async () => {
    const response = await fetch(`${process.env.JANUS_URL}/login/refresh`, {
      method: "POST",
      credentials: "include",
    });
    if (response.status !== 200) {
      throw new Error(
        `Failed to refresh authentication token. Response from Janus: ${await response.text()}`,
      );
    }
  }, []);

  useEffect(() => {
    const load = async () => {
      let attempts = 0;
      while (attempts < 2) {
        const res = await whoami();
        switch (res.type) {
          case "error": {
            dispatch({ type: "error", payload: res.error });
            return;
          }
          case "refresh": {
            try {
              await refresh();
            } catch (error) {
              console.error(error);
              dispatch({ type: "logged-out" });
              return;
            }
            break;
          }
          case "valid": {
            dispatch({
              type: "logged-in",
              payload: { username: res.username },
            });
            return;
          }
        }
        attempts += 1;
      }
    };
    load();
  }, [refresh]);

  const auth = useMemo(
    () => ({
      ...authState,
      login,
      logout,
      refresh,
    }),
    [authState, refresh],
  );

  return <AuthContext.Provider value={auth}>{children}</AuthContext.Provider>;
};

export const useAuth = () => useContext(AuthContext);
