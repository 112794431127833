// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/cssWithMappingToString.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".ProjectSelector-module__project-selector--2hudz{width:100%}.ProjectSelector-module__project-selector--2hudz .ProjectSelector-module__dropdown--2Asak{min-width:200px;max-width:400px}.ProjectSelector-module__project-selector--2hudz .ant-switch-checked{background-color:#00000040}.ProjectSelector-module__project-selector--2hudz .ant-tag{margin:0 8px}.ProjectSelector-module__project-selector--2hudz .ant-page-header-content{padding-top:unset}.ProjectSelector-module__project-selector--2hudz .ant-page-header-content .ant-form-item-label{font-weight:bold}.ProjectSelector-module__project-selector--2hudz .ant-row{margin-bottom:unset}", "",{"version":3,"sources":["webpack://./src/components/ProjectSelector/ProjectSelector.module.css"],"names":[],"mappings":"AAAA,iDACE,UAAA,CAEA,0FACE,eAAA,CACA,eAAA,CAGF,qEACE,0BAAA,CAGF,0DACE,YAAA,CAGF,0EACE,iBAAA,CAEA,+FACE,gBAAA,CAIJ,0DACE,mBAAA","sourcesContent":[".project-selector {\n  width: 100%;\n\n  .dropdown {\n    min-width: 200px;\n    max-width: 400px;\n  }\n\n  :global(.ant-switch-checked) {\n    background-color: #00000040;\n  }\n\n  :global(.ant-tag) {\n    margin: 0 8px;\n  }\n\n  :global(.ant-page-header-content) {\n    padding-top: unset;\n\n    :global(.ant-form-item-label) {\n      font-weight: bold;\n    }\n  }\n\n  :global(.ant-row) {\n    margin-bottom: unset;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"project-selector": "ProjectSelector-module__project-selector--2hudz",
	"dropdown": "ProjectSelector-module__dropdown--2Asak"
};
export default ___CSS_LOADER_EXPORT___;
