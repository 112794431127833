export type DisplayTreeNode = {
  nodeType: DisplayTreeNodeType;
  name: string;
  key: string;
  parentKey: string;
  version?: number;
  loadAsync?: boolean;
  checkable?: boolean;
  checked?: boolean;
  children?: DisplayTreeNode[];
  disabled?: boolean;
  isLeaf?: boolean;
  ancestorKeys?: string[];
  ancestorNames?: string[];
  title?: React.ReactNode;
  selectable?: boolean;
  className?: string;
};

export enum DisplayTreeNodeType {
  Mapping = "mapping",
  Shot = "shot",
  Scene = "scene",
  Space = "space",
  Task = "task",
  Component = "component",
  Folder = "folder",
}
