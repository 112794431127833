import { Spin } from "antd";
import _ from "lodash";
import React from "react";
import { useProject } from "../../../App/state";
import { useBatchesQuery } from "../../../generated/types";
import { Batch } from "../../../types";
import BatchSelector from "./BatchSelector";

interface BatchSelectorContainerProps {
  referenceBatch?: Batch;
  comparisonBatch?: Batch;
  onChangeReferenceBatch?: (batch: Batch) => void;
  onChangeComparisonBatch?: (batch?: Batch) => void;
}

export const BatchSelectorContainer = (props: BatchSelectorContainerProps) => {
  const {
    referenceBatch,
    comparisonBatch,
    onChangeReferenceBatch,
    onChangeComparisonBatch,
  } = props;

  const project = useProject();

  const { data, loading, error } = useBatchesQuery({
    variables: {
      ...project,
    },
  });

  const batches: Batch[] | null = React.useMemo(() => {
    if (data?.batches != null) {
      return _(data.batches)
        .sortBy((batch) => batch.timestamp)
        .reverse()
        .value();
    } else {
      return null;
    }
  }, [data?.batches]);

  React.useEffect(() => {
    if (referenceBatch == null && batches != null && batches.length > 0) {
      if (onChangeReferenceBatch) {
        onChangeReferenceBatch(batches[0]);
      }
      if (batches.length > 1) {
        if (onChangeComparisonBatch) {
          onChangeComparisonBatch(batches[1]);
        }
      }
    }
  }, [
    batches,
    onChangeComparisonBatch,
    onChangeReferenceBatch,
    referenceBatch,
  ]);

  if (loading) {
    return <Spin />;
  }

  if (error || !data || !batches) {
    return <div> Error fetching batches </div>;
  }

  if (referenceBatch == null) {
    return <Spin />;
  }

  return (
    <BatchSelector
      batches={batches}
      referenceBatch={referenceBatch}
      comparisonBatch={comparisonBatch}
      onChangeReferenceBatch={onChangeReferenceBatch}
      onChangeComparisonBatch={onChangeComparisonBatch}
    />
  );
};
