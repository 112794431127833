import _ from "lodash";
import React from "react";
import { useProject } from "../../../App/state";
import { useGetComponentTagTypesQuery } from "../../../generated/types";
import {
  ComponentFieldSelectorContainer,
  ComponentFieldSelectorType,
} from "../ComponentFieldSelectorContainer";
import css from "./ComponentTagSelector.module.css";

const AUTOGENERATED_TAG_PREFIX = "task:";
const INVALID_TAG_REGEX = new RegExp(`^${AUTOGENERATED_TAG_PREFIX}`);

const validateTagName = (name: string): boolean =>
  name.match(INVALID_TAG_REGEX) === null;

const InvalidTagErrorMessage = () => (
  <div className={css["error-message"]}>
    Invalid Tag Name: <em>&#39{AUTOGENERATED_TAG_PREFIX}&#39</em> is a reserved
    prefix, please use a different prefix.
  </div>
);

interface ComponentTag {
  id: string;
  name: string;
}

interface ComponentTagSelectorContainerProps {
  className?: string;
  selectedTags?: string[];
  onChange?: (values: string[]) => void;
}

export const ComponentTagSelectorContainer = (
  props: ComponentTagSelectorContainerProps,
) => {
  const { className, selectedTags, onChange } = props;

  const project = useProject();

  const [invalidTagError, setInvalidTagError] = React.useState(false);

  const { data, loading, error } = useGetComponentTagTypesQuery({
    variables: {
      ...project,
    },
  });

  const componentTags: ComponentTag[] = React.useMemo(
    () =>
      data?.componentTagTypes.map((tag) => ({
        id: tag,
        name: tag,
      })) ?? [],
    [data],
  );

  const onChangeValues = React.useCallback(
    (values: string[]): void => {
      if (onChange === undefined) {
        return;
      }
      const newTagNames = _.without(
        values,
        ...componentTags.map((t) => t.name),
      );
      if (_.every(newTagNames, validateTagName)) {
        setInvalidTagError(false);
        onChange(values);
      } else {
        setInvalidTagError(true);
      }
    },
    [setInvalidTagError, componentTags, onChange],
  );

  const onBlur = React.useCallback(() => {
    setInvalidTagError(false);
  }, [setInvalidTagError]);

  return (
    <>
      {invalidTagError && <InvalidTagErrorMessage />}
      <ComponentFieldSelectorContainer
        componentFieldSelectorType={ComponentFieldSelectorType.Tags}
        className={className}
        selected={selectedTags}
        selectorValues={componentTags}
        loading={loading}
        selectMode={"tags"}
        onChange={onChangeValues}
        onBlur={onBlur}
        error={error}
      />
    </>
  );
};
