import { Button, Card, Col, Row, Space } from "antd";
import _ from "lodash";
import React from "react";
import { Project } from "../../types";
import { useRandomId } from "../../utils/react-utils";
import { Scene } from "../SpacesTree/tree-nodes";
import styles from "./ConfigurationBottomRibbons.module.css";
import { DeleteScenesButton } from "./scenes/DeleteScenesButton";
import { EditSceneForm } from "./scenes/EditSceneForm";

interface ConfigScenesBottomRibbonProps {
  id?: string;
  project: Project;
  selectedScenes: Scene[];
  onNodesDeleted: () => void;
}

const ConfigurationScenesBottomRibbon = (
  props: ConfigScenesBottomRibbonProps,
) => {
  const { id, project, selectedScenes, onNodesDeleted } = props;

  const randomFormId = useRandomId();
  const formId = id ?? randomFormId;

  return (
    <Card className={styles["bottom-ribbon"]}>
      <Row gutter={8}>
        <Col span={8}>
          <Space className={styles.actions} direction="vertical">
            <Row>
              <Space className={styles["actions-row"]}>
                <DeleteScenesButton
                  project={project}
                  disabled={selectedScenes.length === 0}
                  selectedScenes={selectedScenes}
                  onDelete={onNodesDeleted}
                />
                <Button form={formId} block type="primary" htmlType="submit">
                  Apply changes
                </Button>
              </Space>
            </Row>
          </Space>
        </Col>

        <Col span={16}>
          <Space direction="vertical" className={styles.form}>
            <EditSceneForm
              id={formId}
              disabled={_.isEmpty(selectedScenes)}
              selectedScenes={selectedScenes}
              project={project}
            />
          </Space>
        </Col>
      </Row>
    </Card>
  );
};

export default ConfigurationScenesBottomRibbon;
