import { Col, Form, Input, Row, Space } from "antd";
import _ from "lodash";
import React, { useCallback, useMemo } from "react";
import { useNotification } from "../../../contexts/Notifications";
import { useUpdateScenesMutation } from "../../../generated/types";
import { Project } from "../../../types";
import { Scene } from "../../SpacesTree/tree-nodes";
import { UPDATED_SCENES_ERROR, UPDATED_SCENES_SUCCESS } from "../messages";

interface EditSceneFormProps {
  id?: string;
  project: Project;
  disabled?: boolean;
  selectedScenes: Scene[];
}

export const EditSceneForm = (props: EditSceneFormProps) => {
  const { id, project, disabled, selectedScenes } = props;
  const { customer, project: proj, scope } = project;

  const [form] = Form.useForm();

  const multipleNames = useMemo(
    () => _.uniq(selectedScenes.map((scene) => scene.name)).length > 1,
    [selectedScenes],
  );

  const defaultName = useMemo(
    () =>
      selectedScenes.length > 0 && !multipleNames
        ? selectedScenes[0].name
        : null,
    [multipleNames, selectedScenes],
  );

  React.useEffect(() => {
    form.setFieldsValue({ name: defaultName });
  }, [defaultName, form]);

  const notify = useNotification();
  const [updateScenes] = useUpdateScenesMutation({
    onCompleted: () => {
      notify(UPDATED_SCENES_SUCCESS, "success");
    },
    onError: (error) => notify(`${UPDATED_SCENES_ERROR} - ${error}`, "error"),
  });

  const handleSubmit = useCallback(() => {
    const name = form.getFieldValue("name");
    return updateScenes({
      variables: {
        customer: customer,
        project: proj,
        scope: scope,
        scenes: selectedScenes.map((s) => ({
          id: s.id,
          name: name ?? s.name,
          guiIndex: s.guiIndex,
          spaceId: s.spaceId,
        })),
      },
    });
  }, [form, updateScenes, customer, proj, scope, selectedScenes]);

  return (
    <Form id={id} form={form} onFinish={handleSubmit}>
      <Space direction={"vertical"} style={{ width: "100%" }}>
        <Row gutter={8}>
          <Col span={24}>
            <Form.Item
              label="Name"
              name="name"
              rules={[
                {
                  message: "Required",
                  validator: async (_rule, value) => {
                    if (
                      !multipleNames &&
                      (value == null || value.length === 0)
                    ) {
                      throw new Error("Must set a scene name");
                    }
                  },
                },
              ]}
            >
              <Input
                placeholder={multipleNames ? "Multiple selected" : ""}
                disabled={disabled}
              />
            </Form.Item>
          </Col>
        </Row>
      </Space>
    </Form>
  );
};
