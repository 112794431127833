import React, { useEffect, useMemo, useState } from "react";
import { useProject } from "../../../App/state";
import { useGetObjectTypesQuery } from "../../../generated/types";
import {
  ComponentFieldSelectorContainer,
  ComponentFieldSelectorType,
} from "../ComponentFieldSelectorContainer";
import { ModalOptions } from "../CreateComponentFieldModal";
import { CreateObjectForm } from "./CreateObjectForm";

const CREATE_OBJECT_MODAL_TITLE = "Create new object";

type ObjectSelectorContainerProps = {
  className?: string;
  selectedObject?: string;
  onChange?: (value: string[]) => any;
};

export const ObjectSelectorContainer = (
  props: ObjectSelectorContainerProps,
) => {
  const { className, selectedObject, onChange } = props;

  const project = useProject();
  const { data, loading, error } = useGetObjectTypesQuery({
    variables: {
      ...project,
    },
  });

  const objectTypeValues = useMemo(() => {
    const objectTypeVals = data?.objectTypes.map((o) => ({
      id: o,
      name: o,
    }));
    return objectTypeVals;
  }, [data]);

  /* Need state here when adding an item but not posting to the db. This is currently only relevant for the objectType field.
  TODO: The current db structure limits the way you can update the object_type field, this should be changed. */
  const [newVal, setNewVal] = useState<string>();
  useEffect(() => {
    setNewVal(undefined);
  }, [objectTypeValues]);

  const modalOptions: ModalOptions = {
    type: ComponentFieldSelectorType.ObjectType,
    title: CREATE_OBJECT_MODAL_TITLE,
    form: CreateObjectForm,
    setNewVal: setNewVal,
  };

  return (
    <ComponentFieldSelectorContainer
      componentFieldSelectorType={ComponentFieldSelectorType.ObjectType}
      className={className}
      newVal={newVal}
      selected={selectedObject}
      selectorValues={objectTypeValues ?? []}
      loading={loading}
      error={error}
      onChange={onChange}
      modalOptions={modalOptions}
    />
  );
};
