import _ from "lodash";
import { SpotlightType } from "models";

export type Mapping = {
  id: string;
  componentName: string;
  subcontractor?: string;
};

export type Spotlight = {
  id: string;
  type: SpotlightType;
  mapping: Mapping | null;
  createdInBatchId: string;
  resolvedInBatchId?: string;
};

export type SpotlightFormValues = {
  mappingId: string;
  type: SpotlightType;
  createdInBatchId: string;
  resolvedInBatchId?: string;
};

const spotlightTypeToString: Record<SpotlightType, string> = {
  [SpotlightType.MakeGood]: "Make good", // rework Needed 2
  [SpotlightType.NotCompleteAndSoonInaccessible]:
    "Not complete and soon inaccessible", // potential rework  2
  [SpotlightType.LocationMismatch]: "Location mismatch", // Non conformance 1
  [SpotlightType.PartiallyIncomplete]: "Partially incomplete", // potential delay 1
  [SpotlightType.PartiallyRemoved]: "Partially removed", // rework in progress 1
  [SpotlightType.DoubleCheck]: "Double check",
  [SpotlightType.LeaveDown]: "Leave down",
  [SpotlightType.ElementMismatch]: "Element mismatch",
  [SpotlightType.SequenceMismatch]: "Sequence mismatch",
  [SpotlightType.Obsolete]: "Obsolete",
  [SpotlightType.ExternalSystemSpotlightType]: "External System",
};

type InvertResult<T extends Record<PropertyKey, PropertyKey>> = {
  [P in keyof T as T[P]]: P;
};

function invert<T extends Record<PropertyKey, PropertyKey>>(
  obj: T,
): InvertResult<T> {
  return _.invert(obj) as InvertResult<T>;
}

const stringToSpotlightType = invert(spotlightTypeToString);

export const getSpotlightType = (spotlightType: SpotlightType) =>
  spotlightTypeToString[spotlightType];

export const getGraphqlSpotlightType = (spotlightString: string) =>
  stringToSpotlightType[spotlightString];

export const SpotlightTypes = Object.values(SpotlightType).map((type) =>
  getSpotlightType(type),
);
