import { ColumnsType } from "antd/lib/table";
import _ from "lodash";
import React, { useMemo } from "react";
import { Project, Space } from "../../../types";
import { ScrollableTable } from "../ScrollableTable";
import {
  ancestorToRow,
  getComponentColumn,
  getProgressColumn,
} from "../table-utils";
import { ComponentWithMappedSpaces, RecordType } from "../types";
import { MappingCell } from "./MappingCell";

type MappingsTableProps = {
  project: Project;
  spaces: Space[];
  components: ComponentWithMappedSpaces[];
  onDisableMapping: (id: string, disabled: boolean) => any;
};

export const MappingsTable = (props: MappingsTableProps) => {
  const { project, spaces, components, onDisableMapping } = props;
  const columns: ColumnsType<any> = useMemo(
    () => [
      getComponentColumn(),
      // All other columns, representing the child spaces of the selected space.
      ...spaces.map((s, idx) =>
        getProgressColumn(s.name, s.id, idx, spaces.length),
      ),
    ],
    [spaces],
  );

  const componentToRow = React.useCallback(
    (c: ComponentWithMappedSpaces) => {
      const spacesWithoutMappings = spaces.filter(
        (s) => !c.componentMappings.map((m) => m.spaceId).includes(s.id),
      );

      const mappingCellBySpaceId = {
        ..._(c.componentMappings)
          .keyBy("spaceId")
          .mapValues((m) => (
            <MappingCell
              project={project}
              disabled={m.disabled}
              missing={false}
              space={{ id: m.spaceId, name: m.spaceName }}
              component={{ id: c.id, name: c.name }}
              mappingId={m.mappingId}
              weight={m.weight}
              onDisableMapping={onDisableMapping}
            />
          ))
          .value(),
        ..._(spacesWithoutMappings)
          .keyBy("id")
          .mapValues((s) => (
            <MappingCell
              project={project}
              space={{ id: s.id, name: s.name }}
              component={{ id: c.id, name: c.name }}
              missing={true}
            />
          ))
          .value(),
      };

      return {
        key: c.id,
        component: c.name,
        type: RecordType.component,
        ...mappingCellBySpaceId,
      };
    },
    [onDisableMapping, project, spaces],
  );

  const tableData = useMemo(() => {
    const componentsByAncestor = _.groupBy(components, "ancestorPath");
    return Object.entries(componentsByAncestor).flatMap(([, cs]) => [
      ancestorToRow(cs[0].ancestorPath),
      ...cs.map(componentToRow),
    ]);
  }, [componentToRow, components]);

  return <ScrollableTable columns={columns} dataSource={tableData} />;
};
