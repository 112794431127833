import { UploadOutlined } from "@ant-design/icons";
import { Button, Modal } from "antd";
import React, { useCallback, useState } from "react";
import { FileUploader } from "../../../components/FileUploader";
import { useNotification } from "../../../contexts/Notifications";
import { useUploadDatesFileMutation } from "../../../generated/types";
import { Project } from "../../../types";

export type DatesUploadModalProps = {
  project: Project;
};

export const DatesUploadModal = (props: DatesUploadModalProps) => {
  const [visible, setVisible] = useState<boolean>(false);
  return (
    <>
      <Button
        type="primary"
        icon={<UploadOutlined />}
        onClick={() => setVisible(true)}
      >
        Upload Dates
      </Button>
      <Modal
        visible={visible}
        title="Upload Dates"
        onCancel={() => setVisible(false)}
        footer={
          <Button key="back" onClick={() => setVisible(false)}>
            Return
          </Button>
        }
      >
        <DatesFileUploader project={props.project} />
      </Modal>
    </>
  );
};

export type DatesFileUploaderProps = {
  project: Project;
};

const DatesFileUploader = (props: DatesFileUploaderProps) => {
  const { project } = props;
  const { customer, project: proj, scope } = project;
  const notify = useNotification();

  const [uploadFile] = useUploadDatesFileMutation({
    onError: (error) =>
      notify(`Dates file upload failed: ${error.message}`, "error"),
    onCompleted: () => notify("Dates file successfully uploaded!", "success"),
  });
  const upload = useCallback(
    async (files) => {
      if (files.length !== 1) {
        notify(
          "Upload failed, we currently only support uploading one file at a time",
        );
      } else {
        await uploadFile({
          variables: {
            customer: customer,
            project: proj,
            scope: scope,
            file: files[0],
          },
        });
      }
    },
    [notify, uploadFile, customer, proj, scope],
  );

  return <FileUploader title="Upload Dates File" onFilesSelected={upload} />;
};
