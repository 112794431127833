import { CopyOutlined } from "@ant-design/icons";
import { Button } from "antd";
import { format } from "date-fns";
import React from "react";
import CopyToClipboard from "react-copy-to-clipboard";
import { useProject } from "../../App/state";
import { useNotification } from "../../contexts/Notifications";
import { useGetSceneQuery } from "../../generated/types";
import { useDualModeState } from "../../pages/DualModePage/state";

type ViewerTitleProps = {
  title: string;
};

export const ViewerTitle = (props: ViewerTitleProps) => {
  const notify = useNotification();
  const activeProject = useProject();
  const dualMode = useDualModeState();

  const batchtimestamp =
    props.title === "comparison"
      ? dualMode.comparisonBatch?.timestamp
      : dualMode.referenceBatch?.timestamp;

  const sceneResult = useGetSceneQuery({
    variables: {
      customer: activeProject.customer,
      project: activeProject.project,
      scope: activeProject.scope,
      sceneId: dualMode.currentSceneId?.value,
    },
    skip: !dualMode.currentSceneId?.value,
  });

  const shell360Id = sceneResult.data?.scene?.shell360Id;

  const timestamp =
    batchtimestamp && format(new Date(batchtimestamp), "dd/MM/yyyy");
  // This has to be the shell360 scene Id because the info is used for finding a shot
  // in the mapping interface, which still works with shell360 ids!
  // TODO: update this once we have refactored the mapping process
  const text =
    shell360Id === undefined
      ? "You do not have access to see these images, please contact your project lead."
      : `sceneId: ${shell360Id}, timestamp: ${timestamp}`;

  const onCopy = () => {
    notify(`${text} copied to clipboard`);
    console.log(`${text} copied to clipboard`);
  };
  const titleLookup = {
    comparison: "Comparison Shot",
    reference: "Reference Shot",
  };
  return (
    <h2>
      {titleLookup[props.title] ?? props.title}
      {sceneResult.data?.scene && (
        <CopyToClipboard text={text} onCopy={onCopy}>
          <Button icon={<CopyOutlined />} type={"text"} size={"small"} />
        </CopyToClipboard>
      )}
    </h2>
  );
};
