// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/cssWithMappingToString.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".FloorplanEditor-module__spaces-tree-box--2TAdK{overflow-y:auto;height:94%}.FloorplanEditor-module__buttons-box--QSQXw{display:flex;justify-content:center}.FloorplanEditor-module__button--3RmNT{margin:1%}", "",{"version":3,"sources":["webpack://./src/components/FloorplanEditor/FloorplanEditor.module.css"],"names":[],"mappings":"AAAA,gDACI,eAAA,CACA,UAAA,CAGJ,4CACI,YAAA,CACA,sBAAA,CAGJ,uCACI,SAAA","sourcesContent":[".spaces-tree-box {\n    overflow-y: auto;\n    height: 94%;\n}\n\n.buttons-box {\n    display: flex;\n    justify-content: center;\n}\n\n.button {\n    margin: 1%;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"spaces-tree-box": "FloorplanEditor-module__spaces-tree-box--2TAdK",
	"buttons-box": "FloorplanEditor-module__buttons-box--QSQXw",
	"button": "FloorplanEditor-module__button--3RmNT"
};
export default ___CSS_LOADER_EXPORT___;
