import React from "react";
import styles from "./Page.module.css";

export interface Props {
  error: Error;
}

const ErrorPage = ({ error }: Props) => {
  return (
    <div className={styles.page}>
      <p>Something went wrong</p>
      <p>{error.message}</p>
    </div>
  );
};

export default ErrorPage;
