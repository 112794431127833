import { Form, FormInstance, InputNumber } from "antd";
import React from "react";
import { ComponentSelectorContainer } from "./ComponentSelector/ComponentSelectorContainer";

type ComponentAssignmentFormProps = {
  form: FormInstance;
  planId: string;
};

export const ComponentAssignmentForm = (
  props: ComponentAssignmentFormProps,
) => (
  <Form form={props.form}>
    <Form.Item
      name="componentId"
      label="Component"
      rules={[
        {
          required: true,
          message: "Please select a component!",
        },
      ]}
    >
      <ComponentSelectorContainer planId={props.planId} />
    </Form.Item>
    <Form.Item name="weight" label="Weight in task">
      <InputNumber data-testid="form-input-weight" min={0} max={100} />
    </Form.Item>
  </Form>
);
