import { Popover } from "antd";
import React, { PropsWithChildren, useEffect, useState } from "react";
import { PanoPosition } from "../../../types";

const notInViewer = (event, viewer: Viewer) => {
  const container = viewer.getContainer();
  const bounds = container.getBoundingClientRect();
  const xOffset = (event.clientX || event.pageX) - bounds.left;
  const yOffset = (event.clientY || event.pageY) - bounds.top;

  return (
    xOffset < 0 ||
    xOffset > bounds.width ||
    yOffset < 0 ||
    yOffset > bounds.height
  );
};

const calculatePopoverPlacement = (pitch: number, viewer: Viewer) =>
  // if the annotation is in the top half of the viewer, the Popover placement should be below,
  // otherwise it should be above the annotation
  pitch > viewer.getPitch() ? "bottom" : "top";

const createPannellumHotspot = (id: string, yaw: number, pitch: number) => ({
  id: id,
  pitch: pitch,
  yaw: yaw,
  // need to give this something so pannellum doesn't add
  // its own classnames and css styling
  cssClass: "custom-annotation",
});

type AnnotationTransform = {
  transform: string;
  visibility: string;
};

type AnnotationProps = PropsWithChildren<{
  id: string;
  yaw: number;
  pitch: number;
  viewer: Viewer;
  onPositionUpdate: (position: PanoPosition) => void;
  alwaysVisible?: boolean;
  className: string;
  onClick?: () => void;
}>;

export const Annotation = (props: AnnotationProps) => {
  const {
    id,
    yaw,
    pitch,
    viewer,
    onPositionUpdate,
    onClick,
    alwaysVisible,
    className,
    children,
  } = props;
  const [transform, setTransform] = useState<AnnotationTransform>();

  useEffect(() => {
    const bindHotspot = () => {
      const config = viewer.getConfig();
      const hotspot = config.hotSpots.find((hs) => hs.id === id);
      if (hotspot !== undefined) {
        setTransform({
          transform: hotspot.div.style.transform,
          visibility: hotspot.div.style.visibility || "visible",
        });
      }
    };

    const spot = createPannellumHotspot(id, yaw, pitch);
    viewer.on("renderFinished", bindHotspot);
    viewer.addHotSpot(spot);
    bindHotspot();
    return () => {
      viewer.removeHotSpot(spot.id);
      viewer.off("renderFinished", bindHotspot);
    };
  }, [viewer, id, yaw, pitch, setTransform]);

  return (
    <Popover
      content={children}
      placement={calculatePopoverPlacement(pitch, viewer)}
      visible={alwaysVisible}
    >
      <div
        id={id}
        // Draggable needs to be true in order for onDragEnd to be called.
        draggable
        onDragEnd={(event) => {
          if (notInViewer(event, viewer)) {
            return;
          }
          const coords = viewer.mouseEventToCoords(event);
          if (coords) {
            onPositionUpdate({ yaw: coords[1], pitch: coords[0] });
          }
        }}
        style={{
          transform: transform?.transform,
          visibility: transform?.visibility as VisibilityState,
        }}
        className={className}
        onClick={onClick}
      ></div>
    </Popover>
  );
};
