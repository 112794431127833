import { Space } from "../../types";
import { DisplayTreeNode } from "../DisplayTree/types";

export type SpatialConfigTreeNode = Pick<
  DisplayTreeNode,
  "nodeType" | "name" | "key"
> & { parentSpaceId?: string };

export type Scene = {
  id: string;
  name: string;
  path: string[];
  x?: number;
  y?: number;
  zOffset: number;
  aliasSceneId?: string | null;
  subjects?: SceneSubject[];
  parentId: string;
  scanType: SceneScanType;
  guiIndex: number;
};

export enum SceneSubject {
  Firestop = "FIRESTOP",
  Riser = "RISER",
  Facade = "FACADE",
  HighCeiling = "HIGH_CEILING",
  BuildingFullHeight = "BUILDING_FULL_HEIGHT",
}

export enum SceneScanType {
  DEFAULT = "DEFAULT",
  CUPBOARD = "CUPBOARD",
  CLOSE_UP = "CLOSE-UP",
}

export type Drawing = {
  id?: string;
  url: string;
  width: number;
  height: number;
  originX: number;
  originY: number;
  mmScaleFactor: number;
  bimOriginX?: number | null;
  bimOriginY?: number | null;
  bimOriginZ?: number | null;
  floorHeight?: number | null;
  angleToTrueNorth?: number | null;
};

interface SpaceWithAncestorNames extends Space {
  ancestorNames: string[];
}

export type Floorplan = Drawing & {
  id: string;
  space: SpaceWithAncestorNames;
};

export type FloorplanSpace = {
  id: string;
  space: SpaceWithAncestorNames;
};

export type FloorplanIdToSpaceId = {
  [key: string]: string;
};

export type DrawingScene = {
  id: string;
  x: number;
  y: number;
  isSelected: boolean;
  isAlias: boolean;
};

export enum ScanSequenceLevelType {
  Early = "EARLY",
  Mid = "MID",
  Late = "LATE",
}

export enum ScanSequencePrefixType {
  A = "A",
  B = "B",
  C = "C",
  D = "D",
  E = "E",
  F = "F",
  G = "G",
  H = "H",
  I = "I",
  J = "J",
  K = "K",
  L = "L",
  M = "M",
  N = "N",
  O = "O",
  P = "P",
  Q = "Q",
  R = "R",
  S = "S",
  T = "T",
  U = "U",
  V = "V",
  W = "W",
  X = "X",
  Y = "Y",
  Z = "Z",
}

export type Sequence = {
  id: string;
  floorPlanId: string;
  sequencePrefix: ScanSequencePrefixType;
  scanLevel: ScanSequenceLevelType;
  sceneIds?: string[];
};
