import { ProgressStatus, Scope } from "./generated/types";

export { Scope };

export interface Project {
  customer: string;
  project: string;
  scope: Scope;
}

export interface Plan {
  id: string;
  name: string;
  startDate?: Date;
}

export interface Task {
  id: string;
  name: string;
  guiIndex: number;
}

export type PanoView = {
  position: PanoPosition;
  hfov: number;
};

export interface PanoPosition {
  yaw: number;
  pitch: number;
}

export type Subcontractor = {
  id: string;
  name: string;
};

export type Component = {
  id: string;
  name: string;
  subcontractor?: Subcontractor;
  weight?: number; // TODO: make this required when weight implementation in the backend is finished
  type: ComponentType;
  object: string;
  tags: string[];
  guiIndex: number;
};

export type ComponentType = {
  id: string;
  name: string;
};

export type Space = {
  id: string;
  name: string;
};

export enum ProgressChange {
  Up,
  Down,
  NoChange,
}

export interface AggregatedProgressBase {
  spaceId: string;
  batchId: string;
  progress?: number;
  status?: ProgressStatus;
  progressChange?: ProgressChange;
}

export interface Batch {
  id: string;
  weekNumber: number;
  timestamp: string;
}
