import React from "react";

export const useStateWithSubscription = <T>(
  initialValue: T,
  subscription?: (value: T) => any,
): [T, (action: T | ((oldValue: T) => T)) => any] => {
  const [state, setState] = React.useState<T>(initialValue);
  const setStateAndNotify = (action: T | ((oldValue: T) => T)) => {
    setState((oldValue) => {
      const newValue =
        typeof action === "function"
          ? (action as (oldValue: T) => T)(oldValue as T)
          : action;
      subscription?.(newValue);
      return newValue;
    });
  };
  return [state, setStateAndNotify];
};
