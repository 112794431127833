// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/cssWithMappingToString.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".plans-card .planSelectorStyles-module__selector--3x0w6{float:right;width:100%}.plans-mode-page-header .planSelectorStyles-module__selector--3x0w6{width:100%;display:flex;flex-direction:row;flex-wrap:nowrap}", "",{"version":3,"sources":["webpack://./src/components/Selectors/PlanSelector/planSelectorStyles.module.css"],"names":[],"mappings":"AAAA,wDACE,WAAA,CACA,UAAA,CAGF,oEACE,UAAA,CACA,YAAA,CACA,kBAAA,CACA,gBAAA","sourcesContent":[":global(.plans-card) .selector {\n  float: right;\n  width: 100%;\n}\n\n:global(.plans-mode-page-header) .selector {\n  width: 100%;\n  display: flex;\n  flex-direction: row;\n  flex-wrap: nowrap;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"selector": "planSelectorStyles-module__selector--3x0w6"
};
export default ___CSS_LOADER_EXPORT___;
