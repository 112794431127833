import { DeleteOutlined } from "@ant-design/icons";
import { Button, InputNumber } from "antd";
import classNames from "classnames";
import produce from "immer";
import React, { useEffect, useState } from "react";
import { useCallback } from "react";
import styles from "./SequenceMode.module.css";
export interface Card {
  id: string;
  text: string;
  prefix: string;
  order: number;
}

export interface SequencesTableProps {
  cards: Card[];
  onCardsEdit: (cards: string[]) => void;
  isEditMode: boolean;
}

export const SequencesTable = (props: SequencesTableProps) => {
  {
    const { cards, onCardsEdit, isEditMode } = props;

    const onOrderChange = useCallback(
      (cardToUpdate: Card, input: number) => {
        if (isEditMode && input <= cards.length && input > 0) {
          const result = produce(cards, (draft) => {
            draft.splice(cardToUpdate.order - 1, 1);
            draft.splice(input - 1, 0, cardToUpdate);
            return draft;
          });

          onCardsEdit(result.map((card) => card.id));
        }
      },
      [cards, onCardsEdit, isEditMode],
    );

    const deleteCard = useCallback(
      (id: string) => {
        onCardsEdit(cards.filter((card) => card.id !== id).map((c) => c.id));
      },
      [cards, onCardsEdit],
    );

    return (
      <div className={styles["sequence-mode_droppable-container"]}>
        {cards.map((card) => (
          <SequencePointCard
            key={card.id}
            card={card}
            onDeleteCard={deleteCard}
            isEditingDisabled={!isEditMode}
            onOrderChange={(newOrder: number) => onOrderChange(card, newOrder)}
            maxValue={cards.length}
          />
        ))}
      </div>
    );
  }
};

interface SequencePointCardProps {
  card: Card;
  onDeleteCard: (id: string) => void;
  isEditingDisabled: boolean;
  onOrderChange: (newInput: number) => void;
  maxValue: number;
}

const SequencePointCard = ({
  card,
  onDeleteCard,
  isEditingDisabled,
  onOrderChange,
  maxValue,
}: SequencePointCardProps) => (
  <div className={styles["sequence-mode__seq_point_box"]}>
    <div
      className={classNames(
        styles["sequence-mode__seq_point_box_item"],
        styles["name-box"],
      )}
    >
      {card.prefix}
    </div>
    <NumberBox
      cardOrder={card.order}
      onOrderChange={onOrderChange}
      maxValue={maxValue}
      isEditingDisabled={isEditingDisabled}
    />
    <div className={styles["sequence-mode__seq_point_box_item"]}>
      {card.text}
    </div>
    <Button
      className={styles["sequence-mode__seq_point_box_item"]}
      icon={<DeleteOutlined />}
      type="text"
      size="small"
      disabled={isEditingDisabled}
      onClick={() => onDeleteCard(card.id)}
    />
  </div>
);

interface NumberBoxProps {
  cardOrder: number;
  onOrderChange: (newInput: number) => void;
  maxValue: number;
  isEditingDisabled: boolean;
}

const NumberBox = (props: NumberBoxProps) => {
  const { cardOrder, onOrderChange, maxValue, isEditingDisabled } = props;
  const [inputValue, setInputValue] = useState(cardOrder);
  const [isInvalidInput, setIsInvalidInput] = useState(false);
  const [editable, setEditable] = useState(false);

  useEffect(() => {
    setInputValue(cardOrder);
  }, [cardOrder]);

  const handleClick = () => {
    !isEditingDisabled && setEditable(true);
  };

  const handleChange = (value) => {
    if (value > maxValue || value <= 0) {
      setIsInvalidInput(true);
    } else {
      setIsInvalidInput(false);
    }
    setInputValue(value);
  };

  const handleKeyDown = (event) => {
    if (event.key === "Escape") {
      setEditable(false);
      setInputValue(cardOrder);
    }
  };

  const onPressEnter = () => {
    if (inputValue <= maxValue && inputValue > 0) {
      onOrderChange(inputValue);
    } else {
      setInputValue(cardOrder);
    }
    setEditable(false);
  };

  const onBlur = () => {
    setEditable(false);
    setInputValue(cardOrder);
  };

  return (
    <div
      onClick={handleClick}
      onKeyDown={handleKeyDown}
      tabIndex={0}
      className={classNames(
        styles["sequence-mode__seq_point_box_item"],
        styles["number-box"],
      )}
    >
      {editable ? (
        <InputNumber
          className={classNames({ [styles["warning"]]: isInvalidInput })}
          type="number"
          value={inputValue}
          onChange={handleChange}
          onPressEnter={onPressEnter}
          onBlur={onBlur}
          autoFocus
        />
      ) : (
        inputValue
      )}
    </div>
  );
};
