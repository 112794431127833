import { DeleteOutlined, TagsOutlined } from "@ant-design/icons";
import { Button, Tooltip } from "antd";
import _ from "lodash";
import React from "react";
import { useNotification } from "../../../contexts/Notifications";
import {
  useGetExistingSpaceTagsQuery,
  usePatchSpacesMutation,
} from "../../../generated/types";
import { Project } from "../../../types";
import { Space } from "../../SpacesTree/tree-nodes";
import { UPDATED_SPACES_ERROR, UPDATED_SPACES_SUCCESS } from "../messages";
import { EditSpaceTagsModal } from "./EditSpaceTagsModal";
import styles from "./styles.module.css";

interface EditSpaceTagsFormProps {
  project: Project;
  selectedSpaces: Space[];
  onSubmit?: (spaces: string[]) => void;
}

export const EditSpaceTagsForm = (props: EditSpaceTagsFormProps) => {
  const [addTagsModalVisible, setAddTagsModalVisible] =
    React.useState<boolean>(false);
  const [removeTagsModalVisible, setRemoveTagsModalVisible] =
    React.useState<boolean>(false);

  const notify = useNotification();

  const { data: allSpaceTagsRes } = useGetExistingSpaceTagsQuery({
    variables: {
      customer: props.project.customer,
      project: props.project.project,
      scope: props.project.scope,
    },
  });
  const allSpaceTags = allSpaceTagsRes?.spaceTags ?? [];

  const [patchSpaces] = usePatchSpacesMutation({
    onCompleted: (data) => {
      notify(UPDATED_SPACES_SUCCESS, "success");
      if (data.patchSpaces.length > 0) {
        props.onSubmit?.(
          data.patchSpaces
            .map((space) => space.parentSpace?.id)
            .filter((parentId) => !_.isNil(parentId)),
        );
      }
    },
    onError: (error) => notify(`${UPDATED_SPACES_ERROR} - ${error}`, "error"),
  });

  const submitTags = (tags: string[]) => {
    return patchSpaces({
      variables: {
        customer: props.project.customer,
        project: props.project.project,
        scope: props.project.scope,
        ids: props.selectedSpaces.map((s) => s.id),
        update: {
          addTags: tags,
        },
      },
    });
  };

  const removeTags = (tags: string[]) => {
    return patchSpaces({
      variables: {
        customer: props.project.customer,
        project: props.project.project,
        scope: props.project.scope,
        ids: props.selectedSpaces.map((s) => s.id),
        update: {
          removeTags: tags,
        },
      },
    });
  };

  return (
    <>
      <div>
        <div className={styles["tag-button-container"]}>
          <Tooltip title={"Add tags"}>
            <Button
              icon={<TagsOutlined />}
              onClick={() => setAddTagsModalVisible(true)}
            />
          </Tooltip>
        </div>
      </div>
      <div className={styles["tag-button-container"]}>
        <Tooltip title={"Remove tags"}>
          <Button
            icon={<DeleteOutlined />}
            onClick={() => setRemoveTagsModalVisible(true)}
          />
        </Tooltip>
      </div>
      <EditSpaceTagsModal
        title={"Add space tags"}
        visible={addTagsModalVisible}
        onCancel={() => setAddTagsModalVisible(false)}
        onSubmit={(tags: string[]) => {
          submitTags(tags);
          setAddTagsModalVisible(false);
        }}
        actionText={"adding"}
        spaces={props.selectedSpaces}
        allSpaceTags={allSpaceTags ?? []}
        allowNewTagCreation={true}
      />
      <EditSpaceTagsModal
        title={"Remove space tags"}
        visible={removeTagsModalVisible}
        onCancel={() => setRemoveTagsModalVisible(false)}
        onSubmit={(tags: string[]) => {
          removeTags(tags);
          setRemoveTagsModalVisible(false);
        }}
        actionText={"removing"}
        spaces={props.selectedSpaces}
        allSpaceTags={allSpaceTags ?? []}
        allowNewTagCreation={false}
      />
    </>
  );
};
