// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/cssWithMappingToString.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".MajorActionButton-module__container--3MWnm{padding:24px}.MajorActionButton-module__centered--1d76h{padding:24px;text-align:center}", "",{"version":3,"sources":["webpack://./src/components/MajorActionButton/MajorActionButton.module.css"],"names":[],"mappings":"AAAA,4CACE,YAAA,CAGF,2CACE,YAAA,CACA,iBAAA","sourcesContent":[".container {\n  padding: 24px;\n}\n\n.centered {\n  padding: 24px;\n  text-align: center;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"container": "MajorActionButton-module__container--3MWnm",
	"centered": "MajorActionButton-module__centered--1d76h"
};
export default ___CSS_LOADER_EXPORT___;
