import { Alert, Button, List, Modal } from "antd";
import React from "react";
import { SpacesWithAncestors } from "../SpacesWithAncestors";
import styles from "./ConfigPlansBottomRibbon.module.css";

interface Space {
  name: string;
  guiIndex: number;
  ancestors: { name: string; guiIndex: number }[];
}

type ModalConfirmationProps = {
  visible: boolean;
  onCancelCallback: () => any;
  onApllyCallback: () => any;
  affectedComponentNames: string[];
  affectedSpaces: Space[];
  notAffectedSpaces: Space[];
  mappingAction: string;
};

export const ModalConfirmationMappingAction = (
  props: ModalConfirmationProps,
) => (
  <Modal
    visible={props.visible}
    title={`Mapping Action Confirmation`}
    onCancel={props.onCancelCallback}
    width="70%"
    footer={[
      <Button key="back" danger onClick={props.onCancelCallback}>
        Cancel
      </Button>,
      <Button key="submit" type="primary" onClick={props.onApllyCallback}>
        Apply
      </Button>,
    ]}
  >
    <div className={styles["modal-container"]}>
      <Alert
        type="warning"
        showIcon
        message={`You are about to ${props.mappingAction} mappings between`}
      />
      <div className={styles["scrollable-container"]}>
        <h3>Components</h3>
        <List
          size="small"
          dataSource={props.affectedComponentNames}
          renderItem={(item) => <List.Item>{item}</List.Item>}
        />
        <SpacesWithAncestors spaces={props.affectedSpaces} heading={"Spaces"} />
        {props.notAffectedSpaces.length > 0 ? (
          <SpacesWithAncestors
            spaces={props.notAffectedSpaces}
            heading={"The action cannot be performed on the following spaces"}
          />
        ) : (
          ""
        )}
      </div>
    </div>
  </Modal>
);
