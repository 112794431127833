import React, { useCallback } from "react";
import { useProject } from "../../App/state";
import { useFloorplanByIdQuery } from "../../generated/types";
import ErrorPage from "../../pages/ErrorPage";
import LoadingPage from "../../pages/LoadingPage";
import { EditPropertiesModal } from "./EditPropertiesModal";
import { FloorplanEditPropertiesInput } from "./hooks/useFloorplan";

interface EditPropertiesProps {
  floorplanId: string;
  onEdit: (floorplanData: FloorplanEditPropertiesInput) => Promise<void>;
  onClose: () => void;
  spaceName: string;
}

export const EditProperties = (props: EditPropertiesProps) => {
  const { floorplanId, spaceName, onClose, onEdit } = props;
  const [uploadInProgress, setUploadInProgress] = React.useState(false);
  const tenant = useProject();

  const uploadFloorplanCallback = useCallback(
    async (editedFloorplan: Omit<FloorplanEditPropertiesInput, "id">) => {
      setUploadInProgress(true);
      onEdit({
        ...editedFloorplan,
        id: floorplanId,
      });
      setUploadInProgress(false);
      onClose();
    },
    [floorplanId, onEdit, onClose],
  );

  const { data, loading, error } = useFloorplanByIdQuery({
    variables: { tenant, id: floorplanId },
  });

  if (loading) {
    return <LoadingPage />;
  }

  if (error) {
    return <ErrorPage error={error} />;
  }

  const floorplan = data?.floorplans[0];

  if (!floorplan) {
    return (
      <ErrorPage
        error={new Error(`Could not find floorplan with id ${floorplanId}`)}
      />
    );
  }

  return (
    <EditPropertiesModal
      floorplan={floorplan}
      uploadInProgress={uploadInProgress}
      onClose={onClose}
      spaceName={spaceName}
      editFloorplanProperties={uploadFloorplanCallback}
    />
  );
};
