import { Spin } from "antd";
import React, {
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from "react";
import { GetBimTokenQuery } from "../../../generated/types";
import { PanoPosition } from "../types";
import styles from "./BimViewer.module.css";
import {
  cartesianToYawPitch,
  rotateCoordinates,
  translateCoordinates,
  yawPitchToCartesian,
} from "./geometry-utils";
import LockNavigation from "./lockNavigation";
import LockZoom from "./lockZoom";
import { Coordinates } from "./types";
import { BimStateAction } from "./useBimState";

const ZERO_COORDINATES = { x: 0, y: 0, z: 0 };

const FEET_TO_M = 0.3048;
const FOV_RATIO = 1.2;

const SCANNERS_HEIGHT = 1.85;

export type BimViewerProps = {
  selectedUrn: string;
  sceneCoordinates: Coordinates;
  bimOrigin: Coordinates;
  angleToTrueNorth: number;
  imageTarget: PanoPosition;
  setImageTarget: (yaw: number, pitch: number, hfov: number) => void;
  isVisible: boolean;
  fov: number;
  onGetBimAccessToken: () => Promise<GetBimTokenQuery["bimToken"] | null>;
  setBimAsAuthoritativeViewer: () => void;
  isLocationLoading: boolean;
  onChangeBimState: (action: BimStateAction) => void;
};

export const BimViewer = (props: BimViewerProps) => {
  const wrapperRef = useRef<HTMLElement>(null);
  const {
    isLocationLoading,
    selectedUrn,
    sceneCoordinates,
    bimOrigin,
    angleToTrueNorth,
    imageTarget,
    setImageTarget,
    isVisible,
    fov,
    onGetBimAccessToken,
    setBimAsAuthoritativeViewer,
    onChangeBimState,
  } = props;

  const [geometryLoaded, setGeometryLoaded] = useState(false);

  const [viewer, setViewer] = useState<Autodesk.Viewing.GuiViewer3D | null>(
    null,
  );

  const lockNavigationTool = useRef<LockNavigation | null>(null);
  const lockZoomTool = useRef<LockZoom | null>(null);

  const [isModelActive, setIsModelActive] = useState(false);

  const [cameraPositionWithOffset, setCameraPositionWithOffset] =
    useState(ZERO_COORDINATES);
  const [cameraTarget, setCameraTarget] = useState(ZERO_COORDINATES);

  const northYaw: number = useMemo(() => {
    const { yaw } = cartesianToYawPitch(
      cameraPositionWithOffset.x,
      cameraPositionWithOffset.y,
      cameraPositionWithOffset.z,
      cameraPositionWithOffset.x,
      cameraPositionWithOffset.y + 1,
      cameraPositionWithOffset.z,
    );
    return yaw;
  }, [cameraPositionWithOffset]);

  const handleViewerCameraChange = useCallback(() => {
    if (viewer && isModelActive) {
      const newTarget = viewer.navigation.getTarget();
      const currentPosition = viewer.navigation.getPosition();

      const newImageTarget = currentPosition
        ? cartesianToYawPitch(
            currentPosition.x,
            currentPosition.y,
            currentPosition.z,
            newTarget.x,
            newTarget.y,
            newTarget.z,
          )
        : { yaw: 0, pitch: 0 };

      const currentFov = viewer.getFOV();

      setImageTarget(
        newImageTarget.yaw - northYaw,
        newImageTarget.pitch,
        currentFov * FOV_RATIO,
      );
    }
  }, [viewer, isModelActive, setImageTarget, northYaw]);

  useEffect(() => {
    const options = {
      getAccessToken: async function (onSuccess) {
        const tokenData = await onGetBimAccessToken();
        if (tokenData) {
          onSuccess(tokenData.token, tokenData.expiresIn);
        }
      },
    };
    initializeViewerRuntime(options).then(() => {
      if (wrapperRef.current) {
        setViewer(
          new Autodesk.Viewing.GuiViewer3D(wrapperRef.current, {
            theme: "light-theme",
            extensions: ["Autodesk.BimWalk"],
          }),
        );
      }
    });
  }, [onGetBimAccessToken]);

  useEffect(() => {
    if (viewer) {
      viewer.start();
    }

    return () => {
      if (viewer) {
        lockNavigationTool.current &&
          viewer.toolController.deregisterTool(lockNavigationTool.current);
        lockZoomTool.current &&
          viewer.toolController.deregisterTool(lockZoomTool.current);

        viewer.finish();
        setViewer(null);
      }
      runtime.ready = null;
      Autodesk.Viewing.shutdown();
    };
  }, [viewer]);

  const setCameraView = useCallback(() => {
    if (viewer?.model) {
      const globalOffset = viewer.model.getGlobalOffset();

      //rotate x and y to true north
      const rotatedCoords = rotateCoordinates(
        sceneCoordinates,
        -angleToTrueNorth, //rotate clockwise
      );

      //translate to bim origin
      const translatedCoords = translateCoordinates(rotatedCoords, bimOrigin);

      // subtract global offset so the model is
      // placed exactly in its world coordinates
      const cameraPosition = translateCoordinates(translatedCoords, {
        x: -(globalOffset?.x ?? 0),
        y: -(globalOffset?.y ?? 0),
        z: -(globalOffset?.z ?? 0) + SCANNERS_HEIGHT,
      });

      setCameraPositionWithOffset(cameraPosition);

      setCameraTarget(
        yawPitchToCartesian(
          cameraPosition.x,
          cameraPosition.y,
          cameraPosition.z,
          imageTarget.yaw + northYaw,
          imageTarget.pitch,
        ),
      );
    }
  }, [
    viewer,
    sceneCoordinates,
    northYaw,
    imageTarget,
    bimOrigin,
    angleToTrueNorth,
  ]);

  const onExtensionLoaded = useCallback(
    ({ extensionId }) => {
      if (!viewer) {
        return;
      }
      if (
        ![
          "Autodesk.BimWalk",
          "Autodesk.ModelStructure",
          "Autodesk.PropertiesManager",
        ].includes(extensionId)
      ) {
        viewer.unloadExtension(extensionId);
      }
      if (extensionId === "Autodesk.DefaultTools.NavTools") {
        const navTools = viewer.toolbar.getControl("navTools");
        //@ts-ignore: API seems to be buggy
        navTools.removeControl("toolbar-bimWalkTool");
        const settingsTools = viewer?.toolbar.getControl("settingsTools");
        // @ts-ignore: API seems to be buggy
        settingsTools?.removeControl("toolbar-settingsTool");
        // @ts-ignore: API seems to be buggy
        settingsTools?.removeControl("toolbar-fullscreenTool");
      }
    },
    [viewer],
  );

  const onGeometryLoaded = useCallback(() => {
    if (viewer) {
      viewer.setBimWalkToolPopup(false);
      //@ts-ignore: API seems to be buggy
      viewer.loadedExtensions["Autodesk.BimWalk"]?.activate();
      const infoButton = document.getElementById("tooltip-info");
      if (infoButton) {
        infoButton.style.display = "none";
      }
      setGeometryLoaded(true);
      onChangeBimState(BimStateAction.MODEL_SUCCESS);
      setCameraView();
      lockNavigationTool.current = new LockNavigation(viewer);
      viewer.toolController.registerTool(lockNavigationTool.current);
      viewer.toolController.activateTool(lockNavigationTool.current.getName());
    }
  }, [viewer, setCameraView, onChangeBimState]);

  const setGeometryNotLoaded = useCallback(() => {
    setGeometryLoaded(false);
  }, []);

  useEffect(() => {
    if (viewer?.started) {
      viewer.addEventListener(
        Autodesk.Viewing.EXTENSION_LOADED_EVENT,
        onExtensionLoaded,
      );
      viewer.addEventListener(
        Autodesk.Viewing.GEOMETRY_LOADED_EVENT,
        onGeometryLoaded,
      );
      viewer.addEventListener(
        Autodesk.Viewing.MODEL_REMOVED_EVENT,
        setGeometryNotLoaded,
      );
      viewer.addEventListener(
        Autodesk.Viewing.CAMERA_CHANGE_EVENT,
        handleViewerCameraChange,
      );
    }
    return () => {
      if (viewer?.started) {
        viewer.removeEventListener(
          Autodesk.Viewing.GEOMETRY_LOADED_EVENT,
          onGeometryLoaded,
        );
        viewer.removeEventListener(
          Autodesk.Viewing.EXTENSION_LOADED_EVENT,
          onExtensionLoaded,
        );
        viewer.removeEventListener(
          Autodesk.Viewing.CAMERA_CHANGE_EVENT,
          handleViewerCameraChange,
        );
        viewer.addEventListener(
          Autodesk.Viewing.MODEL_REMOVED_EVENT,
          setGeometryNotLoaded,
        );
      }
    };
  }, [
    viewer,
    onGeometryLoaded,
    onExtensionLoaded,
    isModelActive,
    handleViewerCameraChange,
    setGeometryNotLoaded,
  ]);

  const loadModel = useCallback(
    async (bimModel: string) => {
      if (viewer) {
        Autodesk.Viewing.Document.load(
          "urn:" + bimModel,
          async (doc) => {
            const node = doc.getRoot().getDefaultGeometry();
            await doc.downloadAecModelData();
            const aecModelData = node.getAecModelData();
            // transform to place model in its world coordinates
            // when importing into the Viewer
            const tf = aecModelData && aecModelData.refPointTransformation;
            const matrix4 = new THREE.Matrix4()
              .makeBasis(
                new THREE.Vector3(tf[0], tf[1], tf[2]),
                new THREE.Vector3(tf[3], tf[4], tf[5]),
                new THREE.Vector3(tf[6], tf[7], tf[8]),
              )
              .setPosition(
                new THREE.Vector3(
                  // assuming model units in feet
                  tf[9] * FEET_TO_M,
                  tf[10] * FEET_TO_M,
                  tf[11] * FEET_TO_M,
                ),
              );
            viewer.loadDocumentNode(doc, node, {
              placementTransform: matrix4,
              applyScaling: "meters",
            });
          },
          (code, message, errors) => console.error(code, message, errors),
        );
      }
    },
    [viewer],
  );

  useEffect(() => {
    if (viewer && viewer.getAllModels().length === 0) {
      loadModel(selectedUrn);
    }

    return () => {
      if (viewer?.model) {
        viewer?.unloadModel(viewer.model);
      }
    };
  }, [viewer, selectedUrn, loadModel]);

  useEffect(() => setCameraView(), [viewer?.model, setCameraView]);

  useEffect(() => {
    if (viewer) {
      lockZoomTool.current = new LockZoom(viewer, {
        x: cameraPositionWithOffset.x,
        y: cameraPositionWithOffset.y,
        z: cameraPositionWithOffset.z,
      });
      viewer.toolController.registerTool(lockZoomTool.current);
      viewer.toolController.activateTool(lockZoomTool.current.getName());
    }
  }, [
    viewer,
    cameraPositionWithOffset.x,
    cameraPositionWithOffset.y,
    cameraPositionWithOffset.z,
  ]);

  useEffect(() => {
    if (viewer) {
      const currentPosition = viewer.navigation.getPosition();
      if (
        cameraPositionWithOffset.x !== currentPosition?.x ||
        cameraPositionWithOffset.y !== currentPosition?.y ||
        cameraPositionWithOffset.z !== currentPosition?.z
      ) {
        //initial setup
        viewer.navigation.setView(
          new THREE.Vector3(
            cameraPositionWithOffset.x,
            cameraPositionWithOffset.y,
            cameraPositionWithOffset.z,
          ),
          new THREE.Vector3(cameraTarget.x, cameraTarget.y, cameraTarget.z),
        );
      }
      if (!isModelActive) {
        //only set target if it's controlled from the 360image (mouse is not in the model frame)
        viewer.navigation.setTarget(
          new THREE.Vector3(cameraTarget.x, cameraTarget.y, cameraTarget.z),
        );
      }
    }
  }, [viewer, cameraPositionWithOffset, cameraTarget, isModelActive]);

  useEffect(() => {
    if (viewer && !isModelActive) {
      viewer.setFOV(fov / FOV_RATIO);
    }
  }, [viewer, fov, isModelActive, isVisible]);

  const handleMouseEnter = () => {
    setIsModelActive(true);
    setBimAsAuthoritativeViewer();
  };
  const handleMouseLeave = () => {
    setIsModelActive(false);
  };

  return (
    <React.Fragment>
      {(!geometryLoaded || isLocationLoading) && isVisible && <Spin />}
      <div
        onMouseEnter={handleMouseEnter}
        onMouseLeave={handleMouseLeave}
        className={
          isVisible && geometryLoaded && !isLocationLoading
            ? styles["viewer"]
            : styles["viewer-hidden"]
        }
        ref={wrapperRef as React.RefObject<HTMLDivElement>}
      />
    </React.Fragment>
  );
};

const runtime = {
  options: {},
  ready: null,
};

const initializeViewerRuntime = async (options: {
  getAccessToken: (
    onSuccess: (token: string, expiresIn: string) => void,
  ) => Promise<void>;
}) => {
  if (!runtime.ready) {
    runtime.options = { ...options };
    runtime.ready = await new Promise((resolve) =>
      Autodesk.Viewing.Initializer(runtime.options, resolve as () => void),
    );
  } else {
    const propNames = [
      "accessToken",
      "getAccessToken",
      "env",
      "api",
      "language",
    ];
    if (propNames.some((prop) => options[prop] !== runtime.options[prop])) {
      return Promise.reject(
        "Cannot initialize another viewer runtime with different settings.",
      );
    }
  }
  return runtime.ready;
};
