import React, { useCallback, useState } from "react";
import {
  FloorplanCreateInput,
  FloorplanPatchInput,
} from "../hooks/useFloorplan";
import { ImportFloorplanModal } from "./ImportFloorplanModal";

interface PatchFloorplanModalContainerProps {
  onPatch: (floorplanData: FloorplanPatchInput) => any;
  onClose: () => void;
  floorplanId: string;
  spaceId: string;
  spaceName: string;
}

export const PatchFloorplanModalContainer = (
  props: PatchFloorplanModalContainerProps,
) => {
  const { onPatch, onClose, floorplanId, spaceId, spaceName } = props;
  const [uploadInProgress, setUploadInProgress] = useState(false);

  const uploadFloorplanCallback = useCallback(
    async (floorplan: FloorplanCreateInput) => {
      setUploadInProgress(true);
      onPatch({
        ...floorplan,
        id: floorplanId,
      });
      setUploadInProgress(false);
      onClose();
    },
    [floorplanId, onPatch, onClose],
  );

  return (
    <ImportFloorplanModal
      onClose={onClose}
      spaceId={spaceId}
      spaceName={spaceName}
      createFloorplan={uploadFloorplanCallback}
      uploadInProgress={uploadInProgress}
    />
  );
};
