import React from "react";
import { useNotification } from "../../contexts/Notifications";
import { useUpdateInternalAggservProgressesMutation } from "../../generated/types";
import { Project } from "../../types";
import { MajorActionButton } from "../MajorActionButton/MajorActionButton";

interface AggServInternalLoadButtonProps {
  project: Project;
}

export const AggServInternalLoadButton = (
  props: AggServInternalLoadButtonProps,
) => {
  const notify = useNotification();
  const [updateProgressesMutation, { loading }] =
    useUpdateInternalAggservProgressesMutation({
      onCompleted: () =>
        notify(
          `Successfully triggered internal progress copy for ${props.project.customer}-${props.project.project}! Watch out for updates on slack!`,
          "success",
        ),
      onError: (error) =>
        notify(
          `Failed to trigger internal progress copy for ${props.project.customer}-${props.project.project}: ${error}`,
          "error",
        ),
    });

  const onApply = async () => {
    await updateProgressesMutation({
      variables: {
        customer: props.project.customer,
        project: props.project.project,
      },
    });
  };

  return (
    <MajorActionButton
      action={onApply}
      loading={loading}
      actionTitle="Copy internal progresses to agg. service"
      actionMessage="This action will copy internal progresses to the aggregation service (required for report generation). Do not press repeatedly (you'll have to wait for each copy to complete sequentially)."
      actionConfirmation={`Are you sure you want to copy internal progresses for '${props.project.customer}/${props.project.project}'? Updates will appear in Slack.`}
    />
  );
};
