import { Spin } from "antd";
import React from "react";
import {
  ProgressStatus,
  useUpdateRawMappingProgressesMutation,
} from "../../../generated/types";
import { ProgressChange, Project } from "../../../types";
import { EditableProgressCell } from "./EditableProgressCell";

type EditableProgressCellContainerProps = {
  project: Project;
  progress?: number;
  status?: ProgressStatus;
  componentId: string;
  spaceId: string;
  batchId: string;
  progressChange?: ProgressChange;
  onComponentUpdated: (componentIds: string[]) => any;
};

export const EditableProgressCellContainer = (
  props: EditableProgressCellContainerProps,
) => {
  const [updateProgresses, { loading }] = useUpdateRawMappingProgressesMutation(
    {
      update: (cache: any, _data, { variables }) => {
        // Clear progresses cache for the updated component.
        if (variables?.componentId) {
          cache.evict({
            id: `Component:${variables.componentId}`,
            fieldName: "aggregatedProgresses",
          });
        }
      },
      onQueryUpdated(_observableQuery, cacheDiff) {
        const components = cacheDiff.result.componentsByIds;
        // Refetch progresses for components where they've been cleared from the cache.
        const missingProgresses = components.filter(
          (c) => !c.aggregatedProgresses,
        );
        // Return a promise to make sure that the refetch is waited before returning the final result.
        return Promise.resolve(
          props.onComponentUpdated(missingProgresses.map((c) => c.id)),
        );
      },
    },
  );

  const onSubmitUpdate = async (
    componentId: string,
    spaceId: string,
    batchId: string,
    progress: number,
  ) => {
    await updateProgresses({
      variables: {
        customer: props.project.customer,
        project: props.project.project,
        scope: props.project.scope,
        batchId: batchId,
        componentId,
        spaceId,
        progress,
      },
    });
  };

  return loading ? (
    <Spin />
  ) : (
    <EditableProgressCell
      progress={props.progress}
      status={props.status}
      componentId={props.componentId}
      spaceId={props.spaceId}
      batchId={props.batchId}
      onSubmitUpdate={onSubmitUpdate}
      progressChange={props.progressChange}
    ></EditableProgressCell>
  );
};
