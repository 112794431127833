import { PlusOutlined } from "@ant-design/icons";
import { Button, Modal, Spin } from "antd";
import React, { useState } from "react";
import {
  MappingFragmentDoc,
  useCreateMappingMutation,
} from "../../../generated/types";
import { Project } from "../../../types";

type AddMappingModalProps = {
  space: { id: string; name: string };
  component: { id: string; name: string };
  project: Project;
};

export const AddMappingModal = (props: AddMappingModalProps) => {
  const [isLoading, setIsLoading] = useState(false);
  const [isModalVisible, setIsModalVisible] = useState(false);

  const [createMapping] = useCreateMappingMutation({
    update: (cache, { data }) => {
      cache.modify({
        id: `Space:${data?.createMappings[0].space.id}`,
        fields: {
          mappings() {
            [
              // TODO: fix writing to cache to include weight.
              cache.writeFragment({
                id: `Mapping:${data?.createMappings[0].id}`,
                data: data?.createMappings[0],
                fragment: MappingFragmentDoc,
              }),
            ];
          },
        },
      });
    },
  });

  const onClick = () => {
    setIsModalVisible(true);
  };

  const handleCancel = () => setIsModalVisible(false);
  const handleOk = async () => {
    setIsLoading(true);
    await createMapping({
      variables: {
        customer: props.project.customer,
        project: props.project.project,
        scope: props.project.scope,
        componentId: props.component.id,
        spaceId: props.space.id,
      },
    });
    setIsLoading(false);
    setIsModalVisible(false);
  };
  return (
    <>
      <Button type={"text"} icon={<PlusOutlined />} onClick={onClick} />
      <Modal
        visible={isModalVisible}
        destroyOnClose={true}
        onCancel={handleCancel}
        title={"Add mapping"}
        footer={
          isLoading ? (
            <Spin />
          ) : (
            [
              <Button key="ok" type="primary" onClick={handleOk}>
                Ok
              </Button>,
              <Button key="back" onClick={handleCancel}>
                Cancel
              </Button>,
            ]
          )
        }
      >
        <p>
          Are you sure you want to add this mapping on space{" "}
          <b>{props.space.name}</b> and component <b>{props.component.name}</b>?
        </p>
      </Modal>
    </>
  );
};
