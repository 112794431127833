import { List, Result, Space } from "antd";
import { compareAsc, format } from "date-fns";
import React from "react";
import { useNotification } from "../../contexts/Notifications";
import {
  useDeleteAllHolidaysMutation,
  useGetHolidaysQuery,
} from "../../generated/types";
import { Project } from "../../types";
import { ClearHolidaysModal } from "./ClearHolidaysModal";
import { HolidayUploadModal } from "./HolidayUploadModal";
import styles from "./styles.module.css";

type HolidayTableProps = {
  project: Project;
};

export const HolidayTable = (props: HolidayTableProps) => {
  const notify = useNotification();
  const { data, error, loading, refetch } = useGetHolidaysQuery({
    variables: {
      customer: props.project.customer,
      project: props.project.project,
      scope: props.project.scope,
    },
  });
  const [deleteHolidays] = useDeleteAllHolidaysMutation({
    variables: {
      customer: props.project.customer,
      project: props.project.project,
      scope: props.project.scope,
    },
    onError: (deleteError) =>
      notify(`Deleting all holidays failed: ${deleteError.message}`, "error"),
    onCompleted: () => {
      notify("Holidays successfully cleared!", "success");
      refetch();
    },
  });

  const holidays = (data?.holidays ?? [])
    .map((date) => new Date(date))
    .sort(compareAsc);

  if (error) {
    return (
      <Result
        status="error"
        title="Error fetching holidays table"
        subTitle="Please refresh, try later again or escalate to the support team"
      />
    );
  }

  return (
    <List
      size="small"
      loading={loading}
      header={
        <Space className={styles["holidays-table-title"]}>
          <div>Holidays</div>

          <HolidayUploadModal project={props.project} onUpload={refetch} />
          <ClearHolidaysModal onDelete={() => deleteHolidays()} />
        </Space>
      }
      bordered
      dataSource={holidays}
      className={styles["holidays-table"]}
      renderItem={(item: Date) => (
        <List.Item className={styles["holidays-table-item"]}>
          {format(item, "dd/MM/yyyy")}
        </List.Item>
      )}
    />
  );
};
