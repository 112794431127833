import { CaretDownOutlined, CaretUpOutlined } from "@ant-design/icons";
import { InputNumber } from "antd";
import classNames from "classnames";
import _ from "lodash";
import React, { useState } from "react";
import { ProgressStatus } from "../../../generated/types";
import { ProgressChange } from "../../../types";
import { roundProgress } from "../progresses";
import styles from "../styles.module.css";

type EditableProgressCellProps = {
  progress?: number;
  status?: ProgressStatus;
  componentId: string;
  spaceId: string;
  batchId: string;
  progressChange?: ProgressChange;
  onSubmitUpdate?: (
    componentId: string,
    spaceId: string,
    batchId: string,
    progress: number,
  ) => any;
};

export const EditableProgressCell = ({
  progress,
  status,
  componentId,
  spaceId,
  batchId,
  progressChange,
  onSubmitUpdate,
}: EditableProgressCellProps) => {
  const [isEditing, setIsEditing] = useState(false);
  const [cellValue, setCellValue] = useState<number | undefined>(progress);

  const toggleEdit = () => {
    setIsEditing((e) => !e);
  };

  const onUpdate = async () => {
    if (onSubmitUpdate && !_.isNil(cellValue) && cellValue !== progress) {
      await onSubmitUpdate(componentId, spaceId, batchId, cellValue);
    }
    setIsEditing(false);
  };

  if (progress == null || status == null) {
    return <div className={classNames(styles.mapping, styles.missing)} />;
  }

  const roundedProgress = roundProgress(progress, status);
  return (
    <div
      className={classNames(styles["mapping-progress"], {
        [styles["not-started"]]: status === ProgressStatus.NotStarted,
        [styles["completed"]]: status === ProgressStatus.Finished,
      })}
      data-testid={`mapping-${componentId}-${spaceId}-${batchId}`}
    >
      <ProgressIndicator progressChange={progressChange} />
      <div className={styles["progress-number"]}>
        {isEditing ? (
          <InputNumber
            defaultValue={roundedProgress}
            min={0}
            max={100}
            onPressEnter={onUpdate}
            onChange={setCellValue}
            onBlur={() => setIsEditing(false)}
            className={styles["input-number"]}
            autoFocus
            onFocus={(e) => e.target.select()}
          />
        ) : (
          <div style={{ float: "right" }} onClick={toggleEdit}>
            {roundedProgress}
          </div>
        )}
      </div>
    </div>
  );
};

const ProgressIndicator = ({
  progressChange,
}: {
  progressChange?: ProgressChange;
}) => {
  return progressChange === ProgressChange.Up ? (
    <CaretUpOutlined
      className={classNames(
        styles["progress-up"],
        styles["progress-indicator"],
      )}
    />
  ) : progressChange === ProgressChange.Down ? (
    <CaretDownOutlined
      className={classNames(
        styles["progress-down"],
        styles["progress-indicator"],
      )}
    />
  ) : (
    <div />
  );
};
