import { Button, Card } from "antd";
import { format } from "date-fns";
import _ from "lodash";
import React, { useState } from "react";
import xlsx from "xlsx";
import { useNotification } from "../../contexts/Notifications";
import { useGetAllSpacesWithTagsLazyQuery } from "../../generated/types";
import { Project } from "../../types";

interface SpaceTagsDumpGeneratorProps {
  project: Project;
  tagsOnly?: boolean; //only include spaces with tags
}

const toFilename = (project: Project) => {
  // use a custom date format instead of ISO to avoid dots in the filename
  const dateString = format(new Date(), "yyyyMMdd_HHmmss");
  return `${project.customer}-${project.project}-${dateString}-space-tags.csv`;
};

const generateWorkbook = (spaces: Space[], sheetName) => {
  const workbook = xlsx.utils.book_new();
  const data = spaces.map((s) => [s.tags, s.id, s.name, s.path]);
  const worksheet = xlsx.utils.aoa_to_sheet([
    ["tag", "id", "name", "path"],
    ...data,
  ]);
  xlsx.utils.book_append_sheet(workbook, worksheet, sheetName);
  return workbook;
};

type Space = {
  id: string;
  name: string;
  path: string;
  tags: string[];
  sortKey: number[];
};

export const parseSpacesData = (rootSpaces, tagsOnly?: boolean): Space[] => {
  const spaces: Space[] = [];
  rootSpaces.forEach((rootSpace) => {
    if (!tagsOnly || rootSpace.tags.length > 0) {
      spaces.push({
        name: rootSpace.name,
        tags: rootSpace.tags,
        id: rootSpace.id,
        sortKey: rootSpace.guiIndexPath,
        path: rootSpace.ancestors.map((a) => a.name).join("->"),
      });
    }
    rootSpace.descendantSpaces.forEach((space) => {
      if (!tagsOnly || space.tags.length > 0) {
        spaces.push({
          name: space.name,
          tags: space.tags,
          id: space.id,
          sortKey: space.guiIndexPath,
          path: space.ancestors.map((a) => a.name).join("->"),
        });
      }
    });
  });

  // split things so we have 1 tag per row
  const flatSpaces = spaces.flatMap((space) => {
    if (space.tags.length > 0) {
      return space.tags.map((tag) => ({ ...space, tags: [tag] }));
    } else {
      return [space];
    }
  });
  return _.sortBy(flatSpaces, spaceSortKey);
};

const zeroPad = (num, places) => String(num).padStart(places, "0");

const spaceSortKey = (space: Space) =>
  space.sortKey.map((i) => zeroPad(i, 10)).join("-");

export const SpaceTagsDumpGenerator = (props: SpaceTagsDumpGeneratorProps) => {
  const notify = useNotification();
  const [loading, setLoading] = useState(false);
  const [fetchSpacesWithTags] = useGetAllSpacesWithTagsLazyQuery({
    onError: (error) => {
      notify(
        `Failed to generate dump sheet for scene-objects mapping: ${error}`,
        "error",
      );
      setLoading(false);
    },
    onCompleted: (data) => {
      try {
        const spaces = parseSpacesData(data.spacesByFilter, props.tagsOnly);
        const workbook = generateWorkbook(spaces, "SpaceTags");

        xlsx.writeFile(workbook, toFilename(props.project), {
          sheet: "SpaceTags",
        });
      } catch (error) {
        notify(`Failed to generate space tags csv: ${error}`, "error");
      }
      setLoading(false);
    },
    fetchPolicy: "no-cache", // let the User decide when data has changed
  });

  const onApply = async () => {
    setLoading(true);
    fetchSpacesWithTags({
      variables: {
        customer: props.project.customer,
        project: props.project.project,
        scope: props.project.scope,
      },
    });
  };

  return (
    <Card>
      <Button value="generate" onClick={onApply} loading={loading}>
        {props.tagsOnly
          ? "Download only spaces with tags"
          : "Download all spaces"}
      </Button>
    </Card>
  );
};
