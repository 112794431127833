import Icon from "@ant-design/icons";
import React from "react";

const PalmTreeSvg = () => (
  <svg width="1em" height="1em" fill="currentColor" viewBox="0 0 118 123">
    <path d="M923 283.6c-13.4-31.1-32.6-58.9-56.9-82.8-24.3-23.8-52.5-42.4-84-55.5-32.5-13.5-66.9-20.3-102.4-20.3-49.3 0-97.4 13.5-139.2 39-10 6.1-19.5 12.8-28.5 20.1-9-7.3-18.5-14-28.5-20.1-41.8-25.5-89.9-39-139.2-39-35.5 0-69.9 6.8-102.4 20.3-31.4 13-59.7 31.7-84 55.5-24.4 23.9-43.5 51.7-56.9 82.8-13.9 32.3-21 66.6-21 101.9 0 33.3 6.8 68 20.3 103.3 11.3 29.5 27.5 60.1 48.2 91 32.8 48.9 77.9 99.9 133.9 151.6 92.8 85.7 184.7 144.9 188.6 147.3l23.7 15.2c10.5 6.7 24 6.7 34.5 0l23.7-15.2c3.9-2.5 95.7-61.6 188.6-147.3 56-51.7 101.1-102.7 133.9-151.6 20.7-30.9 37-61.5 48.2-91 13.5-35.3 20.3-70 20.3-103.3 0.1-35.3-7-69.6-20.9-101.9z" />
    <path d="M59.5072 123C67.6544 98.1744 63.0183 68.5 55.6619 43.2968C54.2055 43.2968 47.2949 73.5 55.2474 98.0203C34.5249 88.5719 31.0455 63.3917 44.4765 44.533C44.4825 44.5245 44.4748 44.5126 44.4655 44.5173C35.7337 48.9074 15.9398 64.2597 10.0342 75.8383C11.369 57.2528 18.8916 40.6565 41.6676 35.197C41.6779 35.1946 41.6782 35.1797 41.6678 35.1773C26.4665 31.7701 12.6295 38.4868 0 46.8506C8.63764 26.508 21.899 14.3606 47.2949 24.2629C43.941 13.7415 36.128 6.43552 27.0574 0L27.1365 0.00116606C41.143 0.207581 51.9269 0.366507 57.4938 22.9748C57.496 22.9836 57.5082 22.9855 57.5126 22.9776C71.6295 -2.07433 98.0596 -1.83247 118 25.3915C97.5745 17.9157 81.306 17.2192 66.0237 28.9817C66.0162 28.9876 66.0207 28.9999 66.0303 28.9998C100.711 28.8006 112.884 42.9828 109.187 71.1417C91.3189 51.9656 76.2743 41.3135 63.0363 38.7157C63.0285 38.7142 63.0221 38.7215 63.0251 38.7288C69.2531 54.1474 82.1748 83.5113 75.9516 123H59.5072Z" />
  </svg>
);

export const PalmTreeIcon = (props) => (
  <Icon component={PalmTreeSvg} {...props} />
);
