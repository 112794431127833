import { LinkOutlined } from "@ant-design/icons";
import { Button, Tooltip } from "antd";
import React from "react";

interface SceneAliasButtonProps {
  selectedNodeIsScene: boolean;
  aliasExists: boolean;
  size: "large";
  type: "text";
  onClick: () => void;
}

const SHOW_CREATE_TITLE = "Create scene alias";
const SHOW_DELETE_TITLE = "Delete scene alias";
const SHOW_HELP_TITLE = "You need to select a scene to edit the scene alias";

export const SceneAliasButton = (props: SceneAliasButtonProps) => {
  const { selectedNodeIsScene, aliasExists, size, type, onClick } = props;
  return (
    <Tooltip
      title={
        selectedNodeIsScene
          ? aliasExists
            ? SHOW_DELETE_TITLE
            : SHOW_CREATE_TITLE
          : SHOW_HELP_TITLE
      }
    >
      <Button
        size={size}
        icon={<LinkOutlined />}
        type={type}
        disabled={!selectedNodeIsScene}
        onClick={onClick}
      />
    </Tooltip>
  );
};
