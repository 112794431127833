import * as React from "react";
import styles from "./styles.module.css";

type LinkProps = React.PropsWithChildren<{
  onClick?: () => any;
}>;

export const Link = (props: LinkProps) => {
  const className =
    props.onClick === undefined ? styles.disabledLink : styles.link;
  return (
    <span className={className} onClick={props.onClick}>
      {props.children}
    </span>
  );
};
