import {
  AppstoreAddOutlined,
  DragOutlined,
  MergeCellsOutlined,
  PlusCircleOutlined,
  SelectOutlined,
} from "@ant-design/icons";
import { Button, Form, Input, Popover, Tooltip } from "antd";
import classNames from "classnames";
import React, { useState } from "react";
import styles from "./SpatialConfigurationMode.module.css";

interface CreateSceneButtonProps {
  isActive: boolean;
  isTreeNodeSelected: boolean;
  onClick: () => void;
}

const CREATE_TITLE = "Create scenes";
const CREATE_HELP_TITLE = "Select a tree node to create a new scene";

export const CreateSceneButton = (props: CreateSceneButtonProps) => {
  const { isActive, isTreeNodeSelected, onClick } = props;
  return (
    <div className={styles["scene-switch-box"]}>
      <Tooltip title={isTreeNodeSelected ? CREATE_TITLE : CREATE_HELP_TITLE}>
        <Button
          className={classNames({ [styles["active-button"]]: isActive })}
          disabled={!isTreeNodeSelected}
          icon={<PlusCircleOutlined />}
          onClick={onClick}
          type="text"
        />
      </Tooltip>
    </div>
  );
};

const SELECT_TITLE = "Select scenes";

interface SelectSceneButtonProps {
  isActive: boolean;
  onClick: () => void;
  isFloorplanSelected: boolean;
}

export const SelectSceneButton = (props: SelectSceneButtonProps) => {
  const { isActive, onClick, isFloorplanSelected } = props;
  return (
    <div className={styles["scene-switch-box"]}>
      <Tooltip title={SELECT_TITLE}>
        <Button
          className={classNames({ [styles["active-button"]]: isActive })}
          disabled={!isFloorplanSelected}
          icon={<SelectOutlined />}
          onClick={onClick}
          type="text"
        />
      </Tooltip>
    </div>
  );
};

const MOVE_TITLE = "Move scenes";
const MOVE_HELP_TITLE = "Select scenes to move them";

interface MoveSceneButtonProps {
  isActive: boolean;
  onClick: () => void;
  isSelectionScenes: boolean;
}

export const MoveSceneButton = (props: MoveSceneButtonProps) => {
  const { isActive, onClick, isSelectionScenes } = props;
  return (
    <div className={styles["scene-switch-box"]}>
      <Tooltip title={isSelectionScenes ? MOVE_TITLE : MOVE_HELP_TITLE}>
        <Button
          className={classNames({ [styles["active-button"]]: isActive })}
          disabled={!isSelectionScenes}
          icon={<DragOutlined />}
          onClick={onClick}
          type="text"
        />
      </Tooltip>
    </div>
  );
};

const CREATE_SPACE_TITLE = "Create space";
const CREATE_SPACE_HELP_TITLE = "Select a space node to create a new space";

interface CreateSpaceButtonProps {
  onClick: (name?: string) => void;
  isSpaceSelected: boolean;
  disableCreateSceneMode?: () => boolean;
}

export const CreateSpaceButton = (props: CreateSpaceButtonProps) => {
  const { onClick, isSpaceSelected, disableCreateSceneMode } = props;
  return (
    <div className={styles["scene-switch-box"]}>
      <CreateSpacePopover
        onClick={onClick}
        disableCreateSceneMode={disableCreateSceneMode}
      >
        <Tooltip
          title={isSpaceSelected ? CREATE_SPACE_TITLE : CREATE_SPACE_HELP_TITLE}
        >
          <Button
            disabled={!isSpaceSelected}
            icon={<AppstoreAddOutlined />}
            type="text"
          />
        </Tooltip>
      </CreateSpacePopover>
    </div>
  );
};

const CREATE_SPACE_GROUP_TITLE = "Create a new space group";
const CREATE_SPACE_GROUP_HELP_TITLE =
  "Select sibling spaces and scenes to create a new space group";

interface CreateSpaceGroupButtonProps {
  onClick: (name?: string) => void;
  hasSiblingNodesWithoutFloorplanSelected: boolean;
  disableCreateSceneMode?: () => boolean;
}

export const CreateSpaceGroupButton = (props: CreateSpaceGroupButtonProps) => {
  const {
    onClick,
    hasSiblingNodesWithoutFloorplanSelected,
    disableCreateSceneMode,
  } = props;
  return (
    <div className={styles["scene-switch-box"]}>
      <CreateSpacePopover
        onClick={onClick}
        disableCreateSceneMode={disableCreateSceneMode}
      >
        <Tooltip
          title={
            hasSiblingNodesWithoutFloorplanSelected
              ? CREATE_SPACE_GROUP_TITLE
              : CREATE_SPACE_GROUP_HELP_TITLE
          }
        >
          <Button
            disabled={!hasSiblingNodesWithoutFloorplanSelected}
            icon={<MergeCellsOutlined />}
            type="text"
          />
        </Tooltip>
      </CreateSpacePopover>
    </div>
  );
};

export const CreateSpaceForm = (props: {
  onSubmit: (name?: string) => void;
}) => {
  const { onSubmit } = props;
  const [form] = Form.useForm();
  const handleSubmit = async () => {
    onSubmit(form.getFieldValue("name"));
    form.resetFields();
  };

  return (
    <>
      <Form form={form} autoComplete="off">
        <Form.Item label="Name" name="name">
          <Input />
        </Form.Item>
      </Form>
      <Button type="primary" onClick={handleSubmit}>
        Submit
      </Button>
    </>
  );
};

interface CreateSpacePopoverProps {
  onClick: (name?: string) => void;
  children: React.ReactNode;
  disableCreateSceneMode?: () => boolean;
}

export const CreateSpacePopover = (props: CreateSpacePopoverProps) => {
  const { onClick, children, disableCreateSceneMode } = props;
  const [isPopoverVisible, setIsPopoverVisible] = useState(false);

  const updateIsPopoverVisible = (value: boolean) => {
    setIsPopoverVisible(() => {
      disableCreateSceneMode?.();
      return value;
    });
  };

  const onFormSubmit = (name?: string) => {
    onClick(name);
    updateIsPopoverVisible(false);
  };
  return (
    <Popover
      visible={isPopoverVisible}
      trigger="click"
      content={<CreateSpaceForm onSubmit={onFormSubmit} />}
      onVisibleChange={updateIsPopoverVisible}
    >
      {children}
    </Popover>
  );
};
