// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/cssWithMappingToString.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".styles-module__app--N-t44{width:100%;height:100%;min-width:100%;min-height:100%}.styles-module__app--N-t44 .ant-layout-content{overflow-y:auto}.styles-module__app-content--2vgJf{display:flex;flex-direction:column}.styles-module__select-project-prompt--3VtMy{width:100%;display:flex;justify-content:center;align-items:center}", "",{"version":3,"sources":["webpack://./src/App/styles.module.css"],"names":[],"mappings":"AAAA,2BACE,UAAA,CACA,WAAA,CACA,cAAA,CACA,eAAA,CAEA,+CACE,eAAA,CAIJ,mCACE,YAAA,CACA,qBAAA,CAGF,6CACE,UAAA,CAEA,YAAA,CACA,sBAAA,CACA,kBAAA","sourcesContent":[".app {\n  width: 100%;\n  height: 100%;\n  min-width: 100%;\n  min-height: 100%;\n\n  :global(.ant-layout-content) {\n    overflow-y: auto;\n  }\n}\n\n.app-content {\n  display: flex;\n  flex-direction: column;\n}\n\n.select-project-prompt {\n  width: 100%;\n\n  display: flex;\n  justify-content: center;\n  align-items: center;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"app": "styles-module__app--N-t44",
	"app-content": "styles-module__app-content--2vgJf",
	"select-project-prompt": "styles-module__select-project-prompt--3VtMy"
};
export default ___CSS_LOADER_EXPORT___;
