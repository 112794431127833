import React, { useCallback } from "react";
import { DeleteConfirmationModal } from "../DeleteConfirmationModal/DeleteConfirmationModal";

interface DeleteFloorplanContainerProps {
  floorplanId: string;
  onDelete: (id: string) => Promise<void>;
  onClose: () => void;
}

const DELETE_TITLE = "Delete Floorplan";
const DELETE_BODY = "Deleting an existing floorplan";
const DELETE_ALERT =
  "Deleting the floorplan will remove all associated data including sequences and sequence points";

export const DeleteFloorplanContainer = (
  props: DeleteFloorplanContainerProps,
) => {
  const { floorplanId, onClose, onDelete } = props;

  const deleteFloorplanCallback = useCallback(
    async (id: string) => {
      await onDelete(id);
      onClose();
    },
    [onDelete, onClose],
  );

  return (
    <DeleteConfirmationModal
      title={DELETE_TITLE}
      visible={true}
      setVisible={onClose}
      onOk={() => deleteFloorplanCallback(floorplanId)}
      deletionText={floorplanId}
      modalBody={DELETE_BODY}
      alertText={DELETE_ALERT}
    />
  );
};
