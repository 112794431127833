import { Col, Row, Select } from "antd";
import classNames from "classnames";
import React, { useEffect, useReducer } from "react";
import { useGetAllComponentTagTypesByPlanQuery } from "../../generated/types";
import { Project } from "../../types";
import styles from "./styles.module.css";
import { Task } from "./types";

export type SearchInput = {
  term: string[];
  tagsTerm: string[];
};

type UpdateTerm = {
  type: "UPDATE_TERM";
  payload: string[];
};

type UpdateTagsTerm = {
  type: "UPDATE_TAGS";
  payload: string[];
};

type UpdateSearchInput = UpdateTerm | UpdateTagsTerm;

const searchInputReducer = (
  state: SearchInput,
  action: UpdateSearchInput,
): SearchInput => {
  switch (action.type) {
    case "UPDATE_TERM":
      return { ...state, term: action.payload };
    case "UPDATE_TAGS":
      return { ...state, tagsTerm: action.payload };
  }
};

type SearchBarProps = {
  project: Project;
  planId: string;
  onChange: (value: SearchInput) => any;
  allowSearchByTags: boolean;
};

export const SearchBar = (props: SearchBarProps) => {
  const { project, planId, onChange, allowSearchByTags } = props;
  const { customer, project: proj, scope } = project;
  const [searchInput, setSearchInput] = useReducer(searchInputReducer, {
    term: [],
    tagsTerm: [],
  });

  const { data } = useGetAllComponentTagTypesByPlanQuery({
    variables: {
      customer: customer,
      project: proj,
      scope: scope,
      planId: planId,
    },
  });
  const componentTags = data?.componentTagTypesByPlan ?? [];

  useEffect(() => {
    onChange(searchInput);
  }, [onChange, searchInput]);

  return (
    <Row>
      <Col span={allowSearchByTags ? 12 : 24}>
        <Select
          className={classNames(styles["search-bar-multi-select"])}
          placeholder="Search by names ..."
          mode="tags"
          onChange={(value: string[]) => {
            setSearchInput({
              type: "UPDATE_TERM",
              payload: value,
            });
          }}
          allowClear
        />
      </Col>
      {allowSearchByTags && (
        <Col span={12}>
          <Select
            className={classNames(styles["search-bar-multi-select"])}
            placeholder="Search by tags ..."
            mode="multiple"
            onChange={(value: string[]) => {
              setSearchInput({
                type: "UPDATE_TAGS",
                payload: value,
              });
            }}
            allowClear
          >
            {componentTags.map((tag) => (
              <Select.Option key={tag} value={tag}>
                {tag}
              </Select.Option>
            ))}
          </Select>
        </Col>
      )}
    </Row>
  );
};

export const matches = (
  nodeInfo: Task & { tags?: string[] },
  searchInput: SearchInput,
) => {
  // if we don't have a searchInput we are not searching
  if (searchIsEmpty(searchInput)) {
    return false;
  }
  const name = nodeInfo.name;
  const inputTerms = (searchInput?.term as string[]).map((input) =>
    input.toLocaleLowerCase(),
  );
  const nameMatch =
    !inputTerms.length ||
    inputTerms.some((input) => name?.toLocaleLowerCase().indexOf(input) > -1);

  const tagsInputTerms = (searchInput?.tagsTerm as string[]).map((input) =>
    input.toLocaleLowerCase(),
  );

  const tagsMatch =
    !tagsInputTerms.length ||
    nodeInfo.tags?.some((tag) =>
      tagsInputTerms.some(
        (inputTerm) => tag.toLocaleLowerCase().indexOf(inputTerm) > -1,
      ),
    );
  const result =
    nameMatch && tagsMatch && (inputTerms.length || tagsInputTerms.length);

  return !!result;
};

export const searchIsEmpty = (
  searchInput: SearchInput | undefined,
): boolean => {
  return (
    searchInput === undefined ||
    (searchInput.term.length === 0 && searchInput.tagsTerm.length === 0)
  );
};
