import { BorderOuterOutlined, NodeIndexOutlined } from "@ant-design/icons";
import { Button, Select, Tooltip } from "antd";
import classNames from "classnames";
import _ from "lodash";
import React, { useMemo } from "react";
import { useProject } from "../../App/state";
import { FloorplanIdToSpaceId } from "../../components/SpatialConfigurationMode/types";
import { useGetSpaceAncestorNamesByIdQuery } from "../../generated/types";
import styles from "./SpatialConfigModePage.module.css";
import { ActiveMode } from "./SpatialConfigPage";

const SPATIAL_BUTTON_TOOLTIP_TITLE = "Switch to Spatial Mode";
const SEQUENCE_BUTTON_TOOLTIP_TITLE = "Switch to Sequence Mode";
const FLOORPLAN_SELECTOR_PLACEHOLDER = "Select a floor plan";

interface HeaderButtonsBarProps {
  activeMode: ActiveMode;
  setActiveMode: (mode: ActiveMode) => void;
  floorplanIdToSpaceId: FloorplanIdToSpaceId;
  selectedFloorlanId?: string;
  onSelectFloorplanId: (floorplanId: string) => void;
  customButtons: React.ReactNode;
}

export const SpatialConfigHeaderBar = (props: HeaderButtonsBarProps) => {
  const {
    activeMode,
    setActiveMode,
    floorplanIdToSpaceId,
    selectedFloorlanId,
    onSelectFloorplanId,
    customButtons,
  } = props;

  const project = useProject();

  const { data: floorplanSpacesData } = useGetSpaceAncestorNamesByIdQuery({
    variables: {
      tenant: project,
      spaceIds: Object.values(floorplanIdToSpaceId),
    },
    skip: !Object.values(floorplanIdToSpaceId).length,
  });

  const selectFloorplanOptions = useMemo(() => {
    if (floorplanSpacesData?.spacesByFilter) {
      const floorplanSpacesMap = _.keyBy(
        floorplanSpacesData?.spacesByFilter,
        "id",
      );

      return _.sortBy(
        Object.entries(floorplanIdToSpaceId).map(([key, value]) => ({
          id: key,
          label: `${floorplanSpacesMap[value].name} - ${floorplanSpacesMap[
            value
          ].ancestors
            .map((s) => s.name)
            .join("-")}`,
        })),
        "label",
      );
    } else {
      return [];
    }
  }, [floorplanSpacesData, floorplanIdToSpaceId]);

  return (
    <div className={styles["header"]}>
      <div className={styles["action-buttons"]}>
        <SwitchModeButton
          tooltipTitle={SPATIAL_BUTTON_TOOLTIP_TITLE}
          isSelected={activeMode === ActiveMode.DEFAULT}
          onClick={() => setActiveMode(ActiveMode.DEFAULT)}
          icon={<BorderOuterOutlined />}
        />
        <SwitchModeButton
          tooltipTitle={SEQUENCE_BUTTON_TOOLTIP_TITLE}
          isSelected={activeMode === ActiveMode.SEQUENCE}
          onClick={() => setActiveMode(ActiveMode.SEQUENCE)}
          icon={<NodeIndexOutlined />}
        />
        {customButtons && (
          <div className={styles["buttons-box"]}>{customButtons}</div>
        )}
      </div>
      <div className={styles["selector-box"]}>
        <Select
          className={styles["selector"]}
          placeholder={FLOORPLAN_SELECTOR_PLACEHOLDER}
          onChange={onSelectFloorplanId}
          showSearch
          value={selectedFloorlanId}
          filterOption={(input, option) =>
            option?.children
              ?.toLowerCase()
              ?.indexOf(input.toLowerCase().trim()) >= 0
          }
        >
          {selectFloorplanOptions.map((option) => (
            <Select.Option key={option.id} value={option.id}>
              {option.label}
            </Select.Option>
          ))}
        </Select>
      </div>
    </div>
  );
};

interface SwitchModeButtonProps {
  tooltipTitle: string;
  isSelected: boolean;
  icon: React.ReactNode;
  onClick: () => void;
}

export const SwitchModeButton = (props: SwitchModeButtonProps) => {
  const { tooltipTitle, isSelected, onClick, icon } = props;
  return (
    <Tooltip title={tooltipTitle}>
      <Button
        className={classNames([
          {
            [styles["active-button"]]: isSelected,
          },
        ])}
        icon={icon}
        type="text"
        size="large"
        onClick={onClick}
      />{" "}
    </Tooltip>
  );
};
