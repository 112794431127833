import { useState } from "react";

export enum ModelState {
  LOADING = "Loading BIM...",
  LOADED = "Loaded BIM",
  NO_DATA = "Your BIM will be available here once you provide it and we set it up",
}

export enum LocationState {
  LOADING = "Location loading",
  LOADED = "Location loaded",
  NO_DATA = "No location available",
}

export enum SwitchState {
  MODEL_VIEW = "Switch to 360 Image",
  PANO_VIEW = "Switch to BIM Model",
  INACTIVE = "Inactive",
}

export type BimState = {
  model: ModelState;
  location: LocationState;
  switch: SwitchState;
};

export enum BimStateAction {
  MODEL_SUCCESS = "Model success",
  MODEL_ERROR = "Model error",
  MODEL_RESET = "Model reset",
  LOCATION_SUCCESS = "Location success",
  LOCATION_ERROR = "Location error",
  LOCATION_RESET = "Location reset",
  SHOW_MODEL = "Show model",
  HIDE_MODEL = "Hide model",
}

export const useBimState = () => {
  const [bimState, setBimState] = useState<BimState>({
    model: ModelState.LOADING,
    location: LocationState.LOADING,
    switch: SwitchState.INACTIVE,
  });

  const onChangeBimState = (action: BimStateAction) => {
    const transition = (newState: Partial<BimState>) =>
      setBimState((existingState) => ({ ...existingState, ...newState }));

    switch (action) {
      case BimStateAction.SHOW_MODEL:
        switch (bimState.switch) {
          case SwitchState.PANO_VIEW:
            transition({ switch: SwitchState.MODEL_VIEW });
            break;
        }
        break;
      case BimStateAction.HIDE_MODEL:
        switch (bimState.switch) {
          case SwitchState.MODEL_VIEW:
            transition({ switch: SwitchState.PANO_VIEW });
            break;
        }
        break;
      case BimStateAction.LOCATION_SUCCESS:
        switch (bimState.location) {
          case LocationState.NO_DATA:
          case LocationState.LOADING:
            transition({
              location: LocationState.LOADED,
              switch:
                bimState.model === ModelState.LOADED &&
                bimState.switch === SwitchState.INACTIVE
                  ? SwitchState.PANO_VIEW
                  : bimState.switch,
            });
            break;
        }
        break;
      case BimStateAction.LOCATION_RESET:
        switch (bimState.location) {
          case LocationState.LOADED:
          case LocationState.NO_DATA:
            transition({ location: LocationState.LOADING });
            break;
        }
        break;
      case BimStateAction.LOCATION_ERROR:
        switch (bimState.location) {
          case LocationState.LOADED:
          case LocationState.LOADING:
            transition({
              location: LocationState.NO_DATA,
              switch: SwitchState.INACTIVE,
            });
            break;
        }
        break;
      case BimStateAction.MODEL_SUCCESS:
        switch (bimState.model) {
          case ModelState.NO_DATA:
          case ModelState.LOADING:
            transition({
              model: ModelState.LOADED,
              switch:
                bimState.location !== LocationState.NO_DATA
                  ? SwitchState.PANO_VIEW
                  : SwitchState.INACTIVE,
            });
            break;
        }
        break;
      case BimStateAction.MODEL_RESET:
        switch (bimState.model) {
          case ModelState.NO_DATA:
          case ModelState.LOADED:
            transition({ model: ModelState.LOADING });
            break;
        }
        break;
      case BimStateAction.MODEL_ERROR:
        switch (bimState.model) {
          case ModelState.LOADED:
          case ModelState.LOADING:
            transition({
              model: ModelState.LOADING,
              switch: SwitchState.INACTIVE,
            });
            break;
        }
        break;
    }
  };

  return {
    bimState,
    onChangeBimState,
  };
};
