import immutable from "immutable";
import _ from "lodash";
import { EventDataNode } from "rc-tree/lib/interface";
import React from "react";
import { allExpandedDescendants, findNode } from "../display-tree-utils";
import { DisplayTreeNode } from "../types";
import { useStateWithSubscription } from "./state-with-subscription";

export const useCheckable = (
  treeData: DisplayTreeNode[],
  onCheckedCallback?: (keys: string[]) => void,
) => {
  const [checkedNodes, setCheckedNodes] = useStateWithSubscription<
    immutable.Set<string>
  >(immutable.Set(), (newKeys: immutable.Set<string>) =>
    onCheckedCallback?.(Array.from(newKeys)),
  );

  const keys = React.useMemo(() => {
    return {
      checked: checkedNodes.toArray(),
    };
  }, [checkedNodes]);

  const setCheckedChildKeys = React.useCallback(
    (key: string, expandedKeys: string[]) => {
      const node = findNode(treeData, key);
      if (node === undefined) {
        throw new Error(`Could not find tree node with key ${key}`);
      }
      const descendants = allExpandedDescendants(node, expandedKeys);
      if (!_.some(descendants, (d) => keys.checked.includes(d))) {
        setCheckedNodes((nodes) => nodes.union(descendants));
      } else {
        setCheckedNodes(immutable.Set());
      }
    },
    [treeData, keys.checked, setCheckedNodes],
  );

  const setCheckedKeys = React.useCallback(
    (
      checkedKeys: { checked: string[] },
      expandedKeys: string[],
      info: { node: EventDataNode; nativeEvent: MouseEvent },
    ) => {
      if (info.nativeEvent.shiftKey) {
        setCheckedChildKeys(info.node.key as string, expandedKeys);
      } else {
        setCheckedNodes(immutable.Set(checkedKeys.checked));
      }
    },
    [setCheckedNodes, setCheckedChildKeys],
  );

  const checkNodes = React.useCallback(
    (
      visit: (
        node: DisplayTreeNode,
        allKeys: immutable.Set<string>,
      ) => immutable.Set<string>,
    ) => {
      let allKeys = immutable.Set<string>();
      treeData.forEach((node) => {
        allKeys = allKeys.union(visit(node, allKeys));
      });
      setCheckedNodes(allKeys);
    },
    [treeData, setCheckedNodes],
  );

  const checkExpanded = React.useCallback(
    (expandedKeys: string[]) => {
      const visit = (node: DisplayTreeNode, allKeys: immutable.Set<string>) => {
        if (node.checkable) {
          allKeys = allKeys.add(node.key);
        }
        if (expandedKeys.includes(node.key)) {
          node.children?.forEach((child) => {
            allKeys = allKeys.union(visit(child, allKeys));
          });
        }
        return allKeys;
      };
      checkNodes(visit);
    },
    [checkNodes],
  );

  const checkExpandedSearchHits = React.useCallback(
    (searchHits: string[], expandedKeys: string[]) => {
      const visit = (node: DisplayTreeNode, allKeys: immutable.Set<string>) => {
        if (searchHits.includes(node.key) && node.checkable) {
          allKeys = allKeys.add(node.key);
        }
        if (expandedKeys.includes(node.key)) {
          node.children?.forEach((child) => {
            allKeys = allKeys.union(visit(child, allKeys));
          });
        }
        return allKeys;
      };
      checkNodes(visit);
    },
    [checkNodes],
  );

  const uncheckAll = React.useCallback(() => {
    setCheckedNodes(immutable.Set());
  }, [setCheckedNodes]);

  return {
    keys,
    setCheckedKeys,
    setCheckedChildKeys,
    checkExpanded,
    uncheckAll,
    checkExpandedSearchHits,
  };
};
