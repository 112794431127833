import { DownOutlined } from "@ant-design/icons";
import { Button, Card, Col, Dropdown, Menu, Row, Space } from "antd";
import React from "react";
import { Project } from "../../types";
import { useRandomId } from "../../utils/react-utils";
import { Space as SpaceType } from "../SpacesTree/tree-nodes";
import styles from "./ConfigurationBottomRibbons.module.css";
import { DeleteSpacesButton } from "./spaces/DeleteSpacesButton";
import { EditSpaceForm } from "./spaces/EditSpaceForm";
import { EditSpaceTagsForm } from "./spaces/EditSpaceTagsForm";
import { NewSceneForm } from "./spaces/NewSceneForm";
import { NewSpaceForm } from "./spaces/NewSpaceForm";

export type Action = "new space" | "new scene" | "edit" | "delete";

interface ConfigSpacesBottomRibbonProps {
  id?: string;
  project: Project;
  selectedSpaces: SpaceType[];
  onNodesDeleted: () => void;
}

const ConfigurationSpacesBottomRibbon = (
  props: ConfigSpacesBottomRibbonProps,
) => {
  const { id, project, selectedSpaces, onNodesDeleted } = props;

  const [selectedAction, setSelectedAction] = React.useState<Action>("edit");

  const randomFormId = useRandomId();
  const formId = id ?? randomFormId;

  return (
    <Card className={styles["bottom-ribbon"]}>
      <Row gutter={8}>
        <Col span={7}>
          <Space className={styles.actions} direction="vertical">
            <Row>
              <Space className={styles["actions-row"]}>
                <DeleteSpacesButton
                  project={project}
                  selectedSpaces={selectedSpaces}
                  disabled={selectedSpaces.length === 0}
                  onDelete={onNodesDeleted}
                />
                <Dropdown
                  overlay={
                    <Menu
                      onClick={(item) => setSelectedAction(item.key as Action)}
                    >
                      <Menu.Item key="new space">space</Menu.Item>
                      <Menu.Item key="new scene">scene</Menu.Item>
                    </Menu>
                  }
                  data-testid="new-element-type"
                  disabled={selectedSpaces.length === 0}
                >
                  <Button
                    block
                    type={
                      selectedAction === "new scene" ||
                      selectedAction === "new space"
                        ? "primary"
                        : "default"
                    }
                  >
                    new
                    <DownOutlined />
                  </Button>
                </Dropdown>

                <Button
                  className={styles["radio-button"]}
                  disabled={selectedSpaces.length === 0}
                  type={selectedAction === "edit" ? "primary" : "default"}
                  onClick={() => {
                    setSelectedAction("edit");
                  }}
                >
                  edit
                </Button>
              </Space>
            </Row>
            <Row>
              <Space className={styles["actions-row"]}>
                <Button form={formId} block type="primary" htmlType="submit">
                  Apply changes
                </Button>
              </Space>
            </Row>
          </Space>
        </Col>

        <Col span={15}>
          <Space direction="vertical" className={styles.form}>
            {selectedAction === "new scene" ? (
              <NewSceneForm
                id={formId}
                disabled={selectedSpaces.length === 0}
                selectedSpaces={selectedSpaces}
                project={project}
              />
            ) : selectedAction === "new space" ? (
              <NewSpaceForm
                id={formId}
                project={project}
                disabled={selectedSpaces.length === 0}
                selectedSpaces={selectedSpaces}
              />
            ) : (
              <EditSpaceForm
                id={formId}
                project={project}
                disabled={selectedSpaces.length === 0}
                selectedSpaces={selectedSpaces}
              />
            )}
          </Space>
        </Col>
        <Col span={2}>
          <Space className={styles.tags} direction="vertical">
            Tags:
            <EditSpaceTagsForm
              selectedSpaces={selectedSpaces}
              project={project}
            />
          </Space>
        </Col>
      </Row>
    </Card>
  );
};

export default ConfigurationSpacesBottomRibbon;
