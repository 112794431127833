import { DeleteOutlined, TagsOutlined } from "@ant-design/icons";
import { gql } from "@apollo/client";
import { Button, Tooltip } from "antd";
import _ from "lodash";
import React from "react";
import { useNotification } from "../../contexts/Notifications";
import {
  useGetAllComponentTagTypesByPlanQuery,
  useGetExistingComponentsQuery,
  useUpdateComponentTagsMutation,
} from "../../generated/types";
import { Project } from "../../types";
import styles from "./ConfigPlansBottomRibbon.module.css";
import { EditComponentTagsModal } from "./EditComponentTagsModal";

interface EditComponentTagsContainerProps {
  project: Project;
  planId: string | undefined;
  selectedComponents: string[];
}

export const EditComponentTagsContainer = (
  props: EditComponentTagsContainerProps,
) => {
  const [addTagsModalVisible, setAddTagsModalVisible] =
    React.useState<boolean>(false);
  const [removeTagsModalVisible, setRemoveTagsModalVisible] =
    React.useState<boolean>(false);

  const notify = useNotification();

  const { data: allComponentTagTypesRes } =
    useGetAllComponentTagTypesByPlanQuery({
      variables: {
        customer: props.project.customer,
        project: props.project.project,
        scope: props.project.scope,
        planId: props.planId,
      },
    });
  const allComponentTags =
    allComponentTagTypesRes?.componentTagTypesByPlan ?? [];

  const { data: existingComponents } = useGetExistingComponentsQuery({
    variables: {
      customer: props.project.customer,
      project: props.project.project,
      scope: props.project.scope,
      componentIds: props.selectedComponents,
      planId: props.planId,
    },
  });

  const componentsById = _.keyBy(existingComponents?.componentsByIds, "id");
  const selectedComponentsWithAncestors =
    existingComponents?.componentsByIds.map((c) => ({
      guiIndex: c.guiIndex ?? null,
      name: c.name,
      id: c.id,
      tags: c.tags,
      ancestors: c.parentTask
        ? [
            ...c.parentTask.ancestors,
            { name: c.parentTask.name, guiIndex: c.parentTask.guiIndex },
          ]
        : [],
    }));

  const [updateComponentTags] = useUpdateComponentTagsMutation({
    update: (cache, { data }) => {
      cache.modify({
        fields: {
          componentTagTypesByPlan() {
            data?.updateComponents
              ? data.updateComponents.map((component) =>
                  cache.writeFragment({
                    id: `Component:${component.id}`,
                    data: component,
                    fragment: gql`
                      fragment ComponentWithTags on Component {
                        id
                        name
                        tags
                      }
                    `,
                  }),
                )
              : [];
          },
        },
      });
    },
    onCompleted: () => {
      notify("Updated component tags", "success");
    },
    onError: (error) =>
      notify(`${"Error updating component tags"} - ${error}`, "error"),
  });

  const submitTags = (tags: string[]) => {
    return updateComponentTags({
      variables: {
        customer: props.project.customer,
        project: props.project.project,
        scope: props.project.scope,
        components: props.selectedComponents.map((componentId) => ({
          id: componentId,
          tags: _.uniq([...tags, ...componentsById[componentId].tags]),
        })),
      },
    });
  };

  const removeTags = (tags: string[]) => {
    return updateComponentTags({
      variables: {
        customer: props.project.customer,
        project: props.project.project,
        scope: props.project.scope,
        components: props.selectedComponents.map((componentId) => ({
          id: componentId,
          tags: _.difference(componentsById[componentId].tags, tags),
        })),
      },
    });
  };

  return (
    <>
      <div>
        Tags:
        <div className={styles["tag-button-container"]}>
          <Tooltip title={"Add tags"}>
            <Button
              icon={<TagsOutlined />}
              disabled={props.planId ? false : true}
              onClick={() => {
                setAddTagsModalVisible(true);
              }}
            />
          </Tooltip>
        </div>
      </div>
      <div className={styles["tag-button-container"]}>
        <Tooltip title={"Remove tags"}>
          <Button
            icon={<DeleteOutlined />}
            disabled={props.planId ? false : true}
            onClick={() => {
              setRemoveTagsModalVisible(true);
            }}
          />
        </Tooltip>
      </div>
      <>
        <EditComponentTagsModal
          title="Add Component tags"
          actionText="adding"
          allowNewTagCreation={true}
          visible={addTagsModalVisible}
          onCancel={() => setAddTagsModalVisible(false)}
          onSubmit={(tags: string[]) => {
            submitTags(tags);
            setAddTagsModalVisible(false);
          }}
          components={selectedComponentsWithAncestors ?? []}
          allComponentTags={allComponentTags}
        />
        <EditComponentTagsModal
          title="Remove Component tags"
          actionText="removing"
          allowNewTagCreation={false}
          visible={removeTagsModalVisible}
          onCancel={() => setRemoveTagsModalVisible(false)}
          onSubmit={(tags: string[]) => {
            removeTags(tags);
            setRemoveTagsModalVisible(false);
          }}
          components={selectedComponentsWithAncestors ?? []}
          allComponentTags={allComponentTags}
        />
      </>
    </>
  );
};
