import React from "react";
import { SimpleCircleNode } from "../types";

type SimpleCircleProps = {
  node: SimpleCircleNode;
};

export const SimpleCircle = ({ node }: SimpleCircleProps) => {
  return (
    <circle
      id={node.id}
      cx={node.x}
      cy={node.y}
      r={node.size}
      fill={node.color}
      stroke={node.strokeColor}
      strokeWidth={node.strokeWidth}
      paintOrder={"stroke"}
      opacity={node.opacity}
    >
      {node.title && <title>{node.title}</title>}
    </circle>
  );
};
