// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/cssWithMappingToString.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".ComponentTagSelector-module__selector--10nne{width:100%;min-width:150px}.ComponentTagSelector-module__error-message--2EyGN{padding:6px;color:red}", "",{"version":3,"sources":["webpack://./src/components/ComponentViewer/ComponentTagSelector/ComponentTagSelector.module.css"],"names":[],"mappings":"AAAA,8CACE,UAAA,CACA,eAAA,CAEF,mDACE,WAAA,CACA,SAAA","sourcesContent":[".selector {\n  width: 100%;\n  min-width: 150px;\n}\n.error-message {\n  padding: 6px;\n  color: red;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"selector": "ComponentTagSelector-module__selector--10nne",
	"error-message": "ComponentTagSelector-module__error-message--2EyGN"
};
export default ___CSS_LOADER_EXPORT___;
