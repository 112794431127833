import { DeleteFilled } from "@ant-design/icons";
import { Button, Modal } from "antd";
import React, { useState } from "react";

type AnnotationDeletionModalProps = {
  onDelete: (id: string) => void;
  warningMessage: string;
  id: string;
};

export const DeleteButton = (props: AnnotationDeletionModalProps) => {
  const [visible, setVisible] = useState(false);

  return (
    <>
      <Button
        type="text"
        danger
        icon={<DeleteFilled />}
        onClick={() => setVisible(true)}
      />
      <Modal
        visible={visible}
        title={<i>{props.warningMessage}</i>}
        bodyStyle={{ display: "none" }}
        okText="OK"
        okButtonProps={{ danger: true }}
        cancelText="Cancel"
        onCancel={() => setVisible(false)}
        onOk={() => props.onDelete(props.id)}
      />
    </>
  );
};
