import { DeleteOutlined } from "@ant-design/icons";
import { Button } from "antd";
import React, { useCallback, useState } from "react";
import { useDeleteMappingMutation } from "../../../generated/types";
import { Project } from "../../../types";
import { DeleteConfirmationModal } from "../../DeleteConfirmationModal/DeleteConfirmationModal";

type RemoveMappingModalProps = {
  mappingId?: string;
  space: { id: string; name: string };
  component: { id: string; name: string };
  project: Project;
};

const REMOVE_MAPPING_DELETION_MODAL_TITLE = "Remove Mapping";
const DELETE_CONFIRMATION_ALERT_MESSAGE =
  "Are you really sure you want to do this? You will lose all historic aggregation data.";
const DELETE_CONFIRMATION_REQ_TEXT = "delete mapping";

export const RemoveMappingModal = (props: RemoveMappingModalProps) => {
  const { space, component, project, mappingId } = props;

  const [isModalVisible, setIsModalVisible] = useState(false);

  const [deleteMapping] = useDeleteMappingMutation({
    update: (cache, { data }) => {
      cache.evict({ id: `Mapping:${data?.deleteMappings[0]}` });
    },
  });

  const onClick = () => {
    setIsModalVisible(true);
  };

  const handleOk = useCallback(async () => {
    await deleteMapping({
      variables: {
        ...project,
        mappingId: mappingId,
      },
    });
  }, [project, mappingId, deleteMapping]);

  const deletionModalBody = (
    <p>
      Are you sure you want to remove this mapping on space <b>{space.name}</b>{" "}
      and component <b>{component.name}</b>.
    </p>
  );

  return (
    <>
      <Button
        type={"text"}
        danger
        icon={<DeleteOutlined />}
        onClick={onClick}
      />
      <DeleteConfirmationModal
        title={REMOVE_MAPPING_DELETION_MODAL_TITLE}
        visible={isModalVisible}
        setVisible={setIsModalVisible}
        onOk={handleOk}
        deletionText={DELETE_CONFIRMATION_REQ_TEXT}
        modalBody={deletionModalBody}
        alertText={DELETE_CONFIRMATION_ALERT_MESSAGE}
      />
    </>
  );
};
