// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/cssWithMappingToString.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".SequenceMapGenerator-module__select--2A435{margin-bottom:2px;width:79%}.SequenceMapGenerator-module__select-size--3ogvy{margin-bottom:2px;width:20%;margin-left:5px}.SequenceMapGenerator-module__button--2hXZu{margin-right:2px}", "",{"version":3,"sources":["webpack://./src/components/SequenceMapsGenerator/SequenceMapGenerator.module.css"],"names":[],"mappings":"AAAA,4CACI,iBAAA,CACA,SAAA,CAEJ,iDACI,iBAAA,CACA,SAAA,CACA,eAAA,CAEJ,4CACI,gBAAA","sourcesContent":[".select{\n    margin-bottom: 2px;\n    width: 79%;\n}\n.select-size{\n    margin-bottom: 2px;\n    width: 20%;\n    margin-left: 5px;\n}\n.button{\n    margin-right: 2px;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"select": "SequenceMapGenerator-module__select--2A435",
	"select-size": "SequenceMapGenerator-module__select-size--3ogvy",
	"button": "SequenceMapGenerator-module__button--2hXZu"
};
export default ___CSS_LOADER_EXPORT___;
