import React from "react";
import { Project } from "../../../types";
import { SpaceTypeSelector } from "../../Selectors/SpaceTypeSelector";

interface TypeSelectorFormItemProps {
  project: Project;
  disabled?: boolean;
  placeholder?: string;

  value?: string;
  onChange?: (value: any) => void;
}

export const TypeSelectorFormItem = (props: TypeSelectorFormItemProps) => (
  <SpaceTypeSelector
    selectedTypes={props.value != null ? [props.value] : []}
    project={props.project}
    placeholder={props.placeholder}
    disabled={props.disabled}
    onChange={(values) => {
      props.onChange?.(values[0]);
    }}
    allowCreatingNewValue
  />
);
