import { ArrowLeftOutlined } from "@ant-design/icons";
import { Image, Result } from "antd";
import React from "react";
import DisperseLogo from "../../statics/images/disperse-logo.png";
import styles from "./styles.module.css";

const SelectAppPrompt = () => (
  <div className={styles["select-app-prompt"]}>
    <ArrowLeftOutlined style={{ fontSize: "90px", color: "#1890ff" }} />
    <Result
      icon={
        <Image
          className={styles["disperse-logo"]}
          preview={false}
          src={DisperseLogo}
        />
      }
      title="Welcome to the Disperse Core!"
      subTitle="Please select an application to continue..."
    />
  </div>
);

const HomePage = () => (
  <div>
    <SelectAppPrompt />
  </div>
);

export default HomePage;
