// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/cssWithMappingToString.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".ConfigurationLabelComposed-module__tree-container--3ZfIr{color:#141414;display:flex;flex-wrap:wrap}.ConfigurationLabelComposed-module__tree-container--3ZfIr span:not(:first-child)::before{content:\"|\";margin:0 .3em}.ConfigurationLabelComposed-module__focus-text--1Qsaw{color:#141414;display:inline-flex;align-items:center}.ConfigurationLabelComposed-module__periphery-text--1K00v{color:#1890ff}.ConfigurationLabelComposed-module__hoist-marker--1RPIr{margin-right:3px;display:inline-flex;align-items:center;justify-content:center;background-color:#1890ff;color:#fff;font-weight:500;font-size:11px;width:16px;height:16px;border-radius:8px}", "",{"version":3,"sources":["webpack://./src/components/ConfigurationLabelComposed/ConfigurationLabelComposed.module.css"],"names":[],"mappings":"AAAA,0DACE,aAAA,CACA,YAAA,CACA,cAAA,CAGF,yFACE,WAAA,CACA,aAAA,CAGF,sDACE,aAAA,CACA,mBAAA,CACA,kBAAA,CAGF,0DACE,aAAA,CAGF,wDACE,gBAAA,CACA,mBAAA,CACA,kBAAA,CACA,sBAAA,CACA,wBAAA,CACA,UAAA,CACA,eAAA,CACA,cAAA,CACA,UAAA,CACA,WAAA,CACA,iBAAA","sourcesContent":[".tree-container {\n  color: #141414;\n  display: flex;\n  flex-wrap: wrap;\n}\n\n.tree-container span:not(:first-child)::before {\n  content: \"|\";\n  margin: 0 0.3em;\n}\n\n.focus-text {\n  color: #141414;\n  display: inline-flex;\n  align-items: center;\n}\n\n.periphery-text {\n  color: #1890ff;\n}\n\n.hoist-marker {\n  margin-right: 3px;\n  display: inline-flex;\n  align-items: center;\n  justify-content: center;\n  background-color: #1890ff;\n  color: white;\n  font-weight: 500;\n  font-size: 11px;\n  width: 16px;\n  height: 16px;\n  border-radius: 8px;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"tree-container": "ConfigurationLabelComposed-module__tree-container--3ZfIr",
	"focus-text": "ConfigurationLabelComposed-module__focus-text--1Qsaw",
	"periphery-text": "ConfigurationLabelComposed-module__periphery-text--1K00v",
	"hoist-marker": "ConfigurationLabelComposed-module__hoist-marker--1RPIr"
};
export default ___CSS_LOADER_EXPORT___;
