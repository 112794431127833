import _ from "lodash";
import React, { Key } from "react";
import { GetTasksByParentPlanQuery } from "../../generated/types";
import { ROOT_PARENT_KEY } from "../DisplayTree/search-utils";
import { DisplayTreeNode, DisplayTreeNodeType } from "../DisplayTree/types";
import { ComponentNode } from "./ComponentNode";
import { CreateTaskForm } from "./CreateTaskForm";
import { TaskTreeNode } from "./TaskTreeNode";
import { ComponentInTaskTree, DataNode, Task } from "./types";

type MatchFunction = (nodes: DataNode[], parentId?: string) => boolean;
type ReplaceFunction = (nodes: DisplayTreeNode[]) => DisplayTreeNode[];

const CREATE_TASK_NODE_NAME = "Add subtask";

export const visitTree = (
  treeData: DisplayTreeNode[],
  match: MatchFunction,
  replace: ReplaceFunction,
): DisplayTreeNode[] => {
  return _visitTree(treeData, match, replace);
};

// Don't want to expose parentId
const _visitTree = (
  treeData: DisplayTreeNode[],
  match: MatchFunction,
  replace: ReplaceFunction,
  parentId: string = ROOT_PARENT_KEY,
): DisplayTreeNode[] => {
  if (match(treeData, parentId)) {
    treeData = replace(treeData);
  }
  return treeData.map((node) => {
    if (node.children) {
      return {
        ...node,
        children: _visitTree(
          node.children,
          match,
          replace,
          node.key.toString(),
        ),
      };
    }
    return node;
  });
};

export const findSiblings = (
  treeData: DisplayTreeNode[] | undefined,
  key: string,
): DisplayTreeNode[] | undefined => {
  if (treeData === undefined) {
    return undefined;
  } else {
    const found = treeData.some((node) => node.key === key);
    if (found) {
      return treeData;
    }
    const childSearchResult = _(treeData).map((node) =>
      findSiblings(node.children, key),
    );
    return childSearchResult.find((result) => result !== undefined);
  }
};

const getParentIdOrRoot = (parentId: string | undefined): string =>
  parentId ?? ROOT_PARENT_KEY;

export type TasksByParentPlan = GetTasksByParentPlanQuery["tasksByParentPlan"];

export const taskToDisplayTreeNode = (
  task: Task,
  parentId?: string,
): DisplayTreeNode => {
  const childComponents = sortByGuiIndex(task.childComponents ?? []);
  return {
    nodeType: DisplayTreeNodeType.Task,
    name: task.name,
    key: task.id,
    title: <TaskTreeNode taskId={task.id} />,
    parentKey: getParentIdOrRoot(parentId),
    checked: false,
    children:
      childComponents && childComponents.length > 0
        ? childComponents.map((c) => componentToDataNode(c, task.id))
        : undefined,
  };
};

export const componentToDataNode = (
  component: ComponentInTaskTree,
  parentId: string,
  checkable = false,
  ancestorIds?: string[],
  showTags?: boolean,
): DisplayTreeNode => ({
  nodeType: DisplayTreeNodeType.Component,
  name: component.name,
  key: component.id,
  title: <ComponentNode componentId={component.id} showTags={showTags} />,
  parentKey: parentId,
  ancestorKeys: ancestorIds ?? [],
  isLeaf: true,
  checkable: checkable,
});

export const getParentIdToChildTasks = (tasks: TasksByParentPlan) => {
  const parentIdToChildTasks: Record<string, Task[]> = {};
  for (const task of tasks) {
    const parentId = task.parentTask ? task.parentTask.id : ROOT_PARENT_KEY;
    parentIdToChildTasks[parentId] = [
      ...(parentIdToChildTasks[parentId] ?? []),
      { id: task.id, guiIndex: task.guiIndex, name: task.name },
    ];
  }
  return parentIdToChildTasks;
};

export const getTasksAndComponentsByIds = (tasks: TasksByParentPlan) => {
  const tasksAndComponentsByIds: Record<string, Task & { tags?: string[] }> =
    {};
  const parentIdToChildTasks = getParentIdToChildTasks(tasks);
  for (const task of tasks) {
    tasksAndComponentsByIds[task.id] = {
      id: task.id,
      parentId: task.parentTask?.id ?? ROOT_PARENT_KEY,
      name: task.name,
      childComponents: task.childComponents as ComponentInTaskTree[],
      guiIndex: task.guiIndex,
      childTasks: parentIdToChildTasks[task.id],
    };
    for (const childComponent of task.childComponents) {
      tasksAndComponentsByIds[childComponent.id] = {
        id: childComponent.id,
        parentId: task.id,
        name: childComponent.name,
        guiIndex: childComponent.guiIndex as number,
        tags: childComponent.tags,
      };
    }
  }
  return tasksAndComponentsByIds;
};

export const buildTreeDataNodes = (
  tasks: Task[],
  tasksByIds: Record<string, Task>,
  includeComponents: boolean | undefined,
  ancestorIds: string[] = [ROOT_PARENT_KEY],
  checkable: boolean,
  showTags?: boolean,
): DisplayTreeNode[] =>
  sortByGuiIndex(tasks).map((task) => {
    const childComponents = sortByGuiIndex(
      tasksByIds[task.id].childComponents ?? [],
    );
    const childTasks = sortByGuiIndex(tasksByIds[task.id].childTasks ?? []);

    let children: DisplayTreeNode[] | undefined = undefined;
    const hasChildComponents = childComponents && childComponents.length > 0;
    if (hasChildComponents && includeComponents) {
      children = childComponents?.map((c) =>
        componentToDataNode(
          c,
          task.id,
          checkable,
          [...ancestorIds, task.id],
          showTags,
        ),
      );
    } else if (childTasks && childTasks.length > 0) {
      children = buildTreeDataNodes(
        childTasks,
        tasksByIds,
        includeComponents,
        [...ancestorIds, task.id],
        checkable,
        showTags,
      );
    }
    return {
      nodeType: DisplayTreeNodeType.Task,
      key: task.id,
      parentKey: getParentIdOrRoot(tasksByIds[task.id].parentId),
      ancestorKeys: ancestorIds,
      title: <TaskTreeNode taskId={task.id} />,
      checkable: false,
      children: children,
      name: tasksByIds[task.id].name,
    };
  });

export const sortByGuiIndex = <T extends { guiIndex: number }>(
  nodes: T[],
): T[] => _.sortBy(nodes, "guiIndex");

export const findNode = (
  tree: DataNode[],
  key: string,
): DataNode | undefined => {
  for (const node of tree) {
    if (node.key === key) {
      return node;
    }
    if (node.children != null) {
      const found = findNode(node.children, key);
      if (found) {
        return found;
      }
    }
  }
  return undefined;
};

export const allExpandedComponents = (
  node: DataNode,
  expandedKeys: Key[],
): string[] => {
  const descendantKeys: string[] = [];
  const nodeChildren = node.children ?? [];
  nodeChildren.forEach((childNode) => {
    if (childNode.parentKey && expandedKeys.includes(childNode.parentKey)) {
      if (childNode.isLeaf) {
        descendantKeys.push(childNode.key);
      }
      const descendents = allExpandedComponents(childNode, expandedKeys);
      descendantKeys.push(...descendents);
    }
  });
  return descendantKeys;
};

export const createTaskNode = (
  onSave: (name: string, parentId: string) => void,
  parentId?: string,
): DisplayTreeNode => ({
  nodeType: DisplayTreeNodeType.Task,
  name: CREATE_TASK_NODE_NAME,
  key: `add-subtask-to-${parentId}`,
  isLeaf: true,
  parentKey: getParentIdOrRoot(parentId),
  title: (
    <CreateTaskForm
      onSave={(name) => {
        onSave(name, getParentIdOrRoot(parentId));
      }}
    />
  ),
});

export const nodeHasChildComponents = (
  key: string,
  tasksAndComponentsByIds: Record<string, Task>,
): boolean => !!tasksAndComponentsByIds[key].childComponents?.length;

export const addNodeToTree = (
  treeData: DisplayTreeNode[],
  nodeToAdd: DisplayTreeNode,
  position: number,
  parentNodeId?: string,
): DisplayTreeNode[] => {
  const match = (_nodes, parentKey?: string) => parentNodeId === parentKey;
  const replace = (nodes: DisplayTreeNode[]) => {
    const insertPosition = position < 0 ? position + nodes.length : position;
    if (insertPosition < 0 || insertPosition > nodes.length) {
      throw Error(
        `Cannot add node to parent ${parentNodeId} at position ${position}`,
      );
    }
    return [
      ...nodes.slice(0, insertPosition),
      nodeToAdd,
      ...nodes.slice(insertPosition),
    ];
  };
  return visitTree(treeData, match, replace);
};

export const removeNodeFromTree = (
  treeData: DisplayTreeNode[],
  keyToRemove: string,
): DisplayTreeNode[] => {
  return visitTree(
    treeData,
    (nodes) => nodes.some((node) => node.key === keyToRemove),
    (nodes) => nodes.filter((node) => node.key !== keyToRemove),
  );
};

export const findSiblingIndex = (
  treeData: DisplayTreeNode[],
  key: string,
): number => {
  const siblings = findSiblings(treeData, key);
  const index = (siblings as DataNode[]).findIndex((node) => node.key === key);
  return index;
};

// Filter out dummy nodes for drag-and-drop
export const isRealNode = (node): boolean =>
  !node.key.toString().includes("add-subtask");
