// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/cssWithMappingToString.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".styles-module__holidays-table-title--XS8hG{width:100%}.styles-module__holidays-table-title--XS8hG>div:first-child{width:100% !important}.styles-module__holidays-table--341uc{max-width:400px}.styles-module__holidays-table-item--1oHaB{text-align:right}", "",{"version":3,"sources":["webpack://./src/components/HolidayTable/styles.module.css"],"names":[],"mappings":"AAAA,4CACE,UAAA,CACA,4DACE,qBAAA,CAIJ,sCACE,eAAA,CAEF,2CACE,gBAAA","sourcesContent":[".holidays-table-title {\n  width: 100%;\n  > div:first-child {\n    width: 100% !important;\n  }\n}\n\n.holidays-table {\n  max-width: 400px;\n}\n.holidays-table-item {\n  text-align: right;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"holidays-table-title": "styles-module__holidays-table-title--XS8hG",
	"holidays-table": "styles-module__holidays-table--341uc",
	"holidays-table-item": "styles-module__holidays-table-item--1oHaB"
};
export default ___CSS_LOADER_EXPORT___;
