import { DisplayTreeNode, DisplayTreeNodeType } from "./types";

export const allExpandedDescendants = (
  node: DisplayTreeNode,
  expandedKeys: string[],
): string[] => {
  const descendantKeys: string[] = [];
  const nodeChildren = node?.children ?? [];
  nodeChildren.forEach((childNode) => {
    if (
      expandedKeys.includes(childNode.parentKey) &&
      childNode.nodeType !== DisplayTreeNodeType.Folder
    ) {
      descendantKeys.push(childNode.key);
      const descendents = allExpandedDescendants(childNode, expandedKeys);
      descendantKeys.push(...descendents);
    }
  });
  return descendantKeys;
};

export const findNode = (
  treeData: DisplayTreeNode[],
  key: string,
): DisplayTreeNode | undefined => {
  for (const node of treeData) {
    if (node.key === key) {
      return node;
    }
    if (node.children != null) {
      const found = findNode(node.children, key);
      if (found) {
        return found;
      }
    }
  }
  return undefined;
};

export const isStringArray = (arr: any[]): arr is string[] => {
  return arr.reduce((acc, cur) => acc && typeof cur === "string", true);
};

export const getParentFolderKeys = (
  keys: string[],
  treeData: DisplayTreeNode[],
) => {
  const parentFolderKeys: string[] = [];
  keys.forEach((key) => {
    const node = findNode(treeData, key);
    if (node === undefined) {
      return;
    }
    // adding parent in case it's a folder (it's not in ancestors)
    const parentsForNode = [node.parentKey, ...(node.ancestorKeys ?? [])];
    parentFolderKeys.push(...parentsForNode);
  });
  return parentFolderKeys;
};
