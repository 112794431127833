import { Col, Row } from "antd";
import React from "react";
import { Batch } from "../../../types";
import ComparisonBatchDropdown from "./ComparisonBatchDropdown";
import ReferenceBatchDropdown from "./ReferenceBatchDropdown";

type BatchSelectorProps = {
  batches: Batch[];
  referenceBatch: Batch;
  comparisonBatch?: Batch;
  onChangeReferenceBatch?: (batch: Batch) => void;
  onChangeComparisonBatch?: (batch?: Batch) => void;
};

const BatchSelector = (props: BatchSelectorProps) => (
  <Row gutter={16}>
    <Col span={12}>
      <ComparisonBatchDropdown
        batches={props.batches.slice(1)}
        referenceBatch={props.referenceBatch}
        comparisonBatch={props.comparisonBatch}
        onChange={(batch) => {
          if (props.onChangeComparisonBatch) {
            props.onChangeComparisonBatch(batch);
          }
        }}
      />
    </Col>
    <Col span={12}>
      <ReferenceBatchDropdown
        batches={props.batches}
        referenceBatch={props.referenceBatch}
        onChange={(batch) => {
          if (props.onChangeReferenceBatch) {
            props.onChangeReferenceBatch(batch);
          }
        }}
      />
    </Col>
  </Row>
);

export default BatchSelector;
