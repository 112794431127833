import { CheckboxProps } from "antd";
import Checkbox from "antd/lib/checkbox/Checkbox";
import React from "react";

export type CheckboxValue = "checked" | "indeterminate" | "unchecked";

interface IndeterminateCheckboxFormItemProps
  extends Omit<CheckboxProps, "onChange"> {
  value?: CheckboxValue;
  onChange?: (value: CheckboxValue) => void;
}

export const IndeterminateCheckboxFormItem = (
  props: IndeterminateCheckboxFormItemProps,
) => (
  <Checkbox
    {...props}
    checked={props.value === "checked"}
    indeterminate={props.value === "indeterminate"}
    onChange={(e) =>
      props.onChange?.(e.target.checked ? "checked" : "unchecked")
    }
  />
);
