import { Layout } from "antd";
import React from "react";
import { useProject } from "../App/state";
import { ComponentTagsDumpGenerator } from "../components/ComponentTagsDumpGenerator/ComponentTagsDumpGenerator";
import { DatesQADumpGenerator } from "../components/DatesQADumpGenerator/DatesQADumpGenerator";
import { SceneSpaceMappingsGenerator } from "../components/ScenesSpacesGenerator/SceneSpaceMappingsGenerator";
import { SequenceMapsGenerator } from "../components/SequenceMapsGenerator/SequenceMapsGenerator";
import { SpaceTagsDumpGenerator } from "../components/SpaceTagsDumpGenerator/SpaceTagsDumpGenerator";

export const DownloadsPage = () => {
  const project = useProject();

  return (
    <Layout style={{ height: "100%" }}>
      <Layout.Content>
        <SceneSpaceMappingsGenerator project={project} />
        <DatesQADumpGenerator project={project} />
        <SpaceTagsDumpGenerator project={project} />
        <SpaceTagsDumpGenerator project={project} tagsOnly />
        <ComponentTagsDumpGenerator project={project} />
        <SequenceMapsGenerator project={project} />
      </Layout.Content>
    </Layout>
  );
};
