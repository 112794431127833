import React from "react";
import { Scene } from "../SpatialConfigurationMode/types";
import { DeleteSceneAlias } from "./DeleteSceneAlias";
import { SceneAliasModal } from "./SceneAliasModal";

interface SceneAliasEditorProps {
  scene: Pick<Scene, "id" | "name" | "aliasSceneId">;
  onCreate: (id: string, aliasId: string) => Promise<any>;
  onDelete: (id: string) => Promise<any>;
  onClose: () => void;
}

export const SceneAliasEditor = (props: SceneAliasEditorProps) => {
  const { scene, onCreate, onDelete, onClose } = props;

  return (
    <>
      {scene.aliasSceneId ? (
        <DeleteSceneAlias
          sceneId={scene.id}
          onDelete={onDelete}
          onClose={onClose}
        />
      ) : (
        <SceneAliasModal scene={scene} onCreate={onCreate} onClose={onClose} />
      )}
    </>
  );
};
