import { Card, Descriptions, Space, Spin } from "antd";

import React from "react";
import { useNotification } from "../../contexts/Notifications";
import {
  useDeleteTaskMutation,
  useGetTaskQuery,
  useUpdateTaskMutation,
} from "../../generated/types";
import { Project } from "../../types";
import { TaskDeletionModal } from "./TaskDeletionModal";
import { TaskUpdateModal } from "./TaskUpdateModal";
import {
  DELETE_TASK_FAILURE,
  DELETE_TASK_SUCCESS,
  UPDATE_TASK_FAILURE,
  UPDATE_TASK_SUCCESS,
} from "./messages";

type TaskCardProps = {
  taskId: string;
  project: Project;
};

export const TaskCard = (props: TaskCardProps) => {
  const { taskId, project } = props;

  const notify = useNotification();
  const [deleteTask] = useDeleteTaskMutation({
    onCompleted: () => notify(DELETE_TASK_SUCCESS, "success"),
    onError: () => notify(DELETE_TASK_FAILURE, "error"),
    update: (cache: any, { data }) => {
      if (data?.deleteTasks) {
        // deleteTask here holds the id of the deleted task
        cache.evict({ id: `Task:${data.deleteTasks[0]}` });
        // we need to force a refetch of components without a plan
        // because that set might have changed after deleting a task
        cache.evict({ fieldName: "componentsExcludingPlan" });
        cache.evict({ fieldName: "components" });
        cache.gc();
      }
    },
  });

  const [updateTask] = useUpdateTaskMutation({
    onCompleted: () => notify(UPDATE_TASK_SUCCESS, "success"),
    onError: () => notify(UPDATE_TASK_FAILURE, "error"),
  });

  const { data, loading, error } = useGetTaskQuery({
    variables: {
      ...project,
      taskId: taskId,
    },
  });

  const task = data?.task;

  const onDelete = async () => {
    await deleteTask({
      variables: {
        ...project,
        taskId: taskId,
      },
    });
  };

  return (
    <Card
      title="Task"
      extra={
        task && (
          <Space>
            <TaskUpdateModal
              task={task}
              onUpdate={async (name: string) => {
                await updateTask({
                  variables: {
                    ...project,
                    task: {
                      name: name,
                      id: taskId,
                      guiIndex: task.guiIndex,
                      parentTaskId: task.parentTask?.id,
                      planId: task.plan.id,
                    },
                  },
                });
              }}
            />
            <TaskDeletionModal onDelete={onDelete} />
          </Space>
        )
      }
    >
      {loading ? (
        <Spin />
      ) : error || !task ? (
        <div>Error loading task {error?.message}</div>
      ) : (
        <Descriptions size="small">
          <Descriptions.Item label="Name">{task.name}</Descriptions.Item>
          <Descriptions.Item label="Plan">{task.plan.name}</Descriptions.Item>
        </Descriptions>
      )}
    </Card>
  );
};
