import _ from "lodash";
import { useEffect, useState } from "react";
import { useProject } from "../../../App/state";
import { useNotification } from "../../../contexts/Notifications";
import {
  FloorplanIdsDocument,
  useCreateFloorplanMutation,
  useDeleteFoorplanMutation,
  useFloorplanByIdLazyQuery,
  useFloorplanIdsQuery,
  usePatchFloorplanMutation,
} from "../../../generated/types";
import {
  Floorplan,
  FloorplanIdToSpaceId,
} from "../../SpatialConfigurationMode/types";

export type FloorplanCreateInput = {
  spaceId: string;
  originX: number;
  originY: number;
  width: number;
  height: number;
  mmScaleFactor: number;
  floorplanFile: File;
  bimOriginX?: number | null;
  bimOriginY?: number | null;
  bimOriginZ?: number | null;
  floorHeight?: number | null;
  angleToTrueNorth?: number | null;
};

export type FloorplanPatchInput = Partial<FloorplanCreateInput> & {
  id: string;
};

export type FloorplanEditPropertiesInput = Pick<
  FloorplanCreateInput,
  | "bimOriginX"
  | "bimOriginY"
  | "bimOriginZ"
  | "floorHeight"
  | "angleToTrueNorth"
> & {
  id: string;
};

export type FloorplansEditor = {
  floorplan: Floorplan | null;
  floorplanIdToSpaceId: FloorplanIdToSpaceId;
  onCreate: (floorplanData: FloorplanCreateInput) => Promise<void>;
  onPatch: (floorplanData: FloorplanPatchInput) => Promise<void>;
  onDelete: (id: string) => Promise<void>;
  onChange: (id?: string) => void;
};

export const useFloorplan = (): FloorplansEditor => {
  const project = useProject();
  const notify = useNotification();

  const [floorplan, setFloorplan] = useState<Floorplan | null>(null);
  const [floorplanIdToSpaceId, setFloorplanIdToSpaceId] =
    useState<FloorplanIdToSpaceId>({});

  const { data: floorplanIdsData } = useFloorplanIdsQuery({
    variables: {
      tenant: project,
    },
  });

  const [fetchFloorplanById] = useFloorplanByIdLazyQuery({
    onCompleted: (data) => {
      setFloorplan(
        data.floorplans.map((f) => ({
          ...f,
          url: f.signedUrl,
          space: {
            ...f.space,
            ancestorNames: f.space.ancestors.map((s) => s.name),
          },
        }))[0],
      );
    },
  });

  useEffect(() => {
    floorplanIdsData &&
      setFloorplanIdToSpaceId(
        _(
          floorplanIdsData?.floorplans.map((f) => ({
            id: f.id,
            spaceId: f.space.id,
          })),
        )
          .keyBy("id")
          .mapValues("spaceId")
          .value(),
      );
  }, [floorplanIdsData]);

  const [createFloorplan] = useCreateFloorplanMutation({
    refetchQueries: [FloorplanIdsDocument],
    onError: (error) =>
      notify(`Floor plan creation failed: ${error.message}`, "error"),
    onCompleted: () => {
      notify("Floor plan successfully created!", "success");
    },
  });

  const [patchFloorplan] = usePatchFloorplanMutation({
    refetchQueries: [FloorplanIdsDocument],
    onError: (error) =>
      notify(`Floor plan patching failed: ${error.message}`, "error"),
    onCompleted: () => {
      notify("Floor plan successfully patched!", "success");
    },
    update: (cache, { data }) => {
      const fpPatch = data?.patchFloorplan && data.patchFloorplan;
      fpPatch && cache.evict({ id: cache.identify(fpPatch) });
      cache.gc();
    },
  });

  const [deleteFloorplan] = useDeleteFoorplanMutation({
    refetchQueries: [FloorplanIdsDocument],
    onError: (error) =>
      notify(`Failed to delete floor plan: ${error.message}`, "error"),
    onCompleted: () => {
      notify("Floor plan successfully deleted!", "success");
    },
  });

  return {
    floorplan,
    floorplanIdToSpaceId,
    onCreate: async (floorplanData: FloorplanCreateInput) => {
      await createFloorplan({
        variables: {
          tenant: project,
          floorplan: floorplanData,
        },
      });
    },
    onPatch: async (floorplanData: FloorplanPatchInput) => {
      await patchFloorplan({
        variables: {
          tenant: project,
          floorplan: {
            id: floorplanData.id,
            setSpaceId: floorplanData.spaceId,
            setOriginX: floorplanData.originX,
            setOriginY: floorplanData.originY,
            setWidth: floorplanData.width,
            setHeight: floorplanData.height,
            setMMScaleFactor: floorplanData.mmScaleFactor,
            setFloorplanFile: floorplanData.floorplanFile,
            setBimOriginX: floorplanData.bimOriginX,
            setBimOriginY: floorplanData.bimOriginY,
            setBimOriginZ: floorplanData.bimOriginZ,
            setFloorHeight: floorplanData.floorHeight,
            setAngleToTrueNorth: floorplanData.angleToTrueNorth,
          },
        },
      });
      floorplan?.id === floorplanData.id &&
        fetchFloorplanById({
          variables: {
            tenant: project,
            id: floorplan.id,
          },
        });
    },
    onDelete: async (id: string) => {
      await deleteFloorplan({
        variables: {
          tenant: project,
          id: id,
        },
      });
      floorplan?.id === id && setFloorplan(null);
    },
    onChange: (id?: string) => {
      id
        ? fetchFloorplanById({
            variables: {
              tenant: project,
              id: id,
            },
          })
        : setFloorplan(null);
    },
  };
};
