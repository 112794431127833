import { ApolloCache, gql } from "@apollo/client";
import { Space as AntdSpace, Col, Form, Input, Row } from "antd";
import Checkbox from "antd/lib/checkbox/Checkbox";
import React, { useCallback } from "react";
import { useNotification } from "../../../contexts/Notifications";
import { useCreateSpacesMutation } from "../../../generated/types";
import { Space as GraphQlSpace } from "../../../generated/types";
import { Project } from "../../../types";
import { Space } from "../../SpacesTree/tree-nodes";
import { CREATED_SPACES_ERROR, CREATED_SPACES_SUCCESS } from "../messages";
import { CategorySelectorFormItem } from "./CategorySelectorFormItem";
import { TypeSelectorFormItem } from "./TypeSelectorFormItem";

interface NewSpaceFormProps {
  id?: string;
  project: Project;
  selectedSpaces: Space[];
  disabled?: boolean;
  onSubmit?: (spaces: string[]) => void;

  // Used for testing, because antd's select doesn't work well with react testing library
  initialName?: string;
  initialCategory?: string;
  initialType?: string;
}

const updateCacheOnNewSpaces = (
  cache: ApolloCache<any>,
  createdSpaces: Pick<GraphQlSpace, "id" | "name" | "type">[],
) => {
  cache.modify({
    fields: {
      spacesByFilter(existingSpaces = []) {
        const newRefs = createdSpaces.map((newSpace) =>
          cache.writeFragment({
            data: newSpace,
            fragment: gql`
              fragment NewSpace on Space {
                id
                name
                category
                type
              }
            `,
          }),
        );
        return [...existingSpaces, ...newRefs];
      },
    },
  });
};

export const NewSpaceForm = (props: NewSpaceFormProps) => {
  const {
    id,
    project,
    selectedSpaces,
    disabled,
    onSubmit,
    initialName,
    initialCategory,
    initialType,
  } = props;
  const { customer, project: proj, scope } = project;

  const [form] = Form.useForm();

  React.useEffect(() => {
    form.setFieldsValue({
      name: initialName,
      category: initialCategory,
      type: initialType,
    });
  }, [form, initialCategory, initialName, initialType]);

  const notify = useNotification();
  const [createSpaces] = useCreateSpacesMutation({
    onCompleted: (data) => {
      notify(CREATED_SPACES_SUCCESS, "success");
      if (data.createSpaces.length > 0) {
        onSubmit?.(
          data.createSpaces
            .map((space) => space.parentSpace?.id)
            .filter((parentId) => parentId != null),
        );
      }
    },
    onError: (error) => notify(`${CREATED_SPACES_ERROR} - ${error}`, "error"),
    update: (cache, { data }) =>
      data?.createSpaces &&
      updateCacheOnNewSpaces(cache, data?.createSpaces ?? []),
  });

  const handleSubmit = useCallback(() => {
    const name = form.getFieldValue("name");
    const category = form.getFieldValue("category");
    const type = form.getFieldValue("type");
    const isHoist = form.getFieldValue("hoist");

    const newSpace = {
      name: name,
      type: type,
      category: category,
      tags: isHoist ? ["hoist"] : [],
    };

    const spaces = selectedSpaces.length
      ? selectedSpaces.map((parent: Space) => ({
          ...newSpace,
          parentSpaceId: parent.id,
        }))
      : [newSpace];

    return createSpaces({
      variables: {
        customer: customer,
        project: proj,
        scope,
        spaces,
      },
    });
  }, [createSpaces, customer, form, proj, scope, selectedSpaces]);

  return (
    <Form id={id} form={form} onFinish={handleSubmit}>
      <AntdSpace direction={"vertical"} style={{ width: "100%" }}>
        <Row gutter={8}>
          <Col span={16}>
            <Form.Item
              name="name"
              label="Name"
              rules={[{ message: "Required", required: true }]}
            >
              <Input placeholder="Name" disabled={disabled} />
            </Form.Item>
          </Col>
          <Col span={8}>
            <Form.Item name="hoist" valuePropName="checked" label="Hoist">
              <Checkbox disabled={disabled} />
            </Form.Item>
          </Col>
        </Row>
        <Row gutter={8}>
          <Col span={12}>
            <Form.Item
              name="category"
              label="Category"
              rules={[{ message: "Required", required: true }]}
            >
              <CategorySelectorFormItem project={project} disabled={disabled} />
            </Form.Item>
          </Col>
          <Col span={12}>
            <Form.Item
              name="type"
              label="Type"
              rules={[{ message: "Required", required: true }]}
            >
              <TypeSelectorFormItem project={project} disabled={disabled} />
            </Form.Item>
          </Col>
        </Row>
      </AntdSpace>
    </Form>
  );
};
