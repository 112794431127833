import { PanoView } from "../types";

const EPSILON = 1e-6;

export const samePositions = (
  panoView1: PanoView,
  panoView2: PanoView,
  epsilon = EPSILON,
): boolean =>
  Math.abs(panoView1.position.pitch - panoView2.position.pitch) <= epsilon &&
  Math.abs(panoView1.position.yaw - panoView2.position.yaw) <= epsilon &&
  Math.abs(panoView1.hfov - panoView2.hfov) <= epsilon;

// normalise any kind of yaw value to a value that is within -180, + 180
// which is the range pannellum operates in
export const normaliseYaw = (yaw: number): number => {
  const within360 = yaw % 360;
  if (within360 > 180) {
    return within360 - 360;
  } else if (within360 < -180) {
    return within360 + 360;
  }
  return within360;
};
