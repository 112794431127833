import { Button, Popconfirm } from "antd";
import React from "react";
import { useReweightMutation } from "../../generated/types";
import { Project } from "../../types";

type ReweightButtonProps = {
  project: Project;
  planId: string;
};

const MESSAGE =
  "Reweighting will happen automatically on changes to tasks or components. \
  ONLY do this when you suspect that progresses and weights are off. \
  It may take a minute to complete, and you'll have to refresh the page.";

export const ReweightButton = (props: ReweightButtonProps) => {
  const [reweight] = useReweightMutation({
    variables: {
      customer: props.project.customer,
      project: props.project.project,
      scope: props.project.scope,
      planId: props.planId,
    },
  });
  const confirm = () => {
    reweight();
  };
  return (
    <Popconfirm title={MESSAGE} onConfirm={confirm}>
      <Button type="primary">recalculate weights</Button>
    </Popconfirm>
  );
};
