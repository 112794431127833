import React from "react";
import { useNotification } from "../../contexts/Notifications";
import { useReleaseProjectMutation } from "../../generated/types";
import { Project } from "../../types";
import { MajorActionButton } from "../MajorActionButton/MajorActionButton";

interface ProjectReleaseButtonProps {
  project: Project;
}

export const ProjectReleaseButton = (props: ProjectReleaseButtonProps) => {
  const notify = useNotification();
  const [releaseProjectMutation, { loading }] = useReleaseProjectMutation({
    onCompleted: () =>
      notify(
        `Successfully triggered release of ${props.project.customer}-${props.project.project}! Watch out for updates on slack!`,
        "success",
      ),
    onError: (error) =>
      notify(
        `Failed to trigger release for ${props.project.customer}-${props.project.project}: ${error}`,
        "error",
      ),
  });

  const onApply = async () => {
    await releaseProjectMutation({
      variables: {
        customer: props.project.customer,
        project: props.project.project,
        scope: props.project.scope,
      },
    });
  };

  return (
    <MajorActionButton
      action={onApply}
      loading={loading}
      actionTitle="Release project to external"
      actionMessage="This action will copy the data from internal database to external, exposing it to the Customer."
      actionConfirmation={`Are you sure you want to release '${props.project.customer}/${props.project.project}'? Updates will appear in Slack.`}
    />
  );
};
