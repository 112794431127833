import { Breadcrumb, Layout } from "antd";
import React from "react";
import { useGetSpaceAncestorsQuery } from "../../generated/types";
import { Project } from "../../types";
import styles from "./EditorPage.module.css";

export const Footer = (props: {
  selectedSpaceId: string;
  project: Project;
}) => {
  const { data: spaceAncestorsResponse } = useGetSpaceAncestorsQuery({
    variables: {
      customer: props.project.customer,
      project: props.project.project,
      scope: props.project.scope,
      spaceId: props.selectedSpaceId,
    },
  });

  const pathToSpace = spaceAncestorsResponse && [
    ...spaceAncestorsResponse.space.ancestors,
    spaceAncestorsResponse.space,
  ];

  return (
    <Layout.Footer className={styles["footer-layout"]}>
      <Breadcrumb>
        {pathToSpace?.map((s) => (
          <Breadcrumb.Item key={s.id}>{s.name}</Breadcrumb.Item>
        ))}
      </Breadcrumb>
    </Layout.Footer>
  );
};
