import { gql } from "@apollo/client";
import { Alert, Empty, Modal, Spin, Table } from "antd";
import { ColumnsType } from "antd/lib/table";
import { format } from "date-fns";
import _ from "lodash";
import React from "react";
import {
  Scope,
  useGetBatchesQuery,
  useUpdateBatchMutation,
} from "../../generated/types";
import { Batch } from "../../types";
import { InputWithAsyncConfirm } from "../InputWithAsyncConfirm/InputWithAsyncConfirm";
import { EDIT_BATCHES_TXT } from "./EditBatchesButton";
import styles from "./styles.module.css";

const MODAL_MSG = (
  <ul className={styles["edit-batches-alert-msg"]}>
    <li>
      You need the <b>Delivery Core Projects Config</b> permission in Janus to
      edit week numbers
    </li>
    <li>
      If you edit an old week number, subsequent week numbers will NOT be
      updated
    </li>
    <li>The project start date does NOT affect the week numbers shown here</li>
    <li>
      Updates here only go to <b>internal</b>, NOT <b>external</b>. You will
      need to release this project if you want the changes to go to external
    </li>
  </ul>
);

export const UpdateBatchFragmentDoc = gql`
  fragment UpdateBatch on Batch {
    weekNumber
  }
`;

type BatchTableDataRow = {
  key: string;
  weekNumber: Batch;
  timestamp: Batch;
};

type Props = {
  customer: string;
  project: string;
  visible: boolean;
  setVisible: (vis: boolean) => void;
};

export const EditBatchesModal = (props: Props) => {
  const { customer, project, visible, setVisible } = props;

  const { data: batchesData, loading: batchesLoading } = useGetBatchesQuery({
    variables: { customer, project, scope: Scope.Internal },
    skip: visible === false,
  });

  const batchesTableData: BatchTableDataRow[] = _(batchesData?.batches ?? [])
    .map((batch) => ({
      key: batch.id,
      weekNumber: batch,
      timestamp: batch,
    }))
    .sortBy((b) => b.timestamp)
    .value();

  const [updateBatch] = useUpdateBatchMutation({
    update: (cache, { data }) => {
      const updatedBatches = data?.updateBatches;
      if (updatedBatches !== undefined) {
        updatedBatches.forEach((b) =>
          cache.updateFragment(
            {
              id: `Batch:${b.id}`,
              fragment: UpdateBatchFragmentDoc,
            },
            (cur: any) => ({ ...cur, weekNumber: b.weekNumber }),
          ),
        );
      }
    },
  });

  const handleOnUpdateWeekNumber = async (
    batch: Batch,
    newWeekNumber: number,
  ) => {
    await updateBatch({
      variables: {
        customer,
        project,
        batchId: batch.id,
        timestamp: batch.timestamp,
        weekNumber: newWeekNumber,
      },
    });
  };

  const batchesTableColumns: ColumnsType<BatchTableDataRow> = [
    {
      title: "Week Number",
      dataIndex: "weekNumber",
      key: "weekNumber",
      width: "200px",
      render: (batch: Batch) => (
        <InputWithAsyncConfirm<number>
          value={batch.weekNumber}
          confirmButtonText={"ok"}
          confirmButtonPositionLeft={true}
          onConfirm={(newWeekNumber: number) =>
            handleOnUpdateWeekNumber(batch, newWeekNumber)
          }
        />
      ),
    },
    {
      title: "Timestamp",
      dataIndex: "timestamp",
      key: "timestamp",
      render: (batch: Batch) => format(new Date(batch.timestamp), "yyyy/MM/dd"),
    },
  ];

  const batchesTable = (
    <Table
      dataSource={batchesTableData}
      columns={batchesTableColumns}
      pagination={false}
      size="small"
      sticky
    />
  );

  const modalTitle = `${EDIT_BATCHES_TXT}: ${customer}-${project}`;

  return (
    <>
      <Modal
        visible={visible}
        onCancel={() => {
          setVisible(false);
        }}
        title={modalTitle}
        footer={false}
      >
        <Alert
          className={styles["edit-batches-alert"]}
          showIcon={true}
          type={"info"}
          message={MODAL_MSG}
        />
        {batchesLoading ? (
          <Spin />
        ) : batchesTableData?.length === 0 ? (
          <Empty />
        ) : (
          batchesTable
        )}
      </Modal>
    </>
  );
};
