import React, { useCallback } from "react";
import { DeleteConfirmationModal } from "../DeleteConfirmationModal/DeleteConfirmationModal";

interface DeleteSceneAliasProps {
  sceneId: string;
  onDelete: (id: string) => Promise<void>;
  onClose: () => void;
}

const DELETE_TITLE = "Delete Scene Alias";
const DELETE_BODY = "Deleting an existing scene alias";

export const DeleteSceneAlias = (props: DeleteSceneAliasProps) => {
  const { sceneId, onClose, onDelete } = props;

  const deleteSceneAliasCallback = useCallback(
    async (id: string) => {
      await onDelete(id);
      onClose();
    },
    [onDelete, onClose],
  );

  return (
    <DeleteConfirmationModal
      title={DELETE_TITLE}
      visible={true}
      setVisible={onClose}
      onOk={() => deleteSceneAliasCallback(sceneId)}
      modalBody={DELETE_BODY}
    />
  );
};
