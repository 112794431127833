import { UploadOutlined } from "@ant-design/icons";
import { Button, Modal, Tooltip } from "antd";
import React, { useCallback, useState } from "react";
import { FileUploader } from "../../components/FileUploader";
import { useNotification } from "../../contexts/Notifications";
import { useUploadHolidaysFileMutation } from "../../generated/types";
import { Project } from "../../types";

type HolidayUploadModalProps = {
  project: Project;
  onUpload: () => any;
};

export const HolidayUploadModal = (props: HolidayUploadModalProps) => {
  const [visible, setVisible] = useState<boolean>(false);
  return (
    <>
      <Tooltip title="Upload holidays">
        <Button
          icon={<UploadOutlined />}
          type="primary"
          onClick={() => setVisible(true)}
        />
      </Tooltip>
      <Modal
        visible={visible}
        title="Upload Holidays"
        onCancel={() => setVisible(false)}
        footer={
          <Button key="back" onClick={() => setVisible(false)}>
            Return
          </Button>
        }
      >
        <p>
          Please upload a .csv file with one date per row, and in uk date
          format, aka dd/mm/yyyy! Do not include a header in the file
        </p>
        <HolidaysFileUploader
          project={props.project}
          onUpload={props.onUpload}
        />
      </Modal>
    </>
  );
};

type HolidaysFileUploaderProps = {
  project: Project;
  onUpload: () => any;
};

const HolidaysFileUploader = (props: HolidaysFileUploaderProps) => {
  const { project: project, onUpload } = props;
  const { customer, project: proj, scope } = project;
  const notify = useNotification();

  const [uploadFile] = useUploadHolidaysFileMutation({
    onError: (error) =>
      notify(`Holidays file upload failed: ${error.message}`, "error"),
    onCompleted: () => {
      notify("Holidays file successfully uploaded!", "success");
      onUpload();
    },
  });
  const upload = useCallback(
    async (files: File[]) => {
      if (files.length !== 1) {
        notify(
          "Upload failed, we currently only support uploading one file at a time",
        );
      } else {
        await uploadFile({
          variables: {
            customer: customer,
            project: proj,
            scope: scope,
            file: files[0],
          },
        });
      }
    },
    [customer, notify, proj, scope, uploadFile],
  );

  return <FileUploader title="Upload Holidays File" onFilesSelected={upload} />;
};
