import { Form, Input, Space } from "antd";
import React from "react";

type CreateProps = {
  onSave: (taskName: string) => void;
};

export const CreateTaskForm = ({ onSave }: CreateProps) => {
  const [newTaskForm] = Form.useForm();
  return (
    <Space align="baseline">
      <Form form={newTaskForm} layout="inline" style={{ width: "100%" }}>
        <Form.Item name="taskName">
          <Input
            placeholder="Add subtask ..."
            bordered={false}
            size="small"
            allowClear
            onPressEnter={() => {
              const name = newTaskForm.getFieldValue("taskName");
              if (name.length > 0) {
                onSave(name);
                newTaskForm.resetFields();
              }
            }}
          />
        </Form.Item>
      </Form>
    </Space>
  );
};
