import { DeleteOutlined } from "@ant-design/icons";
import { Alert, Button } from "antd";
import Modal from "antd/lib/modal/Modal";
import React, { useCallback, useState } from "react";
import { useNotification } from "../../../contexts/Notifications";
import { useDeleteScenesMutation } from "../../../generated/types";
import { Project } from "../../../types";
import { Scene } from "../../SpacesTree/tree-nodes";
import { DELETED_SCENES_ERROR, DELETED_SCENES_SUCCESS } from "../messages";

interface DeleteScenesButtonProps {
  project: Project;
  disabled?: boolean;
  selectedScenes: Scene[];
  onDelete: () => void;
}

export const DeleteScenesButton = (props: DeleteScenesButtonProps) => {
  const { project, disabled, selectedScenes, onDelete } = props;
  const { customer, project: proj, scope } = project;

  const [showModal, setShowModal] = useState(false);

  const notify = useNotification();
  const [deleteScenes] = useDeleteScenesMutation({
    onCompleted: () => {
      notify(DELETED_SCENES_SUCCESS, "success");
    },
    onError: (error) => notify(`${DELETED_SCENES_ERROR} - ${error}`, "error"),
    update: (cache: any, { data }) => {
      if (data?.deleteScenes) {
        data?.deleteScenes.forEach((deletedId) =>
          cache.evict({ id: `Scene:${deletedId}` }),
        );
        cache.evict({ fieldName: "scenes" });
        cache.gc();
      }
    },
  });

  const handleSubmit = useCallback(() => {
    setShowModal(false);
    onDelete();
    deleteScenes({
      variables: {
        customer: customer,
        project: proj,
        scope: scope,
        sceneIds: selectedScenes.map((scene) => scene.id),
      },
    });
  }, [onDelete, deleteScenes, customer, proj, scope, selectedScenes]);

  return (
    <div>
      <Button
        icon={<DeleteOutlined />}
        disabled={disabled}
        onClick={() => setShowModal(true)}
        title="Delete Scenes"
      ></Button>
      <Modal
        visible={showModal}
        title="Delete Scenes?"
        onOk={handleSubmit}
        onCancel={() => setShowModal(false)}
        okText="Delete"
      >
        <Alert
          type="warning"
          showIcon
          message={`Are you sure you want to delete ${selectedScenes.length} ${
            selectedScenes.length === 1 ? "scene" : "scenes"
          }?`}
        />
      </Modal>
    </div>
  );
};
