import { FilterFilled } from "@ant-design/icons";
import { Input, Space } from "antd";
import React from "react";
import { TableName } from "../../components/ProgressTables/types";
import { MappingStateSelector } from "../../components/Selectors/MappingStateSelector/MappingStateSelector";
import { Filter } from "./types";

type Props = {
  onChange: (filter: Filter) => any;
  placeholder: string;
  tableName: TableName;
};

export const ComponentFilter = (props: Props) => (
  <Space>
    <FilterFilled />
    <Input
      placeholder={props.placeholder}
      onChange={(e) => props.onChange({ name: e.target.value })}
    />
    {props.tableName === "mappings" && (
      <MappingStateSelector
        onChange={(v) => props.onChange({ mappingState: v })}
      />
    )}
  </Space>
);
