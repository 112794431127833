import { CodepenOutlined, PictureOutlined } from "@ant-design/icons";
import { Button, Tooltip } from "antd";
import React from "react";
import styles from "./BimViewer.module.css";
import {
  BimState,
  BimStateAction,
  LocationState,
  ModelState,
  SwitchState,
} from "./useBimState";

type BimViewerSwitchProps = {
  onChangeBimState: (newState: BimStateAction) => void;
  showBimModel: boolean;
  bimState: BimState;
};

export const BimViewerSwitch: React.FC<BimViewerSwitchProps> = ({
  onChangeBimState,
  showBimModel,
  bimState,
}: BimViewerSwitchProps) => {
  const icon = showBimModel ? <PictureOutlined /> : <CodepenOutlined />;
  return (
    <div className={styles["bim-viewer-switch"]}>
      <Tooltip title={bimTooltipTextFromState(bimState)}>
        <Button
          onClick={() =>
            showBimModel
              ? onChangeBimState(BimStateAction.HIDE_MODEL)
              : onChangeBimState(BimStateAction.SHOW_MODEL)
          }
          icon={icon}
          disabled={bimState.switch === SwitchState.INACTIVE}
        />
      </Tooltip>
    </div>
  );
};

const bimTooltipTextFromState = (bimState: BimState) =>
  bimState.model !== ModelState.LOADED
    ? bimState.model
    : bimState.location !== LocationState.LOADED
    ? bimState.location
    : bimState.switch;
