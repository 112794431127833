import * as H from "history";
import _ from "lodash";
import React from "react";
import { unstable_batchedUpdates as batchUpdates } from "react-dom";
import { matchPath, useHistory, useLocation } from "react-router";
import { Scope } from "../generated/types";
import { Project } from "../types";
import { bindActionCreators } from "../utils/react-utils";
import apps, { App } from "./apps";

interface State {
  app: App | null;
  project: Project | null;
}

interface SetAppAction {
  type: "SET_APP";
  payload: App | null;
}

const setApp = (app: App | null): SetAppAction => ({
  type: "SET_APP",
  payload: app,
});

interface SetProjectAction {
  type: "SET_PROJECT";
  payload: Project | null;
}

const setProject = (project: Project | null): SetProjectAction => ({
  type: "SET_PROJECT",
  payload: project,
});

type Action = SetAppAction | SetProjectAction;

interface Actions {
  setApp: (app: App | null) => void;
  setProject: (project: Project | null) => void;
}

export interface AppContextValue extends State, Actions {}

const reducer = (state: State, action: Action): State => {
  switch (action.type) {
    case "SET_APP":
      if (action.payload == null) {
        return { ...state, app: action.payload };
      }
      if (apps[action.payload].selectProject) {
        return { ...state, app: action.payload };
      } else {
        return { ...state, app: action.payload, project: null };
      }
    case "SET_PROJECT":
      return { ...state, project: action.payload };
    default:
      return state;
  }
};

const constructRoute = (app: App | null, project: Project | null) => {
  if (app == null) {
    return "/";
  }
  const config = apps[app];
  if (config.selectProject && project != null) {
    return `${config.route}/${project.customer}/${project.project}`;
  } else {
    return config.route;
  }
};

const AppContext = React.createContext<AppContextValue | undefined>(undefined);

const parseRoute = (
  route,
): { app: App; project: Project | null } | undefined => {
  const match = matchPath<{ app: string; customer?: string; project?: string }>(
    route,
    {
      path: "/:app/:customer?/:project?",
    },
  );
  if (match == null) {
    return undefined;
  }

  const { app, customer, project } = match.params;

  const matchedApp = (Object.keys(apps) as App[]).find(
    (a) => apps[a].route === `/${app}`,
  );
  if (matchedApp == null) {
    return undefined;
  }
  return {
    app: matchedApp,
    project:
      customer != null && project != null
        ? { customer, project, scope: Scope.Internal }
        : null,
  };
};

const stateInitialiser = ({
  history,
  location,
}: {
  history: H.History<any>;
  location: H.Location<any>;
}): State => {
  const route = parseRoute(location.pathname);

  if (route == null) {
    history.push({ pathname: constructRoute(null, null) });
    return { app: null, project: null };
  }

  return { app: route.app, project: route.project ?? null };
};

export const Provider = (props: React.PropsWithChildren<any>) => {
  const location = useLocation();

  const history = useHistory();

  const [state, dispatch] = React.useReducer<
    React.Reducer<State, Action>,
    { history: H.History<any>; location: H.Location<any> }
  >(reducer, { history, location }, stateInitialiser);

  React.useEffect(() => {
    history.push({
      pathname: constructRoute(state.app, state.project),
    });
    const unsubscribe = history.listen((newLocation) => {
      const route = parseRoute(newLocation.pathname);

      if (route != null) {
        batchUpdates(() => {
          if (route.app !== state.app) {
            dispatch(setApp(route.app));
          }
          if (_.isEqual(route.project, state.project)) {
            dispatch(setProject(route.project));
          }
        });
      }
    });
    return () => {
      unsubscribe();
    };
  }, [history, state.app, state.project]);

  const actions = React.useMemo(
    () =>
      bindActionCreators(
        {
          setApp,
          setProject,
        },
        dispatch,
      ),
    [dispatch],
  );

  const context = React.useMemo(
    () => ({
      ...state,
      ...actions,
    }),
    [state, actions],
  );

  return (
    <AppContext.Provider value={context}>{props.children}</AppContext.Provider>
  );
};

export const useAppState = () => {
  const contextValue = React.useContext(AppContext);
  if (contextValue == null) {
    throw new Error("Cannot useAppState outside of a Provider");
  }
  return contextValue;
};

export const useProject = () => {
  const state = useAppState();
  if (state.project == null) {
    throw new Error("Cannot call useProject: No project selected.");
  }
  return state.project;
};
