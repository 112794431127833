import React from "react";
import { Link } from "../Link";
import { DisplayTreeDivider } from "./DisplayTreeDivider";

type DisplayTreeCheckedInfoProps = {
  numberCheckedKeys: number;
  expandCheckedCallback: () => void;
};

export const DisplayTreeCheckedInfo = (props: DisplayTreeCheckedInfoProps) => {
  const { numberCheckedKeys, expandCheckedCallback } = props;

  return (
    <>
      <span>
        <Link onClick={expandCheckedCallback}>Expand Selected Nodes</Link>
      </span>
      <DisplayTreeDivider />
      <span>
        <b>{numberCheckedKeys}</b> checked
      </span>
    </>
  );
};
