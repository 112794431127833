import { Button, Tooltip } from "antd";
import _ from "lodash";
import React, { useState } from "react";
import { useNotification } from "../../contexts/Notifications";
import {
  Component,
  SpaceWithMappingDetailsFragment,
  useUpdateMappingsMutation,
} from "../../generated/types";
import { Project } from "../../types";
import { generateFolderName } from "../SpacesTree/tree-nodes";
import { ModalConfirmationMappingAction } from "./ModalConfirmation";
import { getMappings } from "./mappings-utility";
import {
  DISABLED_MAPPINGS_ERROR,
  DISABLED_MAPPINGS_SUCCESS,
  ENABLED_MAPPINGS_ERROR,
  ENABLED_MAPPINGS_SUCCESS,
} from "./messages";

type EnableDisableMappingsButtonProps = {
  project: Project;
  selectedSpaces: SpaceWithMappingDetailsFragment[];
  selectedComponents: Pick<Component, "id" | "name" | "guiIndex">[];
  updateAction: "enable" | "disable";
  onUpdate: (keys: string[]) => void;
};

const currentStateDiffersFromTheTarget = (
  mapping,
  targetDisableState = "disable",
) => mapping.disabled === !(targetDisableState === "disable");

export const EnableDisableMappingsButton = (
  props: EnableDisableMappingsButtonProps,
) => {
  const {
    project,
    selectedSpaces,
    selectedComponents,
    updateAction,
    onUpdate,
  } = props;

  const [visible, setVisible] = useState(false);
  const isUpdateActionDisable = updateAction === "disable";
  const selectedMappings = getMappings(selectedSpaces, selectedComponents);
  const mappingsToBeAlteredByChange = selectedMappings.filter((mapping) =>
    currentStateDiffersFromTheTarget(mapping, updateAction),
  );

  const spacesWithoutAffectedMappings = _.differenceBy(
    selectedSpaces,
    mappingsToBeAlteredByChange.map((m) => m.space),
    "id",
  );

  const notify = useNotification();
  const [updateMappingsMutation] = useUpdateMappingsMutation({
    onCompleted: () => {
      const nodesToUpdate = selectedSpaces.map((s) =>
        generateFolderName(s.id, "mappings"),
      );
      onUpdate(nodesToUpdate);
      notify(
        isUpdateActionDisable
          ? DISABLED_MAPPINGS_SUCCESS
          : ENABLED_MAPPINGS_SUCCESS,
        "success",
      );
    },
    onError: (error) =>
      notify(
        `${
          isUpdateActionDisable
            ? DISABLED_MAPPINGS_ERROR
            : ENABLED_MAPPINGS_ERROR
        } = ${error}`,
        "error",
      ),
  });

  const onApply = async () => {
    if (mappingsToBeAlteredByChange.length === 0) {
      throw new Error(
        "Logical error: this button shouldn't be enabled if no disabled mappings are selected.",
      );
    }
    setVisible(false);
    await updateMappingsMutation({
      variables: {
        ...project,
        mappingUpdates: mappingsToBeAlteredByChange.map((m) => ({
          id: m.id,
          disabled: isUpdateActionDisable,
        })),
      },
    });
  };

  const onCancel = () => setVisible(false);

  const enableButton = (
    <Button
      block
      value={updateAction}
      disabled={mappingsToBeAlteredByChange.length === 0}
      onClick={() => setVisible(true)}
    >
      {updateAction}
    </Button>
  );
  return (
    <div>
      {selectedSpaces.length > 0 &&
      selectedComponents.length > 0 &&
      mappingsToBeAlteredByChange.length === 0 ? (
        <Tooltip
          title={`${
            selectedMappings.length === 0
              ? "No mappings"
              : isUpdateActionDisable
              ? "No enabled mappings"
              : "No disabled mappings"
          } for the selected components exist in the selected spaces.`}
        >
          {enableButton}
        </Tooltip>
      ) : (
        enableButton
      )}
      <ModalConfirmationMappingAction
        visible={visible}
        onApllyCallback={onApply}
        onCancelCallback={onCancel}
        mappingAction={updateAction}
        affectedComponentNames={_.uniq(
          mappingsToBeAlteredByChange.map((m) => m.component.name),
        )}
        affectedSpaces={_.uniqBy(
          mappingsToBeAlteredByChange.map((m) => m.space),
          "id",
        )}
        notAffectedSpaces={spacesWithoutAffectedMappings.map((s) => ({
          ancestors: s.ancestors,
          guiIndex: s.guiIndex,
          name: s.name,
        }))}
      />
    </div>
  );
};
