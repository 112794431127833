import {
  BuildOutlined,
  CloudDownloadOutlined,
  DeliveredProcedureOutlined,
  EditFilled,
  EyeFilled,
  SettingFilled,
  ToolFilled,
  UnorderedListOutlined,
} from "@ant-design/icons";
import React from "react";
import { ConfigurationPage } from "../pages/ConfigurationModePage";
import { DownloadsPage } from "../pages/DownloadsPage";
import { DualModePage } from "../pages/DualModePage";
import { EditorPage } from "../pages/EditorPage/EditorPage";
import { PlansModePage } from "../pages/PlansModePage";
import { ProjectOverviewPage } from "../pages/ProjectOverviewPage";
import { ReleasePage } from "../pages/ReleasePage";
import { SpatialConfigPage } from "../pages/SpatialConfigModePage";

export type App =
  | "configuration"
  | "spatialConfig"
  | "dual"
  | "plans"
  | "editor"
  | "downloads"
  | "release"
  | "projects";

export interface AppConfig {
  name: string;
  route: `/${string}`;
  selectProject: boolean;
  Page: React.ComponentType<Partial<Record<string, any>>>;
  icon: React.ComponentType<Partial<Record<string, any>>>;
  hidden?: boolean;
}

const apps: Record<App, AppConfig> = {
  configuration: {
    name: "Configuration",
    route: "/configuration",
    Page: ConfigurationPage,
    selectProject: true,
    icon: SettingFilled,
  },
  editor: {
    name: "Editor",
    route: "/editor",
    Page: EditorPage,
    selectProject: true,
    icon: EditFilled,
  },
  plans: {
    name: "Plans Editor",
    route: "/plans",
    Page: PlansModePage,
    selectProject: true,
    icon: ToolFilled,
  },
  dual: {
    name: "Dual Viewer",
    route: "/dual-mode",
    Page: DualModePage,
    selectProject: true,
    icon: EyeFilled,
  },
  spatialConfig: {
    name: "Spatial Configuration",
    route: "/spatial-config",
    Page: SpatialConfigPage,
    selectProject: true,
    icon: BuildOutlined,
  },
  downloads: {
    name: "Downloads",
    route: "/downloads",
    Page: DownloadsPage,
    selectProject: true,
    icon: CloudDownloadOutlined,
  },
  release: {
    name: "Release",
    route: "/release",
    Page: ReleasePage,
    selectProject: true,
    icon: DeliveredProcedureOutlined,
  },
  projects: {
    name: "Projects Overview",
    route: "/projects",
    Page: ProjectOverviewPage,
    selectProject: false,
    icon: UnorderedListOutlined,
  },
};

export default apps;
