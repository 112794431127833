import { Button, Card } from "antd";
import { format } from "date-fns";
import React from "react";
import xlsx from "xlsx";
import { useNotification } from "../../contexts/Notifications";
import { RawSchedule, useGetDatesQaDataLazyQuery } from "../../generated/types";
import { Project } from "../../types";

interface DatesQADumpGeneratorProps {
  project: Project;
}

const toFilename = (project: Project) => {
  // use a custom date format instead of ISO to avoid dots in the filename
  const dateString = format(new Date(), "yyyyMMdd_HHmmss");
  return `${project.customer}-${project.project}-${dateString}-qa-dates-dump.xlsx`;
};

export const generateWorkbook = (rawSchedules: RawSchedule[]) => {
  const workbook = xlsx.utils.book_new();
  const sheetName = "Dates";
  const data = rawSchedules.map((s) => [
    s.taskId,
    s.weight,
    s.startDate ? format(new Date(s.startDate), "dd/MM/yyyy") : "",
    s.endDate ? format(new Date(s.endDate), "dd/MM/yyyy") : "",
    s.spaceId,
  ]);
  const worksheet = xlsx.utils.aoa_to_sheet([
    ["task_id", "weight", "startdate", "enddate", "space_id"],
    ...data,
  ]);
  xlsx.utils.book_append_sheet(workbook, worksheet, sheetName);
  return workbook;
};

export const DatesQADumpGenerator = (props: DatesQADumpGeneratorProps) => {
  const notify = useNotification();
  const [generateDumpQuery] = useGetDatesQaDataLazyQuery({
    onError: (error) =>
      notify(
        `Failed to generate dump sheet for scene-objects mapping: ${error}`,
        "error",
      ),
    onCompleted: (data) => {
      try {
        const workbook = generateWorkbook(data.generateDatesQAData);
        xlsx.writeFile(workbook, toFilename(props.project));
      } catch (error) {
        notify(`Failed to generate dates xlsx: ${error}`, "error");
      }
    },
    fetchPolicy: "no-cache", // let the User decide when data has changed
  });

  const onApply = async () =>
    generateDumpQuery({
      variables: {
        customer: props.project.customer,
        project: props.project.project,
        scope: props.project.scope,
      },
    });

  return (
    <Card>
      <Button value="generate" onClick={onApply}>
        Download Raw Dates
      </Button>
    </Card>
  );
};
