import _ from "lodash";
import { JiraIssue, JiraIssueType } from "models";
import React from "react";
import { useProject } from "../../App/state";
import { useAuth } from "../../contexts/Auth";
import { useNotification } from "../../contexts/Notifications";
import {
  GetJiraIssuesForShotDocument,
  useCreateJiraIssueMutation,
  useDeleteJiraIssueMutation,
  useGetAncestorsForSceneQuery,
  useGetJiraIssuesForShotQuery,
} from "../../generated/types";
import { Shot } from "../DualView/types";
import { IssueButton } from "./IssueButton";
import { MessageModal } from "./MessageModal";
import { RevertModal } from "./RevertModal";
import styles from "./styles.module.css";
import { IssueTypeConfig } from "./types";

const BUTTONS: Record<JiraIssueType, IssueTypeConfig> = {
  [JiraIssueType.Map]: {
    issueType: JiraIssueType.Map,
    label: "Mapping",
    optionalMessage: true,
  },
  [JiraIssueType.Arrow]: {
    issueType: JiraIssueType.Arrow,
    label: "Arrowing",
    optionalMessage: true,
  },
  [JiraIssueType.Level]: { issueType: JiraIssueType.Level, label: "Levelling" },
  [JiraIssueType.FaceBlur]: {
    issueType: JiraIssueType.FaceBlur,
    label: "Face Blurring",
  },
  [JiraIssueType.ChangeDetection]: {
    issueType: JiraIssueType.ChangeDetection,
    label: "Change Detection",
  },
};

interface IssueButtonsProps {
  shot: Shot;
  arrowSceneNavigation: (evt: KeyboardEvent) => void;
}

export const IssueButtons: React.FC<IssueButtonsProps> = (
  props: IssueButtonsProps,
) => {
  const auth = useAuth();
  const tenant = useProject();
  const notify = useNotification();

  const arrowSceneNavigation = props.arrowSceneNavigation;

  const shot = props.shot;
  const uniqueImageId = shot.imageConfig?.uniqueImageId;
  const weekNumber = String(shot.batch.weekNumber);
  const sceneId = shot.scene?.id;
  const username =
    auth.status === "authenticated" ? auth.auth.username : undefined;

  const [isMessageDialogOpen, setIsMessageDialogOpen] = React.useState(false);
  const [isRevertDialogOpen, setIsRevertDialogOpen] = React.useState(false);
  const [selectedIssueType, setSelectedIssueType] =
    React.useState<JiraIssueType>();

  const { data: sceneData } = useGetAncestorsForSceneQuery({
    variables: { ...tenant, sceneId, includeSelfSpace: true },
  });
  const sceneAncestors = [
    ..._.map(sceneData?.scene?.space.ancestors ?? [], "name"),
    sceneData?.scene?.name ?? "",
  ].join(" / ");

  const { data: shotIssuesData } = useGetJiraIssuesForShotQuery({
    variables: {
      tenant,
      uniqueShotId: uniqueImageId as string,
    },
    skip: uniqueImageId === undefined,
  });
  const shotIssues: Partial<Record<JiraIssueType, JiraIssue>> = React.useMemo(
    () => _.keyBy(shotIssuesData?.jiraIssuesForShot ?? [], "type"),
    [shotIssuesData],
  );
  const selectedShotIssue =
    selectedIssueType !== undefined ? shotIssues[selectedIssueType] : undefined;
  const selectedIssueConfig =
    selectedIssueType !== undefined ? BUTTONS[selectedIssueType] : undefined;

  const [createJiraIssue, { loading: loadingCreation }] =
    useCreateJiraIssueMutation({
      onCompleted: (data) =>
        notify(`Created new ${data.createJiraIssue.type} issue!`, "success"),
      onError: (err) => notify(`Failed to create issue! ${err}`, "error"),
      refetchQueries: [GetJiraIssuesForShotDocument],
    });
  const createIssue = React.useCallback(
    async (issueType: JiraIssueType, message?: string) => {
      if (uniqueImageId !== undefined && username !== undefined) {
        createJiraIssue({
          variables: {
            tenant,
            issue: {
              uniqueShotId: uniqueImageId,
              type: issueType,
              week: weekNumber,
              path: sceneAncestors,
              username,
              message,
            },
          },
        });
      }
    },
    [
      createJiraIssue,
      username,
      tenant,
      uniqueImageId,
      sceneAncestors,
      weekNumber,
    ],
  );

  const [deleteJiraIssue, { loading: loadingDeletion }] =
    useDeleteJiraIssueMutation({
      onCompleted: () => notify(`Deleted issue!`, "success"),
      onError: (err) => notify(`Failed to delete issue! ${err}`, "error"),
      refetchQueries: [GetJiraIssuesForShotDocument],
    });
  const deleteIssue = React.useCallback(
    async (issueId: string) =>
      deleteJiraIssue({ variables: { tenant, issueId } }),
    [deleteJiraIssue, tenant],
  );

  const onClickButton = React.useCallback(
    async (issueType: JiraIssueType) => {
      setSelectedIssueType(issueType);
      const hasExistingIssue = issueType in shotIssues;
      const incOptionalMessage = BUTTONS[issueType]?.optionalMessage ?? false;
      if (hasExistingIssue) {
        setIsRevertDialogOpen(true);
      } else if (incOptionalMessage) {
        setIsMessageDialogOpen(true);
      } else {
        await createIssue(issueType);
        setSelectedIssueType(undefined);
      }
    },
    [shotIssues, setIsMessageDialogOpen, setIsRevertDialogOpen, createIssue],
  );

  const onClickConfirmMessage = React.useCallback(
    async (message?: string) => {
      if (selectedIssueType !== undefined) {
        await createIssue(selectedIssueType, message);
        setIsMessageDialogOpen(false);
        setSelectedIssueType(undefined);
      }
    },
    [selectedIssueType, setSelectedIssueType, createIssue],
  );
  const onClickCancelMessage = React.useCallback(() => {
    setIsMessageDialogOpen(false);
    setSelectedIssueType(undefined);
  }, [setIsMessageDialogOpen, setSelectedIssueType]);

  const onClickConfirmRevert = React.useCallback(async () => {
    if (selectedShotIssue !== undefined) {
      await deleteIssue(String(selectedShotIssue.id));
      setIsRevertDialogOpen(false);
      setSelectedIssueType(undefined);
    }
  }, [selectedShotIssue, deleteIssue, setSelectedIssueType]);
  const onClickCancelRevert = React.useCallback(() => {
    setIsRevertDialogOpen(false);
    setSelectedIssueType(undefined);
  }, [setIsRevertDialogOpen, setSelectedIssueType]);

  const loading = loadingCreation || loadingDeletion;

  return (
    <div className={styles["issue-button-group-container"]}>
      {Object.values(BUTTONS).map((issue) => (
        <IssueButton
          key={issue.issueType}
          issue={issue}
          highlighted={issue.issueType in shotIssues}
          disabled={uniqueImageId === undefined}
          loading={loading && issue.issueType === selectedIssueType}
          onClickButton={onClickButton}
        />
      ))}
      <MessageModal
        isOpen={selectedIssueType !== undefined && isMessageDialogOpen}
        loading={loadingCreation}
        onClickConfirm={onClickConfirmMessage}
        onClickCancel={onClickCancelMessage}
        issueConfig={selectedIssueConfig}
        message={selectedShotIssue?.message ?? undefined}
        arrowSceneNavigation={arrowSceneNavigation}
      />
      <RevertModal
        isOpen={selectedIssueType !== undefined && isRevertDialogOpen}
        loading={loadingDeletion}
        onClickConfirm={onClickConfirmRevert}
        onClickCancel={onClickCancelRevert}
        issueConfig={selectedIssueConfig}
        message={selectedShotIssue?.message ?? undefined}
      />
    </div>
  );
};
