import { Spin } from "antd";
import _ from "lodash";
import React from "react";
import { useNotification } from "../../contexts/Notifications";
import {
  ComponentFragmentDoc,
  useGetComponentsByTaskQuery,
  useUpdateComponentsMutation,
} from "../../generated/types";
import { Component, Project } from "../../types";
import { ComponentsTable } from "./ComponentsTable";
import { UPDATE_COMPONENTS_ERROR, UPDATE_COMPONENTS_SUCCESS } from "./messages";
import { getComponentFromGraphQlResponse } from "./types";

type ContainerProps = {
  project: Project;
  taskId: string;
  planId: string;
};

export const ComponentsTableContainer = ({
  project,
  taskId,
  planId,
}: ContainerProps) => {
  const notify = useNotification();
  const [updateComponents] = useUpdateComponentsMutation({
    onCompleted: () => notify(UPDATE_COMPONENTS_SUCCESS, "success"),
    onError: (err) => notify(`${UPDATE_COMPONENTS_ERROR}: ${err}`, "error"),
    update: (cache, { data }) => {
      cache.modify({
        fields: {
          components() {
            data?.updateComponents
              ? data?.updateComponents.map((newComponent) =>
                  cache.writeFragment({
                    id: `Component:${newComponent.id}`,
                    data: newComponent,
                    fragment: ComponentFragmentDoc,
                  }),
                )
              : [];
          },
        },
      });
    },
  });

  const {
    data,
    loading,
    error,
    refetch: refetchComponents,
  } = useGetComponentsByTaskQuery({
    variables: {
      ...project,
      taskId: taskId,
    },
  });

  const components = data?.components
    ? _.orderBy(data?.components, "guiIndex")
    : [];

  if (loading) {
    return <Spin />;
  }

  if (error) {
    return <div> Error fetching components </div>;
  }

  return (
    <ComponentsTable
      project={project}
      components={components.map((c) => getComponentFromGraphQlResponse(c))}
      taskId={taskId}
      planId={planId}
      onComponentsUpdate={refetchComponents}
      draggingRowsEnabled
      updateComponents={async (componentsForUpdate: Component[]) => {
        await updateComponents({
          variables: {
            customer: project.customer,
            project: project.project,
            scope: project.scope,
            components: componentsForUpdate.map((c) => ({
              id: c.id,
              taskId: taskId,
              name: c.name,
              componentTypeId: c.type.id,
              subcontractorId: c.subcontractor?.id,
              weight: c.weight,
              guiIndex: c.guiIndex,
              object: c.object,
              tags: c.tags ?? [],
            })),
          },
        });
      }}
    />
  );
};
