import { Button } from "antd";
import classNames from "classnames";
import React, { useCallback } from "react";
import { DeleteConfirmationModal } from "../../DeleteConfirmationModal/DeleteConfirmationModal";
import { DisplayTreeNodeType } from "../../DisplayTree/types";
import styles from "./OptionsPanel.module.css";

interface OptionsPanelDeleteFormProps {
  onDelete: (id: string[]) => Promise<void>;
  onDeleteButtonClick: () => void;
  ids: string[];
  onClose: () => void;
  modalVisible: boolean;
  nodeType: DisplayTreeNodeType;
}

const DELETE_BUTTON = "Delete";
const DELETION_TEXT = "DELETE";

export const OptionsPanelDeleteForm = (props: OptionsPanelDeleteFormProps) => {
  const {
    onDeleteButtonClick,
    onDelete,
    ids,
    modalVisible,
    onClose,
    nodeType,
  } = props;

  const DELETE_TITLE = `Delete ${nodeType}`;
  const DELETE_BODY = `Deleting an existing ${nodeType}`;
  const DELETE_ALERT = `Deleting the ${nodeType} will remove all associated`;

  const deleteNodeCallback = useCallback(
    async (deleteIds: string[]) => {
      await onDelete(deleteIds);
      onClose();
    },
    [onDelete, onClose],
  );

  return (
    <div className={styles["action-button-row"]}>
      <div>{DELETE_TITLE}</div>
      <Button
        className={classNames([styles["action-button"], styles["delete"]])}
        onClick={onDeleteButtonClick}
      >
        {DELETE_BUTTON}
      </Button>
      {modalVisible && (
        <DeleteConfirmationModal
          title={DELETE_TITLE}
          visible={true}
          setVisible={onClose}
          onOk={() => deleteNodeCallback(ids)}
          deletionText={DELETION_TEXT}
          modalBody={DELETE_BODY}
          alertText={DELETE_ALERT}
        />
      )}
    </div>
  );
};
