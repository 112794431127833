// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/cssWithMappingToString.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".styles-module__select-app-prompt--2wyED{width:100%;display:flex;justify-content:center;align-items:center}.styles-module__select-app-prompt--2wyED .ant-image{width:256px;height:256px;margin:auto;display:block}.styles-module__select-app-prompt--2wyED .ant-image img{width:256px;display:inline-block;margin:4px}", "",{"version":3,"sources":["webpack://./src/pages/HomePage/styles.module.css"],"names":[],"mappings":"AAGA,yCACE,UAAA,CAEA,YAAA,CACA,sBAAA,CACA,kBAAA,CAEA,oDACE,WAAA,CACA,YAAA,CAEA,WAAA,CACA,aAAA,CAEA,wDACE,WAAA,CACA,oBAAA,CACA,UAAA","sourcesContent":["@value logo-size: 256px;\n@value logo-margin: 4px;\n\n.select-app-prompt {\n  width: 100%;\n\n  display: flex;\n  justify-content: center;\n  align-items: center;\n\n  :global(.ant-image) {\n    width: logo-size;\n    height: logo-size;\n\n    margin: auto;\n    display: block;\n\n    img {\n      width: logo-size;\n      display: inline-block;\n      margin: logo-margin;\n    }\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"logo-size": "256px",
	"logo-margin": "4px",
	"select-app-prompt": "styles-module__select-app-prompt--2wyED"
};
export default ___CSS_LOADER_EXPORT___;
