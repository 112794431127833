import { Input, InputNumber } from "antd";
import classNames from "classnames";
import _ from "lodash";
import React, { useEffect, useState } from "react";
import { ComponentTypeSelectorContainer } from "../Selectors/ComponentTypeSelector/ComponentTypeSelectorContainer";
import { SubcontractorSelectorContainer } from "../Selectors/SubcontractorSelector/SubcontractorSelectorContainer";
import { ComponentTagSelectorContainer } from "./ComponentTagSelector/ComponentTagSelectorContainer";
import styles from "./ComponentViewer.module.css";
import { ObjectSelectorContainer } from "./ObjectSelector/ObjectSelectorContainer";
import { ComponentRecord, UpdateCell } from "./types";

export type ComponentsTableCellProps = React.PropsWithChildren<{
  record: ComponentRecord;
  updatedCell: UpdateCell;
  editing: boolean;
  onCellEdit: (
    componentId: string,
    columnName: string,
    value: string | number | string[],
  ) => void;
}>;

export const ComponentsTableCell = (props: ComponentsTableCellProps) => {
  const [editing, setEditing] = useState(false);
  const [isModified, setIsModified] = useState(false);

  // ComponentsTableCellProps.editing changes when updating is cancelled.
  useEffect(() => {
    if (!props.editing) {
      setEditing(false);
      setIsModified(false);
    }
  }, [props.editing]);

  const displayCellInput = () => {
    switch (props.updatedCell.type) {
      case "name":
        return (
          <div className={classNames(styles["name-input-container"])}>
            <Input
              value={props.updatedCell.value}
              className={classNames({ [styles.cell__edited]: isModified })}
              onChange={(event) => {
                props.onCellEdit(
                  props.record.key,
                  props.updatedCell.type,
                  event.target.value,
                );
                setIsModified(event.target.value !== props.record.name);
              }}
              onBlur={(event) => {
                if (editing && event.target.value === props.record.name) {
                  setEditing(false);
                }
              }}
            />
          </div>
        );
      case "typeId":
        return (
          <ComponentTypeSelectorContainer
            className={classNames({ [styles.cell__edited]: isModified })}
            selectedTypeId={props.updatedCell.value}
            onChange={(value: string[]) => {
              props.onCellEdit(
                props.record.key,
                props.updatedCell.type,
                value[0],
              );
              setIsModified(value[0] !== props.record.type?.id);
            }}
          />
        );

      case "subcontractorId":
        return (
          <SubcontractorSelectorContainer
            className={classNames({ [styles.cell__edited]: isModified })}
            selectedSubcontractorId={props.updatedCell.value}
            onChange={(value: string[]) => {
              props.onCellEdit(
                props.record.key,
                props.updatedCell.type,
                value[0],
              );
              setIsModified(value[0] !== props.record.subcontractor?.id);
            }}
          />
        );
      case "weight":
        return (
          <InputNumber
            className={classNames({ [styles.cell__edited]: isModified })}
            value={props.updatedCell.value}
            min={0}
            max={100}
            onChange={(value) => {
              props.onCellEdit(props.record.key, props.updatedCell.type, value);
              setIsModified(value !== props.record.weight);
            }}
            onBlur={(event) => {
              if (
                editing &&
                parseInt(event.target.value, 10) === props.record.weight
              ) {
                setEditing(false);
              }
            }}
          />
        );
      case "object":
        return (
          <ObjectSelectorContainer
            className={classNames({ [styles.cell__edited]: isModified })}
            selectedObject={props.updatedCell.value}
            onChange={(value: string[]) => {
              props.onCellEdit(
                props.record.key,
                props.updatedCell.type,
                value[0],
              );
              setIsModified(value[0] !== props.record.object);
            }}
          />
        );
      case "tags":
        return (
          <ComponentTagSelectorContainer
            className={classNames({ [styles.cell__edited]: isModified })}
            selectedTags={props.updatedCell.value}
            onChange={(values: string[]) => {
              props.onCellEdit(
                props.record.key,
                props.updatedCell.type,
                values,
              );
              setIsModified(
                !_.isEqual(_.sortBy(values), _.sortBy(props.record.tags)),
              );
            }}
          />
        );
    }
  };

  return (
    <div
      onClick={() => {
        setEditing(true);
      }}
      className={styles["cell__editing"]}
    >
      {displayCellInput()}
    </div>
  );
};
