// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/cssWithMappingToString.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".CopySpaceModal-module__spaces-tree-box--2cSJI{overflow-y:auto;height:95%}.CopySpaceModal-module__space-name-box--1P3nH{margin-bottom:10px;font-weight:500}", "",{"version":3,"sources":["webpack://./src/components/CopySpaceEditor/CopySpaceModal.module.css"],"names":[],"mappings":"AAAA,+CACI,eAAA,CACA,UAAA,CAGJ,8CACI,kBAAA,CACA,eAAA","sourcesContent":[".spaces-tree-box {\n    overflow-y: auto;\n    height: 95%;\n}\n\n.space-name-box {\n    margin-bottom: 10px;\n    font-weight: 500;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"spaces-tree-box": "CopySpaceModal-module__spaces-tree-box--2cSJI",
	"space-name-box": "CopySpaceModal-module__space-name-box--1P3nH"
};
export default ___CSS_LOADER_EXPORT___;
