import { SpotlightType } from "models";
import {
  ArrowFragmentFragment,
  SpotlightFragmentFragment,
} from "../../../generated/types";

export type Arrow = {
  id: string;
  yaw: number;
  pitch: number;
  name: string;
  targetSceneId?: string;
  missing: boolean;
};

export type Mapping = {
  id: string;
  componentName: string;
  subcontractor?: string;
  spaceName: string;
};

export type Spotlight = {
  id: string;
  yaw: number;
  pitch: number;
  type: SpotlightType;
  subcontractor?: string;
  mapping: Mapping | null;
  createdInBatchId: string;
  resolvedInBatchId?: string;
};

export const spotlightFromGraphQlSpotlight = (
  spotlight: SpotlightFragmentFragment,
): Spotlight => ({
  id: spotlight.id,
  yaw: spotlight.location.yaw,
  pitch: spotlight.location.pitch,
  type: spotlight.type,
  mapping: spotlight.mapping
    ? {
        id: spotlight.mapping.id,
        componentName: spotlight.mapping.component.name,
        subcontractor: spotlight.mapping.component.subcontractor?.name,
        spaceName: spotlight.mapping.space.name,
      }
    : null,
  createdInBatchId: spotlight.createdInBatch.id,
  resolvedInBatchId: spotlight.resolvedInBatch?.id,
});

const getArrowName = (
  targetScene: ArrowFragmentFragment["targetScene"],
): string => {
  if (!targetScene) {
    return "missing target";
  }
  return `${targetScene.space.name}: ${targetScene.name}`;
};

export const arrowFromGraphQLArrow = (arrow: ArrowFragmentFragment): Arrow => ({
  id: arrow.id,
  yaw: arrow.location.yaw,
  pitch: arrow.location.pitch,
  name: getArrowName(arrow.targetScene),
  targetSceneId: arrow.targetScene?.id,
  missing: arrow.missing,
});
